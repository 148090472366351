import { useEffect, useRef, useState } from "react"
import { BarConfig, BarConfigLeft, BarConfigRight, BoxThanks, Container, Contents, CopyButton, CouponBox, CouponCode, FinishButton, FooterContainer, FormContainer, FormGroup, HiddenInput, Input, InputContainer, ItemMedias, ItemNetwork, Label, MediaButton, NavButton, NavInfor, NetworkIcon, Preview, ProgressBar, ProgressIndicator, Rating, RatingText, Screen, SelectScreen, Star, Stars, SubmitButton, TermsText, TextArea, ThankDescription, ThankTitle, Title } from "./styled"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Controller } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { FaInstagram, FaRegCopy, FaTiktok, FaYoutube } from "react-icons/fa"
import { useSelector } from "react-redux"
import { Users2Icon } from "lucide-react"
import { ThemeProvider } from "styled-components"

const PreviewDimona = (props) => {
    const [isMobile, setIsMobile] = useState(false)
    const configControl = useSelector(state => state.reducerControlConfig)
    //const theme = JSON.parse(configControl.screen_review_configs || '{}')
    //const theme = configControl.screen_review_configs == "null" ? '{}' : JSON.parse(configControl.screen_review_configs)



    const [qtdStars, setQtdStars] = useState(0)

    function classNames(...classes) { return classes.filter(Boolean).join(' ') }

    const [activeScreen, setActiveScreen] = useState(0);
    const [direction, setDirection] = useState('next');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [cupom, setCupom] = useState(null);

    const [urlNetwork, setUrlNetwork] = useState("")
    const totalScreens = 6;

    const defaultConfigColors = {
        background: "#ffffff",
        text: "#282828",
        textSecondary: "#cbd5e1",
        starActive: "#282828",
        starInactive: "#cbd5e1",
        buttonBackground: "#282828",
        buttonText: "#ffffff",
    }

    const handleNext = () => {
        if (activeScreen == 6) setActiveScreen(0)
        setDirection('next');
        // Se estiver na tela 2, pula a tela 3 (redes sociais) e vai direto para a 4
        if (activeScreen === 2) {
            setActiveScreen(4);
        } else if (
            activeScreen == 1 &&
            configControl.screen_review_config_visible_media_cam != 1 &&
            configControl.screen_review_config_visible_media_tiktok != 1 &&
            configControl.screen_review_config_visible_media_upload != 1
        ) {
            setActiveScreen(4)
        } else {
            setActiveScreen((prev) => (prev + 1) % totalScreens);
        }
    };

    const handleBack = () => {
        setDirection('prev');
        // Se estiver na tela 4 (sobre você) ou tela 3 (tiktok), volta para a tela 2 (mostre-o)
        if (
            activeScreen == 4 &&
            configControl.screen_review_config_visible_media_cam != 1 &&
            configControl.screen_review_config_visible_media_tiktok != 1 &&
            configControl.screen_review_config_visible_media_upload != 1
        ) {
            setActiveScreen(1);
        } else if (activeScreen === 4 || activeScreen === 3) {
            setActiveScreen(2);
        } else {
            setActiveScreen((prev) => (prev - 1 + totalScreens) % totalScreens);
        }
    };

    const handleGoToScreen = (screenNumber) => {
        setDirection('next');
        setActiveScreen(screenNumber);
    };

    useEffect(() => {
        console.log("isMobile", isMobile)
    }, [isMobile])

    return (
        <ThemeProvider theme={configControl.screen_review_configs == "" || configControl.screen_review_configs == " " || configControl.screen_review_configs == null || configControl.screen_review_configs == undefined || configControl.screen_review_configs == "null" || configControl.screen_review_configs == "undefined" || configControl.screen_review_configs == "{}" ? defaultConfigColors : JSON.parse(configControl.screen_review_configs)}>
            <Container>
                {/* <SelectScreen>
                    <div className="flex my-4 gap-0 m-0">
                        <button
                            onClick={() => setIsMobile(true)}
                            className={classNames(
                                isMobile ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                'w-[40px] p-2 rounded-md text-sm font-medium flex items-center justify-center',
                            )}
                            type="button" data-headlessui-state="" style={{ border: "0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"></path></svg>
                        </button>
                        <button
                            onClick={() => setIsMobile(false)}
                            className={classNames(
                                !isMobile ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                'w-[40px] p-2 rounded-md text-sm font-medium flex items-center justify-center',
                            )}
                            type="button" data-headlessui-state="" style={{ border: "0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"></path></svg>
                        </button>
                    </div>
                </SelectScreen> */}

                <Preview isMobile={isMobile}>
                    <BarConfig isMobile={isMobile}>
                        <BarConfigLeft>
                            <span />
                            <span />
                            <span />
                        </BarConfigLeft>
                        <BarConfigRight>
                            <div onClick={() => setIsMobile(false)} style={{ cursor: 'pointer' }}>
                                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.0002 9V2.25C14.0002 1.85218 13.8422 1.47064 13.5609 1.18934C13.2796 0.908035 12.898 0.75 12.5002 0.75H3.5002C3.10237 0.75 2.72084 0.908035 2.43954 1.18934C2.15823 1.47064 2.0002 1.85218 2.0002 2.25V9M14.0002 9H2.0002M14.0002 9L14.9602 10.9125C15.018 11.0272 15.0454 11.1549 15.0397 11.2833C15.034 11.4116 14.9954 11.5364 14.9277 11.6455C14.8599 11.7547 14.7652 11.8446 14.6527 11.9067C14.5402 11.9688 14.4137 12.0009 14.2852 12H1.7152C1.58672 12.0009 1.46016 11.9688 1.34766 11.9067C1.23516 11.8446 1.14049 11.7547 1.07273 11.6455C1.00497 11.5364 0.966395 11.4116 0.960699 11.2833C0.955002 11.1549 0.982377 11.0272 1.0402 10.9125L2.0002 9" stroke={!isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>

                            <div onClick={() => setIsMobile(true)} style={{ cursor: 'pointer' }}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.6893 1.5H6.31066C5.63153 1.5 4.98022 1.76978 4.5 2.25C4.01978 2.73022 3.75 3.38153 3.75 4.06066V13.9393C3.75 14.6185 4.01978 15.2698 4.5 15.75C4.98022 16.2302 5.63153 16.5 6.31066 16.5H11.6893C12.3685 16.5 13.0198 16.2302 13.5 15.75C13.9802 15.2698 14.25 14.6185 14.25 13.9393V4.06066C14.25 3.38153 13.9802 2.73022 13.5 2.25C13.0198 1.76978 12.3685 1.5 11.6893 1.5Z" stroke={isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.2998 13.5H9.7998" stroke={isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </BarConfigRight>
                    </BarConfig>
                    {activeScreen === 0 && (
                        <Screen isMobile={isMobile} direction={direction}>
                            <Title>
                                <label>{configControl.screen_review_config_title_star}</label>
                            </Title>
                            <Contents>
                                <RatingComponent onRatingChange={setQtdStars} />
                            </Contents>
                            <FooterContainer>
                                <NavButton active={false}>Voltar</NavButton>
                                <NavInfor>
                                    <ProgressIndicator active={true} />
                                    <ProgressBar active={false} isMobile={isMobile} />
                                    <ProgressIndicator active={false} />
                                    <ProgressBar active={false} isMobile={isMobile} />
                                    <ProgressIndicator active={false} />
                                    <ProgressBar active={false} isMobile={isMobile} />
                                    <ProgressIndicator active={false} />
                                </NavInfor>
                                <NavButton onClick={handleNext}>Próximo</NavButton>
                            </FooterContainer>
                        </Screen>
                    )}

                    {activeScreen === 1 && (
                        <Screen isMobile={isMobile} direction={direction}>
                            <Title>
                                <label>{configControl.screen_review_config_title_comment}</label>
                            </Title>
                            <Contents>
                                <TextArea
                                    value={comment}
                                    placeholder="Adicionar comentário..."
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </Contents>
                            <FooterContainer>
                                <NavButton onClick={handleBack}>Voltar</NavButton>
                                <NavInfor>
                                    <ProgressIndicator active={true} />
                                    <ProgressBar active={true} isMobile={isMobile} />
                                    <ProgressIndicator active={true} />
                                    <ProgressBar active={false} isMobile={isMobile} />
                                    <ProgressIndicator active={false} />
                                    <ProgressBar active={false} isMobile={isMobile} />
                                    <ProgressIndicator active={false} />
                                </NavInfor>
                                <NavButton active={true} onClick={handleNext}>Próximo</NavButton>
                            </FooterContainer>
                        </Screen>
                    )}

                    {activeScreen === 2 && (
                        <Screen isMobile={isMobile} direction={direction}>
                            <Title>
                                <label>{configControl.screen_review_config_title_medias}</label>
                            </Title>
                            <Contents>
                                <ItemMedias>
                                    {configControl.screen_review_config_visible_media_upload == 1 && <MediaButton>
                                        <UploadIcon />
                                        Upload de suas fotos e vídeos
                                    </MediaButton>}

                                    <HiddenInput
                                        //ref={fileInputRef}
                                        type="file"
                                        accept="image/*,video/*"
                                        multiple
                                    //onChange={handleFileChange}
                                    />
                                    {configControl.screen_review_config_visible_media_upload == 1 && <MediaButton
                                        onClick={() => handleGoToScreen(3)}
                                        type="button"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                        </svg>

                                        {isMobile ? 'Publicação de redes sociais' : 'Enviar publicação de redes sociais'}
                                    </MediaButton>}
                                    {configControl.screen_review_config_visible_media_cam == 1 && <MediaButton>
                                        <VideoIcon />
                                        Gravar ao vivo
                                    </MediaButton>}
                                </ItemMedias>

                            </Contents>
                            <FooterContainer>
                                <NavButton onClick={handleBack}>Voltar</NavButton>
                                <NavInfor>
                                    <ProgressIndicator active={true} />
                                    <ProgressBar active={true} isMobile={isMobile} />
                                    <ProgressIndicator active={true} />
                                    <ProgressBar active={true} isMobile={isMobile} />
                                    <ProgressIndicator active={true} />
                                    <ProgressBar active={false} isMobile={isMobile} />
                                    <ProgressIndicator active={false} />
                                </NavInfor>
                                <NavButton onClick={handleNext}>Próximo</NavButton>
                            </FooterContainer>
                        </Screen>
                    )}

                    {activeScreen === 3 && (
                        <Screen isMobile={isMobile} direction={direction}>
                            <Title>
                                <label>{configControl.screen_review_config_title_medias}</label>
                            </Title>
                            <Contents>
                                <FormContainer>
                                    <ItemNetworkComponent isMobile={isMobile} />
                                    <FormGroup>
                                        <Label >
                                            URL
                                        </Label>
                                        <InputContainer>
                                            <Input
                                                value={urlNetwork}
                                                //onChange={(e) => setUrlNetwork(e.target.value)}
                                                type="url"
                                                placeholder="https://www.redesocial.com/id"
                                            />

                                        </InputContainer>
                                    </FormGroup>

                                </FormContainer>
                            </Contents>
                            <FooterContainer>
                                <NavButton onClick={handleBack}>Voltar</NavButton>
                                <NavInfor>
                                    <ProgressIndicator active={true} />
                                    <ProgressBar isMobile={isMobile} active={true} />
                                    <ProgressIndicator active={true} />
                                    <ProgressBar isMobile={isMobile} active={true} />
                                    <ProgressIndicator active={true} />
                                    <ProgressBar isMobile={isMobile} active={false} />
                                    <ProgressIndicator active={false} />
                                </NavInfor>
                                <NavButton active={true} onClick={handleNext}>Próximo</NavButton>
                            </FooterContainer>

                        </Screen>
                    )
                    }

                    {
                        activeScreen === 4 && (
                            <Screen isMobile={isMobile} direction={direction}>
                                <Title>
                                    <label>{configControl.screen_review_config_title_data}</label>
                                </Title>
                                <Contents>
                                    <FormContainer>
                                        <FormGroup>
                                            <Label>Seu nome</Label>
                                            <Input
                                                value={name}
                                                //onChange={(e) => setName(e.target.value)}
                                                type="text"
                                                placeholder="Nome"
                                            />
                                        </FormGroup>
                                        {configControl.screen_review_config_visible_data_whatsapp == 1 && <FormGroup>
                                            <Label>Seu telefone</Label>
                                            <Input
                                                value={phone}
                                                //onChange={(e) => setPhone(e.target.value)}
                                                type="tel"
                                                placeholder="+55 (21) 99999-9999"
                                            />
                                        </FormGroup>}
                                        <FormGroup>
                                            <Label>Seu e-mail</Label>
                                            <Input
                                                value={email}
                                                //onChange={(e) => setEmail(e.target.value)}
                                                type="email"
                                                placeholder="joaodasilva@servidor.com"
                                            />
                                        </FormGroup>
                                        <TermsText>
                                            {configControl.terms_use}
                                            {/* Ao prosseguir, eu reconheço os termos e a política de privacidade, e que minha análise será postada publicamente e compartilhada on-line. */}
                                        </TermsText>
                                        <SubmitButton active={true}>
                                            Enviar
                                        </SubmitButton>
                                    </FormContainer>
                                </Contents>
                                <FooterContainer>
                                    <NavButton onClick={handleBack}>Voltar</NavButton>
                                    <NavInfor>
                                        <ProgressIndicator active={true} />
                                        <ProgressBar isMobile={isMobile} active={true} />
                                        <ProgressIndicator active={true} />
                                        <ProgressBar isMobile={isMobile} active={true} />
                                        <ProgressIndicator active={true} />
                                        <ProgressBar isMobile={isMobile} active={true} />
                                        <ProgressIndicator active={true} />
                                    </NavInfor>
                                    <NavButton onClick={handleNext} active={true}>Próximo</NavButton>
                                </FooterContainer>
                            </Screen>
                        )
                    }

                    {
                        activeScreen === 5 && (
                            <Screen isMobile={isMobile} direction={direction}>
                                <Title>
                                    <label>{configControl.screen_review_config_title_end}</label>
                                </Title>
                                <Contents>
                                    <BoxThanks>
                                        <ThankTitle>
                                            Ficamos gratos pelo envio do seu feedback!
                                        </ThankTitle>
                                        <ThankDescription>
                                            Compartilhe com amigos e grupos.
                                        </ThankDescription>
                                        <CouponBox>
                                            <CouponCode>CUPOM</CouponCode>
                                            <CopyButton onClick={() => navigator.clipboard.writeText(cupom?.cupoms?.code)}>
                                                <FaRegCopy />
                                            </CopyButton>
                                        </CouponBox>
                                        <FinishButton onClick={handleNext}>
                                            Finalizar avaliação
                                        </FinishButton>
                                    </BoxThanks>
                                </Contents>

                            </Screen>
                        )
                    }
                </Preview>
            </Container>
        </ThemeProvider>
    )
}

export default PreviewDimona

const StarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
        <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
    </svg>
);

const UploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
    </svg>
);

const VideoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
    </svg>
);

const TikTokIcon = () => (
    <FaTiktok />
);

const CopyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
    </svg>
);

const RatingComponent = ({ onRatingChange }) => {
    const [rating, setRating] = useState(5);
    const [hoveredRating, setHoveredRating] = useState(0);

    const handleStarClick = (clickedRating) => {
        setRating(clickedRating);
        onRatingChange(clickedRating);
    };

    const handleStarHover = (star) => {
        setHoveredRating(star);
    };

    return (
        <Rating>
            <Stars>
                {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                        key={star}
                        active={star <= (hoveredRating || rating)}
                        onClick={() => handleStarClick(star)}
                        onMouseEnter={() => handleStarHover(star)}
                        onMouseLeave={() => setHoveredRating(0)}
                    >
                        <StarIcon />
                    </Star>
                ))}
            </Stars>
            <RatingText>Atribua uma nota</RatingText>
        </Rating>
    );
};

const ItemNetworkComponent = (isMobile) => {
    return (
        <ItemNetwork>
            <NetworkIcon href="https://www.tiktok.com/" target="_blank" rel="noopener noreferrer">
                <FaTiktok />
                {isMobile ? null : <span>Tik Tok</span>}
            </NetworkIcon>
            <NetworkIcon href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
                {isMobile ? null : <span>Instagram</span>}
            </NetworkIcon>
            <NetworkIcon href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
                {isMobile ? null : <span>Youtube</span>}
            </NetworkIcon>
        </ItemNetwork >
    );
};