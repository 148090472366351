import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { CircleColor, Contents, ContentsColors, ItemTemplate, Title } from "../reviews-questions/styled"
import { BoxButtons, ButtonHelp, ButtonReset, ButtonSave } from "../../../components-new/templatesReviewsQuestions/geb/colors/styled"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { useSubMenu } from "../../../../../empreenderUI/SubMenuContext"
import IconBossVideo from "../../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../../assets/imgs/icon-video.svg";
import { CheckBadgeIcon } from "@heroicons/react/24/solid"
import BarConfigHomeColor from "../../bar-config-home-color"
import BarConfigHomeConfig from "../../bar-config-home-config"
import BarConfigAvaliarColor from "../../bar-config-avaliar-color"
import BarConfigAvaliarConfig from "../../bar-config-avaliar-config"
import BarConfigMuralColor from "../../bar-config-mural-cores"
import BarConfigMuralAjustes from "../../bar-config-mural-ajustes"

import ThumbTemplateLisboa from "../../../../../assets/imgs/thumbs-templates/thumb-template-lisboa.PNG";
import ThumbTemplateParis from "../../../../../assets/imgs/thumbs-templates/thumb-template-paris.PNG";
import { getTranslation } from "../../../../../translations"
import { toast } from "react-toastify"

const tabs = [
    { name: 'Templates', href: '#', current: true },
    { name: 'Cores', href: '#', current: false },
    { name: 'Config', href: '#', current: false },

]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const Mural = () => {

    const [tabActive, setTabActive] = useState('Templates')
    const dispatch = useDispatch()
    const { setSideBarOpen, isSidebarOpen, } = useSubMenu();
    const configControl = useSelector(state => state.reducerControlConfigMural)
    const reducerUpdateConfigMural = useSelector(state => state.reducerUpdateConfigMural)
    const config = useSelector(state => state.reducerGetConfigMural)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    

    const [visibleModalVideo, setVisibleModalVideo] = useState(false)
    const setTheme = (theme) => {
        //dispatch({ type: "CONFIG_CONTROL_SET", payload: { theme: theme } })
    }

    const setDefault = (theme) => {
        //dispatch({ type: "CONFIG_CONTROL_SET", payload: { theme: theme } })
    }

    const getTabColors = () => {
        switch (configControl?.layout_widget) {
            case "modelo01":
                return <BarConfigMuralColor />
            default:
                return <BarConfigMuralColor />
        }
    }

    const getTabConfigs = () => {
        switch (configControl?.layout_widget) {
            case "modelo01":
                return <BarConfigMuralAjustes />
            //return <TabConfigGeb visibleModalVideo={visibleModalVideo} setVisibleModalVideo={setVisibleModalVideo} save={() => save("Config")} setDefault={() => setDefault("Config")} />
            default:
                return <BarConfigMuralAjustes />
        }
    }

    const save = (type) => {
        console.log("configControl save", config)
        dispatch({
            //type: "SAGA_UPDATE_CONFIG_MURAL", payload: {...config.data[0], data_config: JSON.stringify(configControl) }
            type: "SAGA_UPDATE_CONFIG_MURAL", payload: {
                id: config.data[0]?.id,
                data_config: JSON.stringify(configControl)
            }
        })
    }

    const changeTab = (tab) => {
        setTabActive(tab)
    }

    const getTheme = () => {

    }

    useEffect(() => {
        console.log("configControl mural", configControl)
    }, [configControl])

    useEffect(() => {
        const id = "reducerUpdateConfigMural"
        if (!(reducerUpdateConfigMural.loading == false && !reducerUpdateConfigMural.error == false && reducerUpdateConfigMural.data.length == 0)) {
            if (reducerUpdateConfigMural.loading) {
                toast.loading(translation.thema.aguarde, { toastId: id })
            } else {
                if (reducerUpdateConfigMural.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.thema.podeLevar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
                    dispatch({ type: "SAGA_GET_CONFIG_MURAL", })
                }
            }
        } else if (reducerUpdateConfigMural.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    }, [reducerUpdateConfigMural])




    return (
        <>
            {/* <ModalAlertSaveNew visible={visible} setVisible={setVisible} save={() => save(tabActive)} />
            <ModalInforVideo visible={visibleModalVideo} setVisibleModal={setVisibleModalVideo} video={getVideo()} /> */}
            <Title>
                <label>Mural</label>
                <svg onClick={() => { setSideBarOpen(!isSidebarOpen) }} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M12 4L4 12" stroke="#111827" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4 4L12 12" stroke="#111827" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Title>
            <div>
                <div className="grid grid-cols-1 sm:hidden">
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        onChange={(e) => { changeTab(e.target.value) }}
                        defaultValue={tabs.find((tab) => tab.current).name}
                        aria-label="Select a tab"
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-i[#111827]"
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                    <ChevronDownIcon
                        aria-hidden="true"
                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                    />
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                            {tabs.map((tab) => (
                                <a
                                    onClick={() => { changeTab(tab.name) }}
                                    key={tab.name}
                                    href={tab.href}
                                    //aria-current={tab.name == tabActive ? 'page' : undefined}
                                    className={classNames(
                                        tab.name == tabActive
                                            ? 'border-[#111827] text-[#111827]'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                        'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                                    )}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>

            {tabActive == "Templates" &&
                <Contents>
                    <ItemTemplate >
                        <img onClick={() => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_model_widget: "modelo01" } }) }} src={ThumbTemplateLisboa} />
                        <div>
                            <label>Lisboa 1.0 {configControl?.mural_model_widget == "modelo01" && <CheckBadgeIcon style={{ color: "#22C55E", width: "20px" }} />}</label>
                            {/* <span style={{ display: "flex", gap: "4px" }}>
                                <CircleColor onClick={() => { configControl.layout_widget == "modelo04" && setTheme("dark") }} active={configControl.layout_widget == "modelo04" ? getTheme() == "dark" : false} color={"#000000"} />
                                <CircleColor onClick={() => { configControl.layout_widget == "modelo04" && setTheme("light") }} active={configControl.layout_widget == "modelo04" ? getTheme() == "light" : false} color={"#f2f2f2"} />
                                {configControl.layout_widget == "modelo04" && getTheme() == "custom" && (
                                    <CircleColor
                                        onClick={() => {
                                            if (configControl.layout_widget == "modelo04") {
                                                const customConfig = {
                                                    background: '#FFFFFF',
                                                    text: '#374151',
                                                    textSecondary: '#6B7280',
                                                    border: '#E5E7EB',
                                                    starActive: '#6366f1',
                                                    starInactive: '#94a3b8',
                                                    verified: '#6366f1',
                                                    backgroundItem: '#FFFFFF',
                                                    cardBackground: '#F5F5F5'
                                                };
                                                dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify(customConfig) } });
                                            }
                                        }}
                                        active={configControl.layout_widget == "modelo04" ? getTheme() == "custom" : false}
                                        color={"#6366f1"}
                                    />
                                )}
                            </span> */}
                        </div>
                    </ItemTemplate>

                    <ItemTemplate >
                        <img onClick={() => { dispatch({ type: "CONFIG_CONTROL_MURAL_SET", payload: { mural_model_widget: "modelo02" } }) }} src={ThumbTemplateParis} />
                        <div>
                            <label>Paris 1.0 {configControl?.mural_model_widget == "modelo02" && <CheckBadgeIcon style={{ color: "#22C55E", width: "20px" }} />}</label>
                        </div>
                    </ItemTemplate>


                    <ButtonHelp onClick={() => { setVisibleModalVideo(true) }} style={{ maxWidth: "250px" }}>
                        <img width={20} src={IconBossVideo} />
                        <label>{"Tutorial"}</label>
                        <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                    </ButtonHelp>

                    <BoxButtons style={{ maxWidth: "250px" }}>
                        <ButtonReset onClick={() => setDefault("Templates")}>
                            <label>{"Restaurar"}</label>
                        </ButtonReset>

                        <ButtonSave onClick={() => save("Templates")}>
                            {"Salvar"}
                        </ButtonSave>
                    </BoxButtons>

                </Contents>}

            {tabActive == "Cores" &&
                <Contents>
                    <ContentsColors>
                        {getTabColors()}
                    </ContentsColors>
                </Contents>
            }

            {tabActive == "Config" &&
                <Contents>
                    {getTabConfigs()}
                </Contents>}
        </>
    )
}

export default Mural