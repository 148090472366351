export const reviewsData = [
    {
        id: 1,
        media: [
            {
                type: 'video',
                url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            },
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            }
        ],
        text: "Comprei várias camisetas e estou impressionada com a qualidade!",
        user: "Ana Silva",
        date: "2024-10-02",
        rating: 5,
        verified: true
    },
    {
        id: 2,
        media: [
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            }
        ],
        text: "Excelente atendimento e entrega super rápida. A camiseta tem um caimento perfeito e o tamanho está certinho conforme a tabela. Já quero comprar mais!",
        user: "Pedro Henrique",
        date: "2024-08-02",
        rating: 5,
        verified: true
    },
    {
        id: 3,
        media: [
            {
                type: 'video',
                url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            }
        ],
        text: "Adorei a qualidade das camisetas! O único detalhe é que achei que a cor seria um pouco diferente, mas ainda assim ficou ótimo.",
        user: "Mariana Costa",
        date: "2024-05-02",
        rating: 3,
        verified: false
    },
    {
        id: 4,
        media: [
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            },
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            },
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            }
        ],
        text: "Comprei para toda a família e todos adoraram! As camisetas são muito confortáveis e o material é de primeira qualidade. O preço está ótimo pelo que oferece.",
        user: "Roberto Oliveira",
        date: "2024-01-02",
        rating: 5,
        verified: false
    },
    {
        id: 5,
        media: [
            {
                type: 'video',
                url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            },
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            }
        ],
        text: "Super recomendo! A estampa é linda e o tecido é bem fresquinho, perfeito para o verão. Só achei que demorou um pouquinho para chegar. O atendimento também foi excelente.",
        user: "Fernanda Lima",
        date: "2024-28-01",
        rating: 5,
        verified: false
    },
    {
        id: 6,
        media: [],
        text: "Melhor camiseta que já comprei! O tecido é de excelente qualidade e o acabamento é impecável. Vale cada centavo!",
        user: "Lucas Mendes",
        date: "2024-25-01",
        rating: 5,
        verified: false
    },
    {
        id: 7,
        media: [
            {
                type: 'video',
                url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            },
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            },
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            }
        ],
        text: "As camisetas são perfeitas!",
        user: "Juliana Almeida",
        date: "2024-20-01",
        rating: 5,
        verified: true
    },
    {
        id: 8,
        media: [
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            }
        ],
        text: "Gostei bastante da camiseta, mas achei que o tamanho ficou um pouco maior do que esperava. A qualidade é boa!",
        user: "Gabriel Santos",
        date: "2024-15-01",
        rating: 5,
        verified: true
    },
    {
        id: 9,
        media: [],
        text: "Produto de excelente qualidade! A estampa é linda e o tecido é muito confortável. Já estou planejando comprar mais.",
        user: "Isabela Martins",
        date: "2024-10-01",
        rating: 5,
        verified: true
    },
    {
        id: 10,
        media: [
            {
                type: 'image',
                url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            },
            {
                type: 'video',
                url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
            }
        ],
        text: "Ótimo custo-benefício! As camisetas são de qualidade e o preço está muito bom",
        user: "Rafael Costa",
        date: "2024-05-01",
        rating: 4,
        verified: true
    }
];

export const questionsData = [
    {
        id: 1,
        question: "Como funciona o processo de troca caso o produto não sirva?",
        answer: "O processo de troca é bem simples e rápido! Basta entrar em contato com nosso atendimento em até 7 dias após o recebimento. Enviaremos um código de postagem para você devolver o produto sem custo, e assim que recebermos, faremos a troca pelo tamanho desejado ou o reembolso integral.",
        user: "Marina Santos",
        date: "2022-02-08"
    },
    {
        id: 2,
        question: "Qual a diferença entre os tecidos das camisetas básicas e premium?",
        answer: "Nossas camisetas básicas são feitas com algodão 100% e têm gramatura de 160g/m², ideais para uso diário. Já as premium utilizam um blend especial de algodão pima e elastano, com gramatura de 180g/m², oferecendo mais durabilidade, melhor caimento e toque mais macio. O acabamento também é diferenciado, com costuras reforçadas e gola especial que não deforma.",
        user: "Rafael Costa",
        date: "2025-02-22"
    },
    {
        id: 3,
        question: "Vocês entregam no exterior?",
        answer: "Sim! Realizamos entregas internacionais.", 
        user: "John Smith",
        date: "2024-02-06"
    },
    {
        id: 4,
        question: "As medidas da tabela de tamanhos são precisas? Estou em dúvida entre dois tamanhos.",
        answer: "Nossa tabela de tamanhos é atualizada regularmente e as medidas são bem precisas. Para escolher o tamanho ideal, recomendamos medir uma peça que você já tenha e goste do caimento, comparando com nossa tabela. Em caso de dúvida entre dois tamanhos, geralmente é melhor optar pelo maior, especialmente se você preferir um caimento mais confortável.",
        user: "Ana Luiza Mendes",
        date: "2024-02-05"
    },
    {
        id: 5,
        question: "Qual o prazo de entrega?",
        answer: "24h para capitais, 3-5 dias úteis para outras regiões.",
        user: "Pedro Alves",
        date: "2024-02-04"
    },
    {
        id: 6,
        question: "Como é feito o controle de qualidade dos produtos? Gostaria de entender melhor o processo de fabricação e os padrões de qualidade adotados pela empresa.",
        answer: "Nosso controle de qualidade é rigoroso e acontece em várias etapas. Primeiro, inspecionamos toda matéria-prima recebida, verificando a qualidade dos tecidos, aviamentos e acabamentos. Durante a produção, cada peça passa por 3 pontos de verificação: após o corte, durante a costura e no acabamento final. Utilizamos máquinas de última geração e nossa equipe é altamente treinada. Além disso, fazemos testes regulares de lavagem, resistência e durabilidade. Mantemos certificações ISO 9001 e OEKO-TEX, garantindo que nossos produtos sejam livres de substâncias nocivas e sigam padrões internacionais de qualidade.",
        user: "Carla Rodrigues",
        date: "2024-02-03"
    }
];
export const temas = {
    light: {
        background: '#FFFFFF',
        border: '#eeeeee',
        text: '#282828',
        textSecondary: '#94a3b8',
        starActive: '#edaa41',
        starInactive: '#94a3b8',
        buttonBackground: '#282828',
        buttonText: '#FFFFFF',
        verified: '#282828',
    },
    dark: {
        background: '#1F2937',  
        text: '#E5E7EB',  
        textSecondary: '#9CA3AF',
        starActive: '#FFB300',  
        starInactive: '#4B5563',
        buttonBackground: '#374151',  
        buttonText: '#F9FAFB',
        border: '#4B5563',  
        verified: '#E5E7EB',
    }
};