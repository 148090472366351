import React from 'react'
import { Switch, BrowserRouter, Route, Redirect, } from "react-router-dom";

import PageLogin from "../modules/login/pages/home";
import LoginPlus from "../modules/login/pages/LoginPlus";
import PageRegister from "../modules/login/pages/register";
import PagePassword from "../modules/login/pages/password";
import PageDashboard from "../modules/dashboard/pages/home";
import PageDashboardNew from "../modules/dashboard/pages/home-new";
import PageReviews from "../modules/reviews/pages/home";
import PageReviewsNew from "../modules/reviews/pages/home-new";
import PageQuestions from "../modules/questions/pages/home";
import PageQuestionsNew from "../modules/questions/pages/home-new";
import PageIntegrations from "../modules/integrations/pages/home";
import PageIntegrationsNew from "../modules/integrations/pages/home-new";
import PageCode from "../modules/integrations/pages/code";
import PageMercadoshop from "../modules/integrations/pages/mercadoshop";
import PageCupons from "../modules/cupons/pages/home";
import PageCuponsNew from "../modules/cupons/pages/home-new";
import PageAssinatura from "../modules/assinatura/pages/home";
import PageAssinaturaNew from "../modules/assinatura/pages/home-new";
import PageAjuda from "../modules/ajuda/pages/home";
import PageAjudaNew from "../modules/ajuda/pages/home-new";
import InjectAxiosInterceptors from '../services/InjectAxiosInterceptors';
import PageAccount from "../modules/account/pages/home";
import PageAdmin from "../modules/admin/pages/home";
import PageThema from "../modules/thema/pages/home";
import PageThemaNew from "../modules/thema/pages/home";
import PageAffiliate from "../modules/login/pages/affiliate";
import PageProducts from "../modules/products/pages/home";
import PageProductsNew from "../modules/products/pages/home-new";
import PageProductsReviews from "../modules/products/pages/reviews";
import PageProductsQuestions from "../modules/products/pages/questions";

import PageSteps from "../modules/steps/pages/home";
import PageStepsNew from "../modules/steps/pages/home-new";
import PagePositionScriptHome from "../modules/thema/pages/position-home-script";
import PagePositionScriptMural from "../modules/thema/pages/position-mural-script";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import PageWix from "../modules/integrations/pages/wix-integration";
import { useDispatch } from 'react-redux';
import PageEmail from "../modules/email/pages/home";
import PageOrders from "../modules/orders/pages/home";
import PageOrdersNew from "../modules/orders/pages/home-new";

import PageOrderProducts from "../modules/orders/pages/products";

import PageCollect from "../modules/collect/pages/home";
import PageCollectNew from "../modules/collect/pages/home-new";

import PageTrash from "../modules/trash/pages/home";

import PagePositionIntegration from "../modules/thema/pages/position-integration-script";
import LayoutUI from '../components/tailwind/layout-ui';
import LoginAdmin from '../modules/login/pages/login-admin';

const Routes = () => {



    return (
        <BrowserRouter>
            <InjectAxiosInterceptors />
            <Switch>
                <PrivateRoute exact path="/admin" > <PageAdmin /> </PrivateRoute>
                <Route exact path="/eplus/auth/link-magico"> <LoginPlus /> </Route>
                <Route exact path="/login"> <PageLogin /> </Route>
                <Route exact path="/login/admin"> <LoginAdmin /> </Route>
                <Route exact path="/cadastro"> <PageRegister /> </Route>
                <Route exact path="/senha"> <PagePassword /> </Route>
                <Route exact path="/cadastro/afiliado"> <PageAffiliate /> </Route>
                <PrivateRoute exact path="/personalizar/position/script/integration" > <PagePositionIntegration /> </PrivateRoute>
                <PrivateRoute exact path="/personalizar/position/script/home" > <PagePositionScriptHome /> </PrivateRoute>
                <PrivateRoute exact path="/personalizar/position/script/mural" > <PagePositionScriptMural /> </PrivateRoute>
                <PrivateRoute exact path="/lixeira" > <PageTrash /> </PrivateRoute>
                <PrivateRoute exact path="/lixeira/depoimentos" > <PageTrash /> </PrivateRoute>
                <PrivateRoute exact path="/lixeira/avaliacoes" > <PageTrash /> </PrivateRoute>
                <PrivateRoute exact path="/lixeira/cupons" > <PageTrash /> </PrivateRoute>
                <PrivateRoute exact path="/lixeira/perguntas" > <PageTrash /> </PrivateRoute>
                <PrivateRoute exact path="/integracoes/:type" > <PageCode /> </PrivateRoute>
                <PrivateRoute exact path="/wix"> <PageWix /> </PrivateRoute>
                <Route exact path="/mercadoshop"> <PageMercadoshop /> </Route>

                <Route>
                    <LayoutUI>
                        <Switch>
                            <PrivateRoute exact path="/" > <PageDashboardNew /> </PrivateRoute>
                            <PrivateRoute exact path="/perguntas" > <PageQuestionsNew /> </PrivateRoute>
                            <PrivateRoute exact path="/avaliacoes" > <PageReviewsNew /> </PrivateRoute>
                            <PrivateRoute exact path="/cupons" > <PageCuponsNew /> </PrivateRoute>
                            <PrivateRoute exact path="/pagamentos" > <PageAssinaturaNew /> </PrivateRoute>
                            <PrivateRoute exact path="/ajuda" > <PageAjudaNew /> </PrivateRoute>
                            <PrivateRoute exact path="/email" > <PageEmail /> </PrivateRoute>
                            <PrivateRoute exact path="/conta" > <PageAccount /> </PrivateRoute>
                            <PrivateRoute exact path="/integracoes" > <PageIntegrationsNew /> </PrivateRoute>
                            <PrivateRoute exact path="/personalizar" > <PageThemaNew /> </PrivateRoute>
                            <PrivateRoute exact path="/personalizar/tema" > <PageThemaNew /> </PrivateRoute>
                            <PrivateRoute exact path="/personalizar/mural" > <PageThemaNew /> </PrivateRoute>
                            <PrivateRoute exact path="/personalizar/email" > <PageThemaNew /> </PrivateRoute>
                            <PrivateRoute exact path="/personalizar/home" > <PageThemaNew /> </PrivateRoute>
                            <PrivateRoute exact path="/personalizar/avaliar" > <PageThemaNew /> </PrivateRoute>
                            <PrivateRoute exact path="/personalizar/perguntar" > <PageThemaNew /> </PrivateRoute>
                            <PrivateRoute exact path="/produtos" > <PageProductsNew /> </PrivateRoute>
                            <PrivateRoute exact path="/produtos/:id/avaliacoes" > <PageProductsReviews /> </PrivateRoute>
                            <PrivateRoute exact path="/produtos/:id/perguntas" > <PageProductsQuestions /> </PrivateRoute>
                            <PrivateRoute exact path="/pedidos" > <PageOrdersNew /> </PrivateRoute>
                            <PrivateRoute exact path="/pedidos/:id/produtos" > <PageOrderProducts /> </PrivateRoute>
                            <PrivateRoute exact path="/depoimentos" > <PageCollectNew /> </PrivateRoute>
                            <PrivateRoute exact path="/passo-a-passo" > <PageStepsNew /> </PrivateRoute>
                        </Switch>
                    </LayoutUI>
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Routes



const PrivateRoute = ({ children, ...rest }) => {

    const dispatch = useDispatch()
    const urlParams = new URLSearchParams(window.location.search);


    if (rest.path == "/wix") {
        let tokenCode = new URLSearchParams(window.location.search).get("token")
        let instanceCode = new URLSearchParams(window.location.search).get("instance")
        if (tokenCode) localStorage.setItem("wix@code", tokenCode)
        if (instanceCode) localStorage.setItem("wix@instanceCode", instanceCode)
    }


    if (rest.location.pathname == "/integracoes/tray" && urlParams.get("url") && !localStorage.getItem("depoimentos@login")) {
        localStorage.setItem("tray@url", urlParams.get("url"))
    }

    if (rest.location.pathname == "/integracoes/mercadoshop" && !localStorage.getItem("depoimentos@login")) {
        localStorage.setItem("mercadoshop@integration", "pending")
    }

    if (rest.location.pathname == "/integracoes/nuvemshop" && typeof urlParams.get("code") == "string" && !localStorage.getItem("depoimentos@login")) {

        dispatch({ type: "SAGA_GET_TOKEN_NUVEMSHOP", payload: { code: urlParams.get("code") } })
        //localStorage.setItem('nuvemshop@code', urlParams.get("code"))
        //if (typeof urlParams.get("code") == "string") {
        //localStorage.setItem('depoimentos@backurl', urlParams.get("url"))
        //window.location.href = urlParams.get("url");
        //window.open(urlParams.get("url"))
        //}
    }


    if (typeof urlParams.get("ref") == "string") {

        localStorage.setItem('depoimentos@affiliate', urlParams.get("ref"))
        if (typeof urlParams.get("url") == "string") {
            //localStorage.setItem('depoimentos@backurl', urlParams.get("url"))

            window.location.href = urlParams.get("url");
            //window.open(urlParams.get("url"))
        }
    } else {
        return (
            <Route {...rest}
                render={({ location }) => isAuthenticated() ? (children) : (<Redirect to={{ pathname: "/login", state: { from: location } }} />)}
            />)
    }


}

const isAuthenticated = () => {

    return localStorage.getItem('depoimentos@login') || localStorage.getItem('depoimentosAdmin@login') ? true : false


}




