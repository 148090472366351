import styled from "styled-components";


export const ButtonHelp = styled.div`
    background-color: #121212 ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 48px ;
    border-radius: 4px ;
    justify-content: center ;
    //border: 1px dashed #0D6EFD;
    cursor: pointer;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #fff ;
    }

    img{
        width: 44px ;
        height: 44px ;
        cursor: pointer;
    }
`

export const ButtonSave = styled.div`
    background-color: #00803B  ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    font-size: 12px ;
    color: #fff ;
    cursor: pointer;
    flex: 1;
`

export const ButtonReset = styled.div`
    //background-color: red ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    border: 1px solid #D3DAE3;
    cursor: pointer;
    flex: 1;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #000 ;
    }

    img{
        width: 14px ;
        height: 16px ;
    }

`
export const BoxButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`

export const BoxOptions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid #E5E7EB;
    padding: 14px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;

    &:hover {
        border-color: #D1D5DB;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    }

    label {
        font-size: 11px;
        color: #6B7280;
        text-align: center;
    }
`

export const Posicao = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    span {
        display: flex;
        align-items: center;
        
        img {
            margin-right: 10px;
        }
    }

    div {
        display: flex;
        justify-content: center;

        div {
            display: flex;
            background-color: #2563EB;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            height: 34px;
            color: #fff;
            cursor: pointer;
            gap: 8px;
            padding: 0 16px;
            transition: all 0.2s ease;
            min-width: 140px;

            &:hover {
                background-color: #1D4ED8;
                transform: translateY(-1px);
            }

            label {
                cursor: pointer;
                font-size: 11px;
                color: #FFFFFF;
                font-weight: 500;
            }

            img {
                width: 14px;
                height: 14px;
                opacity: 0.9;
            }
        }
    }
`