import { useEffect } from 'react';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getTranslation } from '../../../../translations';

import { Container, Actions, AlertSave } from "./styled";
import IconBossSave from '../../../../assets/imgs/boss-save.svg';

import { useLocation } from "react-router-dom";
import useVerifySaveColors from '../../../../hooks/useVerifySave';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#242527",
        padding: "0px",
        margin: "0px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalAlertSaveNew = ({ visible, setVisible, save }) => {

    const dispatch = useDispatch()
    const location = useLocation();
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Modal
            isOpen={visible}
            style={customStyles}
            ariaHideApp={false}
        //onRequestClose={() => props?.setVisibleModal(false)}
        >
            <span>
                <AlertSave>
                    <img src={IconBossSave} />
                    <span>
                        <label>{translation.thema.alertText}</label>
                        <div>
                            <label onClick={() => { dispatch({ type: "SAGA_GET_CONFIG"}); setVisible(false) }} >
                                {translation.thema.alertDescartar}
                            </label>
                            <span onClick={() => { save(); setVisible(false) }} >
                                {translation.thema.alertSalvar}
                            </span>
                        </div>
                    </span>
                </AlertSave>
            </span>
        </Modal>
    )
}

export default ModalAlertSaveNew
