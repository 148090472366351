import { useSubMenu } from '../SubMenuContext';
import { motion } from 'framer-motion';

const BreveButton = () => {
	const { isSidebarOpen } = useSubMenu();

	return (
		<motion.div initial={{ opacity: isSidebarOpen ? 1 : 0 }} animate={{ opacity: isSidebarOpen ? 1 : 0 }} transition={{ duration: 0.25, ease: [0, 0.71, 0.2, 1.01] }} className='bg-blue-50 text-blue-500 px-1 py-0 font-medium text-[0.625rem] rounded'>
			Breve
		</motion.div>
	);
};

export default BreveButton;
