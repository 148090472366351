import styled from 'styled-components';

export const CardWrapper = styled.div`
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  padding: 1.75rem;
  width: 100%;
  margin: 0 0 1.5rem 0;
  transition: all 0.2s ease;
  break-inside: avoid-column;
  page-break-inside: avoid;
  display: inline-block;
  font-family: ${props => props.font}, sans-serif;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  text-align: left;
  height: fit-content;
`;

export const MediaContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.hasMedia ? '200px' : '0'};
  border-radius: 8px;
  overflow: hidden;
  background: ${props => props.theme.background};
  margin: ${props => props.hasMedia ? '0 0 1rem 0' : '0'};
  display: ${props => props.hasMedia ? 'block' : 'none'};
`;

export const MediaCount = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
`;

export const VideoThumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s ease;
  }

  .play-icon {
    color: white;
    font-size: 32px;
    transition: transform 0.2s ease;
  }

  &:hover {
    .play-overlay {
      background: rgba(0, 0, 0, 0.6);
    }
    .play-icon {
      transform: scale(1.1);
    }
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const ReviewText = styled.p`
  color: ${props => props.theme.text};
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  font-style: italic;
`;

export const UserInfo = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`;

export const UserHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const UserName = styled.h3`
  margin: 0;
  color: ${props => props.theme.text};
  font-size: 1.1rem;
  font-weight: 600;
`;

export const DateText = styled.span`
  color: ${props => props.theme.textSecondary};
  font-size: 0.9rem;
  //font-family: monospace;
`;