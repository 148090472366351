import { ChevronDownIcon } from "@heroicons/react/24/outline"
import { CircleColor, Contents, ContentsColors, ItemTemplate, ItemTemplateTeste, ItemTemplateTesteBox, ItemTemplateTesteInfor, ItemTemplateTesteStatus, ItemTemplateTesteThemes, Title } from "../reviews-questions/styled"
import { BoxButtons, ButtonHelp, ButtonReset, ButtonSave } from "../../../components-new/templatesReviewsQuestions/geb/colors/styled"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { useSubMenu } from "../../../../../empreenderUI/SubMenuContext"
import IconBossVideo from "../../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../../assets/imgs/icon-video.svg";
import { CheckBadgeIcon } from "@heroicons/react/24/solid"
import BarConfigHomeColor from "../../bar-config-home-color"
import BarConfigHomeConfig from "../../bar-config-home-config"
import BarConfigAvaliarColor from "../../bar-config-avaliar-color"
import BarConfigAvaliarConfig from "../../bar-config-avaliar-config"
import BarConfigPerguntarColor from "../../bar-config-perguntar-color"
import BarConfigPerguntarConfig from "../../bar-config-perguntar-config"

import { Radio, RadioGroup } from '@headlessui/react'

import ThumbTemplateMoly from "../../../../../assets/imgs/thumbs-templates/thumb-template-moly.PNG";
import TabColorDimona from "../../../components-new/templatesScreenQuestions/dimona/colors";

import ThumbTemplateMolyNew from "../../../../../assets/imgs/thumbs-templates/thumb-template-moly-screen-ask.PNG";
import ThumbTemplateDimonaNew from "../../../../../assets/imgs/thumbs-templates/thumb-template-dimona-screen-ask.PNG";

const tabs = [
    { name: 'Templates', href: '#', current: true },
    { name: 'Cores', href: '#', current: false },
    { name: 'Config', href: '#', current: false },

]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ScreenQuestion = () => {

    const [tabActive, setTabActive] = useState('Templates')
    const dispatch = useDispatch()
    const { setSideBarOpen, isSidebarOpen, } = useSubMenu();
    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)

    const [visibleModalVideo, setVisibleModalVideo] = useState(false)
    const setTheme = (theme) => {
        if (configControl.layout_form_ask == "dimona") {
            if (theme == "dark") {
                const dataConfig = {
                    background: '#282828',
                    text: '#ffffff',
                    textSecondary: '#5e5e5e',
                    buttonBackground: '#ffffff',
                    buttonText: '#282828',
                }
                dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_configs: JSON.stringify(dataConfig) } })
            } else if (theme == "light") {
                const dataConfig = {
                    background: '#FFFFFF',
                    text: '#282828',
                    textSecondary: '#cbd5e1',
                    buttonBackground: '#282828',
                    buttonText: '#FFFFFF',
                }
                dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_configs: JSON.stringify(dataConfig) } })
            }
        } else {
            if (theme == "dark") {
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        screen_question_color_primary: '#282828',
                        screen_question_color_secondary: '#ffffff',
                        screen_question_color_font: '#ffffff',
                        screen_question_color_font_secondary: '#5e5e5e',
                    }
                })
            } else if (theme == "light") {
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        screen_question_color_primary: '#FFFFFF',
                        screen_question_color_secondary: '#282828',
                        screen_question_color_font: '#282828',
                        screen_question_color_font_secondary: '#cbd5e1',
                    }
                })
            }
        }
    }

    const defaultConfigColors = {
        background: "#ffffff",
        text: "#282828",
        textSecondary: "#cbd5e1",
        buttonBackground: "#282828",
        buttonText: "#ffffff",
    }

    const setDefault = (theme) => {
        if (theme == "Templates") {
            dispatch({
                type: "SAGA_UPDATE_CONFIG", payload: {
                    id: config.data?.id,
                    layout_form_ask: "modelo1",
                }
            })
        } else if (theme == "Cores") {
            if (configControl.layout_form == "modelo1") {
                dispatch({
                    type: "SAGA_UPDATE_CONFIG", payload: {
                        id: config.data?.id,
                        screen_question_color_font: "#212121",
                        screen_question_color_font_secondary: "#ffffff",
                        screen_question_color_primary: "#ffffff",
                        screen_question_color_secondary: "#212121",
                    }
                })
            } else {
                dispatch({
                    type: "SAGA_UPDATE_CONFIG", payload: {
                        id: config.data?.id,
                        screen_question_configs: JSON.stringify(defaultConfigColors)
                    }
                })
            }
        } else if (theme == "Config") {

        }
    }

    const getTabColors = () => {
        switch (configControl?.layout_form_ask) {
            case "modelo1":
                return <BarConfigPerguntarColor />
            case "dimona":
                return <TabColorDimona
                    visibleModalVideo={visibleModalVideo}
                    setVisibleModalVideo={setVisibleModalVideo}
                    save={() => save("Cores")}
                    setDefault={() => setDefault("Cores")}
                />
            default:
                return <BarConfigPerguntarColor />
        }
    }

    const getTabConfigs = () => {
        switch (configControl?.layout_form_ask) {
            case "modelo1":
                return <BarConfigPerguntarConfig />
            //return <TabConfigGeb visibleModalVideo={visibleModalVideo} setVisibleModalVideo={setVisibleModalVideo} save={() => save("Config")} setDefault={() => setDefault("Config")} />
            default:
                return <BarConfigPerguntarConfig />
        }
    }

    const save = (type) => {
        if (type == "Templates") {
            if (configControl.layout_form_ask == "dimona") {
                if (configControl.screen_question_configs == "null" || configControl.screen_question_configs == "" || configControl.screen_question_configs == null) {
                    dispatch({
                        type: "SAGA_UPDATE_CONFIG", payload: {
                            id: config.data.id,
                            screen_question_configs: JSON.stringify({
                                background: '#FFFFFF',
                                text: '#282828',
                                textSecondary: '#cbd5e1',
                                buttonBackground: '#282828',
                                buttonText: '#FFFFFF',
                            })
                        }
                    })
                }
                dispatch({
                    type: "SAGA_UPDATE_CONFIG", payload: {
                        id: config.data?.id,
                        layout_form_ask: configControl.layout_form_ask,
                        screen_question_configs: configControl.screen_question_configs,
                    }
                })
            } else {
                dispatch({
                    type: "SAGA_UPDATE_CONFIG", payload: {
                        id: config.data?.id,
                        layout_form_ask: configControl.layout_form_ask,
                        screen_question_color_font: configControl.screen_question_color_font,
                        screen_question_color_font_secondary: configControl.screen_question_color_font_secondary,
                        screen_question_color_primary: configControl.screen_question_color_primary,
                        screen_question_color_secondary: configControl.screen_question_color_secondary,
                    }
                })
            }

            // dispatch({
            //     type: "SAGA_UPDATE_CONFIG", payload: {
            //         id: config.data?.id,
            //         layout_form_ask: configControl.layout_form_ask,
            //     }
            // })
        } else if (type == "Cores") {
            if (configControl.layout_widget == "modelo1") {
                dispatch({
                    type: "SAGA_UPDATE_CONFIG", payload: {
                        id: config.data?.id,
                        screen_question_color_font: configControl.screen_question_color_font,
                        screen_question_color_font_secondary: configControl.screen_question_color_font_secondary,
                        screen_question_color_primary: configControl.screen_question_color_primary,
                        screen_question_color_secondary: configControl.screen_question_color_secondary,
                    }
                })
            } else {
                dispatch({
                    type: "SAGA_UPDATE_CONFIG", payload: {
                        id: config.data?.id,
                        screen_question_configs: configControl.screen_question_configs
                    }
                })
            }

        } else if (type == "Config") {

        }
    }

    const changeTab = (tab) => {
        setTabActive(tab)
    }

    const getTheme = () => {
        if (configControl.layout_form_ask == "modelo1") {
            if (
                configControl.screen_question_color_primary == '#282828' &&
                configControl.screen_question_color_secondary == '#ffffff' &&
                configControl.screen_question_color_font == '#ffffff' &&
                configControl.screen_question_color_font_secondary == '#5e5e5e'
            ) {
                return "dark"
            } else if (
                configControl.screen_question_color_primary == '#FFFFFF' &&
                configControl.screen_question_color_secondary == '#282828' &&
                configControl.screen_question_color_font == '#282828' &&
                configControl.screen_question_color_font_secondary == '#cbd5e1'
            ) {
                return "light"
            } else {
                return "custom"
            }
        } else if (configControl.layout_form_ask == "dimona") {
            if (configControl.screen_question_configs == "null" || configControl.screen_question_configs == null) return "light"
            const layout_configs = JSON.parse(configControl.screen_question_configs)
            if (
                layout_configs.background == '#282828' &&
                layout_configs.text == '#ffffff' &&
                layout_configs.textSecondary == '#5e5e5e' &&
                layout_configs.buttonBackground == '#ffffff' &&
                layout_configs.buttonText == '#282828'
            ) {
                return "dark"
            } else if (
                layout_configs.background == '#FFFFFF' &&
                layout_configs.text == '#282828' &&
                layout_configs.textSecondary == '#cbd5e1' &&
                layout_configs.buttonBackground == '#282828' &&
                layout_configs.buttonText == '#FFFFFF'
            ) {
                return "light"
            } else {
                return "custom"
            }
        }

    }



    return (
        <>
            {/* <ModalAlertSaveNew visible={visible} setVisible={setVisible} save={() => save(tabActive)} />
            <ModalInforVideo visible={visibleModalVideo} setVisibleModal={setVisibleModalVideo} video={getVideo()} /> */}
            <Title>
                <label>Tela perguntar</label>
                <svg onClick={() => { setSideBarOpen(!isSidebarOpen) }} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M12 4L4 12" stroke="#111827" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4 4L12 12" stroke="#111827" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Title>
            <div>
                <div className="grid grid-cols-1 sm:hidden">
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                        onChange={(e) => { changeTab(e.target.value) }}
                        defaultValue={tabs.find((tab) => tab.current).name}
                        aria-label="Select a tab"
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-i[#111827]"
                    >
                        {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                    <ChevronDownIcon
                        aria-hidden="true"
                        className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                    />
                </div>
                <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
                            {tabs.map((tab) => (
                                <a
                                    onClick={() => { changeTab(tab.name) }}
                                    key={tab.name}
                                    href={tab.href}
                                    //aria-current={tab.name == tabActive ? 'page' : undefined}
                                    className={classNames(
                                        tab.name == tabActive
                                            ? 'border-[#111827] text-[#111827]'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                        'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                                    )}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>

            {tabActive == "Templates" &&
                <Contents>

                    <ItemTemplateTeste>
                        <img
                            onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_form_ask: "modelo1" } }) }}
                            src={ThumbTemplateMolyNew} />
                        <ItemTemplateTesteBox>
                            <ItemTemplateTesteInfor>
                                <label>Moly 1.0</label>
                                {configControl.layout_form_ask == "modelo1" && <ItemTemplateTesteStatus>Ativo</ItemTemplateTesteStatus>}
                            </ItemTemplateTesteInfor>

                            <ItemTemplateTesteThemes>
                                <RadioGroup value={configControl.layout_form_ask + "-" + getTheme()}
                                    className="flex items-center gap-x-2.5 flex-wrap gap-y-2.5">
                                    <Radio
                                        onClick={() => { configControl.layout_form_ask == "modelo1" && setTheme("dark") }}
                                        key={"color.name"}
                                        value={"modelo1-dark"}
                                        aria-label={"color.name"}
                                        className={classNames(
                                            "ring-[#222222]",
                                            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                                        )}
                                        data-tip="Tema Escuro"
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames("bg-[#1F2937]", 'size-5 rounded-full border border-black/10')}
                                        />
                                    </Radio>

                                    <Radio
                                        onClick={() => { configControl.layout_form_ask == "modelo1" && setTheme("light") }}
                                        key={"color.name"}
                                        value={"modelo1-light"}
                                        aria-label={"color.name"}
                                        className={classNames(
                                            "ring-[#222222]",
                                            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                                        )}
                                        data-tip="Tema Claro"
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames("bg-[#f2f2f2]", 'size-5 rounded-full border border-black/10')}
                                        />
                                    </Radio>
                                </RadioGroup>
                            </ItemTemplateTesteThemes>
                        </ItemTemplateTesteBox>
                    </ItemTemplateTeste>

                    <ItemTemplateTeste>
                        <img
                            onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_form_ask: "dimona" } }) }}
                            src={ThumbTemplateDimonaNew} />
                        <ItemTemplateTesteBox>
                            <ItemTemplateTesteInfor>
                                <label>Viena 1.0</label>
                                {configControl.layout_form_ask == "dimona" && <ItemTemplateTesteStatus>Ativo</ItemTemplateTesteStatus>}
                            </ItemTemplateTesteInfor>

                            <ItemTemplateTesteThemes>
                                <RadioGroup value={configControl.layout_form_ask + "-" + getTheme()}
                                    className="flex items-center gap-x-2.5 flex-wrap gap-y-2.5">
                                    <Radio
                                        onClick={() => { configControl.layout_form_ask == "dimona" && setTheme("dark") }}
                                        key={"color.name"}
                                        value={"dimona-dark"}
                                        aria-label={"color.name"}
                                        className={classNames(
                                            "ring-[#222222]",
                                            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                                        )}
                                        data-tip="Tema Escuro"
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames("bg-[#1F2937]", 'size-5 rounded-full border border-black/10')}
                                        />
                                    </Radio>

                                    <Radio
                                        onClick={() => { configControl.layout_form_ask == "dimona" && setTheme("light") }}
                                        key={"color.name"}
                                        value={"dimona-light"}
                                        aria-label={"color.name"}
                                        className={classNames(
                                            "ring-[#222222]",
                                            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                                        )}
                                        data-tip="Tema Claro"
                                    >
                                        <span
                                            aria-hidden="true"
                                            className={classNames("bg-[#f2f2f2]", 'size-5 rounded-full border border-black/10')}
                                        />
                                    </Radio>
                                </RadioGroup>
                            </ItemTemplateTesteThemes>
                        </ItemTemplateTesteBox>
                    </ItemTemplateTeste>


                    <ButtonHelp onClick={() => { setVisibleModalVideo(true) }} style={{ maxWidth: "250px" }}>
                        <img width={20} src={IconBossVideo} />
                        <label>{"Tutorial"}</label>
                        <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                    </ButtonHelp>

                    <BoxButtons style={{ maxWidth: "250px" }}>
                        <ButtonReset onClick={() => setDefault("Templates")}>
                            <label>{"Restaurar"}</label>
                        </ButtonReset>

                        <ButtonSave onClick={() => save("Templates")}>
                            {"Salvar"}
                        </ButtonSave>
                    </BoxButtons>

                </Contents>}

            {tabActive == "Cores" &&
                <Contents>
                    <ContentsColors>
                        {getTabColors()}
                    </ContentsColors>
                </Contents>
            }

            {tabActive == "Config" &&
                <Contents>
                    {getTabConfigs()}
                </Contents>}
        </>
    )
}

export default ScreenQuestion