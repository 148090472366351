import styled from "styled-components";


export const Container = styled.div`

  display: flex ;
  //background-color: yellow;
  flex-direction: column;

`

export const SelectScreen = styled.div`

  display: flex ;
  padding-right: 20px;
  //background-color: gray;

`

export const Contents = styled.div`

    display: flex ;
    padding: 20px;
    background-color: ${props => props.backgroundColor};
    margin: 20px;
    border-radius: 4px;
    flex-direction: column;
`


export const BarOptions = styled.div`

  display: flex ;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;

`
export const Action = styled.div`
  background-color:  transparent;
  display: flex ;
  height: 35px ;
  width: 125px;
  margin-left: 10px ;
  align-items: center ;
  justify-content: center ;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  border: ${props => `1px solid ${props.primaryColor}`}  ;

  
  @media(max-width: 1100px) {
     align-items: center;
     justify-content: center !important ;
     margin-left: 0px ;
     :nth-child(1){
        margin-right: 10px ;
     
      }

  }

  :hover{
    background-color:  ${props => props.primaryColor};
    svg{
      color: ${props => props.fontColorHover};
    }
    label{
      color: ${props => props.fontColorHover} ;
    }
  }

  label{
    cursor: pointer;
    margin-left: 5px;
    color: ${props => props.fontColor};
  }

  svg{
    color: ${props => props.primaryColor};
  }
`

export const BoxOptions = styled.div`
  //background-color:  blueviolet;
  display: flex ;
  height: 50px ;
  flex: 1 ;


  @media(max-width: 1100px) {
     align-items: center;
     justify-content: center !important ;


  }
`
export const BoxActions = styled.div`
  //background-color:  aliceblue;
  display: flex ;
  height: 50px ;
  flex: 1 ;
  //align-items: center ;
  justify-content: end ;

  @media(max-width: 1100px) {
     align-items: center;
     justify-content: center !important ;
  }
`
export const Option = styled.span`
  background-color:  ${props => props.backgroundColor};
  display: flex ;
  height: 35px ;
  width: 125px;
  margin-right: 10px ;
  color: ${props => props.fontColor};
  align-items: center ;
  justify-content: center ;
  //border-radius: 2px 2px 0px 0px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: ${props => props.selected ? `2px solid ${props.primaryColor}` : "none"};
  padding-bottom: ${props => props.selected ? "0px" : "2px"} ;

  @media(max-width: 1100px) {
     align-items: center;
     justify-content: center !important ;
     :nth-child(1){
        margin-left: 10px ;
      }

  }



  label{
    cursor: pointer;

  }
`

export const LayoutTitle = styled.div`
    display: flex;
    height: 80px;
    //background-color: red;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #dcdcdc;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    min-width: 100%;
`

export const LayoutTitleText = styled.label`
    display: flex;
    font-weight: 500;
    color: #222222;
    font-size: 1.25em;

`

export const LayoutTitleActios = styled.span`
    display: flex;
    
    gap: 20px;

    label{
      display: flex;
      background-color: #80808022;
      padding: 10px 20px;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;

      :hover{
        opacity: 0.8;
      }
    }
`

export const LayoutInfor = styled.div`
    display: flex;
    min-height: 200px;
    background-color: ${props => props.background};
    margin: 20px 0px;
    padding: 20px;
    gap: 20px;
    border-radius: 0px 0px 8px 8px;
`

export const LayoutInforNota = styled.div`
    display: flex;
    background-color: ${props => props.background};
    align-items: center;
    justify-content: center;
    width: 320px;
    flex-direction: column;
    padding: 60px;
    gap: 2px;
    border-radius: 8px;

    p{
      margin: 0px;
      padding: 0px;
      //background-color: red;
      display: flex;
      width: 100%;
      color: ${props => props.fontColor};
      font-weight: 600;
      font-size: 18px;
    }

    label{
      margin: 0px;
      padding: 0px;
      //background-color: yellow;
      display: flex;
      width: 100%;
      font-size: 14px;
      color: ${props => props.fontColor};
    }

    span{
      display: flex;
      width: 100%;
      //background-color: blue;
      gap: 3px;
    }
`

export const LayoutInforResume = styled.div`
    display: flex;
    background-color: ${props => props.background};
    align-items: center;
    justify-content: center;
    width: 320px;
    flex-direction: column;
    border-radius: 8px;
`

export const LayoutInforFilter = styled.div`
    display: flex;
    background-color: ${props => props.background};
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    border-radius: 8px;
`

export const LayoutData = styled.div`
    display: flex;
    flex-direction: column;
  
`

export const LayoutInforTitle = styled.label`
    display: flex;
    color: ${props => props.fontColor};
    font-weight: 500;
    //background-color: red;
    width: calc(100% - 40px);
    margin-bottom: 10px;
    margin-top: 20px;
  
`

export const LayoutInforContainer = styled.div`
    display: flex;
    //background-color: red;
    width: calc(100% - 40px);
    flex-direction: column;
    height: 100%;
`

export const ItemNota = styled.span`
    display: flex;
    //background-color: yellow;
    width: 100%;
    gap: 5px;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    margin-bottom: 8px;
    color: ${props => props.fontColor};

    :hover{
      opacity: 0.8;
    }

    div{
      display: flex;
      height: 8px;
      flex: 100px;
      background-color: ${props => props.backgroundColor};
      border-radius: 4px;
    }

    p{
      margin: 0px;
      padding: 0px;
      width: 30px;
      text-align: center;
      color: ${props => props.fontColor};
      //background-color: red;
    }

    label{
      width: 20px;
      //background-color: red;
      text-align: right;
      margin-right: 5px;
      color: ${props => props.fontColor};
    }
  
`

export const ItemFilter = styled.label`
    display: flex;
    background-color: ${props => props.background};
    border: 1px solid #dcdcdc;
    max-height: 40px;
    font-size: 11px;
    border-radius: 4px;
    padding: 10px 12px;
    align-items: center;
    cursor: pointer;
    color: ${props => props.color};
    gap: 10px;

    svg{
      color: ${props => props.color}88;;
    }

    :hover{
      opacity: 0.8;
    }
`