const WhatslyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 3000 3000"
      width={22} height={22}
      {...props}
    >
      <style type="text/css">
        {".klsajdklasjfkdaf{fill:#FFFFFF;}.ijidjivjvi{fill:#0CDCA2;}"}
      </style>
      <g>
        <g id="Layer_2">
          <g>
            <path
              className="klsajdklasjfkdaf"
              d="M2705.8,2211.4c127.3-215.4,194.5-461.1,194.1-711.5C2899.9,726.7,2273.1,100,1500,100
				S100.1,726.8,100.1,1499.9S726.7,2900,1500,2900c231.5,0.4,459.3-57.1,663.1-166.8l0,0l696.3,166.8L2705.8,2211.4L2705.8,2211.4z
				"
            />
            <path
              className="ijidjivjvi"
              d="M2522.4,2103.1c107.9-182.5,164.8-391,164.4-603.1c0-655.5-531.5-1186.8-1186.8-1186.8
				S313.2,844.6,313.2,1499.9S844.6,2686.8,1500,2686.8c196.1,0.3,389.5-48.2,562.2-141.3l0,0l590.4,141.3L2522.4,2103.1
				L2522.4,2103.1z"
            />
            <path
              className="klsajdklasjfkdaf"
              d="M1271.8,1469.5l162-251.5c61.5-95.5,201.4-94.3,261.5,1.9l280.6,449c64.4,103.2-9.7,236.9-131.3,236.9l0,0
				c-53.4,0-103-27.5-131.3-72.8l-157.4-251.9L1375,1845c-26.1,38-69.2,60.8-115.3,60.8l0,0c-48.7,0-93.6-25.3-119-66.6
				l-278.7-453.3c-64.8-105.3,11-241.1,134.7-241.1l0,0c57.1,0,109.8,30.8,137.9,80.6L1271.8,1469.5z"
            />

            <ellipse
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -307.6176 1861.7698)"
              className="klsajdklasjfkdaf"
              cx="2093.5"
              cy="1302.2"
              rx="157.3"
              ry="157.3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default WhatslyIcon;
