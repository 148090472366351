import { createContext, useContext, useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import AvaliacaoPopUp from "../components/AvaliacaoPopUp";
import avaliacoesPortugues from "../components/AvaliacaoPopUp/avaliacoes.json";
import avaliacoesEspanhol from "../components/AvaliacaoPopUp/avaliacoes-es.json";


const AvaliacaoPopUpContext = createContext({});

export const AvaliacaoPopUpProvider = ({ children }) => {


	const avaliacoes = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? avaliacoesEspanhol : avaliacoesPortugues

	const [showAvaliacaoModal, setShowAvaliacaoModal] = useState(true);

	const handleCloseAvaliacaoModal = () => setShowAvaliacaoModal(false);
	const handleShowAvaliacaoModal = () => setShowAvaliacaoModal(true);

	const updateRootVariable = (infos) =>
		infos && Object.entries(infos).forEach((info) => document.documentElement.style.setProperty(info[0], info[1]));

	const delayMessageAvaliacao = (segundos) => new Promise((resolve) => setTimeout(() => resolve(), segundos));

	function iniciaAvaliacao(plataforma, base_url, modal) {
		const mensagens = document.querySelector(`${modal} #sak--popupchat`);
		if (!mensagens) return;
		let msgsInfos = [];
		let projectName = "";
		let promise = Promise.resolve();

		projectName = avaliacoes["options"]["app"];
		msgsInfos = avaliacoes[plataforma];
		updateRootVariable({
			"--modal-background": avaliacoes["options"]["background"],
			"--modal-color": avaliacoes["options"]["color"],
			"--modal-load": avaliacoes["options"]["color"],
		});
		mensagem();

		function loading(time, me = 0) {
			return `
            ${me !== 1
					? `
            <span>
                <img src="img/avaliacaoAssets/beatriz.jpeg" alt="beatriz">
                <time>${time}</time>
            </span>
            `
					: ""
				}
            <div>
                <span class="p-1 pr-2 pl-2 d-flex align-items-center justify-content-center">
                    <div class="sak--loading-ponto"></div>
                </span>
            </div>
            ${me === 1 ? "<time>" + time + "</time>" : ""}
            `;
		}

		function horaAgora() {
			const data = new Date();
			const horas = String(data.getHours()).padStart(2, "0");
			const minutos = String(data.getMinutes()).padStart(2, "0");
			return `${horas}:${minutos}`;
		}

		function montaTexto({ texto }) {
			const textoFormated = texto.replace("[APP]", `<strong>${projectName}</strong>`);
			return `
        <div>
            ${textoFormated}
        </div>
    `;
		}

		function montaVideo({ video }) {
			return `
    <div class="popup--videos">
        <video loop autoplay="autoplay">
            <source src="${video}" type="video/mp4">
        </video>
    </div>
    `;
		}

		function montaEscolhas({ buttons }) {
			const buttonsFormated =
				buttons &&
				buttons
					.map(
						(button) =>
							`<a
                ${button.link ? `href="${button.link}" target="_blank" rel="noopener noreferrer"` : 'href="javascript:void(0)"'}
                ${button.step ? `data-step="${button.step}"` : ""}
                ${button.stepInfo ? `data-stepInfo="${button.stepInfo}"` : ""}
                ${button.finalizado ? `data-finalizado="true"` : ""}
                ${button.fechar
								? setTimeout(() => {
									handleCloseAvaliacaoModal();
								}, 2000)
								: ""
							}
                >
                ${button.icon ? `<i class="${button.icon}"></i>` : ""}
                ${button.texto ? button.texto.replace("[APP]", `<strong>${projectName}</strong>`) : ""}</a>`
					)
					.join("");

			return `
      <div class="popup--buttons">
          <span>
              ${buttonsFormated}
          </span>
      </div>
  `;
		}

		function montaMensagem(dados) {
			const random_mensagem_load_seconds = ~~(Math.random() * (1100 - 400) + 400);
			const { tempo, me, tipo, buttons } = dados;

			const tipoMSG = {
				texto: (props) => montaTexto(props),
				video: (props) => montaVideo(props),
				escolha: (props) => montaEscolhas(props),
			};

			const textoFormatado = tipoMSG[tipo] ? tipoMSG[tipo](dados) : "";

			return delayMessageAvaliacao(tempo).then(() => {
				const item = document.createElement("li");
				me === 1 && item.setAttribute("data-me", true);
				item.innerHTML = loading(horaAgora(), me);
				mensagens.appendChild(item);
				scrollItensPage();

				setTimeout(() => {
					item.querySelector("div").outerHTML = textoFormatado;
					scrollItensPage();
					updateButtonStep(buttons, item);
				}, random_mensagem_load_seconds);
			});
		}

		function updateButtonStep(buttons, element) {
			if (!buttons) return;

			const buttonLi = element.querySelectorAll("a");
			buttons.forEach((button, index) => !button["fechar"] && (buttonLi[index].onclick = () => mensagem(button["step"])));
		}

		function scrollItensPage() {
			const item = mensagens.querySelector("li:last-child");
			item &&
				item.scrollIntoView({
					block: "end",
					behavior: "smooth",
				});
		}

		function updateStep(atualStep) {
			const oldStepsButtons = mensagens.querySelectorAll("a");
			if (!oldStepsButtons) return;

			oldStepsButtons.forEach((button) => {
				const deleteButtonsLi = button.parentNode.parentNode.parentNode;
				let { step, stepinfo, finalizado } = button.dataset;

				if (stepinfo && String(step) === String(atualStep)) {
					const isFinalizado = finalizado ? 1 : 0;
					localStorage.setItem("avaliacaoModal", isFinalizado);
					// submitdado(
					// 	{
					// 		dados: `${step}:${stepinfo}`,
					// 		plataforma: plataforma,
					// 		finalizado: finalizado ? 1 : 0,
					// 	},
					// 	base_url + "Ajax/avaliacaoIntegracao"
					// );
				}

				deleteButtonsLi && deleteButtonsLi.remove();
			});
		}

		function mensagem(step = 1) {
			const filterStep = msgsInfos && msgsInfos.filter((dados) => dados.step === step);
			if (!filterStep) return;
			const existSteps = mensagens.querySelectorAll("li");
			existSteps && step === 1 && existSteps.forEach((step) => step.remove());

			filterStep.forEach((msg) => (promise = promise.then(() => montaMensagem(msg))));

			step !== 1 && updateStep(step);
			step === 1 && handleShowAvaliacaoModal();
		}
	}


	// const paramsTeste = useParams();



	//const route = useRouteMatch()

	return (
		<AvaliacaoPopUpContext.Provider
			value={{ iniciaAvaliacao, setShowAvaliacaoModal, handleCloseAvaliacaoModal, handleShowAvaliacaoModal, showAvaliacaoModal }}
		>
			<>
				{JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform === "nuvemshop" &&
					JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire > 0 ?
					<AvaliacaoPopUp /> : null}
				{children}
			</>
		</AvaliacaoPopUpContext.Provider>
	);
};

export const UseAvaliacaoPopUp = () => {
	const context = useContext(AvaliacaoPopUpContext);

	return context;
};
