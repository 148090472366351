import { useEffect, useState } from "react";
import { BoxButtons, BoxThemes, ButtonHelp, ButtonReset, ButtonSave, ItemColor, ItemTheme } from "./styled"
import { useDispatch, useSelector } from 'react-redux';
import IconBossVideo from "../../../../../../assets/imgs/boss/boss-video.svg"
import IconVideo from "../../../../../../assets/imgs/icon-video.svg"

const Colors = ({ save, setDefault, setVisibleModalVideo }) => {

  const dispatch = useDispatch()
  const configControl = useSelector(state => state.reducerControlConfig)
  //const dataValues = JSON.parse(configControl.layout_configs)
  const [dataValues, setValues] = useState(JSON.parse(configControl.layout_configs))

  // background: '#111827',
  // text: '#F3F4F6',
  // textSecondary: '#9CA3AF',
  // border: '#1F2937',
  // starActive: '#FBBF24',
  // starInactive: '#4B5563',
  // verified: '#FFFFFF',
  // backgroundItem: '#1F2937',
  // cardBackground: '#374151'


  useEffect(() => {
    console.log(configControl.layout_configs)
  }, [configControl])

  const setTheme = (theme) => {

    if (configControl.layout_widget == "modelo04") {

      if (theme == "dark") {
        const dataConfig = {
          background: '#111827',
          text: '#F3F4F6',
          textSecondary: '#9CA3AF',
          border: '#1F2937',
          starActive: '#FBBF24',
          starInactive: '#4B5563',
          verified: '#FFFFFF',
          backgroundItem: '#1F2937',
          cardBackground: '#374151'
        }
        dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify(dataConfig) } })
      } else if (theme == "light") {
        const dataConfig = {
          background: '#FFFFFF',
          text: '#374151',
          textSecondary: '#6B7280',
          border: '#E5E7EB',
          starActive: '#FACC15',
          starInactive: '#CCCCCC',
          verified: '#16A34A',
          backgroundItem: '#F5F5F5',
          cardBackground: '#FFFFFF'
        }
        dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify(dataConfig) } })
      }
    }

    else if (theme == "dark") {
      dispatch({
        type: "CONFIG_CONTROL_SET", payload: {
          star_color_primary: "#DAA520",
          star_color_secondary: "#D3D3D3",
          font_color_primary: "#FFFFFF",
          font_color_secondary: "#FFFFFF",
          primary_color: "#808080",
          background_color_primary: "#000000",
          background_color_secondary: "#000000",
          border_card_color: "#ffffff"
        }
      })
    }
    else if (theme == "light") {
      console.log("teste ")
      dispatch({
        type: "CONFIG_CONTROL_SET", payload: {
          star_color_primary: "#DAA520",
          star_color_secondary: "#D3D3D3",
          font_color_primary: "#000000",
          font_color_secondary: "#FFFFFF",
          primary_color: "#000000",
          background_color_primary: "#FFFFFF",
          background_color_secondary: "#F1F1F1",
          border_card_color: "#000000"
        }
      })
    }
  }

  const getTheme = () => {
    if (configControl.layout_widget == "modelo04") {
      if (configControl.layout_configs == "null") return "light"
      const layout_configs = JSON.parse(configControl.layout_configs)
      if (
        layout_configs.background == '#111827' &&
        layout_configs.text == '#F3F4F6' &&
        layout_configs.textSecondary == '#9CA3AF' &&
        layout_configs.border == '#1F2937' &&
        layout_configs.starActive == '#FBBF24' &&
        layout_configs.starInactive == '#4B5563' &&
        layout_configs.verified == '#FFFFFF' &&
        layout_configs.backgroundItem == '#1F2937' &&
        layout_configs.cardBackground == '#374151'
      ) {
        return "dark"
      } else if (
        layout_configs.background == '#FFFFFF' &&
        layout_configs.text == '#374151' &&
        layout_configs.textSecondary == '#6B7280' &&
        layout_configs.border == '#E5E7EB' &&
        layout_configs.starActive == '#FACC15' &&
        layout_configs.starInactive == '#CCCCCC' &&
        layout_configs.verified == '#16A34A' &&
        layout_configs.backgroundItem == '#F5F5F5' &&
        layout_configs.cardBackground == '#FFFFFF'
      ) {
        return "light"
      } else {
        return "custom"
      }
    } else {
      if (
        configControl.star_color_primary == "#DAA520" &&
        configControl.star_color_secondary == "#D3D3D3" &&
        configControl.font_color_primary == "#FFFFFF" &&
        configControl.font_color_secondary == "#FFFFFF" &&
        configControl.primary_color == "#808080" &&
        configControl.background_color_primary == "#000000" &&
        configControl.background_color_secondary == "#000000" &&
        configControl.border_card_color == "#ffffff"
      ) {
        return "dark"
      } else if (
        configControl.star_color_primary == "#DAA520" &&
        configControl.star_color_secondary == "#D3D3D3" &&
        configControl.font_color_primary == "#000000" &&
        configControl.font_color_secondary == "#FFFFFF" &&
        configControl.primary_color == "#000000" &&
        configControl.background_color_primary == "#FFFFFF" &&
        configControl.background_color_secondary == "#F1F1F1" &&
        configControl.border_card_color == "#000000"
      ) {
        return "light"
      } else {
        return "custom"
      }
    }
  }


  return (
    <>
      <BoxThemes>
        <ItemTheme
           onClick={() => { configControl.layout_widget == "modelo04" && setTheme("light") }}
          className={getTheme() == "light" ? "active" : ""}
          background="#FFFFFF"
          textColor="#374151"
        >
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </ItemTheme>
        <ItemTheme
           onClick={() => { configControl.layout_widget == "modelo04" && setTheme("dark") }}
          className={getTheme() == "dark" ? "active" : ""}
          background="#1F2937"
          textColor="#FFFFFF"
        >
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </ItemTheme>
      </BoxThemes>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Fundo principal</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), backgroundItem: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.layout_configs)?.backgroundItem} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), backgroundItem: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.layout_configs)?.backgroundItem} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Fundo auxiliar</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), cardBackground: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.layout_configs)?.cardBackground} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), cardBackground: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.layout_configs)?.cardBackground} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Texto principal</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), text: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.layout_configs)?.text} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), text: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.layout_configs)?.text} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Texto auxiliar</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), textSecondary: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.layout_configs)?.textSecondary} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), textSecondary: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.layout_configs)?.textSecondary} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Estrela principal</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), starActive: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.layout_configs)?.starActive} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), starActive: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.layout_configs)?.starActive} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Estrela auxiliar</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), starInactive: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.layout_configs)?.starInactive} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), starInactive: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.layout_configs)?.starInactive} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Icone de verificação</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), verified: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.layout_configs)?.verified} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), verified: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.layout_configs)?.verified} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Borda</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), border: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.layout_configs)?.border} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify({ ...JSON.parse(configControl.layout_configs), border: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.layout_configs)?.border} />
        </span>
      </ItemColor>

      <ButtonHelp
        onClick={() => setVisibleModalVideo(true)}
        style={{ minWidth: "250px" }}>
        <img width={20} src={IconBossVideo} />
        <label>{"Precisa de ajuda?"}</label>
        <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
      </ButtonHelp>

      <BoxButtons style={{ minWidth: "250px" }}>
        <ButtonReset onClick={() => setDefault()}>
          <label>{"Restaurar"}</label>
        </ButtonReset>
        <ButtonSave onClick={() => save()}>
          {"Salvar"}
        </ButtonSave>
      </BoxButtons>

    </>
  )
}

export default Colors