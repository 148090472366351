import { ReactNode, forwardRef } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { twMerge } from 'tailwind-merge';

interface DropdownMenuTriggerProps {
	children: ReactNode;
	disabled?: boolean;
	className?: string;
}

export const DropdownMenuTrigger = forwardRef<HTMLButtonElement, DropdownMenuTriggerProps>(({ children, disabled, ...props }, ref) => {
	return (
		<DropdownMenuPrimitive.Trigger ref={ref} asChild disabled={disabled}>
			<button className={twMerge('w-full !outline-none', props.className)}>{children}</button>
		</DropdownMenuPrimitive.Trigger>
	);
});

DropdownMenuTrigger.displayName = 'DropdownMenuTrigger';
