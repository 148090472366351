import styled from "styled-components";

export const ItemSelect = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;

    label{
        font-size: 12px;
    }
  
`

