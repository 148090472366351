import React, { useEffect, useState } from 'react'
import { BoxButtons, BoxColors, BoxOptions, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Container, Efeito, EstiloWidget, InputCustom, Item, ItemLayout, ItemSelect, Layout, Posicao, SelectCustom, Subtitle, Titulo } from './styled'

import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useDispatch, useSelector } from 'react-redux';

import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';
import { BarSubmenu, BarSubmenuItem, ItemOption, Main, MenuOption, Title } from '../bar-config-product-cores/styled';
import { IoIosArrowBack } from 'react-icons/io';


import IconText from "../../../../assets/imgs/icon-text.svg";
import { useHistory, useLocation } from 'react-router';
import { CounterItemsCircle, InputTextAreaCustom } from '../box-config-tema/styled';
import { CloudChange, Setting2, Text } from 'iconsax-react';


const BarConfigPerguntarConfig = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const selectedMenu = useLocation();
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const [controlVisibleMidias, setControlVisibleMidias] = useState("")









    const save = () => {

        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                screen_question_config_title_comment: configControl.screen_question_config_title_comment,
                screen_question_config_title_data: configControl.screen_question_config_title_data,
                screen_question_config_title_end: configControl.screen_question_config_title_end,
                screen_question_config_visible_data_whatsapp: configControl.screen_question_config_visible_data_whatsapp,
                terms_use_question: configControl.terms_use_question,
            }
        })

    }

    const getDefault = () => {
        const themaDefault = {
            id: config.data.id,
            screen_question_config_title_comment: "QUAL SUA DÚVIDA?",
            screen_question_config_title_data: "SOBRE VOCÊ",
            screen_question_config_title_end: "OBRIGADO !",
            screen_question_config_visible_data_whatsapp: "1",
            terms_use_question: "Ao enviar eu reconheço os termos e a política de privacidade, e que minha análise será postada publicamente e compartilhada on-line.",
        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })
    }


    return (
        <Main>

            <Container visible={true}>

                {/* <Title>
                    <label>
                        {translation.thema.telaPerguntar}
                    </label>
                </Title>

                <MenuOption>
                    <ItemOption active={false} onClick={() => {
                        dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "perguntar/cores" })
                    }}>
                        {translation.thema.homepage.cores}
                    </ItemOption>
                    <ItemOption active={true} onClick={() => { dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "perguntar/config" }) }}>
                        {translation.thema.homepage.avancado}
                    </ItemOption>
                </MenuOption> */}

                <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <img width={20} src={IconText} />
                                <label>Títulos</label>
                            </div>
                        </span>
                    </Titulo>

                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.telaComentario}</label>
                        <InputCustom
                            type={"text"}
                            value={configControl.screen_question_config_title_comment}
                            placeholder={""}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_config_title_comment: e.target.value } }) }}
                        />
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.telaDados}</label>
                        <InputCustom
                            type={"text"}
                            value={configControl.screen_question_config_title_data}
                            placeholder={""}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_config_title_data: e.target.value } }) }}
                        />
                    </ItemSelect>

                    <ItemSelect>
                        <label>{translation.thema.boxConfigTema.telaAgradecimento}</label>
                        <InputCustom
                            type={"text"}
                            value={configControl.screen_question_config_title_end}
                            placeholder={""}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_config_title_end: e.target.value } }) }}
                        />
                    </ItemSelect>

                </BoxOptions>

                <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <Setting2 size="24" color="#161718" />
                                <label style={{ marginLeft: "10px" }}>Configurações</label>
                            </div>
                        </span>

                    </Titulo>

                    <ItemSelect>
                        <label>Whatsapp:</label>
                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_question_config_visible_data_whatsapp: e.target.value } }) }}>
                            <option value={"1"} selected={configControl.screen_question_config_visible_data_whatsapp == "1" ? true : false}>Habilitar whatsapp</option>
                            <option value={"0"} selected={configControl.screen_question_config_visible_data_whatsapp == "0" ? true : false}>{translation.thema.boxConfigTema.desabilitarWhats}</option>
                        </SelectCustom>
                    </ItemSelect>
                </BoxOptions>
                {/* 
                <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <Text size="24" color="#161718" />
                                <label style={{marginLeft: "10px"}}>Termos de uso</label>
                            </div>
                        </span>

                    </Titulo>
                    <ItemSelect style={{ position: "relative" }}>
                        <label>{translation.thema.boxConfigTema.termosUso}:</label>
                        <InputTextAreaCustom
                            value={configControl.terms_use}
                            rows={6}
                            maxLength="255"
                            placeholder={`${translation.thema.boxConfigTema.modelo}`}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { terms_use: e.target.value } }) }}
                        />
                        <CounterItemsCircle porc={`${Math.round(configControl.terms_use?.length / 255 * 100)}%`} colorBorder={configControl.terms_use?.length < 255 ? "green" : "red"}>
                            <label>{255 - configControl.terms_use?.length}</label>
                        </CounterItemsCircle>
                    </ItemSelect>
                </BoxOptions> */}


                <ButtonHelp>
                    <img width={20} src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() => getDefault()}>
                        <label>{translation.thema.boxConfigEmail.restaurar}</label>
                    </ButtonReset>
                    <ButtonSave onClick={() => save()}>
                        {translation.thema.boxConfigEmail.salvar}
                    </ButtonSave>
                </BoxButtons>
            </Container>
        </Main >

    )
}

export default BarConfigPerguntarConfig