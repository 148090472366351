import React from 'react';
import { Answer, CardWrapper, Content, Date, Question, QuestionContainer, QuestionHeader, StatusTag, UserInfo, UserName } from './styled';

const Ask04 = ({ question, index, isMobile, configControl }) => {
    return (
        <CardWrapper font={configControl?.font} style={{ display: isMobile == true && index > 1 ? "none" : "block" }}>
            <Content>
                <QuestionContainer>
                    <QuestionHeader>
                        <Question>{question.question}</Question>
                        <StatusTag style={{ display: isMobile ? 'none' : 'initial' }} >Respondida</StatusTag>
                    </QuestionHeader>
                    <Answer>{question.answer}</Answer>
                </QuestionContainer>
                <UserInfo>
                    <UserName>{question.user}</UserName>
                    <Date>{question?.date?.replaceAll("-","/")}</Date>
                </UserInfo>
            </Content>
        </CardWrapper>
    );
};

export default Ask04;
