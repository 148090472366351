import React from 'react';
import { Answer, CardWrapper, Content, Date, Question, QuestionContainer, UserInfo, UserName } from './styled';

const Ask02 = ({ question, index, isMobile, configControl }) => {
    return (
        <CardWrapper font={configControl?.font} style={{ display: isMobile == true && index > 1 ? "none" : "block" }}>
            <Content>
                <QuestionContainer>
                    <Question>{question.question}</Question>
                    <Answer>{question.answer}</Answer>
                    <UserInfo>
                        <UserName>{question.user}</UserName>
                        <Date>{question.date}</Date>
                    </UserInfo>
                </QuestionContainer>
            </Content>
        </CardWrapper>
    );
};

export default Ask02;