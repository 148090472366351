import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Modal from '@radix-ui/react-dialog';
import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface ModalProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children: ReactNode;
	contentClass?: string;
}
const DialogOverlay = forwardRef<React.ElementRef<typeof Modal.Overlay>, React.ComponentPropsWithoutRef<typeof Modal.Overlay>>(({ className, ...props }, ref) => (
	<Modal.Overlay ref={ref} className={twMerge('fixed inset-0 z-[1] z-50 bg-gray-500 bg-opacity-75 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0', className)} {...props} />
));
DialogOverlay.displayName = Modal.Overlay.displayName;

type DialogDescriptionProps = React.HTMLAttributes<HTMLParagraphElement>;

const DialogDescription = forwardRef<HTMLParagraphElement, DialogDescriptionProps>(({ className, ...props }, ref) => <Modal.Description ref={ref} className={twMerge('text-sm text-muted-foreground', className)} {...props} />);

DialogDescription.displayName = Modal.Description.displayName;

export const ModalTitle = forwardRef<React.ElementRef<typeof Modal.Title>, React.ComponentPropsWithoutRef<typeof Modal.Title>>(({ className, ...props }, ref) => <Modal.Title ref={ref} className={twMerge('text-lg font-semibold text-gray-800 leading-none tracking-tight', className)} {...props} />);
ModalTitle.displayName = Modal.Title.displayName;

export const ModalFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => <div className={twMerge('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} />;
ModalFooter.displayName = 'ModalFooter';
const ModalContent = ({ children, ...props }: ModalProps) => {
	// const extractedChildren = Children.toArray(children);
	// const modalTitle = extractedChildren.find((child) => isValidElement(child) && child.type === ModalTitle);
	// const modalFooter = extractedChildren.find((child) => isValidElement(child) && child.type === ModalFooter);

	// const remainingChildren = extractedChildren.filter((child) => !(isValidElement(child) && [ModalTitle, ModalFooter].includes(child.type as never)));

	return (
		<>
			<Modal.Portal>
				<DialogOverlay />
				<Modal.Content
					className={twMerge(
						'custom-scroll fixed left-[50%] top-[50%] h-[80vh] overflow-hidden rounded-md z-50 bg-white grid w-3/6 translate-x-[-50%] translate-y-[-50%] gap-4 border p-4 pr-1 pt-8 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
						props.className
					)}>
					{children}

					{/* <div className='border-b p-4 pt-0'>{modalTitle}</div>

					<div className={twMerge('overflow-y-auto custom-scroll pr-1 h-5/6', props.contentClass)}>{remainingChildren}</div>

					<div className='bg-white p-4 border-t'>{modalFooter}</div> */}

					<Modal.Close asChild>
						<button className='absolute right-2.5 top-2.5 inline-flex size-[16px] appearance-none items-center justify-center rounded-full text-gray-900 outline-none' aria-label='Close'>
							<XMarkIcon />
						</button>
					</Modal.Close>
				</Modal.Content>
			</Modal.Portal>
		</>
	);
};

export default ModalContent;
