import styled from 'styled-components';

export const CardWrapper = styled.div`
  background: ${props => props.theme.cardBackground};
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  padding: 1.75rem;
  width: 100%;
  margin: 0 0 1.5rem 0;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  font-family: ${props => props.font}, sans-serif;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
`;

export const IconContainer = styled.div`
  width: 36px;
  height: 36px;
  min-width: 36px;
  border-radius: 12px;
  background: ${props => props.theme.backgroundItem};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.text};
  font-size: 1.25rem;
  transition: transform 0.2s ease;

  &:hover {
    transform: rotate(15deg);
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const Question = styled.h3`
  color: ${props => props.theme.text};
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
`;

export const Answer = styled.p`
  color: ${props => props.theme.textSecondary};
  font-size: 1rem;
  line-height: 1.6;
  margin: 0.75rem 0 0 0;
  padding: 1rem 1.25rem;
  background: ${props => props.theme.backgroundItem};
  border-radius: 12px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 24px;
    width: 16px;
    height: 16px;
    background: ${props => props.theme.backgroundItem};
    transform: rotate(45deg);
  }
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  padding-top: 1.25rem;
  border-top: 1px solid ${props => props.theme.border};
`;

export const UserName = styled.span`
  color: ${props => props.theme.text};
  font-size: 0.95rem;
  font-weight: 500;
`;

export const Date = styled.span`
  color: ${props => props.theme.textSecondary};
  font-size: 0.9rem;
  font-weight: 400;
`;
