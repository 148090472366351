import styled from "styled-components";


export const Container = styled.div`

  display: flex ;
  //background-color: yellow;
  flex-direction: column;

`

export const SelectScreen = styled.div`

  display: flex ;
  padding-right: 20px;
  //background-color: gray;

`

export const BarOptions = styled.div`

  display: flex ;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;

`

export const WidgetButton = styled.div`

  display: flex ;
  background-color: #fff;
  margin: 20px;
  border-radius: 4px;
  padding: 20px 0px 20px 20px;
  justify-content: end;

`
export const ButtonAction = styled.label`

  writing-mode: vertical-rl; /* Alinha o texto na vertical, de baixo para cima */
  text-orientation: mixed; /* Garante que as letras fiquem legíveis na vertical */
  padding: 20px 10px;
  background-color: #111827;
  color: white;
  font-size: 14px;
  cursor: pointer;
  letter-spacing: 2px;
  border-radius: 20px 0px 0px 20px;
`

// export const Store= styled.div`
//   display: flex ;
//   background-color: #fff;
//   width: calc(100% - 40px);
//   margin: 20px;
//   flex-direction: column;

// `

// export const Menu= styled.div`
//   display: flex ;
//   background-color: purple;
//   width: 100%;
//   padding: 8px;
//   justify-content: space-between;
//   align-items: center;
//   border-radius: 4px;

//   p{
//     display: flex;
//     color: #fff;
//   }

//   label{
//     font-size: 14px;
//     background-color: #fff;
//     height: 36px;
//     width: 200px;
//     border-radius: 4px;
//     display: flex;
//     align-items: center;
//     padding-left: 8px;

//   }

//   div{
//     display: flex;
//     gap: 8px;

//     span{
//       display: flex;
//       flex-direction: column;
//       align-items: center;

//       label{
//         font-size: 12px;
//         width: max-content;
//         height: max-content;
//         background: transparent;
//         color: #fff;
//       }
//     }
//   }

// `

// export const BoxProduct= styled.div`
//   display: flex ;
//   padding: 40px;

// `

// export const WidgetReview= styled.div`
//   display: flex ;

// `

// export const WidgetAsk= styled.div`
//   display: flex ;

// `

// export const BoxImageProduct= styled.div`
//   display: flex ;
//   flex: 1;
//   align-items: center;
//   justify-content: center;

// `

// export const BoxDataProduct= styled.div`
//   display: flex ;
//   flex-direction: column;
//   width: 300px;
//   background-color: yellow;
// `

// export const BoxButtonProduct= styled.div`
//   display: flex ;
//   gap: 20px;
//   margin-top: 20px;

//   label{
//     display: flex;
//     background-color: purple;
//     padding: 8px;
//   }
//   p{
//     display: flex;
//     background-color: purple;
//     padding: 8px;
//   }

// `
