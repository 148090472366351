import React from 'react';
import { Answer, CardWrapper, Content, Date, IconContainer, Question, QuestionContainer, UserInfo, UserName } from './styled';
import { FaQuestion } from 'react-icons/fa';

const Ask03 = ({ question, index, isMobile, configControl }) => {
    return (
        <CardWrapper font={configControl?.font} style={{ display: isMobile == true && index > 1 ? "none" : "block" }}>
            <Content>
                <QuestionContainer>
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
                        <IconContainer style={{ display: isMobile ? 'none' : 'flex' }}>
                            <FaQuestion size={18} />
                        </IconContainer>
                        <div style={{ flex: 1 }}>
                            <Question>{question.question}</Question>
                            <Answer>{question.answer}</Answer>
                        </div>
                    </div>
                </QuestionContainer>
                <UserInfo>
                    <UserName>{question.user}</UserName>
                    <Date>{question.date}</Date> 
                </UserInfo>
            </Content>
        </CardWrapper>
    );
};

export default Ask03;
