import styled from 'styled-components'

export const SelectScreen = styled.div`
  display: flex;
  padding-right: 20px;
  justify-content: end;
  border-bottom: 1px solid #f2f2f2;
`

export const Preview = styled.div`
  display: flex;
  background-color: #E5E7EB;
  margin: 20px;
  //padding: 20px;
  border-radius: 8px;
  width:100%;
  max-width: ${props => props.isMobile ? '400px' : 'calc(100% - 40px)'};
  justify-content: center;
  align-items: center;

  flex-direction: column;

  border-right: 1px solid #e5e7eb;
  border-left: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;

  background-color: rgba(10,23,55,0.5);
  overflow: hidden;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Screen = styled.div`
    display: flex;
    max-width: ${props => props.isMobile ? '360px' : '750px'};   
    flex-direction: column;
    padding: 40px;
    gap: 20px;
    animation: ${props => props.direction === 'next' ? 'slideNext' : 'slidePrev'} 0.3s ease-in-out;
    align-items: center;
    background-color: ${props => props.theme?.background || '#fff'};
    color: ${props => props.theme?.text || '#282828'};
    border-radius: 8px;

    margin-top: 100px;
    margin-bottom: 100px;

    @keyframes slideNext {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slidePrev {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }

    /* @media (max-width: 768px) {
        width: 90%;
        max-width: 90%;
        overflow-x: hidden;
        padding: 16px;
    } */
`

export const Title = styled.div`
    display: flex;
    justify-content: center;

    label {
        font-size: 24px;
        text-align: center;
        color: ${props => props.theme?.text || '#282828'};
    }
`

export const Contents = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`

export const Footer = styled.div`
    display: flex;
    height: 100px;
    background-color: ${props => props.theme?.background || '#fff'};
`

export const ProgressBar = styled.div`
    width: 60px;
    height: 2px;
    background-color: ${props => props.active ? (props.theme?.text || '#282828') : (props.theme?.textSecondary || '#cbd5e1')};
    margin: 0 5px;

    /* @media (max-width: 768px) {
        width: 12px;
    } */
`;

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        display: flex;
        align-items: center;
    }
`;

export const ProgressIndicator = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${props => props.active ? (props.theme?.text || '#282828') : (props.theme?.textSecondary || '#cbd5e1')};
    border-radius: 50%;
    margin: 0 5px;
`;

export const ActiveProgressIndicator = styled(ProgressIndicator)`
    background-color: ${props => props.theme?.text || '#282828'};
`;

export const NavButton = styled.label`
    color: ${props => props.active == false ? (props.theme?.textSecondary || '#cbd5e1') : (props.theme?.text || '#282828')};
    cursor: ${props => props.active == false ? 'default' : 'pointer'};
    font-size: 14px;
    font-weight: 600;
    width: 80px;
    display: flex;
    justify-content: center;

    :hover {
        text-decoration: ${props => props.active == false ? 'none' : 'underline'};
        text-underline-offset: 8px;
    }
`;

export const NavInfor = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
`

export const TextArea = styled.textarea`
    width: ${props => props.isMobile ? '100%' : '400px'};
    height: 200px;
    padding: 15px;
    border: 1px solid ${props => props.theme?.textSecondary || '#cbd5e1'};
    border-radius: 8px;
    resize: none;
    font-size: 14px;
    font-style: italic;
    min-width: 300px;
    color: ${props => props.theme?.text || '#282828'};
    background-color: ${props => props.theme?.background || '#fff'};
    
    &:focus {
        outline: none;
        border-color: ${props => props.theme?.text || '#282828'};
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 500px;

    @media (max-width: 768px) {
        max-width: 300px;
    }
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 8px;

    label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${props => props.theme?.text || '#282828'};
    }
`;

export const Label = styled.label`
    font-size: 14px;
    color: ${props => props.theme?.text || '#282828'};
    font-weight: 600;
    padding-right: 90px;
`;

export const TermsText = styled.p`
    font-size: 12px;
    color: ${props => props.theme?.text || '#282828'};
    line-height: 1.5;
`;

export const Input = styled.input`
    width: calc(100% - 0px);
    padding: 15px;
    border: 1px solid ${props => props.theme?.textSecondary || '#cbd5e1'};
    border-radius: 8px;
    font-size: 14px;
    color: ${props => props.theme?.text || '#282828'};
    background-color: transparent;

    &::placeholder {
        color: ${props => props.theme?.textSecondary || '#94a3b8'};
    }

    &:focus {
        outline: none;
        border-color: ${props => props.theme?.text || '#282828'};
    }
`;

export const SubmitButton = styled.button`
    padding: 12px 30px;
    background: ${props => props.theme?.buttonBackground || '#282828'};
    color: ${props => props.theme?.buttonText || '#fff'};
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    align-self: center;

    opacity: ${props => props.active ? 1 : 0.5};

    &:hover {
        opacity:${props => props.active ? 0.9 : 0.5};
        cursor:${props => props.active ? 'pointer' : 'default'};
    }
`;

export const BoxThanks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 500px;
    text-align: center;

    @media(max-width: 768px) {
        width: 300px;
    }
`;

export const ThankTitle = styled.h2`
    font-size: 16px;
    color: ${props => props.theme?.text || '#282828'};
    font-weight: 600;
`;

export const FinishButton = styled.button`
    padding: 12px 30px;
    background: ${props => props.theme?.buttonBackground || '#282828'};
    color: ${props => props.theme?.buttonText || '#ffffff'};
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    margin-top: 20px;

    &:hover {
        opacity: 0.9;
    }
`;