import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import ReviewsQuestions from "../tailwind/reviews-questions";
import Homepage from "../tailwind/homepage";
import ScreenReview from "../tailwind/screen-review";
import ScreenQuestion from "../tailwind/screen-question";
import Mural from "../tailwind/mural";


const BarLeftMenu = () => {


    const dispatch = useDispatch()
    const selectedMenu = useLocation();
    const edit = useSelector(state => state.reducerControlEdit)
    const location = useLocation()

    useEffect(() => {
        //console.log("selectedMenu", selectedMenu)
    }, [selectedMenu])

    useEffect(() => {
        //console.log("location", location)
    }, [location])


    const getMenu = () => {
        //console.log("getMenu", location)
        switch (location.pathname) {
            case "/personalizar/tema":
                return <ReviewsQuestions />
            case "/personalizar/home":
                return <Homepage />
            case "/personalizar/avaliar":
                return <ScreenReview />
            case "/personalizar/perguntar":
                return <ScreenQuestion />
            case "/personalizar/mural":
                return <Mural />
            case "/personalizar/email":
                return "<Tabs />"
            default:
                break;
        }
    }


    return (
        getMenu()
    )



}

export default BarLeftMenu