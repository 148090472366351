import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { EmpreenderApps } from '../lib/AppsArray';
import React, { ReactNode, useState } from 'react';
import LoaderNavigationApps from './LoaderNavigationApps';
import HeaderApps from './HeaderApps';
import AppPreview from './AppPreview';
import { Button } from './Button';
import { motion } from 'framer-motion';
import Modal from './Modal/Modal';
import ModalContent from './Modal/ModalContent';
import AppModal from './AppModal';
import Boss from './appsIcons/Boss';
import EmpreenderIcon from './appsIcons/EmpreenderIcon';

interface navProps {
	eplusApps: {
		[key: string]: {
			integrations?: Integration;
		};
	} | null;
}

interface appProps {
	icon: ReactNode;
	name: string;
	content: ReactNode;
	eplus: string;
	integrations?: Integration;
	link?: string;
	hide?: boolean;
}

interface Integration {
	[key: string]: {
		show: boolean;
		img: string;
	};
}
const trackClick = (app: string) => {
	fetch(`https://tools.empreender.com.br/produtos-empreender-click?app=${app}&origem=CLICK_QUALQUER`, {
		method: 'POST',
	});
};

const NavigationApps: React.FC<navProps> = ({ eplusApps }) => {
	const contentClass =
		'absolute h-full left-0 top-0 data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-top-52 data-[motion=from-start]:slide-in-from-bottom-52 data-[motion=to-end]:slide-out-to-top-52 data-[motion=to-start]:slide-out-to-bottom-52';

	const [modalAppOpen, setModalAppOpen] = useState(false);
	const [appActive, setAppActive] = useState<appProps | undefined>(undefined);

	const handleOpenModal = (app: appProps) => {
		setModalAppOpen(!modalAppOpen);

		setAppActive(app);
	};

	return (
		<>
			<NavigationMenu.Root orientation='vertical' delayDuration={50} className='relative z-10 flex justify-center w-[250px] box-content pr-0.5'>
				<NavigationMenu.List asChild>
					{eplusApps ? (
						<div className='custom-scroll max-h-[270px] overflow-y-auto overflow-x-hidden'>
							<div className='w-[250px] m-0 flex list-none rounded-md bg-white !p-1 flex-col'>
								<NavigationMenu.Item className='w-full transition-all sticky top-0 bg-white'>
									<NavigationMenu.Trigger className='w-full'>
										<HeaderApps />
									</NavigationMenu.Trigger>
									<NavigationMenu.Content className={contentClass}>
										<ul className='w-[300px] !p-5 h-full'>
											<div className='w-full flex flex-col gap-4 h-full justify-between'>
												<div className='flex flex-row items-center gap-2'>
													<div className='!p-2 !px-4 bg-gray-100 rounded-lg flex items-center justify-center'>
														<span className='flex flex-row gap-1 items-center'>
															<Boss />
															<EmpreenderIcon />
														</span>
													</div>
													<span className='text-gray-900 font-semibold break-normal whitespace-nowrap font-base'></span>
												</div>
												<p className='text-gray-900 text-sm'>+20 apps por R$297/mês. Economize até 60% e impulsione suas vendas!</p>

												<div className='w-full flex flex-row justify-end'>
													<Button onClick={() => trackClick('EMPREENDERPLUS')} variant='outline' asChild>
														<a href='https://app.empreender.plus' target='_blank'>
															Saiba mais
														</a>
													</Button>
												</div>
											</div>
										</ul>
									</NavigationMenu.Content>
								</NavigationMenu.Item>
								{EmpreenderApps.map((app, index) => (
									<React.Fragment key={index}>
										<NavigationMenu.Item>
											<NavigationMenu.Trigger asChild className='group flex select-none items-center w-full justify-between gap-0.5 rounded px-3 py-2 text-[15px] font-medium leading-none text-gray-900 outline-none'>
												{Object.keys(eplusApps).length > 0 ? (
													<a onClick={() => handleOpenModal(app)} className={`flex flex-row h-8 rounded-md items-center justify-between !p-2 gap-2 hover:bg-gray-100 transition cursor-pointer`}>
														<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: index * 0.03 }} className='flex flex-row gap-2 items-center'>
															<span>{app.icon}</span>
															<span className='text-gray-800 text-xs font-normal'>{app.name}</span>
														</motion.div>
													</a>
												) : (
													<a onClick={() => trackClick(app.eplus)} href={`https://app.empreender.plus/?app=${app.eplus}`} target='_blank' className={`flex flex-row h-8 rounded-md items-center justify-between !p-2 gap-2 hover:bg-gray-100 transition cursor-pointer`}>
														<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: index * 0.03 }} className='flex flex-row gap-2 items-center'>
															<span>{app.icon}</span>
															<span className='text-gray-800 text-xs font-normal'>{app.name}</span>
														</motion.div>
													</a>
												)}
											</NavigationMenu.Trigger>
											<NavigationMenu.Content className={contentClass}>
												<AppPreview app={app} eplusApps={eplusApps} />
											</NavigationMenu.Content>
										</NavigationMenu.Item>
									</React.Fragment>
								))}
								<NavigationMenu.Indicator className='top-full z-10 flex h-2.5 items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]'>
									<div className='relative top-[70%] size-2.5 rotate-45 rounded-tl-sm bg-white' />
								</NavigationMenu.Indicator>
							</div>
						</div>
					) : (
						<LoaderNavigationApps />
					)}
				</NavigationMenu.List>

				<div className='perspective-[2000px] absolute top-[-6px] left-[259px] flex w-[300px] justify-center h-[282px] !pointer-events-auto'>
					<NavigationMenu.Viewport className='pointer-events-auto relative h-[282px] w-[300px] origin-[top_center] overflow-hidden rounded-md bg-white data-[state=open]:border border-gray-100 data-[state=open]:animate-in duration-150 data-[state=closed]:animate-out data-[state=closed]:slide-in-right-[10px] data-[state=closed]:fade-out data-[state=open]:fade-in data-[state=open]:slide-in-from-left-[10px]' />
				</div>
			</NavigationMenu.Root>

			<Modal open={modalAppOpen} onOpenChange={setModalAppOpen}>
				<ModalContent>{appActive && <AppModal app={appActive} />}</ModalContent>
			</Modal>
		</>
	);
};

export default NavigationApps;
