import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Fortaleza from "../../components-new/templatesReviewsQuestions/fortaleza";
import Geb from "../../components-new/templatesReviewsQuestions/geb/preview";
import { useEffect, useState } from "react";
import PreviewTemplate from "../../components/preview-template";
import PreviewTema from "../../components/preview-theme";
import { toast } from "react-toastify";
import { getTranslation } from "../../../../translations";
import PreviewHome from "../../components/preview-home";
import PreviewHomeCarrossel from "../../components/preview-home-carrossel";
import PreviewHomeCarrosselMulti from "../../components/preview-home-carrossel-multi";
import PreviewAvaliar from "../../components/preview-avaliar";
import PreviewPerguntar from "../../components/preview-perguntar";
import PreviewMural from "../../components/preview-mural";
import BarConfigEmailAjustes from "../../components/bar-config-email-ajustes";
import PreviewEmail from "../../components/preview-email";
import ModalConfigTimeEmail from "../../components/modal-config-time-email";
import ModalConfigGoogle from "../../components/modal-config-google";
import ModalConfigNotifications from "../../components/modal-config-notifications";
import Dimona from "../../components-new/templatesReviewsQuestions/dimona/preview";
import PreviewDimona from "../../components-new/templatesScreenReview/dimona/preview";
import PreviewDimonaAsk from "../../components-new/templatesScreenQuestions/dimona/preview";

const Home = () => {

    const selectedMenu = useLocation();
    const configControl = useSelector(state => state.reducerControlConfig)
    const dispatch = useDispatch()

    const reducerControlVisibleModalEmail = useSelector(state => state.reducerControlVisibleModalEmail)
    const reducerControlVisibleModalGoogle = useSelector(state => state.reducerControlVisibleModalGoogle)
    const reducerControlVisibleModalNotifications = useSelector(state => state.reducerControlVisibleModalNotifications)
    const [visibleModalEmail, setVisibleModalEmail] = useState(false)
    const [visibleModalGoogle, setVisibleModalGoogle] = useState(false)
    const [visibleModalNotifications, setVisibleModalNotifications] = useState(false)

    const statusUpdateConfig = useSelector(state => state.reducerUpdateConfig)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        const id = "statusUpdateConfig"
        if (!(statusUpdateConfig.loading == false && !statusUpdateConfig.error == false && statusUpdateConfig.data.length == 0)) {
            if (statusUpdateConfig.loading) {
                toast.loading(translation.thema.aguarde, { toastId: id })
            } else {
                if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.thema.podeLevar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
                    dispatch({ type: "SAGA_GET_CONFIG", })
                }
            }
        } else if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    }, [statusUpdateConfig])

    useEffect(() => {
        dispatch({ type: "SAGA_GET_CONFIG" })
        dispatch({ type: "SAGA_GET_CONFIG_MURAL" })
    }, [])

    // < ModalConfigNotifications visible = { reducerControlVisibleModalNotifications } setVisibleModal = { setVisibleModalNotifications } />
    //     <ModalConfigTimeEmail visible={reducerControlVisibleModalEmail} setVisibleModal={setVisibleModalEmail} />
    //     <ModalConfigGoogle visible={reducerControlVisibleModalGoogle} setVisibleModal={setVisibleModalGoogle} />


    const getTemplate = (template) => {
        console.log("template", template)
        switch (template) {
            case "modelo01":
            case "modelo02":
            case "modelo03":
            case "default":
                return (
                    <>
                        <ModalConfigNotifications visible={reducerControlVisibleModalNotifications} setVisibleModal={setVisibleModalNotifications} />
                        <PreviewTema />
                    </>
                )
            case "modelo04":
                return (
                    <>
                        <ModalConfigNotifications visible={reducerControlVisibleModalNotifications} setVisibleModal={setVisibleModalNotifications} />
                        <Geb />
                    </>
                )
            case "dimona":
                return (
                    <>
                        <ModalConfigNotifications visible={reducerControlVisibleModalNotifications} setVisibleModal={setVisibleModalNotifications} />
                        <Dimona />
                    </>
                )
            default:
                break;

        }
    }

    const getWidgetHome = () => {
        switch (configControl.homereviews_widget_style) {
            case "grid":
                return <PreviewHome />
            case "carousel":
                return <PreviewHomeCarrossel />
            case "carousel-multi":
                return <PreviewHomeCarrosselMulti />
            default:
                return <PreviewHomeCarrosselMulti />
        }
    }

    const getWidgetScreenReview = () => {
        switch (configControl.layout_form) {
            case "modelo1":
                return <PreviewAvaliar />
            case "dimona":
                return <PreviewDimona />
            default:
                return <PreviewAvaliar />
        }
    }

    const getWidgetScreenAsk = () => {
        switch (configControl.layout_form_ask) {
            case "modelo1":
                return <PreviewPerguntar />
            case "dimona":
                return <PreviewDimonaAsk /> 
            default:
                return <PreviewPerguntar />
        }
    }

    useEffect(() => {
        //console.log("configControl", configControl?.layout_widget)
    }, [configControl])

    if (selectedMenu.pathname == "/personalizar/tema" || selectedMenu.pathname == "/personalizar") {
        return getTemplate(configControl?.layout_widget)
    }

    if (selectedMenu.pathname == "/personalizar/home") { return getWidgetHome() }
    if (selectedMenu.pathname == "/personalizar/avaliar") { return getWidgetScreenReview() }
    if (selectedMenu.pathname == "/personalizar/perguntar") { return getWidgetScreenAsk() }
    if (selectedMenu.pathname == "/personalizar/mural") {
        return (
            <>
                <ModalConfigGoogle visible={reducerControlVisibleModalGoogle} setVisibleModal={setVisibleModalGoogle} />
                <PreviewMural />
            </>
        )
    }
    if (selectedMenu.pathname == "/personalizar/email") {
        return (
            <div style={{ minHeight: "100vh", paddingBottom: "20px" }}>
                <ModalConfigTimeEmail visible={reducerControlVisibleModalEmail} setVisibleModal={setVisibleModalEmail} />
                <iframe style={{ minHeight: "100vh" }}
                    width={"100%"} height={"100%"} src={`${process.env.REACT_APP_URL_EDITOR_EMAIL}/editor?name=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country == "BR" || JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.country == "PT" ? "review" : "review_es"}&app=lily&user_id_ref=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id}&token=${process.env.REACT_APP_TOKEN_EDITOR_EMAIL}`} /> :
            </div>
        )
    }

    return "null"
}

export default Home