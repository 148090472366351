import * as React from 'react';
import { twMerge } from 'tailwind-merge';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	asChild?: boolean;
	isActive?: boolean;
	children?: React.ReactNode;
	className?: string;
	variant?: string;
	size?: string;
}

interface buttonClassesProps {
	[key: string]: string;
}

const buttonClassNames = ({ variant = 'default', size = 'default' } = {}) => {
	const baseClasses = 'select-none inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50';

	const variantClasses: buttonClassesProps = {
		default: 'text-gray-900 hover:bg-gray-50 text-center',
		secondary: 'bg-gray-900 text-white hover:bg-gray-900/90',
		destructive: 'bg-red-700 text-white hover:bg-red-700/90',
		outline: 'text-gray-900 border border-gray-900 hover:bg-gray-900 hover:text-white',
		link: 'text-gray-900 underline-offset-4 hover:underline',
	};

	const sizeClasses: buttonClassesProps = {
		default: 'px-4 py-1.5',
		icon: 'h-9 w-9',
	};

	return [baseClasses, variantClasses[variant] || variantClasses.default, sizeClasses[size] || sizeClasses.default].join(' ');
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, asChild = false, children, variant = 'default', size = 'default', ...props }, ref) => {
	const currentClass = buttonClassNames({ variant, size });

	if (asChild && React.isValidElement(children)) {
		return React.cloneElement(children as React.ReactElement<ButtonProps>, {
			className: twMerge(currentClass, (children as React.ReactElement<ButtonProps>).props.className, className),
			...props,
		});
	}

	return (
		<button className={twMerge(currentClass, className)} ref={ref} {...props}>
			{children}
		</button>
	);
});

Button.displayName = 'Button';

export { Button };
