import { ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useSubMenu } from '../SubMenuContext';

interface SeparadorProps {
	children: ReactNode;
	isSidebarOpen?: boolean;
}

const Separator = ({ children }: SeparadorProps) => {

	const { isSidebarOpen } = useSubMenu();

	return (
		<div className={`h-5 flex items-center select-none`}>
			<div className={`font-semibold text-gray-400 text-xs break-normal whitespace-nowrap flex items-center h-full`}>
				<AnimatePresence mode="popLayout">
					{isSidebarOpen ? (
						<motion.span className='h-full relative top-px' key={'texto'} initial={isSidebarOpen ? false : { opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.1 }} exit={{ opacity: 0 }}>
							{children}
						</motion.span>
					) : (
						<div className='w-9 h-px bg-gray-100' key={'linha'} />
					)}
				</AnimatePresence>
			</div>
		</div>
	);
};

export default Separator;
