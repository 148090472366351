import React, { useEffect, useState } from 'react'
import { ActionReview, BarOptions, Box, BoxAbas, BoxActionFilter, BoxConfig, BoxList, BoxResume, CardWrapper, Container, Content, Contents, CustomSelect, Date, Divider, GridContainer, Header, InforReviews, InforReviewsLine, ItemAba, ItemStar, QtdReview, Rating, ReviewText, SelectFilter, SelectScreen, UserAvatar, UserInfo, UserName } from './styled'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../../../translations'
import { FaStar } from 'react-icons/fa'
import CardLily from '../../../../components/card-review-lily-preview'
import { reviews } from '../../../../pages/home/dataPreview'
import { ThemeProvider } from 'styled-components'
import Review01 from '../cards/review01'
import Review02 from '../cards/review02'
import Review03 from '../cards/review03'
import Review04 from '../cards/review04'
import Ask01 from '../cards/ask01'
import Ask02 from '../cards/ask02'
import Ask03 from '../cards/ask03'
import Ask04 from '../cards/ask04'
import { BarConfig, BarConfigLeft, BarConfigRight } from '../../dimona/preview/styled'


const Geb = ({ tema = 'dark', cores }) => {


    const temas = {
        light: {
            background: '#FFFFFF',
            text: '#374151',
            textSecondary: '#6B7280',
            border: '#E5E7EB',
            starActive: '#FACC15',
            starInactive: '#CCCCCC',
            verified: '#16A34A',
            backgroundItem: '#FFFFFF', //#FFFFFF
            cardBackground: '#F5F5F5'
        },
        dark: {
            background: '#111827',
            text: '#F3F4F6',
            textSecondary: '#9CA3AF',
            border: '#1F2937',
            starActive: '#FBBF24',
            starInactive: '#4B5563',
            verified: '#FFFFFF',
            backgroundItem: '#1F2937',
            cardBackground: '#374151'
        }
    }

    const [optionSelected, setOptionSelected] = useState("reviews")
    const [isMobile, setIsMobile] = useState(false)
    const configControl = useSelector(state => state.reducerControlConfig)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    // Determina o tema atual baseado na prop tema
    //const temaAtual = tema === 'dark' || tema === 'light' ? temas[tema] : cores
    const temaAtual = configControl.layout_configs == "null" ? temas.light : JSON.parse(configControl.layout_configs)
    // Se for custom e não tiver cores definidas, usa o tema light como fallback
    const currentTheme = temaAtual || temas.light

    const [color_verified, set_color_verified] = useState(currentTheme.verified)
    const [color_star_active, set_color_star_active] = useState(currentTheme.starActive)
    const [color_star_inactive, set_color_star_inactive] = useState(currentTheme.starInactive)

    const reviewsData = [
        {
            id: 1,
            media: [
                {
                    type: 'video',
                    url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                    thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                },
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                }
            ],
            text: "Comprei várias camisetas e estou impressionada com a qualidade!",
            user: "Ana Carolina Silva",
            date: "2024-02-10",
            rating: 5
        },
        {
            id: 2,
            media: [
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                }
            ],
            text: "Excelente atendimento e entrega super rápida. A camiseta tem um caimento perfeito e o tamanho está certinho conforme a tabela. Já quero comprar mais!",
            user: "Pedro Henrique Santos",
            date: "2024-02-08",
            rating: 5
        },
        {
            id: 3,
            media: [
                {
                    type: 'video',
                    url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                    thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                }
            ],
            text: "Adorei a qualidade das camisetas! O único detalhe é que achei que a cor seria um pouco diferente, mas ainda assim ficou ótimo.",
            user: "Mariana Costa",
            date: "2024-02-05",
            rating: 3
        },
        {
            id: 4,
            media: [
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                },
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                },
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                }
            ],
            text: "Comprei para toda a família e todos adoraram! As camisetas são muito confortáveis e o material é de primeira qualidade. O preço está ótimo pelo que oferece.",
            user: "Roberto Oliveira",
            date: "2024-02-01",
            rating: 5
        },
        {
            id: 5,
            media: [
                {
                    type: 'video',
                    url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                    thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                },
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                }
            ],
            text: "Super recomendo! A estampa é linda e o tecido é bem fresquinho, perfeito para o verão. Só achei que demorou um pouquinho para chegar. O atendimento também foi excelente.",
            user: "Fernanda Lima",
            date: "2024-01-28",
            rating: 5
        },
        {
            id: 6,
            media: [],
            text: "Melhor camiseta que já comprei! O tecido é de excelente qualidade e o acabamento é impecável. Vale cada centavo!",
            user: "Lucas Mendes",
            date: "2024-01-25",
            rating: 5
        },
        {
            id: 7,
            media: [
                {
                    type: 'video',
                    url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                    thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                },
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                },
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                }
            ],
            text: "As camisetas são perfeitas!",
            user: "Juliana Almeida",
            date: "2024-01-20",
            rating: 5
        },
        {
            id: 8,
            media: [
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                }
            ],
            text: "Gostei bastante da camiseta, mas achei que o tamanho ficou um pouco maior do que esperava. A qualidade é boa!",
            user: "Gabriel Santos",
            date: "2024-01-15",
            rating: 5
        },
        {
            id: 9,
            media: [],
            text: "Produto de excelente qualidade! A estampa é linda e o tecido é muito confortável. Já estou planejando comprar mais.",
            user: "Isabela Martins",
            date: "2024-01-10",
            rating: 5
        },
        {
            id: 10,
            media: [
                {
                    type: 'image',
                    url: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                },
                {
                    type: 'video',
                    url: "https://www.youtube.com/watch?v=TGQsw5Hk0RQ",
                    thumbnail: "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png"
                }
            ],
            text: "Ótimo custo-benefício! As camisetas são de qualidade e o preço está muito bom",
            user: "Rafael Costa",
            date: "2024-01-05",
            rating: 4
        }
    ];

    const questionsData = [
        {
            id: 1,
            question: "Como funciona o processo de troca caso o produto não sirva?",
            answer: "O processo de troca é bem simples e rápido! Basta entrar em contato com nosso atendimento em até 7 dias após o recebimento. Enviaremos um código de postagem para você devolver o produto sem custo, e assim que recebermos, faremos a troca pelo tamanho desejado ou o reembolso integral.",
            user: "Marina Santos",
            date: "08-02-2024"
        },
        {
            id: 2,
            question: "Qual a diferença entre os tecidos das camisetas básicas e premium?",
            answer: "Nossas camisetas básicas são feitas com algodão 100% e têm gramatura de 160g/m², ideais para uso diário. Já as premium utilizam um blend especial de algodão pima e elastano, com gramatura de 180g/m², oferecendo mais durabilidade, melhor caimento e toque mais macio. O acabamento também é diferenciado, com costuras reforçadas e gola especial que não deforma.",
            user: "Rafael Costa",
            date: "07-02-2024"
        },
        {
            id: 3,
            question: "Vocês entregam no exterior?",
            answer: "Sim! Realizamos entregas internacionais.",
            user: "John Smith",
            date: "06-02-2024"
        },
        {
            id: 4,
            question: "As medidas da tabela de tamanhos são precisas? Estou em dúvida entre dois tamanhos.",
            answer: "Nossa tabela de tamanhos é atualizada regularmente e as medidas são bem precisas. Para escolher o tamanho ideal, recomendamos medir uma peça que você já tenha e goste do caimento, comparando com nossa tabela. Em caso de dúvida entre dois tamanhos, geralmente é melhor optar pelo maior, especialmente se você preferir um caimento mais confortável.",
            user: "Ana Luiza Mendes",
            date: "05-02-2024"
        },
        {
            id: 5,
            question: "Qual o prazo de entrega?",
            answer: "24h para capitais, 3-5 dias úteis para outras regiões.",
            user: "Pedro Alves",
            date: "04-02-2024"
        },
        {
            id: 6,
            question: "Como é feito o controle de qualidade dos produtos? Gostaria de entender melhor o processo de fabricação e os padrões de qualidade adotados pela empresa.",
            answer: "Nosso controle de qualidade é rigoroso e acontece em várias etapas. Primeiro, inspecionamos toda matéria-prima recebida, verificando a qualidade dos tecidos, aviamentos e acabamentos. Durante a produção, cada peça passa por 3 pontos de verificação: após o corte, durante a costura e no acabamento final. Utilizamos máquinas de última geração e nossa equipe é altamente treinada. Além disso, fazemos testes regulares de lavagem, resistência e durabilidade. Mantemos certificações ISO 9001 e OEKO-TEX, garantindo que nossos produtos sejam livres de substâncias nocivas e sigam padrões internacionais de qualidade.",
            user: "Carla Rodrigues",
            date: "03-02-2024"
        }
    ];

    const tabs = [
        { name: 'Avaliações', href: '#', current: false },
        { name: 'Perguntas', href: '#', current: true },
        { name: 'Estrelas', href: '#', current: false },
        { name: 'Botão', href: '#', current: false },
    ]

    const getCards = (review, index) => {
        console.log("configControl.layout", configControl.layout)
        switch (configControl.layout) {
            case "review01":
                return <Review01 key={index}
                    review={review}
                    index={index} isMobile={isMobile} configControl={configControl} />
            case "review02":
                return <Review02 key={index}
                    review={review}
                    index={index} isMobile={isMobile} configControl={configControl} />
            case "review03":
                return <Review03 key={index}
                    review={review}
                    index={index} isMobile={isMobile} configControl={configControl} />
            case "review04":
                return <Review04 key={index}
                    review={review}
                    index={index} isMobile={isMobile} configControl={configControl} />
            default:
                return <Review01 key={index} review={review} index={index} isMobile={isMobile} configControl={configControl} />

        }
    }

    const getAsks = (question, index) => {
        console.log("configControl.layout_question", configControl.layout_question)
        switch (configControl.layout_question) {
            case "ask01":
                return <Ask01 key={index}
                    question={question}
                    index={index} isMobile={isMobile} configControl={configControl} />
            case "ask02":
                return <Ask02 key={index}
                    question={question}
                    index={index} isMobile={isMobile} configControl={configControl} />
            case "ask03":
                return <Ask03 key={index}
                    question={question}
                    index={index} isMobile={isMobile} configControl={configControl} />
            case "ask04":
                return <Ask04 key={index}
                    question={question}
                    index={index} isMobile={isMobile} configControl={configControl} />
            default:
                return <Ask01 key={index} question={question} index={index} isMobile={isMobile} configControl={configControl} />
        }
    }

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        console.log(configControl)
        if (configControl.visible_button_review == 1 && configControl.visible_button_ask == 0) {
            setOptionSelected("reviews")
        } else if (configControl.visible_button_review == 0 && configControl.visible_button_ask == 1) {
            setOptionSelected("asks")
        }
    }, [configControl])
    return (

        <Container>
            <BarOptions>
                <div style={{ padding: "0px 20px" }}>

                    <div className="grid grid-cols-1 sm:hidden">
                        <select
                            defaultValue={tabs.find((tab) => tab.current).name}
                            aria-label="Select a tab"
                            className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                        >
                            {tabs.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                        <ChevronDownIcon
                            aria-hidden="true"
                            className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                        />
                    </div>
                    <div className="hidden sm:block">
                        <nav aria-label="Tabs" className="flex space-x-4">

                            {configControl.visible_button_review == 1 && (
                                <a
                                    style={{ cursor: "pointer" }}
                                    key={"tab.name"}
                                    onClick={() => { setOptionSelected("reviews") }}
                                    aria-current={optionSelected == "reviews" ? 'page' : undefined}
                                    className={classNames(
                                        optionSelected == "reviews" ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                        'rounded-md px-3 py-2 text-sm font-medium',
                                    )}
                                >
                                    {"Avaliações"}
                                </a>
                            )}

                            {configControl.visible_button_ask == 1 && (
                                <a
                                    style={{ cursor: "pointer" }}
                                    key={"perguntas"}
                                    onClick={() => { setOptionSelected("asks") }}
                                    aria-current={optionSelected == "asks" ? 'page' : undefined}
                                    className={classNames(
                                        optionSelected == "asks" ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                        'rounded-md px-3 py-2 text-sm font-medium',
                                    )}
                                >
                                    {"Perguntas"}
                                </a>)}
                        </nav>
                    </div>
                </div>

                {/* <SelectScreen>
                    <div className="flex my-4 gap-0 m-0">
                        <button
                            onClick={() => setIsMobile(true)}
                            className={classNames(
                                isMobile ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                'w-[40px] p-2 rounded-md text-sm font-medium flex items-center justify-center',
                            )}
                            type="button" data-headlessui-state="" style={{ border: "0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"></path></svg>
                        </button>
                        <button
                            onClick={() => setIsMobile(false)}
                            className={classNames(
                                !isMobile ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                'w-[40px] p-2 rounded-md text-sm font-medium flex items-center justify-center',
                            )}
                            type="button" data-headlessui-state="" style={{ border: "0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"></path></svg>
                        </button>
                    </div>
                </SelectScreen> */}
            </BarOptions>


            <Contents isMobile={isMobile}>
                <BarConfig isMobile={isMobile}>
                    <BarConfigLeft>
                        <span />
                        <span />
                        <span />
                    </BarConfigLeft>
                    <BarConfigRight>
                        <div onClick={() => setIsMobile(false)} style={{ cursor: 'pointer' }}>
                            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.0002 9V2.25C14.0002 1.85218 13.8422 1.47064 13.5609 1.18934C13.2796 0.908035 12.898 0.75 12.5002 0.75H3.5002C3.10237 0.75 2.72084 0.908035 2.43954 1.18934C2.15823 1.47064 2.0002 1.85218 2.0002 2.25V9M14.0002 9H2.0002M14.0002 9L14.9602 10.9125C15.018 11.0272 15.0454 11.1549 15.0397 11.2833C15.034 11.4116 14.9954 11.5364 14.9277 11.6455C14.8599 11.7547 14.7652 11.8446 14.6527 11.9067C14.5402 11.9688 14.4137 12.0009 14.2852 12H1.7152C1.58672 12.0009 1.46016 11.9688 1.34766 11.9067C1.23516 11.8446 1.14049 11.7547 1.07273 11.6455C1.00497 11.5364 0.966395 11.4116 0.960699 11.2833C0.955002 11.1549 0.982377 11.0272 1.0402 10.9125L2.0002 9" stroke={!isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                        <div onClick={() => setIsMobile(true)} style={{ cursor: 'pointer' }}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6893 1.5H6.31066C5.63153 1.5 4.98022 1.76978 4.5 2.25C4.01978 2.73022 3.75 3.38153 3.75 4.06066V13.9393C3.75 14.6185 4.01978 15.2698 4.5 15.75C4.98022 16.2302 5.63153 16.5 6.31066 16.5H11.6893C12.3685 16.5 13.0198 16.2302 13.5 15.75C13.9802 15.2698 14.25 14.6185 14.25 13.9393V4.06066C14.25 3.38153 13.9802 2.73022 13.5 2.25C13.0198 1.76978 12.3685 1.5 11.6893 1.5Z" stroke={isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.2998 13.5H9.7998" stroke={isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </BarConfigRight>
                </BarConfig>
                <ThemeProvider theme={currentTheme}>
                    <Box isMobile={isMobile}>
                        <BoxAbas background={"#ffffff"}>
                            <ItemAba isMobile={isMobile} style={{ fontFamily: `${configControl?.font}, sans-serif`, display: `${configControl?.visible_button_review == 1 ? "flex" : "none"}` }} backgroundItem={"#ECECED"} background={"config?.data?.config?.background_color_primary"} active={optionSelected == "reviews" ? true : false}>
                                {`${"Avaliações"} (${"20"})`}
                            </ItemAba>

                            <ItemAba isMobile={isMobile} style={{ fontFamily: `${configControl?.font}, sans-serif`, display: `${configControl?.visible_button_ask == 1 ? "flex" : "none"}` }} backgroundItem={"#ECECED"} background={"config?.data?.config?.background_color_primary"} active={optionSelected == "asks" ? true : false}>
                                {`${"Dúvidas"} (${"10"})`}
                            </ItemAba>
                        </BoxAbas>

                        {optionSelected == "reviews" ?
                            <>
                                <BoxConfig isMobile={isMobile}>
                                    <BoxResume isMobile={isMobile}>
                                        <QtdReview isMobile={isMobile}>
                                            <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>4.7</label>
                                            <span>
                                                <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"100%"}>
                                                    <FaStar size={28} />
                                                    <FaStar size={28} />
                                                </ItemStar>

                                                <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"100%"}>
                                                    <FaStar size={28} />
                                                    <FaStar size={28} />
                                                </ItemStar>

                                                <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"100%"}>
                                                    <FaStar size={28} />
                                                    <FaStar size={28} />
                                                </ItemStar>

                                                <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"100%"}>
                                                    <FaStar size={28} />
                                                    <FaStar size={28} />
                                                </ItemStar>

                                                <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"80%"}>
                                                    <FaStar size={28} />
                                                    <FaStar size={28} />
                                                </ItemStar>
                                            </span>
                                            <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{"10"} Avaliações</p>
                                        </QtdReview>

                                        {/* <Divider isMobile={isMobile} /> */}

                                        <InforReviews isMobile={isMobile}>
                                            <InforReviewsLine isMobile={isMobile} colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"80%"}>
                                                <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>5</label>
                                                <FaStar size={20} color={JSON.parse(configControl?.layout_configs)?.starActive} />
                                                <span>
                                                    <div />
                                                </span>
                                                <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{8}</p>
                                            </InforReviewsLine>

                                            <InforReviewsLine isMobile={isMobile} colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"10%"}>
                                                <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>4</label>
                                                <FaStar size={20} color={JSON.parse(configControl?.layout_configs)?.starActive} />
                                                <span >
                                                    <div />
                                                </span>
                                                <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{1}</p>
                                            </InforReviewsLine>

                                            <InforReviewsLine isMobile={isMobile} colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"10%"}>
                                                <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>3</label>
                                                <FaStar size={20} color={JSON.parse(configControl?.layout_configs)?.starActive} />
                                                <span >
                                                    <div />
                                                </span>
                                                <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{1}</p>
                                            </InforReviewsLine>

                                            <InforReviewsLine isMobile={isMobile} colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"0%"}>
                                                <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>2</label>
                                                <FaStar size={20} color={JSON.parse(configControl?.layout_configs)?.starActive} />
                                                <span>
                                                    <div />
                                                </span>
                                                <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{0}</p>
                                            </InforReviewsLine>

                                            <InforReviewsLine isMobile={isMobile} colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"0%"}>
                                                <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>1</label>
                                                <FaStar size={20} color={JSON.parse(configControl?.layout_configs)?.starActive} />
                                                <span>
                                                    <div />
                                                </span>
                                                <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}> {0}</p>
                                            </InforReviewsLine>
                                        </InforReviews>
                                    </BoxResume>

                                    <BoxActionFilter isMobile={isMobile}>
                                        <ActionReview isMobile={isMobile} style={{ display: configControl?.no_review_store == 1 ? "none" : "flex", fontFamily: `${configControl?.font}, sans-serif` }}>
                                            Escrever uma avaliação
                                        </ActionReview>
                                        <CustomSelect isMobile={isMobile}>
                                            <SelectFilter isMobile={isMobile} style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                                                <option value="all">Todas</option>
                                                <option value="1star">1 Estrela</option>
                                                <option value="2stars">2 Estrela</option>
                                                <option value="3stars">3 Estrela</option>
                                                <option value="4stars">4 Estrela</option>
                                                <option value="5stars">5 Estrela</option>
                                                <option value="image">Com imagem</option>
                                                <option value="video">Com vídeo</option>
                                            </SelectFilter>
                                        </CustomSelect>

                                    </BoxActionFilter>
                                </BoxConfig>
                                <GridContainer columns={isMobile || configControl.layout == 'review03' || configControl.layout == 'review04' ? 1 : 3}>
                                    {reviewsData.map((review, index) => (getCards(review, index)))}
                                </GridContainer>
                            </>
                            :
                            <div style={{ marginTop: "4px" }}>
                                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '0px' }}>
                                    <ActionReview isMobile={isMobile} style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                                        Escrever uma pergunta
                                    </ActionReview>
                                </div>

                                <>
                                    <GridContainer columns={(isMobile || configControl.layout_question === 'ask03' || configControl.layout_question === 'ask04') ? 1 : 2}>
                                        {questionsData.map((question, index) => getAsks(question, index))}
                                    </GridContainer>
                                </>
                            </div>}


                    </Box>
                </ThemeProvider >
            </Contents>

        </Container >

    )
}

export default Geb;