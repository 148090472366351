import React from 'react';
import { FaStar } from 'react-icons/fa';
import { FaPlayCircle } from 'react-icons/fa';
import { Card, Name, Comment, Header, UserInfo, RatingInfo, DateText, Question, DateInfo } from "./styled";
import { useSelector } from 'react-redux';
import { interval } from '../../../../../../../utils/date';


const Ask01 = ({ ask = {} }) => {

    const configControl = useSelector(state => state.reducerControlConfig)

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        return date.toLocaleDateString('pt-BR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        })
    }


    const formatDateInfor = (dateString) => {
        const [day, month, year] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    }

    function timeAgo(dateString) {
        const [day, month, year] = dateString.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        const now = new Date();
        console.log('Data de entrada:', date);
        console.log('Data atual:', now);
        const seconds = Math.floor((now - date) / 1000);
        console.log('Diferença em segundos:', seconds);
        const intervals = [
            { label: 'ano', seconds: 31536000 },
            { label: 'mês', seconds: 2592000 },
            { label: 'dia', seconds: 86400 },
            { label: 'hora', seconds: 3600 },
            { label: 'minuto', seconds: 60 },
            { label: 'segundo', seconds: 1 }
        ];

        for (const interval of intervals) {
            const count = Math.floor(seconds / interval.seconds);
            if (count >= 1) {
                let label = interval.label;
                if (count > 1) {
                    if (label === 'mês') {
                        label = 'meses';
                    } else {
                        label += 's';
                    }
                }
                return `${count} ${label} atrás`;
            }
        }

        return 'agora mesmo';
    }

    if (!ask) return null;

    return (
        <Card>
            <Header>
                <UserInfo>
                    <Name>{ask.user}</Name>
                    {configControl?.visible_date_review == 1 && <DateText>{"22/02/2025"}</DateText>}
                </UserInfo>
                <RatingInfo>
                    {configControl?.visible_date_review == 1 && <DateInfo>{interval("2025-02-22T22:00:00.000Z")}</DateInfo>}
                </RatingInfo>
            </Header>
            <Question>{ask.question}</Question>  
            <Comment>
                {ask.answer || ask.answer} 
            </Comment>
        </Card>
    );
};

export default Ask01;