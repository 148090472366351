import { twMerge } from 'tailwind-merge';

interface PalettePickerProps {
	primary: string;
	secondary: string;
	backgroundColor?: string;
	opacity?: number;
	isActive?: boolean;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>, olors: string) => void;
	className?: string;
	themeName: string;
}

const PalettePicker = ({ primary, secondary, backgroundColor, opacity = 1, isActive, themeName, ...props }: PalettePickerProps) => {
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>, themeName: string) => {
		e.preventDefault();
		props?.onClick?.(e, themeName);
	};

	return (
		<button {...props} onClick={(e) => handleClick(e, themeName)} className={twMerge(`rounded-lg transition-all ring-1 ring-gray-200 shadow-sm w-[70px] ${isActive ? 'ring-gray-900 ring-offset-1 transition' : ''}`, props.className)}>
			<div className='w-full p-3 rounded-lg relative overflow-hidden transition-all'>
				<div className='size-full absolute top-0 left-0' style={{ backgroundColor: backgroundColor ? backgroundColor : '#fff', opacity }}></div>
				<div className='flex items-center gap-2'>
					<div className='w-2.5 h-2.5 rounded-full relative' style={{ backgroundColor: primary }} />
					<div className='flex-1'>
						<div className={`h-2 rounded-full mb-1.5 w-4/5 opacity-80`} style={{ backgroundColor: secondary }} />
						<div
							className='h-2 rounded-full opacity-40 w-7/12'
							style={{
								backgroundColor: secondary,
							}}
						/>
					</div>
				</div>
			</div>
		</button>
	);
};

PalettePicker.displayName = 'PalettePicker';

export default PalettePicker;
