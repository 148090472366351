import { ChevronDownIcon } from '@heroicons/react/16/solid'
import { ContentsColors, Contents, ItemTema, ItemTemplate, Title, ItemColor, CircleColor, ItemTemplateTeste, ItemTemplateTesteThemes, ItemTemplateTesteInfor, ItemTemplateTesteBox, ItemTemplateTesteStatus, BoxImg } from './styled'
import { CheckBadgeIcon, HandThumbUpIcon } from '@heroicons/react/24/solid'
import { useEffect, useState } from 'react'
import { BoxButtons, ButtonHelp, ButtonReset, ButtonSave } from '../../bar-config-product-ajustes/styled'
import IconBossVideo from "../../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../../assets/imgs/icon-video.svg";
import { useDispatch, useSelector } from 'react-redux'
import { useSubMenu } from '../../../../../empreenderUI/SubMenuContext'
import TabColorDefault from '../../tab-colors/default'
import TabConfigDefault from '../../tab-configs/default'
import ModalAlertSaveNew from '../../modal-alert-save-new'
import useVerifySave from '../../../../../hooks/useVerifySave'
import ModalInforVideo from '../../../../../components/modal-infor-video'

import TabColorGeb from "../../../components-new/templatesReviewsQuestions/geb/colors";
import TabColorDimona from "../../../components-new/templatesReviewsQuestions/dimona/colors";
import TabConfigGeb from "../../../components-new/templatesReviewsQuestions/geb/configs";
import TabConfigDimona from "../../../components-new/templatesReviewsQuestions/dimona/configs";
import { Radio, RadioGroup } from '@headlessui/react'

import ThumbTemplateGeb from "../../../../../assets/imgs/thumbs-templates/thumb-template-geb.PNG";
import ThumbTemplateGebLight from "../../../../../assets/imgs/thumbs-templates/thumb-template-light-geb.PNG";
import ThumbTemplateGama from "../../../../../assets/imgs/thumbs-templates/thumb-template-gama.PNG";
import ThumbTemplateFortaleza from "../../../../../assets/imgs/thumbs-templates/thumb-template-fortaleza.PNG";
import ThumbTemplateBrasilia from "../../../../../assets/imgs/thumbs-templates/thumb-template-brasilia.PNG";
import ThumbTemplateParnaiba from "../../../../../assets/imgs/thumbs-templates/thumb-template-parnaiba.PNG";
import ThumbTemplateDimona from "../../../../../assets/imgs/thumbs-templates/thumb-template-dimona.PNG";

import ThumbTemplateTeste from "../../../../../assets/imgs/thumbs-templates/thumb-template-teste.jpg";

const tabs = [
  { name: 'Templates', href: '#', current: true },
  { name: 'Cores', href: '#', current: false },
  { name: 'Config', href: '#', current: false },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ReviewsQuestions() {

  const [tabActive, setTabActive] = useState('Templates')
  const dispatch = useDispatch()
  const { setSideBarOpen, isSidebarOpen, } = useSubMenu();
  const config = useSelector(state => state.reducerGetConfig)
  const configControl = useSelector(state => state.reducerControlConfig)

  const [max_width, set_max_width] = useState("")

  const setTheme = (theme) => {

    if (configControl.layout_widget == "dimona") {

      if (theme == "dark") {
        const dataConfig = {
          background: '#1F2937',
          text: '#E5E7EB',
          textSecondary: '#9CA3AF',
          starActive: '#FFB300',
          starInactive: '#4B5563',
          buttonBackground: '#374151',
          buttonText: '#F9FAFB',
          border: '#4B5563',
          verified: '#E5E7EB',
        }
        dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify(dataConfig) } })
      } else if (theme == "light") {
        const dataConfig = {
          background: '#FFFFFF',
          border: '#eeeeee',
          text: '#282828',
          textSecondary: '#94a3b8',
          starActive: '#edaa41',
          starInactive: '#94a3b8',
          buttonBackground: '#282828',
          buttonText: '#FFFFFF',
          verified: '#282828',
        }
        dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify(dataConfig) } })
      }
    }

    else if (configControl.layout_widget == "modelo04") {

      if (theme == "dark") {
        const dataConfig = {
          background: '#111827',
          text: '#F3F4F6',
          textSecondary: '#9CA3AF',
          border: '#1F2937',
          starActive: '#FBBF24',
          starInactive: '#4B5563',
          verified: '#FFFFFF',
          backgroundItem: '#1F2937',
          cardBackground: '#374151'
        }
        dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify(dataConfig) } })
      } else if (theme == "light") {
        const dataConfig = {
          background: '#FFFFFF',
          text: '#374151',
          textSecondary: '#6B7280',
          border: '#E5E7EB',
          starActive: '#FACC15',
          starInactive: '#CCCCCC',
          verified: '#16A34A',
          backgroundItem: '#F5F5F5',
          cardBackground: '#FFFFFF'
        }
        dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_configs: JSON.stringify(dataConfig) } })
      }
    }

    else if (theme == "dark") {
      dispatch({
        type: "CONFIG_CONTROL_SET", payload: {
          star_color_primary: "#DAA520",
          star_color_secondary: "#D3D3D3",
          font_color_primary: "#FFFFFF",
          font_color_secondary: "#FFFFFF",
          primary_color: "#808080",
          background_color_primary: "#000000",
          background_color_secondary: "#000000",
          border_card_color: "#ffffff"
        }
      })
    }
    else if (theme == "light") {
      console.log("teste ")
      dispatch({
        type: "CONFIG_CONTROL_SET", payload: {
          star_color_primary: "#DAA520",
          star_color_secondary: "#D3D3D3",
          font_color_primary: "#000000",
          font_color_secondary: "#FFFFFF",
          primary_color: "#000000",
          background_color_primary: "#FFFFFF",
          background_color_secondary: "#F1F1F1",
          border_card_color: "#000000"
        }
      })
    }
  }

  const getTheme = () => {
    if (configControl.layout_widget == "dimona") {
      if (configControl.layout_configs == "null") return "light"
      const layout_configs = JSON.parse(configControl.layout_configs)
      if (
        layout_configs.background == '#1F2937' &&
        layout_configs.text == '#E5E7EB' &&
        layout_configs.textSecondary == '#9CA3AF' &&
        layout_configs.starActive == '#FFB300' &&
        layout_configs.starInactive == '#4B5563' &&
        layout_configs.buttonBackground == '#374151' &&
        layout_configs.buttonText == '#F9FAFB' &&
        layout_configs.border == '#4B5563' &&
        layout_configs.verified == '#E5E7EB'
      ) {
        return "dark"
      } else if (
        layout_configs.background == '#FFFFFF' &&
        layout_configs.border == '#eeeeee' &&
        layout_configs.text == '#282828' &&
        layout_configs.textSecondary == '#94a3b8' &&
        layout_configs.starActive == '#edaa41' &&
        layout_configs.starInactive == '#94a3b8' &&
        layout_configs.buttonBackground == '#282828' &&
        layout_configs.buttonText == '#FFFFFF' &&
        layout_configs.verified == '#282828'
      ) {
        return "light"
      } else {
        return "custom"
      }
    }

    else if (configControl.layout_widget == "modelo04") {
      console.log("configControl.layout_configs", configControl.layout_configs)
      if (configControl.layout_configs == "null" || configControl.layout_configs == null) return "light"
      const layout_configs = JSON.parse(configControl.layout_configs)
      if (
        layout_configs.background == '#111827' &&
        layout_configs.text == '#F3F4F6' &&
        layout_configs.textSecondary == '#9CA3AF' &&
        layout_configs.border == '#1F2937' &&
        layout_configs.starActive == '#FBBF24' &&
        layout_configs.starInactive == '#4B5563' &&
        layout_configs.verified == '#FFFFFF' &&
        layout_configs.backgroundItem == '#1F2937' &&
        layout_configs.cardBackground == '#374151'
      ) {
        return "dark"
      } else if (
        layout_configs.background == '#FFFFFF' &&
        layout_configs.text == '#374151' &&
        layout_configs.textSecondary == '#6B7280' &&
        layout_configs.border == '#E5E7EB' &&
        layout_configs.starActive == '#FACC15' &&
        layout_configs.starInactive == '#CCCCCC' &&
        layout_configs.verified == '#16A34A' &&
        layout_configs.backgroundItem == '#F5F5F5' &&
        layout_configs.cardBackground == '#FFFFFF'
      ) {
        return "light"
      } else {
        return "custom"
      }
    } else {
      if (
        configControl.star_color_primary == "#DAA520" &&
        configControl.star_color_secondary == "#D3D3D3" &&
        configControl.font_color_primary == "#FFFFFF" &&
        configControl.font_color_secondary == "#FFFFFF" &&
        configControl.primary_color == "#808080" &&
        configControl.background_color_primary == "#000000" &&
        configControl.background_color_secondary == "#000000" &&
        configControl.border_card_color == "#ffffff"
      ) {
        return "dark"
      } else if (
        configControl.star_color_primary == "#DAA520" &&
        configControl.star_color_secondary == "#D3D3D3" &&
        configControl.font_color_primary == "#000000" &&
        configControl.font_color_secondary == "#FFFFFF" &&
        configControl.primary_color == "#000000" &&
        configControl.background_color_primary == "#FFFFFF" &&
        configControl.background_color_secondary == "#F1F1F1" &&
        configControl.border_card_color == "#000000"
      ) {
        return "light"
      } else {
        return "custom"
      }
    }
  }

  const save = (type) => {

    if (type == "Templates") {

      if (configControl.layout_widget == "modelo04") {
        if (configControl.layout_configs == "null" || configControl.layout_configs == "" || configControl.layout_configs == null) {
          dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
              id: config.data.id,
              layout_configs: JSON.stringify({
                background: '#FFFFFF',
                text: '#374151',
                textSecondary: '#6B7280',
                border: '#E5E7EB',
                starActive: '#FACC15',
                starInactive: '#CCCCCC',
                verified: '#16A34A',
                backgroundItem: '#FFFFFF',
                cardBackground: '#F5F5F5'
              })
            }
          })
        }
        dispatch({
          type: "SAGA_UPDATE_CONFIG", payload: {
            id: config.data?.id,
            layout_widget: configControl.layout_widget,
            layout_configs: configControl.layout_configs,
            layout: "review01",
            layout_question: "ask01",
          }
        })

      } else if (configControl.layout_widget == "dimona") {
        if (configControl.layout_configs == "null" || configControl.layout_configs == "" || configControl.layout_configs == null) {
          dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
              id: config.data.id,
              layout_configs: JSON.stringify({
                background: '#FFFFFF',
                border: '#eeeeee',
                text: '#282828',
                textSecondary: '#94a3b8',
                starActive: '#edaa41',
                starInactive: '#94a3b8',
                buttonBackground: '#282828',
                buttonText: '#FFFFFF',
                verified: '#282828'
              })
            }
          })
        }
        dispatch({
          type: "SAGA_UPDATE_CONFIG", payload: {
            id: config.data?.id,
            layout_widget: configControl.layout_widget,
            layout_configs: configControl.layout_configs,
            layout: "review01",
            layout_question: "ask01",
          }
        })


      } else {
        dispatch({
          type: "SAGA_UPDATE_CONFIG", payload: {
            id: config.data?.id,
            layout_widget: configControl.layout_widget,
            layout: "dropi",
            layout_question: "modelo1",

            star_color_primary: configControl.star_color_primary,
            star_color_secondary: configControl.star_color_secondary,
            font_color_primary: configControl.font_color_primary,
            font_color_secondary: configControl.font_color_secondary,
            primary_color: configControl.primary_color,
            background_color_primary: configControl.background_color_primary,
            background_color_secondary: configControl.background_color_secondary,
            border_card_color: configControl.border_card_color,
            background_color_resume_primary: configControl.background_color_resume_primary,
            background_color_resume_secondary: configControl.background_color_resume_secondary,
            layout_configs: configControl.layout_configs
          }
        })
      }


    } else if (type == "Cores") {
      if (configControl.layout_widget == "modelo04" || configControl.layout_widget == "dimona") {
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: { id: config.data?.id, layout_configs: configControl.layout_configs } })
      } else {
        dispatch({
          type: "SAGA_UPDATE_CONFIG", payload: {
            id: config.data?.id,
            star_color_primary: configControl.star_color_primary,
            star_color_secondary: configControl.star_color_secondary,
            font_color_primary: configControl.font_color_primary,
            font_color_secondary: configControl.font_color_secondary,
            primary_color: configControl.primary_color,
            background_color_primary: configControl.background_color_primary,
            background_color_secondary: configControl.background_color_secondary,
            border_card_color: configControl.border_card_color,
            background_color_resume_primary: configControl.background_color_resume_primary,
            background_color_resume_secondary: configControl.background_color_resume_secondary,
            layout_configs: "null"
          }
        })
      }

    } else {
      dispatch({
        type: "SAGA_UPDATE_CONFIG", payload: {
          id: config.data?.id,
          no_review_star: configControl.no_review_star,
          visible_button_review: configControl.visible_button_review,
          visible_button_ask: configControl.visible_button_ask,
          visible_date_review: configControl.visible_date_review,
          itens_per_page: configControl.itens_per_page,
          layout: configControl.layout,
          layout_question: configControl.layout_question,
          layout_form: configControl.layout_form,
          layout_form_ask: configControl.layout_form_ask,
          font: configControl.font,
          order_reviews: configControl.order_reviews,
          order_asks: configControl.order_asks,
          layout_stars: configControl.layout_stars,
          resume_reviews: configControl.resume_reviews,
          layout_resume: configControl.layout_resume,
          auto_approve: configControl.auto_approve,
          border_card_radius: configControl.border_card_radius,
          border_card_size: configControl.border_card_size,
          position_star_review: configControl.position_star_review,
          no_review_store: configControl.no_review_store,
          script_public_language: configControl.script_public_language,
          //layout_widget: configControl.layout_widget,
          //layout_configs: getLayoutConfigs()
        }
      })

    }
  }

  const getLayoutConfigs = () => {
    if (configControl.layout_widget == "modelo04") {
      try {
        if (configControl?.layout_configs != "null") {
          const data = JSON.parse(configControl?.layout_configs)
          data["max_width"] = max_width
          return JSON.stringify(data)
        } else {
          const newData = {
            color_star_active: "#FACC15",
            color_star_inactive: "#CCCCCC",
            color_verified: "#16A34A",
            max_width: max_width
          }

          return JSON.stringify(newData)
        }
      } catch (error) {
        return configControl?.layout_configs
      }
    } else {
      return "null"
    }
  }

  const setDefault = (type) => {


    const themaDefaultColor = {
      id: config.data.id,
      star_color_primary: "#DAA520",
      star_color_secondary: "#D3D3D3",
      font_color_primary: "#000000",
      font_color_secondary: "#FFFFFF",
      primary_color: "#000000",
      background_color_primary: "#FFFFFF",
      background_color_secondary: "#F1F1F1",
      border_card_color: "#000000",
      background_color_resume_primary: "#f2f2f2",
      background_color_resume_secondary: "#ffffff",
      layout_configs: "null",

    }
    const themaDefaultConfig = {
      id: config.data.id,
      no_review_star: 1,
      visible_button_review: 1,
      visible_button_ask: 1,
      visible_date_review: 1,
      itens_per_page: 20,
      layout: "lily",
      layout_question: "modelo4",
      layout_form: "modelo1",
      layout_form_ask: "modelo1",
      font: "Poppins",
      order_reviews: "default",
      order_asks: "default",
      layout_stars: layout_stars_esquerda,
      resume_reviews: 0,
      layout_resume: "modelo01",
      auto_approve: 1,
      border_card_radius: "4px",
      border_card_size: "1px",
      position_star_review: "default",
      no_review_store: 0,
      script_public_language: "",
      //layout_widget: "default"
    }

    const layout_stars_esquerda = JSON.stringify({
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "left",
      "margin": "5px 0px"
    })

    const themaDefaultConfigTemplate04 = {
      id: config.data.id,
      no_review_star: 1,
      visible_button_review: 1,
      visible_button_ask: 1,
      visible_date_review: 1,
      itens_per_page: 20,
      layout: "review01",
      layout_question: "ask01",
      layout_form: "modelo1",
      layout_form_ask: "modelo1",
      font: "Poppins",
      order_reviews: "default",
      order_asks: "default",
      layout_stars: layout_stars_esquerda,
      resume_reviews: 0,
      layout_resume: "modelo01",
      auto_approve: 1,
      border_card_radius: "4px",
      border_card_size: "1px",
      position_star_review: "default",
      no_review_store: 0,
      script_public_language: "",
      //layout_widget: "default"
    }

    if (type == "Templates") {
      dispatch({ type: "SAGA_UPDATE_CONFIG", payload: { id: config.data.id, layout_widget: "modelo04" } })

    } else if (type == "Cores") {
      if (config.data.layout_widget == "modelo04") {
        dispatch({
          type: "SAGA_UPDATE_CONFIG", payload: {
            id: config.data.id,
            layout_configs: JSON.stringify({
              background: '#FFFFFF',
              text: '#374151',
              textSecondary: '#6B7280',
              border: '#E5E7EB',
              starActive: '#FACC15',
              starInactive: '#CCCCCC',
              verified: '#16A34A',
              backgroundItem: '#FFFFFF',
              cardBackground: '#F5F5F5'
            })
          }
        })
      } else if (config.data.layout_widget == "dimona") {
        dispatch({
          type: "SAGA_UPDATE_CONFIG", payload: {
            id: config.data.id,
            layout_configs: JSON.stringify({
              background: '#FFFFFF',
              border: '#eeeeee',
              text: '#282828',
              textSecondary: '#94a3b8',
              starActive: '#edaa41',
              starInactive: '#94a3b8',
              buttonBackground: '#282828',
              buttonText: '#FFFFFF',
              verified: '#282828'
            })
          }
        })
      } else {
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefaultColor })
      }

    } else {
      if (config.data.layout_widget == "modelo04") {
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefaultConfigTemplate04 })
      } else {
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefaultConfig })
      }
    }

  }

  useEffect(() => {
    const theme = {
      star_color_primary: "#DAA520",
      star_color_secondary: "#D3D3D3",
      font_color_primary: "#000000",
      font_color_secondary: "#FFFFFF",
      primary_color: "#000000",
      background_color_primary: "#FFFFFF",
      background_color_secondary: "#F1F1F1",
      border_card_color: "#000000",
      background_color_resume_primary: "#f2f2f2",
      background_color_resume_secondary: "#ffffff",
      layout_configs: "null",
    }
    configControl.layout_widget != config.data.layout_widget && dispatch({ type: "CONFIG_CONTROL_SET", payload: theme })
  }, [configControl.layout_widget])

  const [visible, setVisible] = useState(false)
  const { compareObjectsAndPrint } = useVerifySave()
  const changeTab = (tab) => {
    //console.log("teste", compareObjectsAndPrint(configControl, config?.data))
    compareObjectsAndPrint(configControl, config?.data) == true ? setTabActive(tab) : setVisible(true)
  }

  const [visibleModalVideo, setVisibleModalVideo] = useState(false)

  const getVideo = () => {
    switch (tabActive) {
      case "Templates":
        return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/NZkhbdkyY3U" : "https://www.youtube.com/embed/v-1sw3H06fo"
      case "Cores":
        return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/NZkhbdkyY3U" : "https://www.youtube.com/embed/v-1sw3H06fo"
      case "Config":
        return JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "es" ? "https://www.youtube.com/embed/NZkhbdkyY3U" : "https://www.youtube.com/embed/v-1sw3H06fo"
      default:
        break;
    }
  }

  const getTabColors = () => {
    switch (configControl?.layout_widget) {
      case "modelo01":
      case "modelo02":
      case "modelo03":
      case "default":
        return <TabColorDefault visibleModalVideo={visibleModalVideo} setVisibleModalVideo={setVisibleModalVideo} save={() => save("Cores")} setDefault={() => setDefault("Cores")} />
      case "modelo04":
        return <TabColorGeb visibleModalVideo={visibleModalVideo} setVisibleModalVideo={setVisibleModalVideo} save={() => save("Cores")} setDefault={() => setDefault("Cores")} />
      case "dimona":
        return <TabColorDimona visibleModalVideo={visibleModalVideo} setVisibleModalVideo={setVisibleModalVideo} save={() => save("Cores")} setDefault={() => setDefault("Cores")} />
      default:
        break;
    }
  }

  const getTabConfigs = () => {
    switch (configControl?.layout_widget) {
      case "modelo01":
      case "modelo02":
      case "modelo03":
      case "default":
        return <TabConfigDefault visibleModalVideo={visibleModalVideo} setVisibleModalVideo={setVisibleModalVideo} save={() => save("Config")} setDefault={() => setDefault("Config")} />
      case "modelo04":
        return <TabConfigGeb visibleModalVideo={visibleModalVideo} setVisibleModalVideo={setVisibleModalVideo} save={() => save("Config")} setDefault={() => setDefault("Config")} />
      case "dimona":
        return <TabConfigDimona visibleModalVideo={visibleModalVideo} setVisibleModalVideo={setVisibleModalVideo} save={() => save("Config")} setDefault={() => setDefault("Config")} />
      default:
        break;
    }
  }

  const product = {
    name: 'Basic Tee',
    price: '$35',
    rating: 3.9,
    reviewCount: 512,
    href: '#',
    breadcrumbs: [
      { id: 1, name: 'Women', href: '#' },
      { id: 2, name: 'Clothing', href: '#' },
    ],
    images: [
      {
        id: 1,
        imageSrc: 'https://tailwindui.com/plus-assets/img/ecommerce-images/product-page-01-featured-product-shot.jpg',
        imageAlt: "Back of women's Basic Tee in black.",
        primary: true,
      },
      {
        id: 2,
        imageSrc: 'https://tailwindui.com/plus-assets/img/ecommerce-images/product-page-01-product-shot-01.jpg',
        imageAlt: "Side profile of women's Basic Tee in black.",
        primary: false,
      },
      {
        id: 3,
        imageSrc: 'https://tailwindui.com/plus-assets/img/ecommerce-images/product-page-01-product-shot-02.jpg',
        imageAlt: "Front of women's Basic Tee in black.",
        primary: false,
      },
    ],
    colors: [
      { name: 'Black', bgColor: 'bg-gray-900', selectedColor: 'ring-gray-900' },
      { name: 'Heather Grey', bgColor: 'bg-gray-400', selectedColor: 'ring-gray-400' },
    ],
    sizes: [
      { name: 'XXS', inStock: true },
      { name: 'XS', inStock: true },
      { name: 'S', inStock: true },
      { name: 'M', inStock: true },
      { name: 'L', inStock: true },
      { name: 'XL', inStock: false },
    ],
    description: `
      <p>The Basic tee is an honest new take on a classic. The tee uses super soft, pre-shrunk cotton for true comfort and a dependable fit. They are hand cut and sewn locally, with a special dye technique that gives each tee it's own look.</p>
      <p>Looking to stock your closet? The Basic tee also comes in a 3-pack or 5-pack at a bundle discount.</p>
    `,
    details: [
      'Only the best materials',
      'Ethically and locally made',
      'Pre-washed and pre-shrunk',
      'Machine wash cold with similar colors',
    ],
  }
  const [selectedColor, setSelectedColor] = useState(product.colors[0])
  return (
    <>
      <ModalAlertSaveNew visible={visible} setVisible={setVisible} save={() => save(tabActive)} />
      <ModalInforVideo visible={visibleModalVideo} setVisibleModal={setVisibleModalVideo} video={getVideo()} />
      <Title>
        <label>Avaliações e Perguntas</label>
        <svg onClick={() => { setSideBarOpen(!isSidebarOpen) }} style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M12 4L4 12" stroke="#111827" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4 4L12 12" stroke="#111827" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </Title>
      <div>
        <div className="grid grid-cols-1 sm:hidden">
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            onChange={(e) => { changeTab(e.target.value) }}
            defaultValue={tabs.find((tab) => tab.current).name}
            aria-label="Select a tab"
            className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-i[#111827]"
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
          <ChevronDownIcon
            aria-hidden="true"
            className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
          />
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <a
                  onClick={() => { changeTab(tab.name) }}
                  key={tab.name}
                  href={tab.href}
                  //aria-current={tab.name == tabActive ? 'page' : undefined}
                  className={classNames(
                    tab.name == tabActive
                      ? 'border-[#111827] text-[#111827]'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
                  )}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {tabActive == "Templates" &&
        <Contents>

          <ItemTemplateTeste>
            {/* <img
              onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "modelo04" } }) }}
              src={ThumbTemplateTeste} /> */}
            <BoxImg onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "modelo04" } }) }} >
              <svg width="234" height="140" viewBox="0 0 234 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2052_12710)">
                  <path d="M0 6C0 2.6863 2.68629 0 6 0H228C231.314 0 234 2.68629 234 6V134C234 137.314 231.314 140 228 140H6C2.68629 140 0 137.314 0 134V6Z" fill="#F5F5F5" />
                  <path d="M8 16C8 11.5817 11.5817 8 16 8H105C109.418 8 113 11.5817 113 16V59C113 63.4183 109.418 67 105 67H16C11.5817 67 8 63.4183 8 59V16Z" fill="white" />
                  <path d="M42.6433 34.0158C42.7556 33.6703 43.2444 33.6703 43.3566 34.0158L43.9682 35.898C44.0184 36.0525 44.1624 36.1571 44.3248 36.1571H46.3039C46.6672 36.1571 46.8182 36.622 46.5243 36.8355L44.9232 37.9988C44.7918 38.0943 44.7368 38.2635 44.787 38.418L45.3986 40.3003C45.5108 40.6458 45.1154 40.933 44.8215 40.7195L43.2204 39.5563C43.089 39.4608 42.911 39.4608 42.7796 39.5563L41.1784 40.7195C40.8846 40.933 40.4891 40.6458 40.6014 40.3003L41.213 38.418C41.2632 38.2635 41.2082 38.0943 41.0767 37.9988L39.4756 36.8355C39.1817 36.622 39.3328 36.1571 39.696 36.1571H41.6751C41.8376 36.1571 41.9816 36.0525 42.0318 35.898L42.6433 34.0158Z" fill="#FACC15" />
                  <path d="M51.6433 34.0158C51.7556 33.6703 52.2444 33.6703 52.3566 34.0158L52.9682 35.898C53.0184 36.0525 53.1624 36.1571 53.3248 36.1571H55.3039C55.6672 36.1571 55.8182 36.622 55.5243 36.8355L53.9232 37.9988C53.7918 38.0943 53.7368 38.2635 53.787 38.418L54.3986 40.3003C54.5108 40.6458 54.1154 40.933 53.8215 40.7195L52.2204 39.5563C52.089 39.4608 51.911 39.4608 51.7796 39.5563L50.1784 40.7195C49.8846 40.933 49.4891 40.6458 49.6014 40.3003L50.213 38.418C50.2632 38.2635 50.2082 38.0943 50.0767 37.9988L48.4756 36.8355C48.1817 36.622 48.3328 36.1571 48.696 36.1571H50.6751C50.8376 36.1571 50.9816 36.0525 51.0318 35.898L51.6433 34.0158Z" fill="#FACC15" />
                  <path d="M60.6433 34.0158C60.7556 33.6703 61.2444 33.6703 61.3566 34.0158L61.9682 35.898C62.0184 36.0525 62.1624 36.1571 62.3248 36.1571H64.3039C64.6672 36.1571 64.8182 36.622 64.5243 36.8355L62.9232 37.9988C62.7918 38.0943 62.7368 38.2635 62.787 38.418L63.3986 40.3003C63.5108 40.6458 63.1154 40.933 62.8215 40.7195L61.2204 39.5563C61.089 39.4608 60.911 39.4608 60.7796 39.5563L59.1784 40.7195C58.8846 40.933 58.4891 40.6458 58.6014 40.3003L59.213 38.418C59.2632 38.2635 59.2082 38.0943 59.0767 37.9988L57.4756 36.8355C57.1817 36.622 57.3328 36.1571 57.696 36.1571H59.6751C59.8376 36.1571 59.9816 36.0525 60.0318 35.898L60.6433 34.0158Z" fill="#FACC15" />
                  <path d="M69.6433 34.0158C69.7556 33.6703 70.2444 33.6703 70.3566 34.0158L70.9682 35.898C71.0184 36.0525 71.1624 36.1571 71.3248 36.1571H73.3039C73.6672 36.1571 73.8182 36.622 73.5243 36.8355L71.9232 37.9988C71.7918 38.0943 71.7368 38.2635 71.787 38.418L72.3986 40.3003C72.5108 40.6458 72.1154 40.933 71.8215 40.7195L70.2204 39.5563C70.089 39.4608 69.911 39.4608 69.7796 39.5563L68.1784 40.7195C67.8846 40.933 67.4891 40.6458 67.6014 40.3003L68.213 38.418C68.2632 38.2635 68.2082 38.0943 68.0767 37.9988L66.4756 36.8355C66.1817 36.622 66.3328 36.1571 66.696 36.1571H68.6751C68.8376 36.1571 68.9816 36.0525 69.0318 35.898L69.6433 34.0158Z" fill="#FACC15" />
                  <path d="M78.6433 34.0158C78.7556 33.6703 79.2444 33.6703 79.3566 34.0158L79.9682 35.898C80.0184 36.0525 80.1624 36.1571 80.3248 36.1571H82.3039C82.6672 36.1571 82.8182 36.622 82.5243 36.8355L80.9232 37.9988C80.7918 38.0943 80.7368 38.2635 80.787 38.418L81.3986 40.3003C81.5108 40.6458 81.1154 40.933 80.8215 40.7195L79.2204 39.5563C79.089 39.4608 78.911 39.4608 78.7796 39.5563L77.1784 40.7195C76.8846 40.933 76.4891 40.6458 76.6014 40.3003L77.213 38.418C77.2632 38.2635 77.2082 38.0943 77.0767 37.9988L75.4756 36.8355C75.1817 36.622 75.3328 36.1571 75.696 36.1571H77.6751C77.8376 36.1571 77.9816 36.0525 78.0318 35.898L78.6433 34.0158Z" fill="#FACC15" />
                  <path d="M121 16C121 11.5817 124.582 8 129 8H218C222.418 8 226 11.5817 226 16V59C226 63.4183 222.418 67 218 67H129C124.582 67 121 63.4183 121 59V16Z" fill="white" />
                  <rect x="129" y="28" width="4" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="137" y="28" width="76" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="217" y="28" width="4" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="129" y="36" width="4" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="137" y="36" width="76" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="217" y="36" width="4" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="129" y="44" width="4" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="137" y="44" width="76" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="217" y="44" width="4" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="137" y="19" width="30" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M7 76C7 73.7909 8.79086 72 11 72H109C111.209 72 113 73.7909 113 76V116C113 118.209 111.209 120 109 120H11C8.79086 120 7 118.209 7 116V76Z" fill="white" />
                  <rect x="13" y="78" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="28" y="78" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="13" y="110" width="93" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="28" y="85" width="39" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M16.3933 96.0158C16.5056 95.6703 16.9944 95.6703 17.1066 96.0158L17.7182 97.898C17.7684 98.0525 17.9124 98.1571 18.0748 98.1571H20.0539C20.4172 98.1571 20.5682 98.622 20.2743 98.8355L18.6732 99.9988C18.5418 100.094 18.4868 100.264 18.537 100.418L19.1486 102.3C19.2608 102.646 18.8654 102.933 18.5715 102.72L16.9704 101.556C16.839 101.461 16.661 101.461 16.5296 101.556L14.9284 102.72C14.6346 102.933 14.2391 102.646 14.3514 102.3L14.963 100.418C15.0132 100.264 14.9582 100.094 14.8267 99.9988L13.2256 98.8355C12.9317 98.622 13.0828 98.1571 13.446 98.1571H15.4251C15.5876 98.1571 15.7316 98.0525 15.7818 97.898L16.3933 96.0158Z" fill="#FACC15" />
                  <path d="M25.3933 96.0158C25.5056 95.6703 25.9944 95.6703 26.1066 96.0158L26.7182 97.898C26.7684 98.0525 26.9124 98.1571 27.0748 98.1571H29.0539C29.4172 98.1571 29.5682 98.622 29.2743 98.8355L27.6732 99.9988C27.5418 100.094 27.4868 100.264 27.537 100.418L28.1486 102.3C28.2608 102.646 27.8654 102.933 27.5715 102.72L25.9704 101.556C25.839 101.461 25.661 101.461 25.5296 101.556L23.9284 102.72C23.6346 102.933 23.2391 102.646 23.3514 102.3L23.963 100.418C24.0132 100.264 23.9582 100.094 23.8267 99.9988L22.2256 98.8355C21.9317 98.622 22.0828 98.1571 22.446 98.1571H24.4251C24.5876 98.1571 24.7316 98.0525 24.7818 97.898L25.3933 96.0158Z" fill="#FACC15" />
                  <path d="M34.3933 96.0158C34.5056 95.6703 34.9944 95.6703 35.1066 96.0158L35.7182 97.898C35.7684 98.0525 35.9124 98.1571 36.0748 98.1571H38.0539C38.4172 98.1571 38.5682 98.622 38.2743 98.8355L36.6732 99.9988C36.5418 100.094 36.4868 100.264 36.537 100.418L37.1486 102.3C37.2608 102.646 36.8654 102.933 36.5715 102.72L34.9704 101.556C34.839 101.461 34.661 101.461 34.5296 101.556L32.9284 102.72C32.6346 102.933 32.2391 102.646 32.3514 102.3L32.963 100.418C33.0132 100.264 32.9582 100.094 32.8267 99.9988L31.2256 98.8355C30.9317 98.622 31.0828 98.1571 31.446 98.1571H33.4251C33.5876 98.1571 33.7316 98.0525 33.7818 97.898L34.3933 96.0158Z" fill="#FACC15" />
                  <path d="M43.3933 96.0158C43.5056 95.6703 43.9944 95.6703 44.1066 96.0158L44.7182 97.898C44.7684 98.0525 44.9124 98.1571 45.0748 98.1571H47.0539C47.4172 98.1571 47.5682 98.622 47.2743 98.8355L45.6732 99.9988C45.5418 100.094 45.4868 100.264 45.537 100.418L46.1486 102.3C46.2608 102.646 45.8654 102.933 45.5715 102.72L43.9704 101.556C43.839 101.461 43.661 101.461 43.5296 101.556L41.9284 102.72C41.6346 102.933 41.2391 102.646 41.3514 102.3L41.963 100.418C42.0132 100.264 41.9582 100.094 41.8267 99.9988L40.2256 98.8355C39.9317 98.622 40.0828 98.1571 40.446 98.1571H42.4251C42.5876 98.1571 42.7316 98.0525 42.7818 97.898L43.3933 96.0158Z" fill="#FACC15" />
                  <path d="M52.3933 96.0158C52.5056 95.6703 52.9944 95.6703 53.1066 96.0158L53.7182 97.898C53.7684 98.0525 53.9124 98.1571 54.0748 98.1571H56.0539C56.4172 98.1571 56.5682 98.622 56.2743 98.8355L54.6732 99.9988C54.5418 100.094 54.4868 100.264 54.537 100.418L55.1486 102.3C55.2608 102.646 54.8654 102.933 54.5715 102.72L52.9704 101.556C52.839 101.461 52.661 101.461 52.5296 101.556L50.9284 102.72C50.6346 102.933 50.2391 102.646 50.3514 102.3L50.963 100.418C51.0132 100.264 50.9582 100.094 50.8267 99.9988L49.2256 98.8355C48.9317 98.622 49.0828 98.1571 49.446 98.1571H51.4251C51.5876 98.1571 51.7316 98.0525 51.7818 97.898L52.3933 96.0158Z" fill="#FACC15" />
                  <path d="M7 130C7 127.791 8.79086 126 11 126H109C111.209 126 113 127.791 113 130V181C113 183.209 111.209 185 109 185H11C8.79086 185 7 183.209 7 181V130Z" fill="white" />
                  <rect x="13" y="135" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="28" y="135" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M124 76C124 73.7909 125.791 72 128 72H226C228.209 72 230 73.7909 230 76V138C230 140.209 228.209 142 226 142H128C125.791 142 124 140.209 124 138V76Z" fill="white" />
                  <rect x="130" y="81" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="145" y="81" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="130" y="113" width="93" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="130" y="121" width="93" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="130" y="129" width="93" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="145" y="88" width="39" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M133.393 99.0158C133.506 98.6703 133.994 98.6703 134.107 99.0158L134.718 100.898C134.768 101.053 134.912 101.157 135.075 101.157H137.054C137.417 101.157 137.568 101.622 137.274 101.836L135.673 102.999C135.542 103.094 135.487 103.264 135.537 103.418L136.149 105.3C136.261 105.646 135.865 105.933 135.572 105.72L133.97 104.556C133.839 104.461 133.661 104.461 133.53 104.556L131.928 105.72C131.635 105.933 131.239 105.646 131.351 105.3L131.963 103.418C132.013 103.264 131.958 103.094 131.827 102.999L130.226 101.836C129.932 101.622 130.083 101.157 130.446 101.157H132.425C132.588 101.157 132.732 101.053 132.782 100.898L133.393 99.0158Z" fill="#FACC15" />
                  <path d="M142.393 99.0158C142.506 98.6703 142.994 98.6703 143.107 99.0158L143.718 100.898C143.768 101.053 143.912 101.157 144.075 101.157H146.054C146.417 101.157 146.568 101.622 146.274 101.836L144.673 102.999C144.542 103.094 144.487 103.264 144.537 103.418L145.149 105.3C145.261 105.646 144.865 105.933 144.572 105.72L142.97 104.556C142.839 104.461 142.661 104.461 142.53 104.556L140.928 105.72C140.635 105.933 140.239 105.646 140.351 105.3L140.963 103.418C141.013 103.264 140.958 103.094 140.827 102.999L139.226 101.836C138.932 101.622 139.083 101.157 139.446 101.157H141.425C141.588 101.157 141.732 101.053 141.782 100.898L142.393 99.0158Z" fill="#FACC15" />
                  <path d="M151.393 99.0158C151.506 98.6703 151.994 98.6703 152.107 99.0158L152.718 100.898C152.768 101.053 152.912 101.157 153.075 101.157H155.054C155.417 101.157 155.568 101.622 155.274 101.836L153.673 102.999C153.542 103.094 153.487 103.264 153.537 103.418L154.149 105.3C154.261 105.646 153.865 105.933 153.572 105.72L151.97 104.556C151.839 104.461 151.661 104.461 151.53 104.556L149.928 105.72C149.635 105.933 149.239 105.646 149.351 105.3L149.963 103.418C150.013 103.264 149.958 103.094 149.827 102.999L148.226 101.836C147.932 101.622 148.083 101.157 148.446 101.157H150.425C150.588 101.157 150.732 101.053 150.782 100.898L151.393 99.0158Z" fill="#FACC15" />
                  <path d="M160.393 99.0158C160.506 98.6703 160.994 98.6703 161.107 99.0158L161.718 100.898C161.768 101.053 161.912 101.157 162.075 101.157H164.054C164.417 101.157 164.568 101.622 164.274 101.836L162.673 102.999C162.542 103.094 162.487 103.264 162.537 103.418L163.149 105.3C163.261 105.646 162.865 105.933 162.572 105.72L160.97 104.556C160.839 104.461 160.661 104.461 160.53 104.556L158.928 105.72C158.635 105.933 158.239 105.646 158.351 105.3L158.963 103.418C159.013 103.264 158.958 103.094 158.827 102.999L157.226 101.836C156.932 101.622 157.083 101.157 157.446 101.157H159.425C159.588 101.157 159.732 101.053 159.782 100.898L160.393 99.0158Z" fill="#FACC15" />
                  <path d="M169.393 99.0158C169.506 98.6703 169.994 98.6703 170.107 99.0158L170.718 100.898C170.768 101.053 170.912 101.157 171.075 101.157H173.054C173.417 101.157 173.568 101.622 173.274 101.836L171.673 102.999C171.542 103.094 171.487 103.264 171.537 103.418L172.149 105.3C172.261 105.646 171.865 105.933 171.572 105.72L169.97 104.556C169.839 104.461 169.661 104.461 169.53 104.556L167.928 105.72C167.635 105.933 167.239 105.646 167.351 105.3L167.963 103.418C168.013 103.264 167.958 103.094 167.827 102.999L166.226 101.836C165.932 101.622 166.083 101.157 166.446 101.157H168.425C168.588 101.157 168.732 101.053 168.782 100.898L169.393 99.0158Z" fill="#FACC15" />
                </g>
                <defs>
                  <clipPath id="clip0_2052_12710">
                    <path d="M0 6C0 2.6863 2.68629 0 6 0H228C231.314 0 234 2.68629 234 6V134C234 137.314 231.314 140 228 140H6C2.68629 140 0 137.314 0 134V6Z" fill="white" />
                  </clipPath>
                </defs>
              </svg>


            </BoxImg>
            <ItemTemplateTesteBox>
              <ItemTemplateTesteInfor>
                <label>GEB 1.0</label>
                {configControl.layout_widget == "modelo04" && <ItemTemplateTesteStatus>Ativo</ItemTemplateTesteStatus>}
              </ItemTemplateTesteInfor>
              <ItemTemplateTesteThemes>

                <RadioGroup value={configControl.layout_widget + "-" + getTheme()}
                  //onChange={setSelectedColor}
                  className="flex items-center gap-x-2.5 flex-wrap gap-y-2.5">

                  <Radio
                    onClick={() => { configControl.layout_widget == "modelo04" && setTheme("dark") }}
                    key={"color.name"}
                    value={"modelo04-dark"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Escuro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#1F2937]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>

                  <Radio
                    onClick={() => { configControl.layout_widget == "modelo04" && setTheme("light") }}
                    key={"color.name"}
                    value={"modelo04-light"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Claro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#f2f2f2]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>
                </RadioGroup>
              </ItemTemplateTesteThemes>
            </ItemTemplateTesteBox>
          </ItemTemplateTeste>

          <ItemTemplateTeste>
            {/* <img
              onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "dimona" } }) }}
              src={ThumbTemplateDimona} /> */}
            <BoxImg onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "dimona" } }) }}>
              <svg width="234" height="140" viewBox="0 0 234 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 6C0 2.6863 2.68629 0 6 0H228C231.314 0 234 2.68629 234 6V134C234 137.314 231.314 140 228 140H6C2.68629 140 0 137.314 0 134V6Z" fill="#F5F5F5" />
                <path d="M8 16C8 11.5817 11.5817 8 16 8H81C85.4183 8 89 11.5817 89 16V124C89 128.418 85.4183 132 81 132H16C11.5817 132 8 128.418 8 124V16Z" fill="white" />
                <path d="M97 16C97 11.5817 100.582 8 105 8H215C219.418 8 223 11.5817 223 16V59C223 63.4183 219.418 67 215 67H105C100.582 67 97 63.4183 97 59V16Z" fill="white" />
                <rect x="103" y="17" width="12" height="12" rx="6" fill="#E4E4E4" />
                <rect x="118" y="17" width="50" height="4" rx="2" fill="#E4E4E4" />
                <rect x="103" y="49" width="115" height="4" rx="2" fill="#E4E4E4" />
                <rect x="103" y="56" width="115" height="4" rx="2" fill="#E4E4E4" />
                <rect x="118" y="24" width="39" height="4" rx="2" fill="#E4E4E4" />
                <path d="M106.393 35.0158C106.506 34.6703 106.994 34.6703 107.107 35.0158L107.718 36.898C107.768 37.0525 107.912 37.1571 108.075 37.1571H110.054C110.417 37.1571 110.568 37.622 110.274 37.8355L108.673 38.9988C108.542 39.0943 108.487 39.2635 108.537 39.418L109.149 41.3003C109.261 41.6458 108.865 41.933 108.572 41.7195L106.97 40.5563C106.839 40.4608 106.661 40.4608 106.53 40.5563L104.928 41.7195C104.635 41.933 104.239 41.6458 104.351 41.3003L104.963 39.418C105.013 39.2635 104.958 39.0943 104.827 38.9988L103.226 37.8355C102.932 37.622 103.083 37.1571 103.446 37.1571H105.425C105.588 37.1571 105.732 37.0525 105.782 36.898L106.393 35.0158Z" fill="#FACC15" />
                <path d="M115.393 35.0158C115.506 34.6703 115.994 34.6703 116.107 35.0158L116.718 36.898C116.768 37.0525 116.912 37.1571 117.075 37.1571H119.054C119.417 37.1571 119.568 37.622 119.274 37.8355L117.673 38.9988C117.542 39.0943 117.487 39.2635 117.537 39.418L118.149 41.3003C118.261 41.6458 117.865 41.933 117.572 41.7195L115.97 40.5563C115.839 40.4608 115.661 40.4608 115.53 40.5563L113.928 41.7195C113.635 41.933 113.239 41.6458 113.351 41.3003L113.963 39.418C114.013 39.2635 113.958 39.0943 113.827 38.9988L112.226 37.8355C111.932 37.622 112.083 37.1571 112.446 37.1571H114.425C114.588 37.1571 114.732 37.0525 114.782 36.898L115.393 35.0158Z" fill="#FACC15" />
                <path d="M124.393 35.0158C124.506 34.6703 124.994 34.6703 125.107 35.0158L125.718 36.898C125.768 37.0525 125.912 37.1571 126.075 37.1571H128.054C128.417 37.1571 128.568 37.622 128.274 37.8355L126.673 38.9988C126.542 39.0943 126.487 39.2635 126.537 39.418L127.149 41.3003C127.261 41.6458 126.865 41.933 126.572 41.7195L124.97 40.5563C124.839 40.4608 124.661 40.4608 124.53 40.5563L122.928 41.7195C122.635 41.933 122.239 41.6458 122.351 41.3003L122.963 39.418C123.013 39.2635 122.958 39.0943 122.827 38.9988L121.226 37.8355C120.932 37.622 121.083 37.1571 121.446 37.1571H123.425C123.588 37.1571 123.732 37.0525 123.782 36.898L124.393 35.0158Z" fill="#FACC15" />
                <path d="M133.393 35.0158C133.506 34.6703 133.994 34.6703 134.107 35.0158L134.718 36.898C134.768 37.0525 134.912 37.1571 135.075 37.1571H137.054C137.417 37.1571 137.568 37.622 137.274 37.8355L135.673 38.9988C135.542 39.0943 135.487 39.2635 135.537 39.418L136.149 41.3003C136.261 41.6458 135.865 41.933 135.572 41.7195L133.97 40.5563C133.839 40.4608 133.661 40.4608 133.53 40.5563L131.928 41.7195C131.635 41.933 131.239 41.6458 131.351 41.3003L131.963 39.418C132.013 39.2635 131.958 39.0943 131.827 38.9988L130.226 37.8355C129.932 37.622 130.083 37.1571 130.446 37.1571H132.425C132.588 37.1571 132.732 37.0525 132.782 36.898L133.393 35.0158Z" fill="#FACC15" />
                <path d="M142.393 35.0158C142.506 34.6703 142.994 34.6703 143.107 35.0158L143.718 36.898C143.768 37.0525 143.912 37.1571 144.075 37.1571H146.054C146.417 37.1571 146.568 37.622 146.274 37.8355L144.673 38.9988C144.542 39.0943 144.487 39.2635 144.537 39.418L145.149 41.3003C145.261 41.6458 144.865 41.933 144.572 41.7195L142.97 40.5563C142.839 40.4608 142.661 40.4608 142.53 40.5563L140.928 41.7195C140.635 41.933 140.239 41.6458 140.351 41.3003L140.963 39.418C141.013 39.2635 140.958 39.0943 140.827 38.9988L139.226 37.8355C138.932 37.622 139.083 37.1571 139.446 37.1571H141.425C141.588 37.1571 141.732 37.0525 141.782 36.898L142.393 35.0158Z" fill="#FACC15" />
                <path d="M97 81C97 76.5817 100.582 73 105 73H215C219.418 73 223 76.5817 223 81V124C223 128.418 219.418 132 215 132H105C100.582 132 97 128.418 97 124V81Z" fill="white" />
                <rect x="103" y="82" width="12" height="12" rx="6" fill="#E4E4E4" />
                <rect x="118" y="82" width="50" height="4" rx="2" fill="#E4E4E4" />
                <rect x="103" y="114" width="115" height="4" rx="2" fill="#E4E4E4" />
                <rect x="103" y="121" width="115" height="4" rx="2" fill="#E4E4E4" />
                <rect x="118" y="89" width="39" height="4" rx="2" fill="#E4E4E4" />
                <path d="M106.393 100.016C106.506 99.6703 106.994 99.6703 107.107 100.016L107.718 101.898C107.768 102.052 107.912 102.157 108.075 102.157H110.054C110.417 102.157 110.568 102.622 110.274 102.836L108.673 103.999C108.542 104.094 108.487 104.264 108.537 104.418L109.149 106.3C109.261 106.646 108.865 106.933 108.572 106.72L106.97 105.556C106.839 105.461 106.661 105.461 106.53 105.556L104.928 106.72C104.635 106.933 104.239 106.646 104.351 106.3L104.963 104.418C105.013 104.264 104.958 104.094 104.827 103.999L103.226 102.836C102.932 102.622 103.083 102.157 103.446 102.157H105.425C105.588 102.157 105.732 102.052 105.782 101.898L106.393 100.016Z" fill="#FACC15" />
                <path d="M115.393 100.016C115.506 99.6703 115.994 99.6703 116.107 100.016L116.718 101.898C116.768 102.052 116.912 102.157 117.075 102.157H119.054C119.417 102.157 119.568 102.622 119.274 102.836L117.673 103.999C117.542 104.094 117.487 104.264 117.537 104.418L118.149 106.3C118.261 106.646 117.865 106.933 117.572 106.72L115.97 105.556C115.839 105.461 115.661 105.461 115.53 105.556L113.928 106.72C113.635 106.933 113.239 106.646 113.351 106.3L113.963 104.418C114.013 104.264 113.958 104.094 113.827 103.999L112.226 102.836C111.932 102.622 112.083 102.157 112.446 102.157H114.425C114.588 102.157 114.732 102.052 114.782 101.898L115.393 100.016Z" fill="#FACC15" />
                <path d="M124.393 100.016C124.506 99.6703 124.994 99.6703 125.107 100.016L125.718 101.898C125.768 102.052 125.912 102.157 126.075 102.157H128.054C128.417 102.157 128.568 102.622 128.274 102.836L126.673 103.999C126.542 104.094 126.487 104.264 126.537 104.418L127.149 106.3C127.261 106.646 126.865 106.933 126.572 106.72L124.97 105.556C124.839 105.461 124.661 105.461 124.53 105.556L122.928 106.72C122.635 106.933 122.239 106.646 122.351 106.3L122.963 104.418C123.013 104.264 122.958 104.094 122.827 103.999L121.226 102.836C120.932 102.622 121.083 102.157 121.446 102.157H123.425C123.588 102.157 123.732 102.052 123.782 101.898L124.393 100.016Z" fill="#FACC15" />
                <path d="M133.393 100.016C133.506 99.6703 133.994 99.6703 134.107 100.016L134.718 101.898C134.768 102.052 134.912 102.157 135.075 102.157H137.054C137.417 102.157 137.568 102.622 137.274 102.836L135.673 103.999C135.542 104.094 135.487 104.264 135.537 104.418L136.149 106.3C136.261 106.646 135.865 106.933 135.572 106.72L133.97 105.556C133.839 105.461 133.661 105.461 133.53 105.556L131.928 106.72C131.635 106.933 131.239 106.646 131.351 106.3L131.963 104.418C132.013 104.264 131.958 104.094 131.827 103.999L130.226 102.836C129.932 102.622 130.083 102.157 130.446 102.157H132.425C132.588 102.157 132.732 102.052 132.782 101.898L133.393 100.016Z" fill="#FACC15" />
                <path d="M142.393 100.016C142.506 99.6703 142.994 99.6703 143.107 100.016L143.718 101.898C143.768 102.052 143.912 102.157 144.075 102.157H146.054C146.417 102.157 146.568 102.622 146.274 102.836L144.673 103.999C144.542 104.094 144.487 104.264 144.537 104.418L145.149 106.3C145.261 106.646 144.865 106.933 144.572 106.72L142.97 105.556C142.839 105.461 142.661 105.461 142.53 105.556L140.928 106.72C140.635 106.933 140.239 106.646 140.351 106.3L140.963 104.418C141.013 104.264 140.958 104.094 140.827 103.999L139.226 102.836C138.932 102.622 139.083 102.157 139.446 102.157H141.425C141.588 102.157 141.732 102.052 141.782 101.898L142.393 100.016Z" fill="#FACC15" />
              </svg>

            </BoxImg>
            <ItemTemplateTesteBox>
              <ItemTemplateTesteInfor>
                <label>Paris 1.0</label>
                {configControl.layout_widget == "dimona" && <ItemTemplateTesteStatus>Ativo</ItemTemplateTesteStatus>}
              </ItemTemplateTesteInfor>
              <ItemTemplateTesteThemes>

                <RadioGroup value={configControl.layout_widget + "-" + getTheme()}
                  className="flex items-center gap-x-2.5 flex-wrap gap-y-2.5">

                  <Radio
                    onClick={() => { configControl.layout_widget == "dimona" && setTheme("dark") }}
                    key={"color.name"}
                    value={"dimona-dark"}
                    aria-label={"color.name"}
                    className={classNames("ring-[#222222]", 'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity')}
                    data-tip="Tema Escuro"                  >
                    <span aria-hidden="true" className={classNames("bg-[#1F2937]", 'size-5 rounded-full border border-black/10')} />
                  </Radio>

                  <Radio
                    onClick={() => { configControl.layout_widget == "dimona" && setTheme("light") }}
                    key={"color.name"}
                    value={"dimona-light"}
                    aria-label={"color.name"} className={classNames("ring-[#222222]", 'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity')}
                    data-tip="Tema Claro"
                  >
                    <span aria-hidden="true" className={classNames("bg-[#f2f2f2]", 'size-5 rounded-full border border-black/10')} />
                  </Radio>
                </RadioGroup>
              </ItemTemplateTesteThemes>
            </ItemTemplateTesteBox>
          </ItemTemplateTeste>

          <ItemTemplateTeste>
            {/* <img
              onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "modelo01" } }) }}
              src={ThumbTemplateFortaleza} /> */}
            <BoxImg onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "modelo01" } }) }} >
              <svg width="234" height="140" viewBox="0 0 234 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 6C0 2.6863 2.68629 0 6 0H228C231.314 0 234 2.68629 234 6V134C234 137.314 231.314 140 228 140H6C2.68629 140 0 137.314 0 134V6Z" fill="#F5F5F5" />
                <path d="M8 16C8 11.5817 11.5817 8 16 8H105C109.418 8 113 11.5817 113 16V59C113 63.4183 109.418 67 105 67H16C11.5817 67 8 63.4183 8 59V16Z" fill="white" />
                <rect x="50" y="23" width="22" height="4" rx="2" fill="#E4E4E4" />
                <rect x="37" y="31" width="48" height="4" rx="2" fill="#E4E4E4" />
                <path d="M42.6433 42.0158C42.7556 41.6703 43.2444 41.6703 43.3566 42.0158L43.9682 43.898C44.0184 44.0525 44.1624 44.1571 44.3248 44.1571H46.3039C46.6672 44.1571 46.8182 44.622 46.5243 44.8355L44.9232 45.9988C44.7918 46.0943 44.7368 46.2635 44.787 46.418L45.3986 48.3003C45.5108 48.6458 45.1154 48.933 44.8215 48.7195L43.2204 47.5563C43.089 47.4608 42.911 47.4608 42.7796 47.5563L41.1784 48.7195C40.8846 48.933 40.4891 48.6458 40.6014 48.3003L41.213 46.418C41.2632 46.2635 41.2082 46.0943 41.0767 45.9988L39.4756 44.8355C39.1817 44.622 39.3328 44.1571 39.696 44.1571H41.6751C41.8376 44.1571 41.9816 44.0525 42.0318 43.898L42.6433 42.0158Z" fill="#FACC15" />
                <path d="M51.6433 42.0158C51.7556 41.6703 52.2444 41.6703 52.3566 42.0158L52.9682 43.898C53.0184 44.0525 53.1624 44.1571 53.3248 44.1571H55.3039C55.6672 44.1571 55.8182 44.622 55.5243 44.8355L53.9232 45.9988C53.7918 46.0943 53.7368 46.2635 53.787 46.418L54.3986 48.3003C54.5108 48.6458 54.1154 48.933 53.8215 48.7195L52.2204 47.5563C52.089 47.4608 51.911 47.4608 51.7796 47.5563L50.1784 48.7195C49.8846 48.933 49.4891 48.6458 49.6014 48.3003L50.213 46.418C50.2632 46.2635 50.2082 46.0943 50.0767 45.9988L48.4756 44.8355C48.1817 44.622 48.3328 44.1571 48.696 44.1571H50.6751C50.8376 44.1571 50.9816 44.0525 51.0318 43.898L51.6433 42.0158Z" fill="#FACC15" />
                <path d="M60.6433 42.0158C60.7556 41.6703 61.2444 41.6703 61.3566 42.0158L61.9682 43.898C62.0184 44.0525 62.1624 44.1571 62.3248 44.1571H64.3039C64.6672 44.1571 64.8182 44.622 64.5243 44.8355L62.9232 45.9988C62.7918 46.0943 62.7368 46.2635 62.787 46.418L63.3986 48.3003C63.5108 48.6458 63.1154 48.933 62.8215 48.7195L61.2204 47.5563C61.089 47.4608 60.911 47.4608 60.7796 47.5563L59.1784 48.7195C58.8846 48.933 58.4891 48.6458 58.6014 48.3003L59.213 46.418C59.2632 46.2635 59.2082 46.0943 59.0767 45.9988L57.4756 44.8355C57.1817 44.622 57.3328 44.1571 57.696 44.1571H59.6751C59.8376 44.1571 59.9816 44.0525 60.0318 43.898L60.6433 42.0158Z" fill="#FACC15" />
                <path d="M69.6433 42.0158C69.7556 41.6703 70.2444 41.6703 70.3566 42.0158L70.9682 43.898C71.0184 44.0525 71.1624 44.1571 71.3248 44.1571H73.3039C73.6672 44.1571 73.8182 44.622 73.5243 44.8355L71.9232 45.9988C71.7918 46.0943 71.7368 46.2635 71.787 46.418L72.3986 48.3003C72.5108 48.6458 72.1154 48.933 71.8215 48.7195L70.2204 47.5563C70.089 47.4608 69.911 47.4608 69.7796 47.5563L68.1784 48.7195C67.8846 48.933 67.4891 48.6458 67.6014 48.3003L68.213 46.418C68.2632 46.2635 68.2082 46.0943 68.0767 45.9988L66.4756 44.8355C66.1817 44.622 66.3328 44.1571 66.696 44.1571H68.6751C68.8376 44.1571 68.9816 44.0525 69.0318 43.898L69.6433 42.0158Z" fill="#FACC15" />
                <path d="M78.6433 42.0158C78.7556 41.6703 79.2444 41.6703 79.3566 42.0158L79.9682 43.898C80.0184 44.0525 80.1624 44.1571 80.3248 44.1571H82.3039C82.6672 44.1571 82.8182 44.622 82.5243 44.8355L80.9232 45.9988C80.7918 46.0943 80.7368 46.2635 80.787 46.418L81.3986 48.3003C81.5108 48.6458 81.1154 48.933 80.8215 48.7195L79.2204 47.5563C79.089 47.4608 78.911 47.4608 78.7796 47.5563L77.1784 48.7195C76.8846 48.933 76.4891 48.6458 76.6014 48.3003L77.213 46.418C77.2632 46.2635 77.2082 46.0943 77.0767 45.9988L75.4756 44.8355C75.1817 44.622 75.3328 44.1571 75.696 44.1571H77.6751C77.8376 44.1571 77.9816 44.0525 78.0318 43.898L78.6433 42.0158Z" fill="#FACC15" />
                <path d="M121 16C121 11.5817 124.582 8 129 8H218C222.418 8 226 11.5817 226 16V59C226 63.4183 222.418 67 218 67H129C124.582 67 121 63.4183 121 59V16Z" fill="white" />
                <rect x="129" y="28" width="4" height="4" rx="2" fill="#E4E4E4" />
                <rect x="137" y="28" width="76" height="4" rx="2" fill="#E4E4E4" />
                <rect x="217" y="28" width="4" height="4" rx="2" fill="#E4E4E4" />
                <rect x="129" y="36" width="4" height="4" rx="2" fill="#E4E4E4" />
                <rect x="137" y="36" width="76" height="4" rx="2" fill="#E4E4E4" />
                <rect x="217" y="36" width="4" height="4" rx="2" fill="#E4E4E4" />
                <rect x="129" y="44" width="4" height="4" rx="2" fill="#E4E4E4" />
                <rect x="137" y="44" width="76" height="4" rx="2" fill="#E4E4E4" />
                <rect x="217" y="44" width="4" height="4" rx="2" fill="#E4E4E4" />
                <rect x="137" y="19" width="30" height="4" rx="2" fill="#E4E4E4" />
                <path d="M8 81C8 76.5817 11.5817 73 16 73H222C226.418 73 230 76.5817 230 81V124C230 128.418 226.418 132 222 132H16C11.5817 132 8 128.418 8 124V81Z" fill="white" />
                <rect x="14" y="82" width="12" height="12" rx="6" fill="#E4E4E4" />
                <rect x="29" y="82" width="50" height="4" rx="2" fill="#E4E4E4" />
                <rect x="14" y="114" width="211" height="4" rx="2" fill="#E4E4E4" />
                <rect x="14" y="121" width="211" height="4" rx="2" fill="#E4E4E4" />
                <rect x="29" y="89" width="39" height="4" rx="2" fill="#E4E4E4" />
                <path d="M17.3933 100.016C17.5056 99.6703 17.9944 99.6703 18.1066 100.016L18.7182 101.898C18.7684 102.052 18.9124 102.157 19.0748 102.157H21.0539C21.4172 102.157 21.5682 102.622 21.2743 102.836L19.6732 103.999C19.5418 104.094 19.4868 104.264 19.537 104.418L20.1486 106.3C20.2608 106.646 19.8654 106.933 19.5715 106.72L17.9704 105.556C17.839 105.461 17.661 105.461 17.5296 105.556L15.9284 106.72C15.6346 106.933 15.2391 106.646 15.3514 106.3L15.963 104.418C16.0132 104.264 15.9582 104.094 15.8267 103.999L14.2256 102.836C13.9317 102.622 14.0828 102.157 14.446 102.157H16.4251C16.5876 102.157 16.7316 102.052 16.7818 101.898L17.3933 100.016Z" fill="#FACC15" />
                <path d="M26.3933 100.016C26.5056 99.6703 26.9944 99.6703 27.1066 100.016L27.7182 101.898C27.7684 102.052 27.9124 102.157 28.0748 102.157H30.0539C30.4172 102.157 30.5682 102.622 30.2743 102.836L28.6732 103.999C28.5418 104.094 28.4868 104.264 28.537 104.418L29.1486 106.3C29.2608 106.646 28.8654 106.933 28.5715 106.72L26.9704 105.556C26.839 105.461 26.661 105.461 26.5296 105.556L24.9284 106.72C24.6346 106.933 24.2391 106.646 24.3514 106.3L24.963 104.418C25.0132 104.264 24.9582 104.094 24.8267 103.999L23.2256 102.836C22.9317 102.622 23.0828 102.157 23.446 102.157H25.4251C25.5876 102.157 25.7316 102.052 25.7818 101.898L26.3933 100.016Z" fill="#FACC15" />
                <path d="M53.3933 100.016C53.5056 99.6703 53.9944 99.6703 54.1066 100.016L54.7182 101.898C54.7684 102.052 54.9124 102.157 55.0748 102.157H57.0539C57.4172 102.157 57.5682 102.622 57.2743 102.836L55.6732 103.999C55.5418 104.094 55.4868 104.264 55.537 104.418L56.1486 106.3C56.2608 106.646 55.8654 106.933 55.5715 106.72L53.9704 105.556C53.839 105.461 53.661 105.461 53.5296 105.556L51.9284 106.72C51.6346 106.933 51.2391 106.646 51.3514 106.3L51.963 104.418C52.0132 104.264 51.9582 104.094 51.8267 103.999L50.2256 102.836C49.9317 102.622 50.0828 102.157 50.446 102.157H52.4251C52.5876 102.157 52.7316 102.052 52.7818 101.898L53.3933 100.016Z" fill="#FACC15" />
              </svg>
            </BoxImg>
            <ItemTemplateTesteBox>
              <ItemTemplateTesteInfor>
                <label>Fortaleza 1.0</label>
                {configControl.layout_widget == "modelo01" && <ItemTemplateTesteStatus>Ativo</ItemTemplateTesteStatus>}
              </ItemTemplateTesteInfor>
              <ItemTemplateTesteThemes>
                <RadioGroup value={configControl.layout_widget + "-" + getTheme()}
                  className="flex items-center gap-x-2.5 flex-wrap gap-y-2.5">
                  <Radio
                    onClick={() => { configControl.layout_widget == "modelo01" && setTheme("dark") }}
                    key={"color.name"}
                    value={"modelo01-dark"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Escuro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#1F2937]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>

                  <Radio
                    onClick={() => { configControl.layout_widget == "modelo01" && setTheme("light") }}
                    key={"color.name"}
                    value={"modelo01-light"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Claro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#f2f2f2]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>
                </RadioGroup>
              </ItemTemplateTesteThemes>
            </ItemTemplateTesteBox>
          </ItemTemplateTeste>

          <ItemTemplateTeste>
            {/* <img
              onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "modelo03" } }) }}
              src={ThumbTemplateParnaiba} /> */}
            <BoxImg onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "modelo03" } }) }}>
              <svg width="234" height="140" viewBox="0 0 234 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2052_12387)">
                  <path d="M0 6C0 2.6863 2.68629 0 6 0H228C231.314 0 234 2.68629 234 6V134C234 137.314 231.314 140 228 140H6C2.68629 140 0 137.314 0 134V6Z" fill="#F5F5F5" />
                  <path d="M8 44C8 39.5817 11.5817 36 16 36H103C107.418 36 111 39.5817 111 44V124C111 128.418 107.418 132 103 132H16C11.5817 132 8 128.418 8 124V44Z" fill="white" />
                  <path d="M123 44C123 39.5817 126.582 36 131 36H222C226.418 36 230 39.5817 230 44V87C230 91.4183 226.418 95 222 95H131C126.582 95 123 91.4183 123 87V44Z" fill="white" />
                  <rect x="129" y="45" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="144" y="45" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="129" y="77" width="96" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="129" y="84" width="96" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="144" y="52" width="39" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M132.393 63.0158C132.506 62.6703 132.994 62.6703 133.107 63.0158L133.718 64.898C133.768 65.0525 133.912 65.1571 134.075 65.1571H136.054C136.417 65.1571 136.568 65.622 136.274 65.8355L134.673 66.9988C134.542 67.0943 134.487 67.2635 134.537 67.418L135.149 69.3003C135.261 69.6458 134.865 69.933 134.572 69.7195L132.97 68.5563C132.839 68.4608 132.661 68.4608 132.53 68.5563L130.928 69.7195C130.635 69.933 130.239 69.6458 130.351 69.3003L130.963 67.418C131.013 67.2635 130.958 67.0943 130.827 66.9988L129.226 65.8355C128.932 65.622 129.083 65.1571 129.446 65.1571H131.425C131.588 65.1571 131.732 65.0525 131.782 64.898L132.393 63.0158Z" fill="#FACC15" />
                  <path d="M141.393 63.0158C141.506 62.6703 141.994 62.6703 142.107 63.0158L142.718 64.898C142.768 65.0525 142.912 65.1571 143.075 65.1571H145.054C145.417 65.1571 145.568 65.622 145.274 65.8355L143.673 66.9988C143.542 67.0943 143.487 67.2635 143.537 67.418L144.149 69.3003C144.261 69.6458 143.865 69.933 143.572 69.7195L141.97 68.5563C141.839 68.4608 141.661 68.4608 141.53 68.5563L139.928 69.7195C139.635 69.933 139.239 69.6458 139.351 69.3003L139.963 67.418C140.013 67.2635 139.958 67.0943 139.827 66.9988L138.226 65.8355C137.932 65.622 138.083 65.1571 138.446 65.1571H140.425C140.588 65.1571 140.732 65.0525 140.782 64.898L141.393 63.0158Z" fill="#FACC15" />
                  <path d="M150.393 63.0158C150.506 62.6703 150.994 62.6703 151.107 63.0158L151.718 64.898C151.768 65.0525 151.912 65.1571 152.075 65.1571H154.054C154.417 65.1571 154.568 65.622 154.274 65.8355L152.673 66.9988C152.542 67.0943 152.487 67.2635 152.537 67.418L153.149 69.3003C153.261 69.6458 152.865 69.933 152.572 69.7195L150.97 68.5563C150.839 68.4608 150.661 68.4608 150.53 68.5563L148.928 69.7195C148.635 69.933 148.239 69.6458 148.351 69.3003L148.963 67.418C149.013 67.2635 148.958 67.0943 148.827 66.9988L147.226 65.8355C146.932 65.622 147.083 65.1571 147.446 65.1571H149.425C149.588 65.1571 149.732 65.0525 149.782 64.898L150.393 63.0158Z" fill="#FACC15" />
                  <path d="M159.393 63.0158C159.506 62.6703 159.994 62.6703 160.107 63.0158L160.718 64.898C160.768 65.0525 160.912 65.1571 161.075 65.1571H163.054C163.417 65.1571 163.568 65.622 163.274 65.8355L161.673 66.9988C161.542 67.0943 161.487 67.2635 161.537 67.418L162.149 69.3003C162.261 69.6458 161.865 69.933 161.572 69.7195L159.97 68.5563C159.839 68.4608 159.661 68.4608 159.53 68.5563L157.928 69.7195C157.635 69.933 157.239 69.6458 157.351 69.3003L157.963 67.418C158.013 67.2635 157.958 67.0943 157.827 66.9988L156.226 65.8355C155.932 65.622 156.083 65.1571 156.446 65.1571H158.425C158.588 65.1571 158.732 65.0525 158.782 64.898L159.393 63.0158Z" fill="#FACC15" />
                  <path d="M168.393 63.0158C168.506 62.6703 168.994 62.6703 169.107 63.0158L169.718 64.898C169.768 65.0525 169.912 65.1571 170.075 65.1571H172.054C172.417 65.1571 172.568 65.622 172.274 65.8355L170.673 66.9988C170.542 67.0943 170.487 67.2635 170.537 67.418L171.149 69.3003C171.261 69.6458 170.865 69.933 170.572 69.7195L168.97 68.5563C168.839 68.4608 168.661 68.4608 168.53 68.5563L166.928 69.7195C166.635 69.933 166.239 69.6458 166.351 69.3003L166.963 67.418C167.013 67.2635 166.958 67.0943 166.827 66.9988L165.226 65.8355C164.932 65.622 165.083 65.1571 165.446 65.1571H167.425C167.588 65.1571 167.732 65.0525 167.782 64.898L168.393 63.0158Z" fill="#FACC15" />
                  <path d="M123 111C123 106.582 126.582 103 131 103H222C226.418 103 230 106.582 230 111V154C230 158.418 226.418 162 222 162H131C126.582 162 123 158.418 123 154V111Z" fill="white" />
                  <rect x="129" y="112" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="144" y="112" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="144" y="119" width="39" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M132.393 130.016C132.506 129.67 132.994 129.67 133.107 130.016L133.718 131.898C133.768 132.052 133.912 132.157 134.075 132.157H136.054C136.417 132.157 136.568 132.622 136.274 132.836L134.673 133.999C134.542 134.094 134.487 134.264 134.537 134.418L135.149 136.3C135.261 136.646 134.865 136.933 134.572 136.72L132.97 135.556C132.839 135.461 132.661 135.461 132.53 135.556L130.928 136.72C130.635 136.933 130.239 136.646 130.351 136.3L130.963 134.418C131.013 134.264 130.958 134.094 130.827 133.999L129.226 132.836C128.932 132.622 129.083 132.157 129.446 132.157H131.425C131.588 132.157 131.732 132.052 131.782 131.898L132.393 130.016Z" fill="#FACC15" />
                  <path d="M141.393 130.016C141.506 129.67 141.994 129.67 142.107 130.016L142.718 131.898C142.768 132.052 142.912 132.157 143.075 132.157H145.054C145.417 132.157 145.568 132.622 145.274 132.836L143.673 133.999C143.542 134.094 143.487 134.264 143.537 134.418L144.149 136.3C144.261 136.646 143.865 136.933 143.572 136.72L141.97 135.556C141.839 135.461 141.661 135.461 141.53 135.556L139.928 136.72C139.635 136.933 139.239 136.646 139.351 136.3L139.963 134.418C140.013 134.264 139.958 134.094 139.827 133.999L138.226 132.836C137.932 132.622 138.083 132.157 138.446 132.157H140.425C140.588 132.157 140.732 132.052 140.782 131.898L141.393 130.016Z" fill="#FACC15" />
                  <path d="M150.393 130.016C150.506 129.67 150.994 129.67 151.107 130.016L151.718 131.898C151.768 132.052 151.912 132.157 152.075 132.157H154.054C154.417 132.157 154.568 132.622 154.274 132.836L152.673 133.999C152.542 134.094 152.487 134.264 152.537 134.418L153.149 136.3C153.261 136.646 152.865 136.933 152.572 136.72L150.97 135.556C150.839 135.461 150.661 135.461 150.53 135.556L148.928 136.72C148.635 136.933 148.239 136.646 148.351 136.3L148.963 134.418C149.013 134.264 148.958 134.094 148.827 133.999L147.226 132.836C146.932 132.622 147.083 132.157 147.446 132.157H149.425C149.588 132.157 149.732 132.052 149.782 131.898L150.393 130.016Z" fill="#FACC15" />
                  <path d="M159.393 130.016C159.506 129.67 159.994 129.67 160.107 130.016L160.718 131.898C160.768 132.052 160.912 132.157 161.075 132.157H163.054C163.417 132.157 163.568 132.622 163.274 132.836L161.673 133.999C161.542 134.094 161.487 134.264 161.537 134.418L162.149 136.3C162.261 136.646 161.865 136.933 161.572 136.72L159.97 135.556C159.839 135.461 159.661 135.461 159.53 135.556L157.928 136.72C157.635 136.933 157.239 136.646 157.351 136.3L157.963 134.418C158.013 134.264 157.958 134.094 157.827 133.999L156.226 132.836C155.932 132.622 156.083 132.157 156.446 132.157H158.425C158.588 132.157 158.732 132.052 158.782 131.898L159.393 130.016Z" fill="#FACC15" />
                  <path d="M168.393 130.016C168.506 129.67 168.994 129.67 169.107 130.016L169.718 131.898C169.768 132.052 169.912 132.157 170.075 132.157H172.054C172.417 132.157 172.568 132.622 172.274 132.836L170.673 133.999C170.542 134.094 170.487 134.264 170.537 134.418L171.149 136.3C171.261 136.646 170.865 136.933 170.572 136.72L168.97 135.556C168.839 135.461 168.661 135.461 168.53 135.556L166.928 136.72C166.635 136.933 166.239 136.646 166.351 136.3L166.963 134.418C167.013 134.264 166.958 134.094 166.827 133.999L165.226 132.836C164.932 132.622 165.083 132.157 165.446 132.157H167.425C167.588 132.157 167.732 132.052 167.782 131.898L168.393 130.016Z" fill="#FACC15" />
                  <path d="M6 16C6 11.5817 9.58172 8 14 8H105C109.418 8 113 11.5817 113 16V20C113 24.4183 109.418 28 105 28H14C9.58172 28 6 24.4183 6 20V16Z" fill="#E4E4E4" />
                  <path d="M121 16C121 11.5817 124.582 8 129 8H220C224.418 8 228 11.5817 228 16V20C228 24.4183 224.418 28 220 28H129C124.582 28 121 24.4183 121 20V16Z" fill="#E4E4E4" />
                </g>
                <defs>
                  <clipPath id="clip0_2052_12387">
                    <path d="M0 6C0 2.6863 2.68629 0 6 0H228C231.314 0 234 2.68629 234 6V134C234 137.314 231.314 140 228 140H6C2.68629 140 0 137.314 0 134V6Z" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </BoxImg>
            <ItemTemplateTesteBox>
              <ItemTemplateTesteInfor>
                <label>Parnaíba 1.0</label>
                {configControl.layout_widget == "modelo03" && <ItemTemplateTesteStatus>Ativo</ItemTemplateTesteStatus>}
              </ItemTemplateTesteInfor>
              <ItemTemplateTesteThemes>
                <RadioGroup value={configControl.layout_widget + "-" + getTheme()}
                  className="flex items-center gap-x-2.5 flex-wrap gap-y-2.5">
                  <Radio
                    onClick={() => { configControl.layout_widget == "modelo03" && setTheme("dark") }}
                    key={"color.name"}
                    value={"modelo03-dark"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Escuro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#1F2937]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>

                  <Radio
                    onClick={() => { configControl.layout_widget == "modelo03" && setTheme("light") }}
                    key={"color.name"}
                    value={"modelo03-light"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Claro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#f2f2f2]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>
                </RadioGroup>
              </ItemTemplateTesteThemes>
            </ItemTemplateTesteBox>
          </ItemTemplateTeste>

          <ItemTemplateTeste>
            {/* <img
              onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "modelo02" } }) }}
              src={ThumbTemplateBrasilia} /> */}
            <BoxImg onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "modelo02" } }) }}>
              <svg width="234" height="140" viewBox="0 0 234 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2052_12482)">
                  <path d="M0 6C0 2.6863 2.68629 0 6 0H228C231.314 0 234 2.68629 234 6V134C234 137.314 231.314 140 228 140H6C2.68629 140 0 137.314 0 134V6Z" fill="#F5F5F5" />
                  <path d="M8 12C8 9.79086 9.79086 8 12 8H109C111.209 8 113 9.79086 113 12V52C113 54.2091 111.209 56 109 56H12C9.79086 56 8 54.2091 8 52V12Z" fill="white" />
                  <rect x="14" y="14" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="29" y="14" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="14" y="46" width="92" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="29" y="21" width="39" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M17.3933 32.0158C17.5056 31.6703 17.9944 31.6703 18.1066 32.0158L18.7182 33.898C18.7684 34.0525 18.9124 34.1571 19.0748 34.1571H21.0539C21.4172 34.1571 21.5682 34.622 21.2743 34.8355L19.6732 35.9988C19.5418 36.0943 19.4868 36.2635 19.537 36.418L20.1486 38.3003C20.2608 38.6458 19.8654 38.933 19.5715 38.7195L17.9704 37.5563C17.839 37.4608 17.661 37.4608 17.5296 37.5563L15.9284 38.7195C15.6346 38.933 15.2391 38.6458 15.3514 38.3003L15.963 36.418C16.0132 36.2635 15.9582 36.0943 15.8267 35.9988L14.2256 34.8355C13.9317 34.622 14.0828 34.1571 14.446 34.1571H16.4251C16.5876 34.1571 16.7316 34.0525 16.7818 33.898L17.3933 32.0158Z" fill="#FACC15" />
                  <path d="M26.3933 32.0158C26.5056 31.6703 26.9944 31.6703 27.1066 32.0158L27.7182 33.898C27.7684 34.0525 27.9124 34.1571 28.0748 34.1571H30.0539C30.4172 34.1571 30.5682 34.622 30.2743 34.8355L28.6732 35.9988C28.5418 36.0943 28.4868 36.2635 28.537 36.418L29.1486 38.3003C29.2608 38.6458 28.8654 38.933 28.5715 38.7195L26.9704 37.5563C26.839 37.4608 26.661 37.4608 26.5296 37.5563L24.9284 38.7195C24.6346 38.933 24.2391 38.6458 24.3514 38.3003L24.963 36.418C25.0132 36.2635 24.9582 36.0943 24.8267 35.9988L23.2256 34.8355C22.9317 34.622 23.0828 34.1571 23.446 34.1571H25.4251C25.5876 34.1571 25.7316 34.0525 25.7818 33.898L26.3933 32.0158Z" fill="#FACC15" />
                  <path d="M35.3933 32.0158C35.5056 31.6703 35.9944 31.6703 36.1066 32.0158L36.7182 33.898C36.7684 34.0525 36.9124 34.1571 37.0748 34.1571H39.0539C39.4172 34.1571 39.5682 34.622 39.2743 34.8355L37.6732 35.9988C37.5418 36.0943 37.4868 36.2635 37.537 36.418L38.1486 38.3003C38.2608 38.6458 37.8654 38.933 37.5715 38.7195L35.9704 37.5563C35.839 37.4608 35.661 37.4608 35.5296 37.5563L33.9284 38.7195C33.6346 38.933 33.2391 38.6458 33.3514 38.3003L33.963 36.418C34.0132 36.2635 33.9582 36.0943 33.8267 35.9988L32.2256 34.8355C31.9317 34.622 32.0828 34.1571 32.446 34.1571H34.4251C34.5876 34.1571 34.7316 34.0525 34.7818 33.898L35.3933 32.0158Z" fill="#FACC15" />
                  <path d="M44.3933 32.0158C44.5056 31.6703 44.9944 31.6703 45.1066 32.0158L45.7182 33.898C45.7684 34.0525 45.9124 34.1571 46.0748 34.1571H48.0539C48.4172 34.1571 48.5682 34.622 48.2743 34.8355L46.6732 35.9988C46.5418 36.0943 46.4868 36.2635 46.537 36.418L47.1486 38.3003C47.2608 38.6458 46.8654 38.933 46.5715 38.7195L44.9704 37.5563C44.839 37.4608 44.661 37.4608 44.5296 37.5563L42.9284 38.7195C42.6346 38.933 42.2391 38.6458 42.3514 38.3003L42.963 36.418C43.0132 36.2635 42.9582 36.0943 42.8267 35.9988L41.2256 34.8355C40.9317 34.622 41.0828 34.1571 41.446 34.1571H43.4251C43.5876 34.1571 43.7316 34.0525 43.7818 33.898L44.3933 32.0158Z" fill="#FACC15" />
                  <path d="M53.3933 32.0158C53.5056 31.6703 53.9944 31.6703 54.1066 32.0158L54.7182 33.898C54.7684 34.0525 54.9124 34.1571 55.0748 34.1571H57.0539C57.4172 34.1571 57.5682 34.622 57.2743 34.8355L55.6732 35.9988C55.5418 36.0943 55.4868 36.2635 55.537 36.418L56.1486 38.3003C56.2608 38.6458 55.8654 38.933 55.5715 38.7195L53.9704 37.5563C53.839 37.4608 53.661 37.4608 53.5296 37.5563L51.9284 38.7195C51.6346 38.933 51.2391 38.6458 51.3514 38.3003L51.963 36.418C52.0132 36.2635 51.9582 36.0943 51.8267 35.9988L50.2256 34.8355C49.9317 34.622 50.0828 34.1571 50.446 34.1571H52.4251C52.5876 34.1571 52.7316 34.0525 52.7818 33.898L53.3933 32.0158Z" fill="#FACC15" />
                  <path d="M8 66C8 63.7909 9.79086 62 12 62H109C111.209 62 113 63.7909 113 66V117C113 119.209 111.209 121 109 121H12C9.79086 121 8 119.209 8 117V66Z" fill="white" />
                  <rect x="14" y="71" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="29" y="71" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="14" y="103" width="92" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="14" y="110" width="92" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="29" y="78" width="39" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M17.3933 89.0158C17.5056 88.6703 17.9944 88.6703 18.1066 89.0158L18.7182 90.898C18.7684 91.0525 18.9124 91.1571 19.0748 91.1571H21.0539C21.4172 91.1571 21.5682 91.622 21.2743 91.8355L19.6732 92.9988C19.5418 93.0943 19.4868 93.2635 19.537 93.418L20.1486 95.3003C20.2608 95.6458 19.8654 95.933 19.5715 95.7195L17.9704 94.5563C17.839 94.4608 17.661 94.4608 17.5296 94.5563L15.9284 95.7195C15.6346 95.933 15.2391 95.6458 15.3514 95.3003L15.963 93.418C16.0132 93.2635 15.9582 93.0943 15.8267 92.9988L14.2256 91.8355C13.9317 91.622 14.0828 91.1571 14.446 91.1571H16.4251C16.5876 91.1571 16.7316 91.0525 16.7818 90.898L17.3933 89.0158Z" fill="#FACC15" />
                  <path d="M26.3933 89.0158C26.5056 88.6703 26.9944 88.6703 27.1066 89.0158L27.7182 90.898C27.7684 91.0525 27.9124 91.1571 28.0748 91.1571H30.0539C30.4172 91.1571 30.5682 91.622 30.2743 91.8355L28.6732 92.9988C28.5418 93.0943 28.4868 93.2635 28.537 93.418L29.1486 95.3003C29.2608 95.6458 28.8654 95.933 28.5715 95.7195L26.9704 94.5563C26.839 94.4608 26.661 94.4608 26.5296 94.5563L24.9284 95.7195C24.6346 95.933 24.2391 95.6458 24.3514 95.3003L24.963 93.418C25.0132 93.2635 24.9582 93.0943 24.8267 92.9988L23.2256 91.8355C22.9317 91.622 23.0828 91.1571 23.446 91.1571H25.4251C25.5876 91.1571 25.7316 91.0525 25.7818 90.898L26.3933 89.0158Z" fill="#FACC15" />
                  <path d="M35.3933 89.0158C35.5056 88.6703 35.9944 88.6703 36.1066 89.0158L36.7182 90.898C36.7684 91.0525 36.9124 91.1571 37.0748 91.1571H39.0539C39.4172 91.1571 39.5682 91.622 39.2743 91.8355L37.6732 92.9988C37.5418 93.0943 37.4868 93.2635 37.537 93.418L38.1486 95.3003C38.2608 95.6458 37.8654 95.933 37.5715 95.7195L35.9704 94.5563C35.839 94.4608 35.661 94.4608 35.5296 94.5563L33.9284 95.7195C33.6346 95.933 33.2391 95.6458 33.3514 95.3003L33.963 93.418C34.0132 93.2635 33.9582 93.0943 33.8267 92.9988L32.2256 91.8355C31.9317 91.622 32.0828 91.1571 32.446 91.1571H34.4251C34.5876 91.1571 34.7316 91.0525 34.7818 90.898L35.3933 89.0158Z" fill="#FACC15" />
                  <path d="M44.3933 89.0158C44.5056 88.6703 44.9944 88.6703 45.1066 89.0158L45.7182 90.898C45.7684 91.0525 45.9124 91.1571 46.0748 91.1571H48.0539C48.4172 91.1571 48.5682 91.622 48.2743 91.8355L46.6732 92.9988C46.5418 93.0943 46.4868 93.2635 46.537 93.418L47.1486 95.3003C47.2608 95.6458 46.8654 95.933 46.5715 95.7195L44.9704 94.5563C44.839 94.4608 44.661 94.4608 44.5296 94.5563L42.9284 95.7195C42.6346 95.933 42.2391 95.6458 42.3514 95.3003L42.963 93.418C43.0132 93.2635 42.9582 93.0943 42.8267 92.9988L41.2256 91.8355C40.9317 91.622 41.0828 91.1571 41.446 91.1571H43.4251C43.5876 91.1571 43.7316 91.0525 43.7818 90.898L44.3933 89.0158Z" fill="#FACC15" />
                  <path d="M53.3933 89.0158C53.5056 88.6703 53.9944 88.6703 54.1066 89.0158L54.7182 90.898C54.7684 91.0525 54.9124 91.1571 55.0748 91.1571H57.0539C57.4172 91.1571 57.5682 91.622 57.2743 91.8355L55.6732 92.9988C55.5418 93.0943 55.4868 93.2635 55.537 93.418L56.1486 95.3003C56.2608 95.6458 55.8654 95.933 55.5715 95.7195L53.9704 94.5563C53.839 94.4608 53.661 94.4608 53.5296 94.5563L51.9284 95.7195C51.6346 95.933 51.2391 95.6458 51.3514 95.3003L51.963 93.418C52.0132 93.2635 51.9582 93.0943 51.8267 92.9988L50.2256 91.8355C49.9317 91.622 50.0828 91.1571 50.446 91.1571H52.4251C52.5876 91.1571 52.7316 91.0525 52.7818 90.898L53.3933 89.0158Z" fill="#FACC15" />
                  <path d="M8 131C8 128.791 9.79086 127 12 127H109C111.209 127 113 128.791 113 131V182C113 184.209 111.209 186 109 186H12C9.79086 186 8 184.209 8 182V131Z" fill="white" />
                  <rect x="14" y="136" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="29" y="136" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M121 12C121 9.79086 122.791 8 125 8H222C224.209 8 226 9.79086 226 12V74C226 76.2091 224.209 78 222 78H125C122.791 78 121 76.2091 121 74V12Z" fill="white" />
                  <rect x="127" y="17" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="142" y="17" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="127" y="49" width="92" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="127" y="57" width="92" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="127" y="65" width="92" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="142" y="24" width="39" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M130.393 35.0158C130.506 34.6703 130.994 34.6703 131.107 35.0158L131.718 36.898C131.768 37.0525 131.912 37.1571 132.075 37.1571H134.054C134.417 37.1571 134.568 37.622 134.274 37.8355L132.673 38.9988C132.542 39.0943 132.487 39.2635 132.537 39.418L133.149 41.3003C133.261 41.6458 132.865 41.933 132.572 41.7195L130.97 40.5563C130.839 40.4608 130.661 40.4608 130.53 40.5563L128.928 41.7195C128.635 41.933 128.239 41.6458 128.351 41.3003L128.963 39.418C129.013 39.2635 128.958 39.0943 128.827 38.9988L127.226 37.8355C126.932 37.622 127.083 37.1571 127.446 37.1571H129.425C129.588 37.1571 129.732 37.0525 129.782 36.898L130.393 35.0158Z" fill="#FACC15" />
                  <path d="M139.393 35.0158C139.506 34.6703 139.994 34.6703 140.107 35.0158L140.718 36.898C140.768 37.0525 140.912 37.1571 141.075 37.1571H143.054C143.417 37.1571 143.568 37.622 143.274 37.8355L141.673 38.9988C141.542 39.0943 141.487 39.2635 141.537 39.418L142.149 41.3003C142.261 41.6458 141.865 41.933 141.572 41.7195L139.97 40.5563C139.839 40.4608 139.661 40.4608 139.53 40.5563L137.928 41.7195C137.635 41.933 137.239 41.6458 137.351 41.3003L137.963 39.418C138.013 39.2635 137.958 39.0943 137.827 38.9988L136.226 37.8355C135.932 37.622 136.083 37.1571 136.446 37.1571H138.425C138.588 37.1571 138.732 37.0525 138.782 36.898L139.393 35.0158Z" fill="#FACC15" />
                  <path d="M148.393 35.0158C148.506 34.6703 148.994 34.6703 149.107 35.0158L149.718 36.898C149.768 37.0525 149.912 37.1571 150.075 37.1571H152.054C152.417 37.1571 152.568 37.622 152.274 37.8355L150.673 38.9988C150.542 39.0943 150.487 39.2635 150.537 39.418L151.149 41.3003C151.261 41.6458 150.865 41.933 150.572 41.7195L148.97 40.5563C148.839 40.4608 148.661 40.4608 148.53 40.5563L146.928 41.7195C146.635 41.933 146.239 41.6458 146.351 41.3003L146.963 39.418C147.013 39.2635 146.958 39.0943 146.827 38.9988L145.226 37.8355C144.932 37.622 145.083 37.1571 145.446 37.1571H147.425C147.588 37.1571 147.732 37.0525 147.782 36.898L148.393 35.0158Z" fill="#FACC15" />
                  <path d="M157.393 35.0158C157.506 34.6703 157.994 34.6703 158.107 35.0158L158.718 36.898C158.768 37.0525 158.912 37.1571 159.075 37.1571H161.054C161.417 37.1571 161.568 37.622 161.274 37.8355L159.673 38.9988C159.542 39.0943 159.487 39.2635 159.537 39.418L160.149 41.3003C160.261 41.6458 159.865 41.933 159.572 41.7195L157.97 40.5563C157.839 40.4608 157.661 40.4608 157.53 40.5563L155.928 41.7195C155.635 41.933 155.239 41.6458 155.351 41.3003L155.963 39.418C156.013 39.2635 155.958 39.0943 155.827 38.9988L154.226 37.8355C153.932 37.622 154.083 37.1571 154.446 37.1571H156.425C156.588 37.1571 156.732 37.0525 156.782 36.898L157.393 35.0158Z" fill="#FACC15" />
                  <path d="M166.393 35.0158C166.506 34.6703 166.994 34.6703 167.107 35.0158L167.718 36.898C167.768 37.0525 167.912 37.1571 168.075 37.1571H170.054C170.417 37.1571 170.568 37.622 170.274 37.8355L168.673 38.9988C168.542 39.0943 168.487 39.2635 168.537 39.418L169.149 41.3003C169.261 41.6458 168.865 41.933 168.572 41.7195L166.97 40.5563C166.839 40.4608 166.661 40.4608 166.53 40.5563L164.928 41.7195C164.635 41.933 164.239 41.6458 164.351 41.3003L164.963 39.418C165.013 39.2635 164.958 39.0943 164.827 38.9988L163.226 37.8355C162.932 37.622 163.083 37.1571 163.446 37.1571H165.425C165.588 37.1571 165.732 37.0525 165.782 36.898L166.393 35.0158Z" fill="#FACC15" />
                  <path d="M121 88C121 85.7909 122.791 84 125 84H222C224.209 84 226 85.7909 226 88V139C226 141.209 224.209 143 222 143H125C122.791 143 121 141.209 121 139V88Z" fill="white" />
                  <rect x="127" y="93" width="12" height="12" rx="6" fill="#E4E4E4" />
                  <rect x="142" y="93" width="50" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="127" y="125" width="92" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="127" y="132" width="92" height="4" rx="2" fill="#E4E4E4" />
                  <rect x="142" y="100" width="39" height="4" rx="2" fill="#E4E4E4" />
                  <path d="M130.393 111.016C130.506 110.67 130.994 110.67 131.107 111.016L131.718 112.898C131.768 113.052 131.912 113.157 132.075 113.157H134.054C134.417 113.157 134.568 113.622 134.274 113.836L132.673 114.999C132.542 115.094 132.487 115.264 132.537 115.418L133.149 117.3C133.261 117.646 132.865 117.933 132.572 117.72L130.97 116.556C130.839 116.461 130.661 116.461 130.53 116.556L128.928 117.72C128.635 117.933 128.239 117.646 128.351 117.3L128.963 115.418C129.013 115.264 128.958 115.094 128.827 114.999L127.226 113.836C126.932 113.622 127.083 113.157 127.446 113.157H129.425C129.588 113.157 129.732 113.052 129.782 112.898L130.393 111.016Z" fill="#FACC15" />
                  <path d="M139.393 111.016C139.506 110.67 139.994 110.67 140.107 111.016L140.718 112.898C140.768 113.052 140.912 113.157 141.075 113.157H143.054C143.417 113.157 143.568 113.622 143.274 113.836L141.673 114.999C141.542 115.094 141.487 115.264 141.537 115.418L142.149 117.3C142.261 117.646 141.865 117.933 141.572 117.72L139.97 116.556C139.839 116.461 139.661 116.461 139.53 116.556L137.928 117.72C137.635 117.933 137.239 117.646 137.351 117.3L137.963 115.418C138.013 115.264 137.958 115.094 137.827 114.999L136.226 113.836C135.932 113.622 136.083 113.157 136.446 113.157H138.425C138.588 113.157 138.732 113.052 138.782 112.898L139.393 111.016Z" fill="#FACC15" />
                  <path d="M148.393 111.016C148.506 110.67 148.994 110.67 149.107 111.016L149.718 112.898C149.768 113.052 149.912 113.157 150.075 113.157H152.054C152.417 113.157 152.568 113.622 152.274 113.836L150.673 114.999C150.542 115.094 150.487 115.264 150.537 115.418L151.149 117.3C151.261 117.646 150.865 117.933 150.572 117.72L148.97 116.556C148.839 116.461 148.661 116.461 148.53 116.556L146.928 117.72C146.635 117.933 146.239 117.646 146.351 117.3L146.963 115.418C147.013 115.264 146.958 115.094 146.827 114.999L145.226 113.836C144.932 113.622 145.083 113.157 145.446 113.157H147.425C147.588 113.157 147.732 113.052 147.782 112.898L148.393 111.016Z" fill="#FACC15" />
                  <path d="M157.393 111.016C157.506 110.67 157.994 110.67 158.107 111.016L158.718 112.898C158.768 113.052 158.912 113.157 159.075 113.157H161.054C161.417 113.157 161.568 113.622 161.274 113.836L159.673 114.999C159.542 115.094 159.487 115.264 159.537 115.418L160.149 117.3C160.261 117.646 159.865 117.933 159.572 117.72L157.97 116.556C157.839 116.461 157.661 116.461 157.53 116.556L155.928 117.72C155.635 117.933 155.239 117.646 155.351 117.3L155.963 115.418C156.013 115.264 155.958 115.094 155.827 114.999L154.226 113.836C153.932 113.622 154.083 113.157 154.446 113.157H156.425C156.588 113.157 156.732 113.052 156.782 112.898L157.393 111.016Z" fill="#FACC15" />
                  <path d="M166.393 111.016C166.506 110.67 166.994 110.67 167.107 111.016L167.718 112.898C167.768 113.052 167.912 113.157 168.075 113.157H170.054C170.417 113.157 170.568 113.622 170.274 113.836L168.673 114.999C168.542 115.094 168.487 115.264 168.537 115.418L169.149 117.3C169.261 117.646 168.865 117.933 168.572 117.72L166.97 116.556C166.839 116.461 166.661 116.461 166.53 116.556L164.928 117.72C164.635 117.933 164.239 117.646 164.351 117.3L164.963 115.418C165.013 115.264 164.958 115.094 164.827 114.999L163.226 113.836C162.932 113.622 163.083 113.157 163.446 113.157H165.425C165.588 113.157 165.732 113.052 165.782 112.898L166.393 111.016Z" fill="#FACC15" />
                </g>
                <defs>
                  <clipPath id="clip0_2052_12482">
                    <path d="M0 6C0 2.6863 2.68629 0 6 0H228C231.314 0 234 2.68629 234 6V134C234 137.314 231.314 140 228 140H6C2.68629 140 0 137.314 0 134V6Z" fill="white" />
                  </clipPath>
                </defs>
              </svg>

            </BoxImg>
            <ItemTemplateTesteBox>
              <ItemTemplateTesteInfor>
                <label>Brasília 1.0</label>
                {configControl.layout_widget == "modelo02" && <ItemTemplateTesteStatus>Ativo</ItemTemplateTesteStatus>}
              </ItemTemplateTesteInfor>
              <ItemTemplateTesteThemes>
                <RadioGroup value={configControl.layout_widget + "-" + getTheme()}
                  className="flex items-center gap-x-2.5 flex-wrap gap-y-2.5">
                  <Radio
                    onClick={() => { configControl.layout_widget == "modelo02" && setTheme("dark") }}
                    key={"color.name"}
                    value={"modelo02-dark"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Escuro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#1F2937]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>

                  <Radio
                    onClick={() => { configControl.layout_widget == "modelo02" && setTheme("light") }}
                    key={"color.name"}
                    value={"modelo02-light"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Claro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#f2f2f2]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>
                </RadioGroup>
              </ItemTemplateTesteThemes>
            </ItemTemplateTesteBox>
          </ItemTemplateTeste>

          <ItemTemplateTeste>
            {/* <img
              onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "default" } }) }}
              src={ThumbTemplateGama} /> */}
            <BoxImg onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_widget: "default" } }) }}>
              <svg width="234" height="140" viewBox="0 0 234 140" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 6C0 2.6863 2.68629 0 6 0H228C231.314 0 234 2.68629 234 6V134C234 137.314 231.314 140 228 140H6C2.68629 140 0 137.314 0 134V6Z" fill="#F5F5F5" />
                <path d="M8 32C8 27.5817 11.5817 24 16 24H222C226.418 24 230 27.5817 230 32V67C230 71.4183 226.418 75 222 75H16C11.5817 75 8 71.4183 8 67V32Z" fill="white" />
                <rect x="14" y="32" width="12" height="12" rx="6" fill="#E4E4E4" />
                <rect x="29" y="32" width="50" height="4" rx="2" fill="#E4E4E4" />
                <rect x="14" y="64" width="211" height="4" rx="2" fill="#E4E4E4" />
                <rect x="29" y="39" width="39" height="4" rx="2" fill="#E4E4E4" />
                <path d="M17.3933 50.0158C17.5056 49.6703 17.9944 49.6703 18.1066 50.0158L18.7182 51.8981C18.7684 52.0526 18.9124 52.1572 19.0748 52.1572H21.0539C21.4172 52.1572 21.5682 52.622 21.2743 52.8356L19.6732 53.9988C19.5418 54.0943 19.4868 54.2636 19.537 54.4181L20.1486 56.3003C20.2608 56.6458 19.8654 56.9331 19.5715 56.7196L17.9704 55.5563C17.839 55.4608 17.661 55.4608 17.5296 55.5563L15.9284 56.7196C15.6346 56.9331 15.2391 56.6458 15.3514 56.3003L15.963 54.4181C16.0132 54.2636 15.9582 54.0943 15.8267 53.9988L14.2256 52.8356C13.9317 52.622 14.0828 52.1572 14.446 52.1572H16.4251C16.5876 52.1572 16.7316 52.0526 16.7818 51.8981L17.3933 50.0158Z" fill="#FACC15" />
                <path d="M26.3933 50.0158C26.5056 49.6703 26.9944 49.6703 27.1066 50.0158L27.7182 51.8981C27.7684 52.0526 27.9124 52.1572 28.0748 52.1572H30.0539C30.4172 52.1572 30.5682 52.622 30.2743 52.8356L28.6732 53.9988C28.5418 54.0943 28.4868 54.2636 28.537 54.4181L29.1486 56.3003C29.2608 56.6458 28.8654 56.9331 28.5715 56.7196L26.9704 55.5563C26.839 55.4608 26.661 55.4608 26.5296 55.5563L24.9284 56.7196C24.6346 56.9331 24.2391 56.6458 24.3514 56.3003L24.963 54.4181C25.0132 54.2636 24.9582 54.0943 24.8267 53.9988L23.2256 52.8356C22.9317 52.622 23.0828 52.1572 23.446 52.1572H25.4251C25.5876 52.1572 25.7316 52.0526 25.7818 51.8981L26.3933 50.0158Z" fill="#FACC15" />
                <path d="M35.3933 50.0158C35.5056 49.6703 35.9944 49.6703 36.1066 50.0158L36.7182 51.8981C36.7684 52.0526 36.9124 52.1572 37.0748 52.1572H39.0539C39.4172 52.1572 39.5682 52.622 39.2743 52.8356L37.6732 53.9988C37.5418 54.0943 37.4868 54.2636 37.537 54.4181L38.1486 56.3003C38.2608 56.6458 37.8654 56.9331 37.5715 56.7196L35.9704 55.5563C35.839 55.4608 35.661 55.4608 35.5296 55.5563L33.9284 56.7196C33.6346 56.9331 33.2391 56.6458 33.3514 56.3003L33.963 54.4181C34.0132 54.2636 33.9582 54.0943 33.8267 53.9988L32.2256 52.8356C31.9317 52.622 32.0828 52.1572 32.446 52.1572H34.4251C34.5876 52.1572 34.7316 52.0526 34.7818 51.8981L35.3933 50.0158Z" fill="#FACC15" />
                <path d="M44.3933 50.0158C44.5056 49.6703 44.9944 49.6703 45.1066 50.0158L45.7182 51.8981C45.7684 52.0526 45.9124 52.1572 46.0748 52.1572H48.0539C48.4172 52.1572 48.5682 52.622 48.2743 52.8356L46.6732 53.9988C46.5418 54.0943 46.4868 54.2636 46.537 54.4181L47.1486 56.3003C47.2608 56.6458 46.8654 56.9331 46.5715 56.7196L44.9704 55.5563C44.839 55.4608 44.661 55.4608 44.5296 55.5563L42.9284 56.7196C42.6346 56.9331 42.2391 56.6458 42.3514 56.3003L42.963 54.4181C43.0132 54.2636 42.9582 54.0943 42.8267 53.9988L41.2256 52.8356C40.9317 52.622 41.0828 52.1572 41.446 52.1572H43.4251C43.5876 52.1572 43.7316 52.0526 43.7818 51.8981L44.3933 50.0158Z" fill="#FACC15" />
                <path d="M53.3933 50.0158C53.5056 49.6703 53.9944 49.6703 54.1066 50.0158L54.7182 51.8981C54.7684 52.0526 54.9124 52.1572 55.0748 52.1572H57.0539C57.4172 52.1572 57.5682 52.622 57.2743 52.8356L55.6732 53.9988C55.5418 54.0943 55.4868 54.2636 55.537 54.4181L56.1486 56.3003C56.2608 56.6458 55.8654 56.9331 55.5715 56.7196L53.9704 55.5563C53.839 55.4608 53.661 55.4608 53.5296 55.5563L51.9284 56.7196C51.6346 56.9331 51.2391 56.6458 51.3514 56.3003L51.963 54.4181C52.0132 54.2636 51.9582 54.0943 51.8267 53.9988L50.2256 52.8356C49.9317 52.622 50.0828 52.1572 50.446 52.1572H52.4251C52.5876 52.1572 52.7316 52.0526 52.7818 51.8981L53.3933 50.0158Z" fill="#FACC15" />
                <path d="M8 89C8 84.5817 11.5817 81 16 81H222C226.418 81 230 84.5817 230 89V124C230 128.418 226.418 132 222 132H16C11.5817 132 8 128.418 8 124V89Z" fill="white" />
                <rect x="14" y="89" width="12" height="12" rx="6" fill="#E4E4E4" />
                <rect x="29" y="89" width="50" height="4" rx="2" fill="#E4E4E4" />
                <rect x="14" y="121" width="211" height="4" rx="2" fill="#E4E4E4" />
                <rect x="29" y="96" width="39" height="4" rx="2" fill="#E4E4E4" />
                <path d="M17.3933 107.016C17.5056 106.67 17.9944 106.67 18.1066 107.016L18.7182 108.898C18.7684 109.053 18.9124 109.157 19.0748 109.157H21.0539C21.4172 109.157 21.5682 109.622 21.2743 109.836L19.6732 110.999C19.5418 111.094 19.4868 111.264 19.537 111.418L20.1486 113.3C20.2608 113.646 19.8654 113.933 19.5715 113.72L17.9704 112.556C17.839 112.461 17.661 112.461 17.5296 112.556L15.9284 113.72C15.6346 113.933 15.2391 113.646 15.3514 113.3L15.963 111.418C16.0132 111.264 15.9582 111.094 15.8267 110.999L14.2256 109.836C13.9317 109.622 14.0828 109.157 14.446 109.157H16.4251C16.5876 109.157 16.7316 109.053 16.7818 108.898L17.3933 107.016Z" fill="#FACC15" />
                <path d="M26.3933 107.016C26.5056 106.67 26.9944 106.67 27.1066 107.016L27.7182 108.898C27.7684 109.053 27.9124 109.157 28.0748 109.157H30.0539C30.4172 109.157 30.5682 109.622 30.2743 109.836L28.6732 110.999C28.5418 111.094 28.4868 111.264 28.537 111.418L29.1486 113.3C29.2608 113.646 28.8654 113.933 28.5715 113.72L26.9704 112.556C26.839 112.461 26.661 112.461 26.5296 112.556L24.9284 113.72C24.6346 113.933 24.2391 113.646 24.3514 113.3L24.963 111.418C25.0132 111.264 24.9582 111.094 24.8267 110.999L23.2256 109.836C22.9317 109.622 23.0828 109.157 23.446 109.157H25.4251C25.5876 109.157 25.7316 109.053 25.7818 108.898L26.3933 107.016Z" fill="#FACC15" />
                <path d="M35.3933 107.016C35.5056 106.67 35.9944 106.67 36.1066 107.016L36.7182 108.898C36.7684 109.053 36.9124 109.157 37.0748 109.157H39.0539C39.4172 109.157 39.5682 109.622 39.2743 109.836L37.6732 110.999C37.5418 111.094 37.4868 111.264 37.537 111.418L38.1486 113.3C38.2608 113.646 37.8654 113.933 37.5715 113.72L35.9704 112.556C35.839 112.461 35.661 112.461 35.5296 112.556L33.9284 113.72C33.6346 113.933 33.2391 113.646 33.3514 113.3L33.963 111.418C34.0132 111.264 33.9582 111.094 33.8267 110.999L32.2256 109.836C31.9317 109.622 32.0828 109.157 32.446 109.157H34.4251C34.5876 109.157 34.7316 109.053 34.7818 108.898L35.3933 107.016Z" fill="#FACC15" />
                <path d="M44.3933 107.016C44.5056 106.67 44.9944 106.67 45.1066 107.016L45.7182 108.898C45.7684 109.053 45.9124 109.157 46.0748 109.157H48.0539C48.4172 109.157 48.5682 109.622 48.2743 109.836L46.6732 110.999C46.5418 111.094 46.4868 111.264 46.537 111.418L47.1486 113.3C47.2608 113.646 46.8654 113.933 46.5715 113.72L44.9704 112.556C44.839 112.461 44.661 112.461 44.5296 112.556L42.9284 113.72C42.6346 113.933 42.2391 113.646 42.3514 113.3L42.963 111.418C43.0132 111.264 42.9582 111.094 42.8267 110.999L41.2256 109.836C40.9317 109.622 41.0828 109.157 41.446 109.157H43.4251C43.5876 109.157 43.7316 109.053 43.7818 108.898L44.3933 107.016Z" fill="#FACC15" />
                <path d="M53.3933 107.016C53.5056 106.67 53.9944 106.67 54.1066 107.016L54.7182 108.898C54.7684 109.053 54.9124 109.157 55.0748 109.157H57.0539C57.4172 109.157 57.5682 109.622 57.2743 109.836L55.6732 110.999C55.5418 111.094 55.4868 111.264 55.537 111.418L56.1486 113.3C56.2608 113.646 55.8654 113.933 55.5715 113.72L53.9704 112.556C53.839 112.461 53.661 112.461 53.5296 112.556L51.9284 113.72C51.6346 113.933 51.2391 113.646 51.3514 113.3L51.963 111.418C52.0132 111.264 51.9582 111.094 51.8267 110.999L50.2256 109.836C49.9317 109.622 50.0828 109.157 50.446 109.157H52.4251C52.5876 109.157 52.7316 109.053 52.7818 108.898L53.3933 107.016Z" fill="#FACC15" />
                <path d="M152 9C152 6.79086 153.791 5 156 5H183C185.209 5 187 6.79086 187 9V15C187 17.2091 185.209 19 183 19H156C153.791 19 152 17.2091 152 15V9Z" fill="#E4E4E4" />
                <path d="M8 9C8 6.79086 9.79086 5 12 5H39C41.2091 5 43 6.79086 43 9V15C43 17.2091 41.2091 19 39 19H12C9.79086 19 8 17.2091 8 15V9Z" fill="#E4E4E4" />
                <path d="M195 9C195 6.79086 196.791 5 199 5H226C228.209 5 230 6.79086 230 9V15C230 17.2091 228.209 19 226 19H199C196.791 19 195 17.2091 195 15V9Z" fill="#E4E4E4" />
                <path d="M51 9C51 6.79086 52.7909 5 55 5H82C84.2091 5 86 6.79086 86 9V15C86 17.2091 84.2091 19 82 19H55C52.7909 19 51 17.2091 51 15V9Z" fill="#E4E4E4" />
              </svg>
            </BoxImg>
            <ItemTemplateTesteBox>
              <ItemTemplateTesteInfor>
                <label>Gama 1.0</label>
                {configControl.layout_widget == "default" && <ItemTemplateTesteStatus>Ativo</ItemTemplateTesteStatus>}
              </ItemTemplateTesteInfor>
              <ItemTemplateTesteThemes>
                <RadioGroup value={configControl.layout_widget + "-" + getTheme()}
                  className="flex items-center gap-x-2.5 flex-wrap gap-y-2.5">
                  <Radio
                    onClick={() => { configControl.layout_widget == "default" && setTheme("dark") }}
                    key={"color.name"}
                    value={"default-dark"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Escuro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#1F2937]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>

                  <Radio
                    onClick={() => { configControl.layout_widget == "default" && setTheme("light") }}
                    key={"color.name"}
                    value={"default-light"}
                    aria-label={"color.name"}
                    className={classNames(
                      "ring-[#222222]",
                      'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none data-[checked]:ring-1 data-[focus]:data-[checked]:ring data-[focus]:data-[checked]:ring-offset-1 before:content-[attr(data-tip)] before:absolute before:px-2 before:py-1 before:left-0 before:-translate-y-full before:bg-gray-700 before:text-white before:text-xs before:rounded-md before:opacity-0 hover:before:opacity-100 before:transition-opacity before:whitespace-nowrap before:-top-2 after:content-[""] after:absolute after:left-[7px] after:-top-2 after:border-[6px] after:border-transparent after:border-t-gray-700 after:opacity-0 hover:after:opacity-100 after:transition-opacity'
                    )}
                    data-tip="Tema Claro"
                  >
                    <span
                      aria-hidden="true"
                      className={classNames("bg-[#f2f2f2]", 'size-5 rounded-full border border-black/10')}
                    />
                  </Radio>
                </RadioGroup>
              </ItemTemplateTesteThemes>
            </ItemTemplateTesteBox>
          </ItemTemplateTeste>

          {/* <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} /> */}

          <ButtonHelp onClick={() => { setVisibleModalVideo(true) }} style={{ maxWidth: "250px" }}>
            <img width={20} src={IconBossVideo} />
            <label>{"Precisa de ajuda?"}</label>
            <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
          </ButtonHelp>

          <BoxButtons style={{ maxWidth: "250px" }}>
            <ButtonReset onClick={() => setDefault("Templates")}>
              <label>{"Restaurar"}</label>
            </ButtonReset>

            <ButtonSave onClick={() => save("Templates")}>
              {"Salvar"}
            </ButtonSave>
          </BoxButtons>

        </Contents>}

      {tabActive == "Cores" &&
        <Contents>
          {/* <Title>Temas</Title> */}
          <ContentsColors>
            {getTabColors()}
          </ContentsColors>
        </Contents>
      }

      {tabActive == "Config" &&
        <Contents>
          {getTabConfigs()}
        </Contents>}
    </>
  )
}
