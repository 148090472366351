import { EmpreenderApps } from '../lib/AppsArray';
import SkelletonLoader from './Skelleton';

const LoaderNavigationApps = () => {
	const loadersArray = Array.from({ length: EmpreenderApps.length });
	return (
		<div className="custom-scroll max-h-[200px] overflow-y-auto overflow-x-hidden">
			<div className="w-[250px] m-0 flex list-none rounded-md bg-white p-1 flex-col">
				<div className="rounded-md !flex !flex-col items-center justify-between p-2 gap-2">
					{loadersArray.map((_, index) => (
						<SkelletonLoader key={index} width="100%" className="h-8" />
					))}
				</div>
			</div>
		</div>
	);
};
export default LoaderNavigationApps;
