import styled from 'styled-components'

export const SelectScreen = styled.div`
  display: flex;
  padding-right: 20px;
  justify-content: end;
  border-bottom: 1px solid ${props => props.theme?.border || '#f2f2f2'};
`

export const Preview = styled.div`
  display: flex;
  //background-color: ${props => props.theme?.backgroundSecondary || '#E5E7EB'};
  margin: 20px;
  width: 100%;
  //padding: 20px;
  border-radius: 8px;
  max-width: ${props => props.isMobile ? '400px' : 'calc(100% - 40px)'};
  justify-content: center;
  flex-direction: column;
  align-items: center;
  //background-color: transparent;

  border-right: 1px solid #e5e7eb;
  border-left: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;

  background-color: rgba(10,23,55,0.5);
  overflow: hidden;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //background-color: red;
`

export const Screen = styled.div`
    display: flex;
    max-width: ${props => props.isMobile ? '360px' : '750px'};   
    flex-direction: column;
    padding: 40px;
    gap: 20px;
    animation: ${props => props.direction === 'next' ? 'slideNext' : 'slidePrev'} 0.3s ease-in-out;
    align-items: center;
    background-color: ${props => props.theme?.background || '#fff'};
    border-radius: 8px;
    margin-top: 100px;
    margin-bottom: 100px;

    @keyframes slideNext {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slidePrev {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0);
        }
    }

    /* @media (max-width: 768px) {
        width: 90%;
        max-width: 90%;
        overflow-x: hidden;
        padding: 16px;
    } */
`

export const Title = styled.div`
    display: flex;
    justify-content: center;

    label {
        font-size: 24px;
        text-align: center;
        color: ${props => props.theme?.text || '#282828'};
    }
`

export const Contents = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`

export const Footer = styled.div`
    display: flex;
    height: 100px;
    background-color: ${props => props.theme?.backgroundSecondary || '#f2f2f2'};
`

export const ProgressBar = styled.div`
    width: ${props => props.isMobile ? '12px' : '60px'};
    height: 2px;
    background-color: ${props => props.active ? (props.theme?.text || '#282828') : (props.theme?.textSecondary || '#cbd5e1')};
    margin: 0 5px;

    /* @media (max-width: 768px) {
        width: 12px;
    } */
`;

export const FooterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div {
        display: flex;
        align-items: center;
    }
`;

export const ProgressIndicator = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${props => props.active ? (props.theme?.text || '#282828') : (props.theme?.textSecondary || '#cbd5e1')};
    border-radius: 50%;
    margin: 0 5px;
`;

export const ActiveProgressIndicator = styled(ProgressIndicator)`
    background-color: ${props => props.theme?.text || '#282828'};
`;

export const NavButton = styled.label`
    color: ${props => props.active == false ? (props.theme?.textSecondary || '#cbd5e1') : (props.theme?.text || '#282828')};
    cursor: ${props => props.active == false ? 'default' : 'pointer'};
    font-size: 14px;
    font-weight: 600;
    width: 80px;
    display: flex;
    justify-content: center; 

    :hover {
        text-decoration: ${props => props.active == false ? 'none' : 'underline'};
        text-underline-offset: 8px;
    }
`;

export const NavInfor = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
`

export const Rating = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 40px 0px;
`;

export const Stars = styled.div`
    display: flex;
    gap: 2px;
`;

export const Star = styled.div`
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: ${props => props.active ? (props.theme?.starActive || '#282828') : (props.theme?.starInactive || '#cbd5e1')};

    svg {
        width: 100%;
        height: 100%;
    }

    &:hover {
        color: ${props => props.theme?.starActive || '#282828'};
    }
`;

export const RatingText = styled.span`
    color: ${props => props.theme?.text || '#282828'};
    font-size: 20px;
    font-weight: 600;
`;

export const InputContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const Input = styled.input`
    width: calc(100% - 0px);
    padding: 15px;
    border: 1px solid ${props => props.theme?.textSecondary || '#cbd5e1'};
    border-radius: 8px;
    font-size: 14px;
    color: ${props => props.theme?.text || '#282828'};
    background-color: transparent;

    &::placeholder {
        color: ${props => props.theme?.textSecondary || '#94a3b8'};
    }

    &:focus {
        outline: none;
        border-color: ${props => props.theme?.text || '#282828'};
    }
`;

export const TextArea = styled.textarea`
    width: calc(100% - 32px);
    height: 200px;
    padding: 15px;
    border: 1px solid ${props => props.theme?.textSecondary || '#cbd5e1'};
    border-radius: 8px;
    resize: none;
    font-size: 14px;
    font-style: italic;
    min-width: 300px;
    background-color: transparent;
    color: ${props => props.theme?.text || '#282828'};

    ::placeholder {
        color: ${props => props.theme?.textSecondary || '#94a3b8'}; /* Cor do placeholder */
        //opacity: 1; /* Garante que a opacidade não reduza a visibilidade */
    }
    
    &:focus {
        outline: none;
        border-color: ${props => props.theme?.text || '#282828'};
    }
`;

export const BtnSend = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    height: 40px;
    background: ${props => props.theme?.backgroundSecondary || '#282828'};
    color: ${props => props.theme?.text || '#fff'};
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 0.9;
    }
`;

export const HiddenInput = styled.input`
    display: none;
`;

export const TermsText = styled.p`
    font-size: 12px;
    color: ${props => props.theme?.text || '#282828'};
    line-height: 1.5;
`;

export const SubmitButton = styled.button`
    padding: 12px 30px;
    background: ${props => props.theme?.buttonBackground || '#282828'};
    color: ${props => props.theme?.buttonText || '#fff'};
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    align-self: center;

    opacity: ${props => props.active ? 1 : 0.5};

    &:hover {
        opacity:${props => props.active ? 0.9 : 0.5};
        cursor:${props => props.active ? 'pointer' : 'default'};
    }
`;

export const BoxThanks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 500px;
    text-align: center;

    @media(max-width: 768px) {
        width: 300px;
    }
`;

export const ThankTitle = styled.h2`
    font-size: 16px;
    color: ${props => props.theme?.text || '#282828'};
    font-weight: 600;
`;

export const ThankDescription = styled.p`
    font-size: 14px;
    color: ${props => props.theme?.text || '#282828'};
    line-height: 1.5;
    max-width: 400px;
`;

export const CouponBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    border: 2px dashed ${props => props.theme?.textSecondary || '#cbd5e1'};
    padding: 10px 20px;
    border-radius: 8px;
`;

export const CouponCode = styled.span`
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.theme?.text || '#282828'};
`;

export const CopyButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: ${props => props.theme?.text || '#282828'};

    svg {
        width: 20px;
        height: 20px;
    }

    &:hover {
        opacity: 0.8;
    }
`;

export const FinishButton = styled.button`
    padding: 12px 30px;
    background: ${props => props.theme?.buttonBackground || '#282828'};
    color: ${props => props.theme?.buttonText || '#fff'};
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        opacity: 0.9;
    }
`;

export const ListMedias = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    max-width: 500px;
    margin-top: 24px;
    padding: 16px;
    background: ${props => props.theme?.backgroundSecondary || '#f8fafc'};
    border-radius: 12px;
    overflow-x: scroll;
    cursor: grab;
    user-select: none;
    touch-action: pan-x;
    -webkit-user-drag: none;

    &:active {
        cursor: grabbing;
    }

    /* Esconde a barra de rolagem mas mantém a funcionalidade */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    @media (max-width: 768px) {
        max-width: 250px;
    }
`;

export const ScrollableContainer = styled.div`
    /* Estilo para a barra de rolagem */
    &::-webkit-scrollbar {
        width: 4px; /* Largura da barra de rolagem */
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme?.textSecondary || '#888'}; /* Cor da parte que pode ser arrastada */
        border-radius: 10px; /* Bordas arredondadas */
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: ${props => props.theme?.text || '#555'}; /* Cor ao passar o mouse */
    }

    &::-webkit-scrollbar-track {
        background: ${props => props.theme?.backgroundSecondary || '#f1f1f1'}; /* Cor do fundo da barra de rolagem */
    }
`;

export const MediaItem = styled.div`
    position: relative;
    flex: 0 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    transition: all 0.2s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

export const MediaOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease;

    ${MediaItem}:hover & {
        opacity: 1;
    }
`;

export const DeleteButton = styled.button`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: none;
    background: ${props => props.theme?.backgroundSecondary || 'white'};
    color: ${props => props.theme?.text || '#282828'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 18px;

    &:hover {
        background: ${props => props.theme?.backgroundSecondary || '#ff4444'};
        color: ${props => props.theme?.text || 'white'};
    }
`;

export const VideoIndicator = styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: rgba(0,0,0,0.5);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
`;

export const ItemNetwork = styled.div`
    display: flex;
    gap: 32px;
    justify-content: center;
    margin-top: 20px;
`;

export const NetworkIcon = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: ${props => props.theme?.text || '#282828'};
    font-size: 14px;

    svg {
        font-size: 20px;
    }

    &:hover {
        opacity: 0.7;
    }
`;

export const ItemMedias = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 500px;

    @media (max-width: 768px) {
        max-width: 300px;
    }
`;

export const MediaButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    padding: 15px;
    border: 1px solid ${props => props.theme?.textSecondary || '#cbd5e1'};
    border-radius: 8px;
    background: transparent;
    font-size: 14px;
    color: ${props => props.theme?.text || '#282828'};
    cursor: pointer;
    font-weight: 600;

    &:hover {
        background: ${props => props.theme?.buttonBackground || '#282828'};
        color: ${props => props.theme?.buttonText || 'white'};
    }

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 500px;

    @media (max-width: 768px) {
        max-width: 300px;
    }
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    gap: 8px;

    label {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export const Label = styled.label`
    font-size: 14px;
    color: ${props => props.theme?.text || '#282828'};
    font-weight: 600;
    padding-right: 90px;

`;

export const BarConfig = styled.div`
    display: flex;
    max-width: ${props => props.isMobile ? '360px !important' : '1280px !important'};
    min-width: 100%;

    padding: 8px 16px;
    background-color: #f9fafb;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;

    border-top: 1px solid #e5e7eb;
`;

export const BarConfigLeft = styled.div`
      display: flex;
  //background-color: red;
    gap: 4px;


    span {
        min-width: 8px;
        min-height: 8px;
        border-radius: 50%;
        background-color: ${props => props.color};
        
    }

    span:nth-child(1){
        background-color: #F66689;    
    }
    span:nth-child(2){
        background-color: #FFCE5B;    
    }
    span:nth-child(3){
        background-color: #56D990;    
    }
`;

export const BarConfigRight = styled.div`
    display: flex;
  //background-color: blue;
  align-items: center;
  gap: 16px;
`;
