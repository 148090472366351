import styled from "styled-components";


export const Container = styled.div`

  display: flex ;
  //background-color: yellow;
  flex-direction: column;

`

export const SelectScreen = styled.div`

  display: flex ;
  padding-right: 20px;
  //background-color: gray;

`

export const BarOptions = styled.div`

  display: flex ;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;

`

export const Contents = styled.div`

    display: flex ;
    padding: 20px;
    background-color: ${props => props.backgroundColor};
    margin: 20px;
    border-radius: 4px;
    flex-direction: column;
`

