import * as SelectPrimitive from '@radix-ui/react-select';
import { Check, ChevronDown, ChevronsUpDown, ChevronUp } from 'lucide-react';
import { Children, cloneElement, forwardRef, isValidElement, ReactElement, useState } from 'react';

import { twMerge } from 'tailwind-merge';

interface props {
	isOpen: boolean;
}

interface IconProps {
	children: ReactElement;
}

const Select = ({ children, ...props }: SelectPrimitive.SelectProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const processedChildren = Children.map(children, (child) => {
		if (isValidElement(child) && child.type === SelectContent) {
			return cloneElement(child as React.ReactElement<props>, { isOpen });
		}
		return child;
	});

	return (
		<SelectPrimitive.Root {...props} open={isOpen} onOpenChange={setIsOpen}>
			{processedChildren}
		</SelectPrimitive.Root>
	);
};

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>>(({ className, children, ...props }, ref) => (
	<SelectPrimitive.Trigger ref={ref} className={twMerge('flex h-10 w-full items-center justify-between rounded-md outline-none border border-gray-200 bg-white px-2 py-1.5 pr-3 text-sm disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1', className)} {...props}>
		{children}
		<div>
			<SelectPrimitive.Icon asChild>
				<ChevronsUpDown strokeWidth={1.5} className="text-gray-400 size-4" />
			</SelectPrimitive.Icon>
		</div>
	</SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = forwardRef<React.ElementRef<typeof SelectPrimitive.ScrollUpButton>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>>(({ className, ...props }, ref) => (
	<SelectPrimitive.ScrollUpButton ref={ref} className={twMerge('flex cursor-default items-center justify-center py-1', className)} {...props}>
		<ChevronUp strokeWidth={1.5} className="h-4 w-4" />
	</SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = forwardRef<React.ElementRef<typeof SelectPrimitive.ScrollDownButton>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>>(({ className, ...props }, ref) => (
	<SelectPrimitive.ScrollDownButton ref={ref} className={twMerge('flex cursor-default items-center justify-center py-1', className)} {...props}>
		<ChevronDown strokeWidth={1.5} className="h-4 w-4" />
	</SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = forwardRef<React.ElementRef<typeof SelectPrimitive.Content>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>>(({ className, children, position = 'popper', ...props }, ref) => {
	return (
		<SelectPrimitive.Portal>
			<SelectPrimitive.Content ref={ref} className={twMerge('relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border border-gray-200 bg-white text-gray-900 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2', position === 'popper' && 'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1', className)} position={position} {...props}>
				<SelectScrollUpButton />
				<SelectPrimitive.Viewport className={twMerge('p-0', position === 'popper' && 'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]')}>{children}</SelectPrimitive.Viewport>
				<SelectScrollDownButton />
			</SelectPrimitive.Content>
		</SelectPrimitive.Portal>
	);
});
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = forwardRef<React.ElementRef<typeof SelectPrimitive.Label>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>>(({ className, ...props }, ref) => <SelectPrimitive.Label ref={ref} className={twMerge('py-1.5 pl-9 pr-2 text-sm font-semibold', className)} {...props} />);
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectIcon = ({ children }: IconProps) => {
	return <div>{children}</div>;
};

const SelectItem = forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>>(({ className, children, ...props }, ref) => {
	const selectIcon = Children.toArray(children).find((child) => isValidElement(child) && child.type === SelectIcon);

	const filteredChildren = Children.toArray(children).filter((child) => !(isValidElement(child) && child.type === SelectIcon));

	return (
		<SelectPrimitive.Item ref={ref} className={twMerge('relative flex w-full items-center justify-between gap-1 cursor-pointer select-none h-10 p-2 text-sm outline-none focus:bg-gray-100 hover:bg-gray-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50', className)} {...props}>
			<div className="flex gap-1 items-center">
				<SelectPrimitive.ItemText asChild>
					<div className="flex gap-1 items-center">
						{selectIcon && <div className="size-6">{selectIcon}</div>}
						<span className="flex flex-row gap-1 items-center">{filteredChildren}</span>
					</div>
				</SelectPrimitive.ItemText>
			</div>

			<span className="size-6 p-1">
				<SelectPrimitive.ItemIndicator>
					<Check strokeWidth={1.5} className="size-full" />
				</SelectPrimitive.ItemIndicator>
			</span>
		</SelectPrimitive.Item>
	);
});
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = forwardRef<React.ElementRef<typeof SelectPrimitive.Separator>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>>(({ className, ...props }, ref) => <SelectPrimitive.Separator ref={ref} className={twMerge('-mx-1 my-1 h-px bg-muted', className)} {...props} />);
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export { Select, SelectIcon, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectLabel, SelectItem, SelectSeparator, SelectScrollUpButton, SelectScrollDownButton };
