import React from 'react';
import { FaStar } from 'react-icons/fa';
import { FaPlayCircle } from 'react-icons/fa';
import { Card, BoxImages, Stars, Name, Comment, Reviews, Header, UserInfo, RatingInfo, DateText, MediaContainer } from "./styled";
import { useSelector } from 'react-redux';
import { CheckBadgeIcon } from '@heroicons/react/24/solid';


const Review01 = ({ review = {}, currentTheme }) => {

    const configControl = useSelector(state => state.reducerControlConfig)

    const renderStars = (rating) => {
        return Array.from({ length: 5 }, (_, index) => (
            <FaStar key={index} style={{ color: index < rating ? currentTheme.starActive : currentTheme.starInactive }} />
        ));
    };

    const renderMedia = () => {
        if (review.media && review.media.length > 0) {
            return (
                <MediaContainer>
                    {review.media.map((media, index) => (
                        <div key={index} style={{ position: 'relative', width: '150px', height: '100px', overflow: 'hidden' }}>
                            <img 
                                src={media.type === 'video' ? media.thumbnail : media.url} 
                                alt={`Media ${index + 1}`}
                                style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer', borderRadius: '4px' }}
                            />
                            {media.type === 'video' && (
                                <FaPlayCircle style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: 'black',
                                    fontSize: '2rem',
                                }} />
                            )}
                        </div>
                    ))}
                </MediaContainer>
            );
        }
        return null;
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        return date.toLocaleDateString('pt-BR', { 
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        })
    }
    if (!review) return null;

    return (
        <Card>
            <Header>
                <UserInfo>
                    <Name>{review.user}{review.verified == true && <CheckBadgeIcon style={{color: currentTheme.verified}} />} </Name>
                    {configControl?.visible_date_review == 1 && (
                        <DateText>23 de fevereiro de 2025</DateText>
                    )}
                </UserInfo>
                <RatingInfo>
                    <Stars>
                        {renderStars(review.rating || 5)}
                    </Stars>
                    <Reviews>{review.rating || 5} estrelas</Reviews> 
                </RatingInfo>
            </Header>
            <Comment>
                {review.text || review.answer}
            </Comment>
            {renderMedia()}
        </Card>
    );
};

export default Review01;