import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.isMobile ? '15px' : '20px'};
  padding: ${props => props.isMobile ? '15px' : '20px'};
  background-color: ${props => props.theme.background};
  max-width: ${props => props.isMobile ? '100%' : '800px'};
  margin: 0 auto;
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.border};
  padding-bottom: 10px;
  margin-bottom: 20px;
  flex-wrap: ${props => props.isMobile ? 'wrap' : 'nowrap'};
  gap: ${props => props.isMobile ? '10px' : '0'};
`;

export const MenuItem = styled.button`
  background: none;
  border: none;
  padding: ${props => props.isMobile ? '6px 12px' : '8px 16px'};
  font-size: ${props => props.isMobile ? '14px' : '16px'};
  cursor: pointer;
  color: ${props => props.active ? props.theme.menuActive : props.theme.menuInactive};
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  border-bottom: 2px solid ${props => props.active ? props.theme.menuActive : 'transparent'};
  margin-right: ${props => props.isMobile ? '10px' : '20px'};
  transition: all 0.3s ease;

  &:hover {
    color: ${props => props.theme.menuActive};
  }
`;

export const ButtonsContainer = styled.div`
  margin-left: ${props => props.isMobile ? '0' : 'auto'};
  width: ${props => props.isMobile ? '100%' : 'auto'};
  display: flex;
  justify-content: ${props => props.isMobile ? 'center' : 'flex-end'};
  margin-top: ${props => props.isMobile ? '10px' : '0'};
`;

export const ActionButton = styled.button`
  background-color: ${props => props.cancel ? props.theme.buttonCancel : props.theme.buttonBg};
  color: ${props => props.theme.buttonText};
  border: none;
  padding: ${props => props.isMobile ? '8px 12px' : '8px 16px'};
  border-radius: 4px;
  font-size: ${props => props.isMobile ? '12px' : '14px'};
  cursor: pointer;
  transition: opacity 0.3s ease;
  width: ${props => props.isMobile ? '100%' : 'auto'};
  margin: ${props => props.isMobile ? '5px 0' : '0 5px'};

  &:hover {
    opacity: 0.9;
  }
`;

export const QuestionForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid ${props => props.theme.border};
  border-radius: 4px;
  background-color: ${props => props.theme.cardBg};
  color: ${props => props.theme.text};
  resize: vertical;
  min-height: 100px;
  font-family: inherit;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.buttonBg};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
`;

export const ReviewCard = styled.div`
  background-color: ${props => props.theme.cardBg};
  border: 1px solid ${props => props.theme.border};
  border-radius: 8px;
  padding: ${props => props.isMobile ? '12px' : '15px'};
  display: flex;
  flex-direction: column;
  gap: ${props => props.isMobile ? '8px' : '10px'};
  margin-bottom: ${props => props.isMobile ? '10px' : '15px'};
`;

export const ReviewImage = styled.div`
  width: 100%;
  height: ${props => props.isMobile ? '120px' : '150px'};
  background-color: ${props => props.theme.border};
  border-radius: 4px;
  margin-bottom: ${props => props.isMobile ? '8px' : '10px'};

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 8px;
`;

export const StarIcon = styled.span`
  color: ${props => props.filled ? props.theme.starFilled : props.theme.starEmpty};
  font-size: ${props => props.isMobile ? '18px' : '20px'};
`;

export const VerifiedIcon = styled.span`
  color: ${props => props.theme.verifiedColor};
  margin-left: 8px;
  font-size: ${props => props.isMobile ? '12px' : '14px'};
`;

export const ReviewDate = styled.span`
  color: ${props => props.theme.text};
  font-size: ${props => props.isMobile ? '12px' : '14px'};
  opacity: 0.7;
`;

export const ReviewText = styled.p`
  color: ${props => props.theme.text};
  font-size: ${props => props.isMobile ? '12px' : '14px'};
  line-height: 1.4;
  margin: 8px 0;
`;
