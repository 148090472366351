import styled from "styled-components";

export const ItemColor = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 2px;

    label{
        font-size: 14px;
    }

    span{
        display: flex;
        align-items: center;
        gap: 12px;

        input[type=text]{
            display: flex;
            height: 34px;
            font-size: 14px;
            flex: 1;
            border-radius: 4px;

            &:focus {
                border: 2px solid #111827 !important; 
                //box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Efeito de brilho (opcional) */
            }
        }

        input[type=color] {
            width: 24px;
            height: 24px; 
            border-radius: 50%;
            overflow: hidden;
            padding: 0px;
            margin: 0px;
            border: none;
            cursor: pointer;
            font-size: 11px ;   
            border: 1px solid #f1f1f144;
        }

        input[type=color]::-webkit-color-swatch {
            border: none;
            border-radius: 50%;
            padding: 0;
            cursor: pointer;
            font-size: 11px ;
        }

        input[type=color]::-webkit-color-swatch-wrapper {
            border: none;
            border-radius: 50%;
            padding: 0;
            cursor: pointer;
            font-size: 11px ;
        }
    
    }
  
`

export const ButtonHelp = styled.div`
    background-color: #121212 ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 48px ;
    border-radius: 4px ;
    justify-content: center ;
    //border: 1px dashed #0D6EFD;
    cursor: pointer;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #fff ;
    }

    img{
        width: 44px ;
        height: 44px ;
        cursor: pointer;
    }
`

export const ButtonSave = styled.div`
    background-color: #00803B  ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    font-size: 12px ;
    color: #fff ;
    cursor: pointer;
    flex: 1;
`

export const ButtonReset = styled.div`
    //background-color: red ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    border: 1px solid #D3DAE3;
    cursor: pointer;
    flex: 1;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #000 ;
    }

    img{
        width: 14px ;
        height: 16px ;
    }

`

export const BoxThemes = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
`

export const ItemTheme = styled.div`
    width: 115px;
    height: 60px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: ${props => props.background};
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    padding: 0 12px;

    .line {
        height: 2px;
        background-color: ${props => props.textColor || '#000000'};
        opacity: 0.8;

        &:nth-child(1) {
            width: 60%;
            margin-left: auto;
        }

        &:nth-child(2) {
            width: 75%;
            margin-left: auto;
        }

        &:nth-child(3) {
            width: 85%;
        }

        &:nth-child(4) {
            width: 65%;
        }

        &:nth-child(5) {
            width: 90%;
            margin-left: auto;
        }

        &:nth-child(6) {
            width: 100%;
        }
    }

    &:hover {
        transform: scale(1.02);
    }

    &.active {
        outline: 2px solid #2563eb;
        outline-offset: 2px;
        border: none;
    }
`

export const BoxButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`