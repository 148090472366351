import styled, { } from "styled-components";

export const Title = styled.div`
    display: flex;
    justify-content: space-between  ;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: #111827;
    padding: 0px 4px;
`

export const Contents = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0px;

`

export const ContentsColors = styled.div`
    display: flex;
    //flex-direction: column;
    gap: 12px;
    margin: 0px 0px;
    flex-wrap: wrap ;
`

export const ItemTemplate = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    
    //height: 240px;
    //background-color: red;
    
    
    img{
        height: 140px;
        border-radius: 4px 4px 0px 0px;
        cursor: pointer;
    }

    div{
        display: flex;
        height: 40px;
        //background-color: gray;
        border-radius: 0px 0px 4px 4px;
        align-items: center;
        padding: 0px 8px;
        border: 1px solid #f2f2f2;
        justify-content: space-between;

        label{
            font-size: 14px;
            display: flex;
            gap: 4px;
            align-items: center;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
            color: #111827;
        }
    }

`

export const ItemTemplateTeste = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    
    img{
        height: 140px;
        border-radius: 4px 4px 0px 0px;
        cursor: pointer;
        object-fit: cover;
    }


`
export const ItemTemplateTesteBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    //border: 1px solid #f2f2f2;
    border-radius: 0px 0px 4px 4px;
    padding: 0px 8px 8px 8px;
    
`
export const ItemTemplateTesteInfor = styled.div`
    display: flex;
    width: 100%;
    //justify-content: space-between;
    align-items: center ;
    height: 40px;
    gap: 12px;
    //background-color: green;

    label{
        font-size: 14px;
        display: flex;
        gap: 4px;
        align-items: center;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: #111827;
    }
    
`
export const ItemTemplateTesteThemes = styled.div`
    display: flex;
    width: 100%;
    gap: 8px;
    flex-wrap: wrap;
    //background-color: red;
    
`
export const ItemTemplateTesteStatus = styled.label`
    display: flex;
    align-items: center;
    font-size: 11px !important;
    padding: 0px 12px;
    background-color: #f0fdf4;
    border-radius: 20px;
    color: #15803d !important;
    height: 20px;
    
`

export const ItemTema = styled.div`
    display: flex;
    border: 2px dashed #BBF7D0;
    height: 40px;
    width: 125px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #F0FDF4;
    cursor: pointer;

    label{
        font-size: 14px;
        color: #166534;
        cursor: pointer;
    }
`

export const ItemColor = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 4px;

    label{
        font-size: 14px;
    }

    span{
        display: flex;
        align-items: center;
        gap: 12px;

        input{
            display: flex;
            height: 34px;
            font-size: 14px;
            flex: 1;
            border-radius: 4px;

            &:focus {
                border: 2px solid #111827 !important; 
                //box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Efeito de brilho (opcional) */
            }
        }
    
        div{
            display: flex;
            height: 24px;
            min-width: 24px;
            border-radius: 50%;
            background-color: #166534;
        }
    }
  
`

export const CircleColor = styled.div`
    display: flex;
    min-width: 26px;
    max-height: 26px;
    //background-color: red;
    border: ${props => `1px solid ${props.active ? "#808080" : "transparent"} !important`} ;
    border-radius: 50% !important;
    position: relative;
    //background-color: red;

    &::before {
        content: '';
        position: absolute;
        border-radius: 50% !important;
        top: 2px; /* Espaçamento interno superior */
        left: 2px; /* Espaçamento interno à esquerda */
        //right: 2px; /* Espaçamento interno à direita */
        //bottom: 2px; Espaçamento interno inferior
        width: 20px;
        height: 20px;
        background-color: ${props => props.color}; /* Cor para visualizar o espaço (opcional) */
        cursor: pointer;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    }
        
`

export const BoxImg = styled.div`
    display: flex;
    background-color: #ffffff;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;

    svg{
        border-radius: 8px;
        cursor: pointer;
    }


`