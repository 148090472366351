import { StarIcon, CheckBadgeIcon } from "@heroicons/react/24/solid"
import { CardWrapper, Content, ReviewText, UserInfo, UserName, RatingContainer, MediaContainer, VideoThumbnail, UserHeader, DateText, MediaGrid } from "./styled"
import { useSelector } from "react-redux"

const Review03 = ({ review, index, isMobile, configControl }) => {
    
    const renderStars = () => {
        const stars = []
        for (let i = 0; i < review.rating; i++) {
            stars.push(
                <StarIcon 
                    key={i} 
                    style={{ 
                        color: JSON.parse(configControl?.layout_configs)?.starActive, 
                        width: "20px" 
                    }} 
                />
            )
        }
        return stars
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        return date.toLocaleDateString('pt-BR', { 
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        })
    }

    const renderMediaItems = () => {
        if (!review.media || review.media.length === 0) return null

        return review.media.map((mediaItem, index) => {
            if (mediaItem.type === 'video') {
                return (
                    <MediaContainer key={index}>
                        <VideoThumbnail>
                            <img 
                                src={mediaItem.thumbnail} 
                                alt="Video thumbnail" 
                            />
                            <div className="play-overlay">
                                <div className="play-icon">▶</div>
                            </div>
                        </VideoThumbnail>
                    </MediaContainer>
                )
            } else {
                return (
                    <MediaContainer key={index}>
                        <img 
                            src={mediaItem.url} 
                            alt="Review media" 
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    </MediaContainer>
                )
            }
        })
    }

    return (
        <CardWrapper font={configControl?.font} style={{ display: isMobile == true && index > 1 ? "none" : "block" }} key={review.id}>
            <Content>
                <RatingContainer>
                    {renderStars()}
                </RatingContainer>
                <ReviewText>{review.text}</ReviewText>
                <UserInfo>
                    <UserHeader>
                        <UserName>{review.user}</UserName>
                        <CheckBadgeIcon style={{ 
                            color: JSON.parse(configControl?.layout_configs)?.verified || '#4CAF50',
                            width: "20px",
                            marginLeft: "4px"
                        }} />
                    </UserHeader>
                    <DateText style={{ 
                        display: configControl?.visible_date_review == 1 ? "block" : "none" 
                    }}>
                        {formatDate(review.date)}
                    </DateText>
                </UserInfo>
                <MediaGrid>
                    {renderMediaItems()}
                </MediaGrid>
            </Content>
        </CardWrapper>
    )
}

export default Review03