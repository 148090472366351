// import BoxReviews from '../box-reviews'
// import BoxQuestions from '../box-questions'
import { ActionQuetion, ActionReview, BoxAction, BoxActionFilter, BoxImage, BoxListReviews, BoxQuestionsList, BoxQuestionsNew, BoxResume, BoxResumeActionFilter, ButtonMore, Card, CardText, ColumnContainer, Comment, Container, Contents, CustomSelect, Divider, ImageWrapper, InforReviews, InforReviewsLine, ItemStar, More, Name, QtdReview, QuestionInfor, SelectFilter, Stars } from './styled'
import { AiFillStar } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { BoxAbas } from './styled'
import { ItemAba } from './styled'
import { FaStar } from 'react-icons/fa'
import CardBK from '../card-bk'
import BoxQuestions from '../box-questions'
import { data } from './dataReviews'
import CardTiktokBK from '../card-tiktok-review-bk'
import CardInstaBK from '../card-review-insta-preview-BK'
import CardQuestionLargeBK from '../card-question-large-bk'
// import CardBK from '../card-bk'
// import CardTiktokBK from '../card-tiktok-review-bk'
// import CardInstaBK from '../card-review-insta-preview-BK'
//import { BoxAbas, ItemAba } from '../layout-03/styled'

const Layout04BK = (props) => {

    //const config = useSelector(state => state.SliceConfig)
    ////const filters = useSelector(state => state.SliceFilters)
    //const [order, setOrder] = useState("default")
    //const [order_questions, setOrder_questions] = useState("default")
    //const [producId, setProducId] = useState(null)
    const dispatch = useDispatch()
    const [color_verified, set_color_verified] = useState("#000000")
    const [color_star_active, set_color_star_active] = useState("#FACC15")
    const [color_star_inactive, set_color_star_inactive] = useState("#cccccc")



    const configControl = useSelector(state => state.reducerControlConfig)

    //const [selectFilter, setSelectFilter] = useState("")
    //const [translation, setTranslation] = useState(getTranslation("pt-br"))

    // useEffect(() => {
    //     //console.log("teste menu config", config)
    //     if (config?.data?.config?.script_public_language != "" && config?.data?.config?.script_public_language != null && config?.data?.config?.script_public_language != "null") {
    //         setTranslation(getTranslation(config?.data?.config?.script_public_language))
    //     } else {
    //         setTranslation(getTranslation(config?.data?.language))
    //     }
    // }, [config])



    // useEffect(() => {
    //     setFilters({ ...filters?.data, order: order })
    //     if (producId != null) {
    //         setFilters({ ...filters?.data, order: order })
    //         filterReviews(producId, filters?.data?.image, filters?.data?.video, filters?.data?.rating, filters?.data?.verified)
    //     }
    // }, [order])

    // useEffect(() => {
    //     if (producId != null) {
    //         //setFilters({ ...filters?.data, order: order })
    //         orderAsks(producId, order_questions)
    //     }
    // }, [order_questions])


    // const calcPorcent = (qtd) => {

    //     switch (qtd) {
    //         case 5:
    //             return (config.data.numberReviews.fiveStar / config.data.numberReviews.maxReviews) * 100
    //         case 4:
    //             return (config.data.numberReviews.fourStar / config.data.numberReviews.maxReviews) * 100
    //         case 3:
    //             return (config.data.numberReviews.threeStar / config.data.numberReviews.maxReviews) * 100
    //         case 2:
    //             return (config.data.numberReviews.twoStar / config.data.numberReviews.maxReviews) * 100
    //         case 1:
    //             return (config.data.numberReviews.oneStar / config.data.numberReviews.maxReviews) * 100

    //         default:
    //             break;
    //     }

    // }

    // async function filterReviews(product_id, image, video, rating, verified) {

    //     if (config?.data?.config) {
    //         const response = await fetch(
    //             `${process.env.NEXT_PUBLIC_REACT_APP_API_URL}/review/pagination/${product_id}?page=${1}&per_page=${config.data?.config?.itens_per_page}&order_reviews=${order}&image=${image}&video=${video}&rating=${rating}&verified=${verified}`,
    //             { method: "GET", }
    //         );
    //         const reviewPaginate = await response.json();

    //         dispatch(setReviews(reviewPaginate.reviews));
    //     }

    // }


    // async function orderAsks(product_id, order) {

    //     if (config?.data?.config) {
    //         const response = await fetch(
    //             `${process.env.NEXT_PUBLIC_REACT_APP_API_URL}/ask/pagination/${product_id}?page=${1}&per_page=${config.data?.config?.itens_per_page}&order_asks=${order}`,
    //             {
    //                 method: "GET",
    //             }
    //         );
    //         const askPaginate = await response.json();
    //         dispatch(setAsks(askPaginate?.asks));
    //     }
    // }


    // useEffect(() => {

    //     if (!isNaN(config?.data?.reviews?.length)) {
    //         if (config?.data?.reviews?.length > 0) {
    //             setProducId(config?.data?.reviews[0]?.product_id)
    //         }
    //     }
    // }, [config])






    // const getPorcentage = (value) => {
    //     if (isNaN(value)) {

    //     } else {
    //         const porcentage = value / config?.data?.numberReviews?.maxReviews * 100
    //         return `${Math.trunc(porcentage)}%`
    //     }

    // }


    // const getPorcentageStar = (value) => {

    //     if (config?.data?.numberReviews?.avgReview > value) {
    //         return `100%`
    //     } else {

    //         console.log("getPorcentageStar", config?.data?.numberReviews?.avgReview?.toFixed(2).toString()?.slice(0, 3).split("").reverse())
    //         return `${config?.data?.numberReviews?.avgReview?.toFixed(2).toString()?.slice(0, 3).split("").reverse()[0]}0%`
    //     }
    // }

    // useEffect(() => {
    //     console.log("selectFilter", selectFilter)
    //     if (selectFilter == "image") { dispatch(setFilters({ image: 1, video: 0, rating: 0, verified: 0, order: 0 })) }
    //     else if (selectFilter == "video") { dispatch(setFilters({ image: 0, video: 1, rating: 0, verified: 0, order: 0 })) }
    //     else if (selectFilter == "5stars") { dispatch(setFilters({ image: 0, video: 0, rating: 5, verified: 0, order: 0 })) }
    //     else if (selectFilter == "4stars") { dispatch(setFilters({ image: 0, video: 0, rating: 4, verified: 0, order: 0 })) }
    //     else if (selectFilter == "3stars") { dispatch(setFilters({ image: 0, video: 0, rating: 3, verified: 0, order: 0 })) }
    //     else if (selectFilter == "2stars") { dispatch(setFilters({ image: 0, video: 0, rating: 2, verified: 0, order: 0 })) }
    //     else if (selectFilter == "1stars") { dispatch(setFilters({ image: 0, video: 0, rating: 1, verified: 0, order: 0 })) }
    //     else { { dispatch(setFilters({ image: 0, video: 0, rating: 0, verified: 0, order: 0 })) } }
    // }, [selectFilter])


    useEffect(() => {
        try {
            if (configControl?.layout_configs != "null") {
                const data = JSON.parse(configControl?.layout_configs)
                // set_color_background_item(data.color_background_item)
                // set_color_text(data.color_text)
                set_color_star_active(data.color_star_active)
                set_color_star_inactive(data.color_star_inactive)
                set_color_verified(data.color_verified)
            } else {
                // set_color_background_item("#FFFFFF")
                // set_color_text("#000000")
                set_color_star_active("#FACC15")
                set_color_star_inactive("#CCCCCC")
                set_color_verified("#000000")

            }
        } catch (error) {

        }
    }, [configControl])

    return (

        <Container >
            <Contents>
                <BoxAbas background={"#ffffff"}                >
                    {/* {"config?.data?.config?.visible_button_review" == 1 &&
                        <ItemAba backgroundItem={"#ECECED"} background={"config?.data?.config?.background_color_primary"} active={props.activeTab == "reviews" ? true : false} onClick={() => { props.setActiveTab("reviews") }} >
                            {`${"translation.box.Avaliacoes"} (${"config?.data?.pagination?.review.total"})`}
                        </ItemAba>}

                    {"config?.data?.config?.visible_button_ask" == 1 &&
                        <ItemAba backgroundItem={"#ECECED"} background={"config?.data?.config?.background_color_primary"} active={props.activeTab == "asks" ? true : false} onClick={() => { props.setActiveTab("asks") }} >
                            {`${"translation.box.duvidas"} (${"config?.data?.pagination?.ask?.total"})`}
                        </ItemAba>} */}

                    <ItemAba style={{ fontFamily: `${configControl?.font}, sans-serif` }} backgroundItem={"#ECECED"} background={"config?.data?.config?.background_color_primary"} active={props.optionSelected == "reviews" ? true : false} onClick={() => { props.setOptionSelected("reviews") }} >
                        {`${"Avaliações"} (${"20"})`}
                    </ItemAba>

                    <ItemAba style={{ fontFamily: `${configControl?.font}, sans-serif` }} backgroundItem={"#ECECED"} background={"config?.data?.config?.background_color_primary"} active={props.optionSelected == "questions" ? true : false} onClick={() => { props.setOptionSelected("questions") }} >
                        {`${"Dúvidas"} (${"10"})`}
                    </ItemAba>
                </BoxAbas>

                {props.optionSelected == "reviews" ?
                    <>
                        <BoxResumeActionFilter>
                            <BoxResume>
                                <QtdReview>
                                    <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>4.8</label>
                                    <span>
                                        <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"100%"}>
                                            <FaStar size={28} />
                                            <FaStar size={28} />
                                        </ItemStar>

                                        <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"100%"}>
                                            <FaStar size={28} />
                                            <FaStar size={28} />
                                        </ItemStar>


                                        <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"100%"}>
                                            <FaStar size={28} />
                                            <FaStar size={28} />
                                        </ItemStar>


                                        <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"100%"}>
                                            <FaStar size={28} />
                                            <FaStar size={28} />
                                        </ItemStar>

                                        <ItemStar colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"80%"}>
                                            <FaStar size={28} />
                                            <FaStar size={28} />
                                        </ItemStar>
                                    </span>
                                    <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{"10"} Avaliações</p>
                                </QtdReview>

                                <Divider />

                                <InforReviews>
                                    <InforReviewsLine colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"80%"}>
                                        <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>5</label>
                                        <FaStar size={20} color={color_star_active} />
                                        <span>
                                            <div />
                                        </span>
                                        <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{8}</p>
                                    </InforReviewsLine>

                                    <InforReviewsLine colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"10%"}>
                                        <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>4</label>
                                        <FaStar size={20} color={color_star_active} />
                                        <span >
                                            <div />
                                        </span>
                                        <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{1}</p>
                                    </InforReviewsLine>

                                    <InforReviewsLine colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"10%"}>
                                        <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>3</label>
                                        <FaStar size={20} color={color_star_active} />
                                        <span >
                                            <div />
                                        </span>
                                        <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{1}</p>
                                    </InforReviewsLine>

                                    <InforReviewsLine colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"0%"}>
                                        <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>2</label>
                                        <FaStar size={20} color={color_star_active} />
                                        <span>
                                            <div />
                                        </span>
                                        <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}>{0}</p>
                                    </InforReviewsLine>

                                    <InforReviewsLine colorActive={color_star_active} colorInactive={color_star_inactive} porcentage={"0%"}>
                                        <label style={{ fontFamily: `${configControl?.font}, sans-serif` }}>1</label>
                                        <FaStar size={20} color={color_star_active} />
                                        <span>
                                            <div />
                                        </span>
                                        <p style={{ fontFamily: `${configControl?.font}, sans-serif` }}> {0}</p>
                                    </InforReviewsLine>
                                </InforReviews>
                            </BoxResume>



                            <BoxActionFilter>
                                <ActionReview style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                                    Escrever uma avaliação
                                </ActionReview>
                                <CustomSelect>
                                    <SelectFilter style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                                        <option value="all">Todas</option>
                                        <option value="1star">1 Estrela</option>
                                        <option value="2stars">2 Estrela</option>
                                        <option value="3stars">3 Estrela</option>
                                        <option value="4stars">4 Estrela</option>
                                        <option value="5stars">5 Estrela</option>
                                        <option value="image">Com imagem</option>
                                        <option value="video">Com vídeo</option>
                                    </SelectFilter>
                                </CustomSelect>

                            </BoxActionFilter>
                        </BoxResumeActionFilter>
                        <BoxListReviews>
                            {/* {data.map((review) => {
                                return <CardBK key={"index"} review={review} />
                            })} */}

                            {data.map((review) => {
                                return (
                                    review?.media[0]?.type == "tiktok" ?
                                        <CardTiktokBK
                                            color_star_active={color_star_active}
                                            color_verified={color_verified}
                                            color_star_inactive={color_star_inactive}
                                            key={"index"} review={review} /> :
                                        review?.media[0]?.type == "instagram" ?
                                            <CardInstaBK
                                                color_star_active={color_star_active}
                                                color_verified={color_verified}
                                                color_star_inactive={color_star_inactive}
                                                key={"index"} item={review} /> :
                                            <CardBK
                                                color_star_active={color_star_active}
                                                color_verified={color_verified}
                                                color_star_inactive={color_star_inactive}
                                                key={"index"}
                                                review={review}
                                            />
                                )
                            })}
                            {/* <CardBK key={"index"} review={{
                                rating: 4,
                                name: "Marcelo Silva",
                                message: "A camisa é extremamente confortável, feita de um tecido macio que se ajusta bem ao corpo. As cores são vibrantes e permanecem intactas mesmo após várias lavagens.",
                                verified: true,
                                media: [
                                    { type: "image", url: "https://http2.mlstatic.com/D_NQ_NP_810185-MLB44845697975_022021-O.webp" }
                                ]
                            }} />
                            <CardBK key={"index"} review={{
                                rating: 4,
                                name: "Mariana Meneses",
                                message: "Camisa estilosa e de alta qualidade, perfeita para quem busca conforto e elegância no dia a dia.",
                                verified: false,
                                media: [
                                    { type: "image", url: "https://down-br.img.susercontent.com/file/br-11134207-7r98o-lrdv2axmjjm528" }
                                ]
                            }} />
                            {Array(10).fill(
                                <CardBK key={"index"} review={{
                                    rating: 5,
                                    name: "João Silva",
                                    message: "muito bom",
                                    verified: true,
                                    media: [
                                        { type: "image", url: "https://img.focanamoda.com.br/cache/1000x1000/g/i/girafa-camiseta-masculina_7.jpg" }
                                    ]
                                }} />
                            )}

                            <CardBK key={"index"} review={{
                                rating: 4,
                                name: "Maria Almeida",
                                message: "A camisa possui um ótimo caimento e design moderno, ideal para diversas ocasiões. O material é durável, mas ainda assim leve, proporcionando conforto durante todo o dia.",
                                verified: false,
                                media: [
                                    { type: "image", url: "https://down-br.img.susercontent.com/file/c773c29049ae4bec95f5bf2b2422e748" }
                                ]
                            }} /> */}
                            {/* {config?.data?.reviews?.map((review, index) => {

                                return (
                                    review?.media[0]?.type == "tiktok" ?

                                        <CardTiktokBK key={index} review={review} />
                                        :
                                        review?.media[0]?.type == "instagram" ?
                                            <CardInstaBK key={index} item={review} /> :
                                            <CardBK key={index} review={review} />
                                )
                            })} */}
                        </BoxListReviews>
                    </> :
                    // <BoxQuestions bk={true} />
                    // "<BoxQuestions activeTab={props.activeTab} /> "
                    <BoxQuestionsNew>
                        {false ?
                            <span style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", gap: "20px" }}>
                                <QuestionInfor style={{ fontFamily: `${configControl?.font}, sans-serif` }}>Esse produdo ainda não tem perguntas</QuestionInfor>
                                <ActionQuetion style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                                    Escrever uma pergunta
                                </ActionQuetion>
                            </span> :
                            <>
                                <BoxQuestionsList>

                                    <CardQuestionLargeBK key={"1"} item={{ name: "João Silva", message: "Qual o prazo de entrega?", response: "Até 30 dias úteis.", dateMessage: "01 de janeiro de 2022", dateResponse: "01 de janeiro de 2022" }} />
                                    <CardQuestionLargeBK key={"2"} item={{ name: "Maria Fernandes", message: "Posso trocar?", response: "Sim. Entre em contato com nosso suporte (99) 9.9999-9999.", dateMessage: "05 de fevereiro de 2023", dateResponse: "06 de fevereiro de 2023" }} />
                                    <CardQuestionLargeBK key={"3"} item={{ name: "Pedro Almeida", message: "Tem outros modelos disponíveis?", response: "Não. No momento só trabalhamos cmo modelos para adultos.", dateMessage: "10 de janeiro de 2024", dateResponse: "11 de janeiro de 2024" }} />
                                    <CardQuestionLargeBK key={"4"} item={{ name: "Paulo Rodrigues", message: "Tem modelo infantil?", response: "Sim. até 12 anos, entre em contato com nosso suporte (99) 9.9999-9999.", dateMessage: "01 de agosto de 2024", dateResponse: "01 de agosto de 2024" }} />
                                    <CardQuestionLargeBK key={"5"} item={{ name: "Mateus Santos", message: "Qual número de suporte?", response: "", dateMessage: "01 de setembro de 2024", dateResponse: "" }} />

                                    {false &&
                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                                            <ButtonMore style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                                                {/* {translation?.box.verMais} */}
                                                Carregar mais
                                            </ButtonMore>
                                        </div>}
                                </BoxQuestionsList>
                                <BoxAction>
                                    <QuestionInfor style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                                        Não encontrou a dúvida que procurava?
                                    </QuestionInfor>
                                    <ActionQuetion style={{ fontFamily: `${configControl?.font}, sans-serif` }}>
                                        Escrever uma pergunta
                                    </ActionQuetion>
                                </BoxAction>
                            </>}
                    </BoxQuestionsNew>
                }

            </Contents>
        </Container >

    )
}

export default Layout04BK

