import { ReactNode, forwardRef } from 'react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

interface DropdownMenuContentProps extends DropdownMenuPrimitive.DropdownMenuProps {
	children: ReactNode;
	isOpen?: boolean;
	className?: string;
	delay?: number;
	verificarSide?: boolean | false;
	side?: 'bottom' | 'left' | 'right' | 'top' | undefined;
	sideOffset?: number;
	align?: 'center' | 'start' | 'end' | undefined;
}

export const DropdownMenuContent = forwardRef<HTMLDivElement, DropdownMenuContentProps>(({ children, isOpen, side, className, delay, sideOffset, align, ...props }, ref) => {
	const getAnimation = () => {
		switch (side) {
			case 'bottom':
				return { initial: { opacity: 0, y: 5 }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: 5 } };
			case 'top':
				return { initial: { opacity: 0, y: 5 }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: 5 } };
			case 'left':
				return { initial: { opacity: 0, x: 5 }, animate: { opacity: 1, x: 0 }, exit: { opacity: 0, x: 5 } };
			case 'right':
				return { initial: { opacity: 0, x: -5 }, animate: { opacity: 1, x: 0 }, exit: { opacity: 0, x: -5 } };
			default:
				return { initial: { opacity: 0, y: -5 }, animate: { opacity: 1, y: 0 }, exit: { opacity: 0, y: -5 } };
		}
	};

	const animation = getAnimation();

	return (
		<DropdownMenuPrimitive.Portal forceMount>
			<AnimatePresence>
				{isOpen && (
					<DropdownMenuPrimitive.Content align={align || 'center'} side={side} sideOffset={sideOffset} asChild forceMount ref={ref} {...props}>
						<motion.div
							initial={animation.initial}
							animate={animation.animate}
							exit={animation.exit}
							transition={{ duration: 0.15, delay }}
							key='dropdown'
							className={twMerge('dropdown-empreender min-w-[220px] border border-gray-100 rounded-md bg-white !p-[5px] flex flex-col', className)}>
							{children}
						</motion.div>
					</DropdownMenuPrimitive.Content>
				)}
			</AnimatePresence>
		</DropdownMenuPrimitive.Portal>
	);
});

export const DropDownItem = ({ children, ...props }: DropdownMenuContentProps) => {
	return (
		<DropdownMenuPrimitive.Item asChild {...props} className={twMerge('cursor-pointer data-[highlighted]:bg-gray-100 outline-none p-1 rounded-md text-gray-900 justify-start', props.className)}>
			{children}
		</DropdownMenuPrimitive.Item>
	);
};

DropdownMenuContent.displayName = 'DropdownMenuContent';
