import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  margin-bottom: 60px;



`

export const SelectScreen = styled.div`
  display: flex;
  padding-right: 20px;
  
`

export const BarOptions = styled.div`
  display: flex;
  background-color: ${props => props.theme.cardBackground};
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 16px 0px;
`

export const Contents = styled.div`
    display: flex;
    padding: 20px 20px;
    margin: 20px;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    background-color: ${props => props.isMobile ? "transparent" : props.theme.backgroundItem};

    //background-color: #E5E7EB;

    background-color: transparent;
`

export const Box = styled.div`
    display: flex;
    max-width: ${props => props.isMobile ? '360px' : '1280px'};
    width: 100%;
    flex-direction: column;
    background-color: ${props => props.theme.backgroundItem};
    padding: 20px;
    border-radius: 0px 0px 8px 8px;

    border-right: 1px solid #e5e7eb;
    border-left: 1px solid #e5e7eb;
    border-bottom: 1px solid #e5e7eb;
`

export const BoxAbas = styled.div`
    display: flex;
    height: 80px;
    background-color: ${props => props.theme.cardBackground};
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
    margin-bottom: 20px;
    border-radius: 8px;
    width: 100%;
`

export const ItemAba = styled.label`
    display: flex;
    background-color: ${props => props.active ? props.theme.backgroundItem : 'transparent'};
    flex: 1;
    height: 60px;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.isMobile ? '1rem' : '1.5rem'};
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: ${props => props.theme.textSecondary};

    box-shadow: ${props => props.active ? `0 8px 24px rgba(0, 0, 0, 0.08)` : 'none'};
    //0 8px 24px rgba(0, 0, 0, 0.08);

    &:hover {
        background-color: ${props => !props.active && props.theme.backgroundItem};
        opacity: 0.8;
    }

    @media(max-width: 500px) {
        font-size: 18px;
    }
`

export const BoxConfig = styled.div`
    display: flex;
    flex-direction: ${props => props.isMobile ? 'column' : 'row'};
    flex-wrap: wrap;
    gap: 20px;
`

export const BoxResume = styled.div`
    display: flex;
    flex:1;
    gap: 20px;
    flex-direction: ${props => props.isMobile ? 'column' : 'row'};


    @media(max-width: 650px) {
        flex-direction: column;
        height: max-content;
    }
`

export const QtdReview = styled.div`
    display: flex;
    //width: ${props => props.isMobile ? '100%' : '370px'};
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding: 20px;
    background-color: ${props => props.theme.cardBackground};
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);

    @media(max-width: 650px) {
        width: 100%;
    }

    label {
        font-size: 76px;
        color: ${props => props.theme.text};
        font-family: Rubik, sans-serif;
        font-weight: 600;
    }

    p {
        font-size: 20px;
        padding: 0px;
        margin: 0px;
        color: ${props => props.theme.textSecondary};
        font-family: Rubik, sans-serif;
    }
    
    span {
        display: flex;
        gap: 5px;
    }
`

export const InforReviews = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    background-color: ${props => props.theme.cardBackground};
    border-radius: 8px;

    width:${props => props.isMobile ? '100%' : '400px'};
    //background-color: yellow;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);

    @media(max-width: 950px) {
        width: 100%;
    }
`

export const InforReviewsLine = styled.div`
    display: flex;
    align-items: center;
    height: 36px;
    gap: 12px;

    span {
        min-height: 10px;
        height: 10px;
        //width: ${props => props.isMobile ? 'calc(100% - 100px)' : '200px'};
        width: 100%;
        background-color: ${props => props.theme.starInactive};
        position: relative;
        border-radius: 5px;
        overflow: hidden;

        @media(max-width: 950px) {
            width: 100%;
        }

        div {
            position: absolute;
            background-color: ${props => props.theme.starActive};
            height: 10px;
            width: ${props => props.porcentage};
            border-radius: 5px;
        }
    }

    label {
        font-size: 20px;
        color: ${props => props.theme.textSecondary};
        font-weight: 400;
        font-family: Rubik, sans-serif;
        min-width: 20px;
        text-align: right;
    }

    p {
        font-size: 20px;
        margin: 0px;
        padding: 0px;
        color: ${props => props.theme.textSecondary};
        font-weight: 400;
        font-family: Rubik, sans-serif;
        //background-color: red;
        width: 28px;
        text-align: end;
    }
`

export const Divider = styled.div`
    display: ${props => props.isMobile ? "none" : "flex"};
    background-color: ${props => props.theme.border};
    width: 2px;
    min-width: 2px;
    margin: 0px 20px;
    border-radius: 2px;
`

export const ItemStar = styled.div`
    display: flex;
    position: relative;
    overflow: hidden;

    svg {
        color: ${props => props.theme.starInactive};
    }

    svg:nth-child(2) {
        position: absolute;
        top: 0px;
        left: 0px;
        color: ${props => props.theme.starActive};
        clip-path: ${props => `polygon(0 0, ${props.porcentage} 0, ${props.porcentage} 100%, 0 100%)`};
    }
`

export const BoxActionFilter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: ${props => props.isMobile ? "100%" : "400px"};
    align-items: center;
    margin-top: ${props => props.isMobile ? "20px" : "0px"};
    gap: 20px;

    //background-color: red;

    @media(max-width: 950px) {
        flex-direction: row;
        align-items: center;
    }

    @media(max-width: 650px) {
        flex-direction: column;
    }
`

export const CustomSelect = styled.div`
    display: inline-block;
    max-width: ${props => props.isMobile ? "100%" : "300px"};
    width: ${props => props.isMobile ? "100%" : "300px"};
    border: 1px solid ${props => props.theme.border};
    border-radius: 8px;
    position: relative;
    
    ::after {
        content: '';
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${props => props.theme.text};
        pointer-events: none;
    }
`

export const SelectFilter = styled.select`
    display: flex;
    height: 48px;
    padding: 0px 16px;
    font-family: Rubik, sans-serif;
    width: 100%;
    outline: none;
    background-color: ${props => props.theme.cardBackground};
    color: ${props => props.theme.text};
    border: none;
    cursor: pointer;
    appearance: none;
    border-radius: 8px;

    &:focus {
        border-color: ${props => props.theme.border};
    }
`

export const ActionReview = styled.label`
    display: flex;
    background-color: ${props => props.theme.cardBackground};
    color: ${props => props.theme.text};
    padding: 0px 24px;
    height: 56px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: ${props => props.isMobile ? "100%" : "300px"};
    width: ${props => props.isMobile ? "100%" : "300px"};

    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);

    &:hover {
        background-color: ${props => props.theme.backgroundItem};
        opacity: 0.9;
    }

    @media(max-width: 650px) {
        width: 100%;
    }
`

export const BoxList = styled.div`
    display: flex;
    flex-direction: column;
`

export const GridContainer = styled.div`
  /* column-count: ${props => props.columns || 1};
  column-gap: 1.5rem;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  padding-top: 1.5rem; */
  column-count: ${props => props.columns || 1};
  column-gap: 1.5rem;
  padding: 1.5rem 0;
  width: 100%;
  //background-color: red;
`;

export const CardWrapper = styled.div`
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  break-inside: avoid;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid ${props => props.theme.border};

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const UserAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const UserName = styled.h3`
  margin: 0;
  color: ${props => props.theme.text};
  font-size: 1.1rem;
  font-weight: 600;
`;

export const Rating = styled.div`
  color: ${props => props.theme.starActive};
  font-weight: 500;
`;

export const ReviewText = styled.p`
  flex: 1;
  color: ${props => props.theme.textSecondary};
  line-height: 1.6;
  margin: 0;
`;

export const Date = styled.small`
  color: ${props => props.theme.textSecondary};
  align-self: flex-end;
`;