import { useEffect, useState } from 'react';
import PalettePicker from '../../../../../empreenderUI/ui/PalettePicker'
import { ItemColor } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { BoxButtons, ButtonHelp, ButtonReset, ButtonSave } from '../../bar-config-product-ajustes/styled';
import ColorPicker from '../../../../../empreenderUI/ui/ColorPicker/ColorPicker'

import IconBossVideo from "../../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../../assets/imgs/icon-video.svg";
import IconBossSave from '../../../../../assets/imgs/boss-save.svg';


const TabColorDefault = ({ save, visibleModalVideo, setVisibleModalVideo, setDefault }) => {


    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const dispatch = useDispatch()
    const [themeColor, setThemeColor] = useState(null)

    const setTheme = (theme) => {
        if (theme == "dark") {
            dispatch({
                type: "CONFIG_CONTROL_SET", payload: {
                    star_color_primary: "#DAA520",
                    star_color_secondary: "#D3D3D3",
                    font_color_primary: "#FFFFFF",
                    font_color_secondary: "#FFFFFF",
                    primary_color: "#808080",
                    background_color_primary: "#000000",
                    background_color_secondary: "#000000",
                    border_card_color: "#ffffff"
                }
            })
            setThemeColor("dark")
        }
        else if (theme == "light") {
            dispatch({
                type: "CONFIG_CONTROL_SET", payload: {
                    star_color_primary: "#DAA520",
                    star_color_secondary: "#D3D3D3",
                    font_color_primary: "#000000",
                    font_color_secondary: "#FFFFFF",
                    primary_color: "#000000",
                    background_color_primary: "#FFFFFF",
                    background_color_secondary: "#F1F1F1",
                    border_card_color: "#000000"
                }
            })
            setThemeColor("light")
        }
    }

    const getTheme = () => {
        if (
            configControl.star_color_primary == "#DAA520" &&
            configControl.star_color_secondary == "#D3D3D3" &&
            configControl.font_color_primary == "#FFFFFF" &&
            configControl.font_color_secondary == "#FFFFFF" &&
            configControl.primary_color == "#808080" &&
            configControl.background_color_primary == "#000000" &&
            configControl.background_color_secondary == "#000000" &&
            configControl.border_card_color == "#ffffff"
        ) {
            return "dark"
        } else if (
            configControl.star_color_primary == "#DAA520" &&
            configControl.star_color_secondary == "#D3D3D3" &&
            configControl.font_color_primary == "#000000" &&
            configControl.font_color_secondary == "#FFFFFF" &&
            configControl.primary_color == "#000000" &&
            configControl.background_color_primary == "#FFFFFF" &&
            configControl.background_color_secondary == "#F1F1F1" &&
            configControl.border_card_color == "#000000"
        ) {
            return "light"
        } else {
            return "custom"
        }
    }




    return (
        <>

            {/* <ModalAlertSaveNew /> */}



            {/* <PalettePicker
                onClick={() => { setTheme("light") }}
                style={{ width: "120px" }}
                isActive={getTheme() == "light"}
                primary='#000000'
                secondary='#000000'
                backgroundColor='#FFFFFF'
            />

            <PalettePicker
                onClick={() => { setTheme("dark") }}
                style={{ width: "120px" }}
                isActive={getTheme() == "dark"}
                primary='#FFFFFF'
                secondary='#ffffff'
                backgroundColor='#000000'
            /> */}



            {/* <PalettePicker
                style={{ width: "120px" }}
                isActive={activeIndex === index}
                primary='#'
                secondary='#808080'
                backgroundColor='#f2f2f2'
            /> */}

            {/* <div style={{ backgroundColor: "yellow", display: "flex", width: "100%" }}>
                <ColorPicker style={{ minWidth: "500px !important", backgroundColor: "yellow" }} side='right' placeholder='Principal' color={configControl?.primary_color} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { primary_color: color } }) }} />
            </div> */}

            {/* <ColorPicker side='right' placeholder='Fundo principal' color={configControl?.background_color_primary} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_primary: color } }) }} />
            <ColorPicker side='right' placeholder='Fundo auxiliar' color={configControl?.background_color_secondary} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_secondary: color } }) }} />
            <ColorPicker side='right' placeholder='Estrelas ativas' color={configControl?.star_color_primary} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_primary: color } }) }} />
            <ColorPicker side='right' placeholder='Estrelas inativas' color={configControl?.star_color_secondary} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_secondary: color } }) }} />
            <ColorPicker side='right' placeholder='Principal dos textos' color={configControl?.font_color_primary} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_primary: color } }) }} />
            <ColorPicker side='right' placeholder='Auxiliar os textos' color={configControl?.font_color_secondary} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_secondary: color } }) }} />
            <ColorPicker side='right' placeholder='Borda' color={configControl?.border_card_color} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { border_card_color: color } }) }} />
            <ColorPicker side='right' placeholder='Fundo resumo' color={configControl?.background_color_resume_primary} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_primary: color } }) }} />
            <ColorPicker side='right' placeholder='Fundo auxiliar resumo' color={configControl?.background_color_resume_secondary} onChange={(color) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_secondary: color } }) }} /> */}


            <ItemColor>
                <label style={{ fontSize: "12px", }}>Principal</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { primary_color: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.primary_color} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { primary_color: e.target.value } }) }} type='color' value={configControl.primary_color} />
                </span>
            </ItemColor>

            <ItemColor>
                <label style={{ fontSize: "12px", }}>Fundo principal</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_primary: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.background_color_primary} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_primary: e.target.value } }) }} type='color' value={configControl.background_color_primary} />
                </span>
            </ItemColor>

            <ItemColor>
                <label style={{ fontSize: "12px", }}>Fundo auxiliar</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_secondary: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.background_color_secondary} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_secondary: e.target.value } }) }} type='color' value={configControl.background_color_secondary} />
                </span>
            </ItemColor>

            <ItemColor>
                <label style={{ fontSize: "12px", }}>Estrelas ativas</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_primary: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.star_color_primary} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_primary: e.target.value } }) }} type='color' value={configControl.star_color_primary} />
                </span>
            </ItemColor>

            <ItemColor>
                <label style={{ fontSize: "12px", }}>Estrelas inativas</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_secondary: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.star_color_secondary} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { star_color_secondary: e.target.value } }) }} type='color' value={configControl.star_color_secondary} />
                </span>
            </ItemColor>

            <ItemColor>
                <label style={{ fontSize: "12px", }}>Principal dos textos</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_primary: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.font_color_primary} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_primary: e.target.value } }) }} type='color' value={configControl.font_color_primary} />
                </span>
            </ItemColor>

            <ItemColor>
                <label style={{ fontSize: "12px", }}>Auxiliar os textos</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_secondary: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.font_color_secondary} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font_color_secondary: e.target.value } }) }} type='color' value={configControl.font_color_secondary} />
                </span>
            </ItemColor>

            <ItemColor>
                <label style={{ fontSize: "12px", }}>Borda</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { border_card_color: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.border_card_color} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { border_card_color: e.target.value } }) }} type='color' value={configControl.border_card_color} />
                </span>
            </ItemColor>

            <ItemColor>
                <label style={{ fontSize: "12px", }}>Fundo resumo</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_primary: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.background_color_resume_primary} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_primary: e.target.value } }) }} type='color' value={configControl.background_color_resume_primary} />
                </span>
            </ItemColor>

            <ItemColor>
                <label style={{ fontSize: "12px", }}>Fundo auxiliar resumo</label>
                <span>
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_secondary: e.target.value } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={configControl.background_color_resume_secondary} />
                    <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_color_resume_secondary: e.target.value } }) }} type='color' value={configControl.background_color_resume_secondary} />
                </span>
            </ItemColor>

            <ButtonHelp onClick={() => setVisibleModalVideo(true)} style={{ minWidth: "250px" }}>
                <img width={20} src={IconBossVideo} />
                <label>{"Tutorial"}</label>
                <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
            </ButtonHelp>

            <BoxButtons style={{ minWidth: "250px" }}>
                <ButtonReset
                    onClick={() => setDefault()}

                >
                    <label>{"Restaurar"}</label>
                </ButtonReset>
                <ButtonSave
                    onClick={() => save()}
                >
                    {"Salvar"}
                </ButtonSave>
            </BoxButtons>

        </>

    )
}

export default TabColorDefault