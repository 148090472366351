import React from 'react';

const DashIconBranco = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Camada_1"
    x={0}
    y={0}
    width={22} height={22}
    viewBox="0 0 3000 3000"
    {...props}
  >
    <style>{".dashiconclasse{fill:#fff}"}</style>
    <path
      d="M1194.8 123.7c3.7 15.2 170.5 713.1 188.6 787.5 1.6 6.6.5 10.9-4.1 15.8-123.9 133-401.6 431.8-417.4 448.8-282-93.4-564.1-186.9-847.1-280.6 1.1-4.2 2.1-7.9 3-11.6 15.8-60.7 42.2-117.1 70.5-172.7C259.7 770.1 351.4 644.1 462 531.6 576.7 415 707.2 319.9 852.5 244.9c76.8-39.7 156.6-72 238.9-97.9 23.9-7.5 49-11.5 73.6-17 9.6-2.1 19.3-4.1 29.8-6.3zM1354.1 330.3c11.1-2.1 21.7-4.3 32.4-6.1 53-8.9 106.4-15.8 160.2-14.4 39.4 1 78.8 3.6 118.1 7.5 40.2 3.9 80.3 9.2 120.2 15.7 114.3 18.6 223.1 54.5 327.4 104.8 127.5 61.5 241 142.8 342.5 241.2 68.6 66.5 130.1 138.8 180.7 220.1 24.5 39.4 60 107.6 76.2 146.5-3 1.5-464.1 204.9-515.9 228-6.4 2.9-11.1 2.8-17.2-1.2-239.8-157.2-479.7-314.4-719.8-471.3-6.2-4-9-8.7-10.4-15.6-24.2-116.1-86.4-413.1-93.1-445.6-.6-2.8-.8-5.8-1.3-9.6zM2846.9 1924.4c-14.4 65.3-34.5 127.5-58.9 188.4-64.6 161.4-158.9 303.1-281.8 425.9-98.9 98.8-211.4 178.7-338.1 240.8-148.2-286.7-296.1-573-445.1-861.3 375.5 2 748.9 4.1 1123.9 6.2zM269.6 1257.7c209.3 64.4 417.8 128.5 627.9 193.1-3.6 4.3-183.6 212.3-271.1 313.4-10.3 11.9-9.2 7-3.7 21.5 127 333.5 384 1008.5 386.1 1014.5-4.4-1.7-7.3-2.7-10.1-4-108.7-50.3-209.3-113.3-300.4-191.1-214.1-182.9-357.4-410.5-429.4-682.8-16.7-63.2-28-127.5-34.9-192.5-7.8-73.1-9.3-146.2-5.2-219.5 4.7-82.7 18.6-163.9 38.3-244.2.5-2.3 1.3-4.5 2.5-8.4zM2802.8 1142.4c17.9 39.8 29.2 80.6 40.5 121.4 32.1 115.6 44.4 233.5 41.6 353.2-1 40.9-2.4 81.9-8.7 124.1-201.8-132.9-402.3-265.1-604.3-398.2 177.5-67.1 353.4-133.5 530.9-200.5z"
      className="dashiconclasse"
    />
    <path
      d="M775.2 1910.4c-2.8-7.1 5.1-13.6 11.5-9.6 155.9 96.2 1180.3 732.4 1183.5 735.7 10.3 11 67.4 129.3 90.9 174.6 7.6 14.6 2.7 21.6-11 25.6-48.9 14-97.8 28.2-146.9 41.6-31.6 8.6-63.2 18.1-95.5 23.3-52.8 8.5-105.9 17-159.2 19.9-62.8 3.4-126 2.5-188.9.1-35.4-1.3-70.6-9.2-105.8-14.5-24.4-3.7-48.9-6.8-72.9-12.1-35-7.7-70.3-15.2-104-26.8-11-3.8-20.7-19.1-25.8-31.3-38.2-91.1-75-182.7-112.2-274.1-46.3-113.8-92.4-227.6-138.8-341.3-35.1-86.5-101.7-251.7-124.9-311.1zM1753.3 1832.2c-4.6 0-6.1-6.1-2.1-8.3l550.8-304.5c13.4-7.5 22.4-7.1 35 1.2 127.7 85.5 379.9 252.9 440.5 293.2 4.1 2.7 2.2 9-2.7 9.1-104.9 1.4-699.4 9-1021.5 9.3zM1522.2 977.6c-10.9-7.3-16.6-6.9-25.7 3.2-158.8 175.4-656.7 745.4-735.4 838.2-4.3 5.1-.7 12.8 5.9 12.8 86.9.4 494.7.4 714.1 1.3 30.7.1 57.7-7.1 83.7-21.3 111.4-60.6 222.5-121.7 334.1-181.9 93.4-50.4 310.9-167 328.2-177.1-8.4-6.5-507.3-342.1-704.9-475.2zm75 569.7c-47.9.5-87-37.7-87.5-85.3-.5-47 39.3-87.1 86.4-87.1 47.4-.1 86.6 39.3 86.5 86.8-.1 47.1-38.1 85.2-85.4 85.6z"
      className="dashiconclasse"
    />
  </svg>
);

export default DashIconBranco;
