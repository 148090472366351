import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useVerifySaveColors = () => {

    const compareObjects = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        const commonKeys = keys1.filter(key => keys2.includes(key));

        let hasDifferences = false;

        commonKeys.forEach(key => {
            if (obj1[key] !== obj2[key]) {
                console.log(`Diferença na chave '${key}': obj1: ${obj1[key]}, obj2: ${obj2[key]}`);
                hasDifferences = true;
            }
        });

        if (hasDifferences) {
            return false;
        }
        console.log("Os objetos são iguais.");
        return true;
    };

    const compareObjectsAndPrint = (obj1, obj2) => {
        return compareObjects(obj1, obj2);
    };
    return { compareObjectsAndPrint };
}
export default useVerifySaveColors;
