import { useEffect, useState } from 'react';
import { BoxImageAdd, BoxImages, BoxStars, Container, Delete, ImageReview, Name, Section, SectionLeft, Text } from './styled'
import StarVazia from "../../../../assets/imgs/star-vazia.svg";
import StarCheia from "../../../../assets/imgs/star-cheia.svg";

const CardImportReview = (props) => {

    const [hoverStar, setHoverStar] = useState(null)

    const deleteReview = () => {
        if (props.countAlertDelete >= 0) {
            props.delete(props.item.id)
        } else {
            props.setVisibleConfirm(true);
            props.setReviewSelected(props.item.id)
        }
        props.setCountAlertDelete(props.countAlertDelete + 1)
    }


    return (
        <Container>
            <SectionLeft>
                <Name>
                    {/* {props.item?.name} */}
                    <input type='text' value={props.item.name} onChange={(e) => { props.changeName(props.item.id, e.target.value) }} />

                    <span >
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                            <path d="M6.66667 0.833374V2.50004M3.33333 0.833374V2.50004M1.25 4.16671H8.75M2.08333 1.66671H7.91667C8.3769 1.66671 8.75 2.0398 8.75 2.50004V8.33338C8.75 8.79361 8.3769 9.16671 7.91667 9.16671H2.08333C1.6231 9.16671 1.25 8.79361 1.25 8.33338V2.50004C1.25 2.0398 1.6231 1.66671 2.08333 1.66671Z" stroke="#344054" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <label for="date-review">
                            {new Date(props.item.date).toLocaleDateString("en-GB")}
                        </label> */}

                        <input onChange={(e) => { props.changeDate(props.item.id, e.target.value) }} id='date-review' value={props.item.date} type='date' />
                    </span>

                </Name>
                <Text>
                    {/* {props.item?.message} */}
                    <textarea
                        rows={6}
                        value={props.item.message}
                        onChange={(e) => { props.changeMessage(props.item.id, e.target.value) }}
                    />
                </Text>
            </SectionLeft>
            <Section>
                {false == false ?
                    <BoxImages>
                        {props?.item?.images?.map((item) => {
                            return (
                                <ImageReview>
                                    {console.log("test props?.item", props?.item)}
                                    {item?.includes("http") ?
                                        <img src={item} /> :
                                        <img src={"https://cf.shopee.com.br/file/" + item} />}

                                    <span>
                                        <svg style={{ cursor: "pointer" }} onClick={() => { props.deleteImage(props.item.id, item) }} xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                            <path d="M13.9722 3.2H10.8611V2.4C10.8611 1.76348 10.6153 1.15303 10.1777 0.702944C9.74011 0.252856 9.14662 0 8.52778 0H6.97222C6.35338 0 5.75989 0.252856 5.32231 0.702944C4.88472 1.15303 4.63889 1.76348 4.63889 2.4V3.2H1.52778C1.3215 3.2 1.12367 3.28429 0.977806 3.43431C0.831944 3.58434 0.75 3.78783 0.75 4C0.75 4.21217 0.831944 4.41566 0.977806 4.56569C1.12367 4.71571 1.3215 4.8 1.52778 4.8H2.30556V13.6C2.30556 14.2365 2.55139 14.847 2.98897 15.2971C3.42656 15.7471 4.02005 16 4.63889 16H10.8611C11.4799 16 12.0734 15.7471 12.511 15.2971C12.9486 14.847 13.1944 14.2365 13.1944 13.6V4.8H13.9722C14.1785 4.8 14.3763 4.71571 14.5222 4.56569C14.6681 4.41566 14.75 4.21217 14.75 4C14.75 3.78783 14.6681 3.58434 14.5222 3.43431C14.3763 3.28429 14.1785 3.2 13.9722 3.2ZM6.19444 2.4C6.19444 2.18783 6.27639 1.98434 6.42225 1.83431C6.56811 1.68429 6.76594 1.6 6.97222 1.6H8.52778C8.73406 1.6 8.93189 1.68429 9.07775 1.83431C9.22361 1.98434 9.30556 2.18783 9.30556 2.4V3.2H6.19444V2.4ZM11.6389 13.6C11.6389 13.8122 11.5569 14.0157 11.4111 14.1657C11.2652 14.3157 11.0674 14.4 10.8611 14.4H4.63889C4.43261 14.4 4.23478 14.3157 4.08892 14.1657C3.94306 14.0157 3.86111 13.8122 3.86111 13.6V4.8H11.6389V13.6Z" fill="white" />
                                        </svg>
                                    </span>
                                </ImageReview> 
                            )
                        })}

                        {props?.item?.videos?.map((item) => {
                            { console.log("props?.item?.videos", item) }
                            return (

                                <ImageReview>
                                    <img src={item.thumbnail_url} />
                                    <span>
                                        <svg style={{ cursor: "pointer" }} onClick={() => { props.deleteImage(props.item.id, item) }} xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                                            <path d="M13.9722 3.2H10.8611V2.4C10.8611 1.76348 10.6153 1.15303 10.1777 0.702944C9.74011 0.252856 9.14662 0 8.52778 0H6.97222C6.35338 0 5.75989 0.252856 5.32231 0.702944C4.88472 1.15303 4.63889 1.76348 4.63889 2.4V3.2H1.52778C1.3215 3.2 1.12367 3.28429 0.977806 3.43431C0.831944 3.58434 0.75 3.78783 0.75 4C0.75 4.21217 0.831944 4.41566 0.977806 4.56569C1.12367 4.71571 1.3215 4.8 1.52778 4.8H2.30556V13.6C2.30556 14.2365 2.55139 14.847 2.98897 15.2971C3.42656 15.7471 4.02005 16 4.63889 16H10.8611C11.4799 16 12.0734 15.7471 12.511 15.2971C12.9486 14.847 13.1944 14.2365 13.1944 13.6V4.8H13.9722C14.1785 4.8 14.3763 4.71571 14.5222 4.56569C14.6681 4.41566 14.75 4.21217 14.75 4C14.75 3.78783 14.6681 3.58434 14.5222 3.43431C14.3763 3.28429 14.1785 3.2 13.9722 3.2ZM6.19444 2.4C6.19444 2.18783 6.27639 1.98434 6.42225 1.83431C6.56811 1.68429 6.76594 1.6 6.97222 1.6H8.52778C8.73406 1.6 8.93189 1.68429 9.07775 1.83431C9.22361 1.98434 9.30556 2.18783 9.30556 2.4V3.2H6.19444V2.4ZM11.6389 13.6C11.6389 13.8122 11.5569 14.0157 11.4111 14.1657C11.2652 14.3157 11.0674 14.4 10.8611 14.4H4.63889C4.43261 14.4 4.23478 14.3157 4.08892 14.1657C3.94306 14.0157 3.86111 13.8122 3.86111 13.6V4.8H11.6389V13.6Z" fill="white" />
                                        </svg>
                                    </span>
                                </ImageReview>
                            )
                        })}

                        {/* {Array(3).fill(<img src='https://blog.americanasmarketplace.com.br/wp-content/uploads/2018/04/imagem163-Como-tirar-uma-1000x670-1.png' />)} */}
                    </BoxImages> : null}
                <BoxStars>

                    <span onClick={() => { props.changeRating(props.item.id, 1) }} onMouseEnter={() => setHoverStar(1)} onMouseLeave={() => setHoverStar(null)}>
                        {/* {hoverStar !== null && hoverStar > 0 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8.9871 0.660163L6.68262 5.57344L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005L4.63224 12.2123L3.74998 17.6117C3.59117 18.5877 4.56872 19.3188 5.38746 18.8623L9.99994 16.3129L14.6124 18.8623C15.4312 19.315 16.4087 18.5877 16.2499 17.6117L15.3676 12.2123L19.0979 8.39005C19.7684 7.70352 19.3978 6.50489 18.4732 6.36387L13.3173 5.57344L11.0128 0.660163C10.5999 -0.215618 9.40353 -0.226751 8.9871 0.660163Z" fill="#FFC400" />
                            </svg> :
                            props.item.rating > 5 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M8.9871 0.660163L6.68262 5.57344L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005L4.63224 12.2123L3.74998 17.6117C3.59117 18.5877 4.56872 19.3188 5.38746 18.8623L9.99994 16.3129L14.6124 18.8623C15.4312 19.315 16.4087 18.5877 16.2499 17.6117L15.3676 12.2123L19.0979 8.39005C19.7684 7.70352 19.3978 6.50489 18.4732 6.36387L13.3173 5.57344L11.0128 0.660163C10.5999 -0.215618 9.40353 -0.226751 8.9871 0.660163Z" fill="#FFC400" />
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                    <path d="M7.21665 6.28745L6.80183 6.35105L1.64588 7.14148L1.64528 7.14157C1.52374 7.16011 1.41677 7.24596 1.36788 7.40438C1.31869 7.56376 1.35321 7.7261 1.46482 7.84037L1.46504 7.84059L5.19525 11.6629L5.47269 11.9471L5.40863 12.3392L4.52645 17.7381C4.52645 17.7381 4.52644 17.7381 4.52644 17.7381C4.49608 17.9249 4.57134 18.0669 4.67727 18.1472C4.7815 18.2262 4.89524 18.236 5.00439 18.1752L5.0069 18.1738L9.61938 15.6244L9.99994 15.414L10.3805 15.6244L14.993 18.1738L14.9931 18.1739C15.1033 18.2348 15.2186 18.2245 15.3232 18.1454C15.4287 18.0655 15.5036 17.9248 15.4735 17.7386C15.4735 17.7386 15.4735 17.7386 15.4735 17.7385C15.4735 17.7384 15.4735 17.7382 15.4734 17.7381L7.21665 6.28745ZM7.21665 6.28745L7.39486 5.9075L9.6992 0.994512L7.21665 6.28745ZM0.902028 8.39005L4.63224 12.2123L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005Z" stroke="#FFC400" stroke-width="1.57337" />
                                </svg>} */}
                        {
                            hoverStar == null ?
                                <>
                                    {props.item.rating > 0 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </> :
                                <>
                                    {hoverStar > 0 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </>
                        }
                    </span>

                    <span onClick={() => { props.changeRating(props.item.id, 2) }} onMouseEnter={() => setHoverStar(2)} onMouseLeave={() => setHoverStar(null)}>
                        {/* {hoverStar !== null && hoverStar > 1 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8.9871 0.660163L6.68262 5.57344L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005L4.63224 12.2123L3.74998 17.6117C3.59117 18.5877 4.56872 19.3188 5.38746 18.8623L9.99994 16.3129L14.6124 18.8623C15.4312 19.315 16.4087 18.5877 16.2499 17.6117L15.3676 12.2123L19.0979 8.39005C19.7684 7.70352 19.3978 6.50489 18.4732 6.36387L13.3173 5.57344L11.0128 0.660163C10.5999 -0.215618 9.40353 -0.226751 8.9871 0.660163Z" fill="#FFC400" />
                            </svg> :
                            props.item.rating > 1 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M8.9871 0.660163L6.68262 5.57344L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005L4.63224 12.2123L3.74998 17.6117C3.59117 18.5877 4.56872 19.3188 5.38746 18.8623L9.99994 16.3129L14.6124 18.8623C15.4312 19.315 16.4087 18.5877 16.2499 17.6117L15.3676 12.2123L19.0979 8.39005C19.7684 7.70352 19.3978 6.50489 18.4732 6.36387L13.3173 5.57344L11.0128 0.660163C10.5999 -0.215618 9.40353 -0.226751 8.9871 0.660163Z" fill="#FFC400" />
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M7.21665 6.28745L6.80183 6.35105L1.64588 7.14148L1.64528 7.14157C1.52374 7.16011 1.41677 7.24596 1.36788 7.40438C1.31869 7.56376 1.35321 7.7261 1.46482 7.84037L1.46504 7.84059L5.19525 11.6629L5.47269 11.9471L5.40863 12.3392L4.52645 17.7381C4.52645 17.7381 4.52644 17.7381 4.52644 17.7381C4.49608 17.9249 4.57134 18.0669 4.67727 18.1472C4.7815 18.2262 4.89524 18.236 5.00439 18.1752L5.0069 18.1738L9.61938 15.6244L9.99994 15.414L10.3805 15.6244L14.993 18.1738L14.9931 18.1739C15.1033 18.2348 15.2186 18.2245 15.3232 18.1454C15.4287 18.0655 15.5036 17.9248 15.4735 17.7386C15.4735 17.7386 15.4735 17.7386 15.4735 17.7385C15.4735 17.7384 15.4735 17.7382 15.4734 17.7381L7.21665 6.28745ZM7.21665 6.28745L7.39486 5.9075L9.6992 0.994512L7.21665 6.28745ZM0.902028 8.39005L4.63224 12.2123L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005Z" stroke="#FFC400" stroke-width="1.57337" />
                                </svg>} */}

                        {
                            hoverStar == null ?
                                <>
                                    {props.item.rating > 1 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </> :
                                <>
                                    {hoverStar > 1 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </>
                        }
                    </span>

                    <span onClick={() => { props.changeRating(props.item.id, 3) }} onMouseEnter={() => setHoverStar(3)} onMouseLeave={() => setHoverStar(null)}>
                        {/* {hoverStar !== null && hoverStar > 2 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8.9871 0.660163L6.68262 5.57344L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005L4.63224 12.2123L3.74998 17.6117C3.59117 18.5877 4.56872 19.3188 5.38746 18.8623L9.99994 16.3129L14.6124 18.8623C15.4312 19.315 16.4087 18.5877 16.2499 17.6117L15.3676 12.2123L19.0979 8.39005C19.7684 7.70352 19.3978 6.50489 18.4732 6.36387L13.3173 5.57344L11.0128 0.660163C10.5999 -0.215618 9.40353 -0.226751 8.9871 0.660163Z" fill="#FFC400" />
                            </svg> :
                            props.item.rating > 2 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M8.9871 0.660163L6.68262 5.57344L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005L4.63224 12.2123L3.74998 17.6117C3.59117 18.5877 4.56872 19.3188 5.38746 18.8623L9.99994 16.3129L14.6124 18.8623C15.4312 19.315 16.4087 18.5877 16.2499 17.6117L15.3676 12.2123L19.0979 8.39005C19.7684 7.70352 19.3978 6.50489 18.4732 6.36387L13.3173 5.57344L11.0128 0.660163C10.5999 -0.215618 9.40353 -0.226751 8.9871 0.660163Z" fill="#FFC400" />
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M7.21665 6.28745L6.80183 6.35105L1.64588 7.14148L1.64528 7.14157C1.52374 7.16011 1.41677 7.24596 1.36788 7.40438C1.31869 7.56376 1.35321 7.7261 1.46482 7.84037L1.46504 7.84059L5.19525 11.6629L5.47269 11.9471L5.40863 12.3392L4.52645 17.7381C4.52645 17.7381 4.52644 17.7381 4.52644 17.7381C4.49608 17.9249 4.57134 18.0669 4.67727 18.1472C4.7815 18.2262 4.89524 18.236 5.00439 18.1752L5.0069 18.1738L9.61938 15.6244L9.99994 15.414L10.3805 15.6244L14.993 18.1738L14.9931 18.1739C15.1033 18.2348 15.2186 18.2245 15.3232 18.1454C15.4287 18.0655 15.5036 17.9248 15.4735 17.7386C15.4735 17.7386 15.4735 17.7386 15.4735 17.7385C15.4735 17.7384 15.4735 17.7382 15.4734 17.7381L7.21665 6.28745ZM7.21665 6.28745L7.39486 5.9075L9.6992 0.994512L7.21665 6.28745ZM0.902028 8.39005L4.63224 12.2123L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005Z" stroke="#FFC400" stroke-width="1.57337" />
                                </svg>} */}

                        {
                            hoverStar == null ?
                                <>
                                    {props.item.rating > 2 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </> :
                                <>
                                    {hoverStar > 2 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </>
                        }
                    </span>

                    <span onClick={() => { props.changeRating(props.item.id, 4) }} onMouseEnter={() => setHoverStar(4)} onMouseLeave={() => setHoverStar(null)}>
                        {/* {hoverStar !== null && hoverStar > 3 ?
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M8.9871 0.660163L6.68262 5.57344L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005L4.63224 12.2123L3.74998 17.6117C3.59117 18.5877 4.56872 19.3188 5.38746 18.8623L9.99994 16.3129L14.6124 18.8623C15.4312 19.315 16.4087 18.5877 16.2499 17.6117L15.3676 12.2123L19.0979 8.39005C19.7684 7.70352 19.3978 6.50489 18.4732 6.36387L13.3173 5.57344L11.0128 0.660163C10.5999 -0.215618 9.40353 -0.226751 8.9871 0.660163Z" fill="#FFC400" />
                            </svg> :
                            props.item.rating > 3 ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M8.9871 0.660163L6.68262 5.57344L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005L4.63224 12.2123L3.74998 17.6117C3.59117 18.5877 4.56872 19.3188 5.38746 18.8623L9.99994 16.3129L14.6124 18.8623C15.4312 19.315 16.4087 18.5877 16.2499 17.6117L15.3676 12.2123L19.0979 8.39005C19.7684 7.70352 19.3978 6.50489 18.4732 6.36387L13.3173 5.57344L11.0128 0.660163C10.5999 -0.215618 9.40353 -0.226751 8.9871 0.660163Z" fill="#FFC400" />
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M7.21665 6.28745L6.80183 6.35105L1.64588 7.14148L1.64528 7.14157C1.52374 7.16011 1.41677 7.24596 1.36788 7.40438C1.31869 7.56376 1.35321 7.7261 1.46482 7.84037L1.46504 7.84059L5.19525 11.6629L5.47269 11.9471L5.40863 12.3392L4.52645 17.7381C4.52645 17.7381 4.52644 17.7381 4.52644 17.7381C4.49608 17.9249 4.57134 18.0669 4.67727 18.1472C4.7815 18.2262 4.89524 18.236 5.00439 18.1752L5.0069 18.1738L9.61938 15.6244L9.99994 15.414L10.3805 15.6244L14.993 18.1738L14.9931 18.1739C15.1033 18.2348 15.2186 18.2245 15.3232 18.1454C15.4287 18.0655 15.5036 17.9248 15.4735 17.7386C15.4735 17.7386 15.4735 17.7386 15.4735 17.7385C15.4735 17.7384 15.4735 17.7382 15.4734 17.7381L7.21665 6.28745ZM7.21665 6.28745L7.39486 5.9075L9.6992 0.994512L7.21665 6.28745ZM0.902028 8.39005L4.63224 12.2123L1.52667 6.36387C0.602058 6.50489 0.231507 7.70352 0.902028 8.39005Z" stroke="#FFC400" stroke-width="1.57337" />
                                </svg>} */}
                        {
                            hoverStar == null ?
                                <>
                                    {props.item.rating > 3 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </> :
                                <>
                                    {hoverStar > 3 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </>
                        }
                    </span>

                    <span onClick={() => { props.changeRating(props.item.id, 5) }} onMouseEnter={() => setHoverStar(5)} onMouseLeave={() => setHoverStar(null)}>
                        {
                            hoverStar == null ?
                                <>
                                    {props.item.rating > 4 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </> :
                                <>
                                    {hoverStar > 4 ?
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                        <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}
                                </>
                        }
                    </span>
                </BoxStars>
            </Section>

            <Delete
                //onClick={() => { props.delete(props.item.id) }}
                onClick={() => { deleteReview() }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                    <path d="M1 7L7 1M1 1L7 7" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </Delete>
        </Container >
    )
}

export default CardImportReview