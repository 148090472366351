import * as ModalRoot from '@radix-ui/react-dialog';
import { ReactNode } from 'react';
import { useSubMenu } from '../../SubMenuContext';

interface ModalProps {
	children: ReactNode;
	onOpenChange?: (state: boolean) => void;
}

const Modal = ({ children, ...props }: ModalProps extends ModalRoot.DialogProps ? ModalRoot.DialogProps : null) => {
	const { clearSub } = useSubMenu();

	return (
		<ModalRoot.Root
			onOpenChange={(state) => {
				if (props.onOpenChange) props.onOpenChange(state);
				if (!state) clearSub?.();
			}}
			{...props}>
			{children}
		</ModalRoot.Root>
	);
};

export default Modal;
