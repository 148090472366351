import styled from "styled-components";

export const ItemColor = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 2px;

    label{
        font-size: 14px;
    }

    span{
        display: flex;
        align-items: center;
        gap: 12px;

        input[type=text]{
            display: flex;
            height: 34px;
            font-size: 14px;
            flex: 1;
            border-radius: 4px;

            &:focus {
                border: 2px solid #111827 !important; 
                //box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Efeito de brilho (opcional) */
            }
        }

        input[type=color] {
            width: 24px;
            height: 24px; 
            border-radius: 50%;
            overflow: hidden;
            padding: 0px;
            margin: 0px;
            border: none;
            cursor: pointer;
            font-size: 11px ;   
            border: 1px solid #f1f1f144;
        }

        input[type=color]::-webkit-color-swatch {
            border: none;
            border-radius: 50%;
            padding: 0;
            cursor: pointer;
            font-size: 11px ;
        }

        input[type=color]::-webkit-color-swatch-wrapper {
            border: none;
            border-radius: 50%;
            padding: 0;
            cursor: pointer;
            font-size: 11px ;
        }
    
    }
  
`

export const InputColor = styled.span`
       

   
`