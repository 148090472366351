import styled from 'styled-components';

export const CardWrapper = styled.div`
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  padding: 1.75rem;
  width: 100%;
  margin: 0 0 1.5rem 0;
  transition: all 0.2s ease;
  font-family: ${props => props.font}, sans-serif;
  break-inside: avoid-column;
  page-break-inside: avoid;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
  height: fit-content;
`;

export const MediaContainer = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.hasMedia ? '200px' : '0'};
  border-radius: 8px;
  overflow: hidden;
  background: ${props => props.theme.background};
  margin: ${props => props.hasMedia ? '1rem 0' : '0'};
  display: ${props => props.hasMedia ? 'block' : 'none'};
`;

export const MediaCount = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
`;

export const VideoThumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    transition: background 0.2s ease;
  }

  &:hover .play-icon {
    background: rgba(0, 0, 0, 0.9);
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 0.5rem;
`;

export const ReviewText = styled.p`
  color: ${props => props.theme.text};
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  font-style: italic;
`;

export const UserInfo = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const UserHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const UserName = styled.h3`
  margin: 0;
  color: ${props => props.theme.text};
  font-size: 1.1rem;
  font-weight: 600;
`;

export const DateText = styled.span`
  color: ${props => props.theme.textSecondary};
  font-size: 0.9rem;
`;

export const VerifiedIcon = styled.span`
  color: ${props => props.theme.primary};
  font-size: 1.1rem;
  margin-left: 4px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const UserAvatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
`;

export const Rating = styled.div`
  color: ${props => props.theme.starActive};
  font-weight: 500;
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
    gap: 16px;
    height: max-content;
    break-inside: avoid;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 28px 0px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px, rgba(255, 255, 255, 0.01) 0px 0px 0px 1px inset; /* box-shadow */
    padding-bottom: 20px;
`;

export const CardPlay = styled.div`
  width: 150px;
  height: 100px ;
  display: flex ;
  justify-content: center ;
  align-items: center ;
  border: 2px solid gray ;
  background-color: ${props => props.theme.background_color_primary} ;
  border-radius: 5px ;
`

export const BoxImage = styled.div`
    display: flex;
    position: relative;
    border-radius: 16px;

    img{
        width: 100%;
        object-fit: cover;
        border-radius: 16px !important;
    }

    div{
        position: relative;
        border-radius: 16px;
        img{
            width: 100%;
            object-fit: cover;
            border-radius: 16px !important;
        }
        span{
            background-color: #00000088;
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            top: 0px;
            border-radius: 16px;
        }
    }

    svg{
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        
    }
`

export const Stars = styled.div`
    display: flex;
    gap: 3px;
    padding: 0px 20px;
    
`

export const Name = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    color: #1f2937;
    font-size: 18px;
    font-weight: 700;
    padding: 0px 20px;
    align-items: center;
    
`

export const CardText = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    padding: 0px 20px;
    
`

export const More = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    color: #00000080;
    font-size: 18px;
    padding: 0px 20px;
`

export const Comment = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    color: #000000b3;
    font-size: 18px;
    display: flex;
    padding: 0px 20px;
    line-height: 1.8;
`
