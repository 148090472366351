import { useEffect, useRef, useState } from "react"
import { BoxThanks, Container, Contents, FinishButton, FooterContainer, FormContainer, FormGroup, Input, Label, NavButton, NavInfor, Preview, ProgressBar, ProgressIndicator, Screen, SelectScreen, SubmitButton, TermsText, TextArea, ThankTitle, Title } from "./styled"
import { ThemeProvider } from "styled-components"
import { useSelector } from "react-redux"
import { BarConfig, BarConfigLeft, BarConfigRight } from "../../../templatesScreenReview/dimona/preview/styled"



const PreviewDimona = (props) => {
    const [isMobile, setIsMobile] = useState(false)



    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [activeScreen, setActiveScreen] = useState(0);

    const [direction, setDirection] = useState('next');
    const configControl = useSelector(state => state.reducerControlConfig)
    const theme = JSON.parse(configControl.screen_question_configs || '{}')

    const defaultConfigColors = {
        background: "#ffffff",
        text: "#282828",
        textSecondary: "#cbd5e1",
        buttonBackground: "#282828",
        buttonText: "#ffffff",
    }

    const totalScreens = 3;

    const handleNext = () => {
        if (activeScreen === totalScreens - 1) {
            setActiveScreen(0)
        } else {
            setActiveScreen((prev) => (prev + 1) % totalScreens);
        }
        setDirection('next');
    };

    const handleBack = () => {
        setDirection('prev');
        setActiveScreen((prev) => (prev - 1 + totalScreens) % totalScreens);
    };

    useEffect(() => {
        console.log("theme", theme, defaultConfigColors)
    }, [theme])


    return (
        <ThemeProvider theme={theme == "" || theme == " " || theme == null || theme == undefined || theme == "null" || theme == "undefined" || theme == "{}" ? defaultConfigColors : theme}>

            <Container>
                {/* <SelectScreen>
                    <div className="flex my-4 gap-0 m-0">
                        <button
                            onClick={() => setIsMobile(true)}
                            className={classNames(
                                isMobile ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                'w-[40px] p-2 rounded-md text-sm font-medium flex items-center justify-center',
                            )}
                            type="button" data-headlessui-state="" style={{ border: "0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"></path></svg>
                        </button>
                        <button
                            onClick={() => setIsMobile(false)}
                            className={classNames(
                                !isMobile ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                'w-[40px] p-2 rounded-md text-sm font-medium flex items-center justify-center',
                            )}
                            type="button" data-headlessui-state="" style={{ border: "0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"></path></svg>
                        </button>
                    </div>
                </SelectScreen> */}
                <Preview isMobile={isMobile}>
                    <BarConfig isMobile={isMobile}>
                        <BarConfigLeft>
                            <span />
                            <span />
                            <span />
                        </BarConfigLeft>
                        <BarConfigRight>
                            <div onClick={() => setIsMobile(false)} style={{ cursor: 'pointer' }}>
                                <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.0002 9V2.25C14.0002 1.85218 13.8422 1.47064 13.5609 1.18934C13.2796 0.908035 12.898 0.75 12.5002 0.75H3.5002C3.10237 0.75 2.72084 0.908035 2.43954 1.18934C2.15823 1.47064 2.0002 1.85218 2.0002 2.25V9M14.0002 9H2.0002M14.0002 9L14.9602 10.9125C15.018 11.0272 15.0454 11.1549 15.0397 11.2833C15.034 11.4116 14.9954 11.5364 14.9277 11.6455C14.8599 11.7547 14.7652 11.8446 14.6527 11.9067C14.5402 11.9688 14.4137 12.0009 14.2852 12H1.7152C1.58672 12.0009 1.46016 11.9688 1.34766 11.9067C1.23516 11.8446 1.14049 11.7547 1.07273 11.6455C1.00497 11.5364 0.966395 11.4116 0.960699 11.2833C0.955002 11.1549 0.982377 11.0272 1.0402 10.9125L2.0002 9" stroke={!isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>

                            <div onClick={() => setIsMobile(true)} style={{ cursor: 'pointer' }}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.6893 1.5H6.31066C5.63153 1.5 4.98022 1.76978 4.5 2.25C4.01978 2.73022 3.75 3.38153 3.75 4.06066V13.9393C3.75 14.6185 4.01978 15.2698 4.5 15.75C4.98022 16.2302 5.63153 16.5 6.31066 16.5H11.6893C12.3685 16.5 13.0198 16.2302 13.5 15.75C13.9802 15.2698 14.25 14.6185 14.25 13.9393V4.06066C14.25 3.38153 13.9802 2.73022 13.5 2.25C13.0198 1.76978 12.3685 1.5 11.6893 1.5Z" stroke={isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M8.2998 13.5H9.7998" stroke={isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </BarConfigRight>
                    </BarConfig>
                    {activeScreen === 0 &&
                        <Screen direction={direction}>
                            <Title>
                                <label>{configControl.screen_question_config_title_comment}</label>
                            </Title>
                            <Contents>
                                <TextArea isMobile={isMobile}
                                    //value={comment}
                                    placeholder="Adicionar dúvida..."
                                //onChange={(e) => setComment(e.target.value)}
                                />
                            </Contents>
                            <FooterContainer>
                                <NavButton active={false}>Voltar</NavButton>
                                <NavInfor>
                                    <ProgressIndicator active={true} />
                                    <ProgressBar active={false} />
                                    <ProgressIndicator active={false} />
                                </NavInfor>
                                <NavButton active={true} onClick={handleNext}>Próximo</NavButton>
                            </FooterContainer>
                        </Screen>}

                    {activeScreen === 1 &&
                        <Screen isMobile={isMobile} direction={direction}>
                            <Title>
                                <label>{configControl.screen_question_config_title_data}</label>
                            </Title>
                            <Contents>
                                <FormContainer>
                                    <FormGroup>
                                        <Label>Seu nome</Label>
                                        <Input
                                            //value={name}
                                            //onChange={(e) => setName(e.target.value)}
                                            type="text"
                                            placeholder="Nome"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Seu telefone</Label>
                                        <Input
                                            //value={whatsapp}
                                            //onChange={(e) => setWhatsapp(e.target.value)}
                                            type="tel"
                                            placeholder="+55 (21) 99999-9999"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Seu e-mail</Label>
                                        <Input
                                            //value={email}
                                            //onChange={(e) => setEmail(e.target.value)}
                                            type="email"
                                            placeholder="joaodasilva@servidor.com"
                                        />
                                    </FormGroup>
                                    <TermsText>
                                        {configControl.terms_use_question}
                                    </TermsText>
                                    <SubmitButton active={true}>
                                        Enviar
                                    </SubmitButton>
                                </FormContainer>
                            </Contents>
                            <FooterContainer>
                                <NavButton onClick={handleBack}>Voltar</NavButton>
                                <NavInfor>
                                    <ProgressIndicator active={true} />
                                    <ProgressBar isMobile={isMobile} active={true} />
                                    <ProgressIndicator active={true} />
                                </NavInfor>
                                <NavButton onClick={handleNext} active={true}>Próximo</NavButton>
                            </FooterContainer>
                        </Screen>}

                    {activeScreen === 2 &&
                        <Screen isMobile={isMobile} direction={direction}>
                            <Title>
                                <label>{configControl.screen_question_config_title_end}</label>
                            </Title>
                            <Contents>
                                <BoxThanks>
                                    <ThankTitle>
                                        Ficamos gratos pelo envio do seu feedback!
                                    </ThankTitle>
                                    <FinishButton onClick={handleNext}>
                                        Finalizar avaliação
                                    </FinishButton>
                                </BoxThanks>
                            </Contents>
                        </Screen>}
                </Preview>
            </Container>
        </ThemeProvider>
    )
}

export default PreviewDimona
