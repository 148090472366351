import { ReactNode, useState, Children, isValidElement, cloneElement, useEffect, useRef } from 'react';
import { SubMenuProvider, useSubMenu } from './SubMenuContext';
import TopBar from './TopBar';
import ButtonSuporte from './ui/ButtonSuporte';
import { motion } from 'framer-motion';
import Content from './Content';
import ContentApp from './ContentApp';
import PerfilMenu from './ui/PerfilMenu';
import Separator from './ui/Separator';
import ItemMenu from './ui/ItemMenu';
import { eplusHub } from './api/eplus';
import { Copy, Lightbulb } from 'lucide-react';
import { useCopyToClipboard } from './lib/hook/copyClipBoard';
import { Button } from './ui/Button';
import ItemIcon from './ui/ItemIcon';
import ItemText from './ui/ItemText';

type HeadersType = {
	[key: string]: string;
};
interface SideBarProps {
	children: ReactNode;
	app: string;
	classeAtiva?: string;
	isOpen?: boolean | false | true;
	appEndPoint?: string;
	headers?: HeadersType;
	chave_empreender?: string;
	onKeyCopy?: (key: string) => void;
}

interface ChildProps {
	chave_empreender: string;
}

interface ItemMenuProps {
	setSidebarOpen: (toggle: boolean) => void;
	classeAtiva?: string;
}

interface SeparatorProps {
	setSidebarOpen: (toggle: boolean) => void;
}

interface EplusApps {
	[key: string]: {
		integrations?: Integration;
	};
}

declare global {
	interface Window {
		configsAppsPlus: EplusApps;
	}
}

interface Integration {
	[key: string]: {
		show: boolean;
		img: string;
	};
}

const SideBar = ({ children, app, classeAtiva, isOpen = false, appEndPoint, headers, chave_empreender = '', onKeyCopy = () => {} }: SideBarProps) => {
	const [dataUser, setDataUser] = useState<EplusApps | null>(null);

	const [isSidebarOpen, setSidebarOpen] = useState(isOpen || false);

	const sidebarElements = Children.map(children, (child) => {
		if (!isValidElement(child)) return null;

		if (child.type === ItemMenu || child.type === Separator) {
			return cloneElement(child as React.ReactElement<ItemMenuProps | SeparatorProps>, {
				setSidebarOpen,
				classeAtiva,
			});
		}

		return null;
	});

	useEffect(() => {
		const options = {
			method: 'POST',
			credentials: 'include' as RequestCredentials,
			headers,
			body: JSON.stringify({
				acao: 'buscarUsuarioComConfigs',
			}),
		};

		if (!dataUser) {
			eplusHub(options, appEndPoint).then((res) => {
				try {
					const { name, imagem_perfil } = res.data;
					setDataUser({
						name: name || '',
						imagem_perfil: imagem_perfil || '',
					});
				} catch (error) {
					console.log(error);
				}
			});
		}
	}, [dataUser]);

	const contents = Children.map(children, (child) => (isValidElement(child) && child.type === Content ? child : null));

	const contentApp = Children.map(children, (child) => (isValidElement(child) && child.type === ContentApp ? child : null));

	const perfilMenu = Children.map(children, (child) => {
		if (isValidElement(child) && child.type === PerfilMenu) {
			return cloneElement(child, { ...dataUser, chave_empreender } as ChildProps);
		}
		return null;
	});

	return (
		<SubMenuProvider isSidebarOpen={isSidebarOpen} setSideBarOpen={setSidebarOpen} appEndPoint={appEndPoint} headers={headers}>
			<div className='h-screen w-screen overflow-hidden z-[1000]'>
				<TopBar app={app} />

				<div className='flex flex-row flex-nowrap w-full h-full'>
					<div className='flex flex-row bg-white'>
						{/* Sidebar */}
						<motion.div
							exit={{ width: 0 }}
							initial={{ width: isSidebarOpen ? 290 : 68 }}
							animate={{ width: isSidebarOpen ? 290 : 68 }}
							className={`bg-white border-r !px-[15.5px] !py-5 border-gray-200 h-[calc(100%-60px)] justify-between flex flex-col gap-1 max-[460px]:z-[2] max-[460px]:absolute`}>
							<div className='overflow-x-hidden overflow-y-scroll custom-scroll zero-scroll rounded-md'>
								<div className='flex flex-col gap-1 select-none'>{sidebarElements}</div>
							</div>

							<div className='flex flex-col gap-2 select-none bg-white'>
								<ButtonSuporte app={app} />
								<Button asChild className='cursor-pointer z-[0] justify-start w-full px-1.5 gap-3 overflow-hidden'>
									<a href='https://forms.gle/Y7vNCoDSyfnzJjGr7' target='_blank'>
										<ItemIcon>
											<div className='relative'>
												<Lightbulb strokeWidth='1.5' className='text-gray-400' />
											</div>
										</ItemIcon>
										<ItemText>Enviar feedback</ItemText>
									</a>
								</Button>
								<div className='group/container relative'>
									{perfilMenu}
									<CopyButton onKeyCopy={onKeyCopy} chave_empreender={chave_empreender} />
								</div>
							</div>
						</motion.div>

						{/* Terceiro menu */}
						<div className='flex flex-row max-[460px]:relative max-[460px]:left-[68px] max-[460px]:z-[1]'>{contents}</div>
					</div>

					{/* Aplicativo */}
					<div className='w-full h-full overflow-auto relative'>{contentApp}</div>
				</div>
			</div>
		</SubMenuProvider>
	);
};

const CopyButton = ({ chave_empreender, onKeyCopy }: { chave_empreender: string; onKeyCopy: (key: string) => void }) => {
	const { state } = useSubMenu();
	const [, copy] = useCopyToClipboard();
	const spanRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (spanRef.current) {
			spanRef.current.style.setProperty('left', `${state.chaveWidth}px`, 'important');
		}
	}, [state.chaveWidth]);

	const copyKey = (e: React.MouseEvent<HTMLSpanElement>) => {
		e.stopPropagation();
		e.preventDefault();

		if (chave_empreender) copy(chave_empreender);

		if (onKeyCopy && chave_empreender) onKeyCopy(chave_empreender);
	};

	return (
		<span ref={spanRef} className='absolute top-[33px] left-[112px] block text-gray-500 cursor-pointer transition-all ease-in-out size-3 invisible opacity-100 group-hover/container:opacity-100 group-hover/container:visible' onClick={copyKey}>
			{chave_empreender ? <Copy className='size-full' /> : null}
		</span>
	);
};

export default SideBar;
