const WhatslyIconBranco = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      version="1.1"
      id="Camada_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 3000 3000"
      width={22} height={22}
      {...props}
    >
      <style type="text/css">{".gggddffff3321{display:none;fill:#3B01FC;}"}</style>
      <g>
        <g>
          <path
            className="ldfldflf"
            d="M-3576.1,1539.7v3353.7h3240V1539.7H-3576.1z M-827.3,3761.9l129.9,582.6l-589.3-141
			c-172.4,92.9-365.3,141.4-561.1,141.1c-274.8,0-527.6-93.5-728.6-250.5c-277.6-216.8-456.1-554.7-456.1-934.2
			c0-654.3,530.4-1184.7,1184.7-1184.7c221.8,0,429.4,61,606.9,167.1c346,206.8,577.8,585.2,577.8,1017.6
			C-662.8,3371.7-719.5,3579.6-827.3,3761.9L-827.3,3761.9z"
          />
          <path
            className="ldfldflf"
            d="M-1240.9,2142.3c-177.5-106.1-385.1-167.1-606.9-167.1c-654.3,0-1184.7,530.4-1184.7,1184.7
			c0,379.5,178.5,717.4,456.1,934.2c201,157,453.9,250.5,728.6,250.5c195.8,0.3,388.7-48.2,561.1-141.1l589.3,141l-129.9-582.6l0,0
			c107.8-182.3,164.5-390.2,164.2-602C-663.1,2727.4-894.8,2349.1-1240.9,2142.3z M-1504,3564.9c-53.3,0-102.8-27.5-131-72.7
			l-157.1-251.3l-180.5,263.4c-26,38-69.1,60.6-115.1,60.6c-37.5,0-72.8-15.1-98.7-41c-7.6-7.6-14.4-16.1-20.2-25.5l-278.2-452.5
			c-64.6-105.2,11-240.5,134.5-240.5c57,0,109.6,30.8,137.6,80.5l137,243.5l161.7-251c46.4-71.8,137.1-88.9,204.6-51.7
			c22,12.1,41.6,29.9,56.3,53.6l280.1,448.1C-1308.6,3431.4-1382.6,3564.9-1504,3564.9z M-1255.5,3119.4c-86.7,0-157-70.3-157-157
			s70.3-157,157-157s157,70.3,157,157C-1098.5,3049.1-1168.8,3119.4-1255.5,3119.4z"
          />
        </g>
      </g>
      <g>
        <g>
          <circle className="gggddffff3321" cx="1689.6" cy="1427" r="50.7" />
          <path
            className="gggddffff3321"
            d="M1457.9,1602.1l58.3-85l50.8,81.2c9.1,14.6,25.1,23.5,42.3,23.5c39.2,0,63.1-43.1,42.3-76.4l-90.4-144.8
			c-4.8-7.7-11.1-13.4-18.2-17.3c-21.8-12-51.2-6.5-66.1,16.7l-52.2,81.1l-44.3-78.7c-9-16.1-26-26-44.5-26
			c-39.9,0-64.3,43.7-43.4,77.7l89.9,146.1c1.9,3,4,5.8,6.5,8.2c8.3,8.3,19.7,13.3,31.9,13.3
			C1435.6,1621.7,1449.5,1614.4,1457.9,1602.1z"
          />
          <path
            className="st2wly1"
            d="M2701.5,2125.5c113.7-216,165.7-407.9,165.3-653.3c0-496.2-263.5-931-658.1-1172
			C2000.6,173.2,1756.2,100,1494.6,100C736.8,99.9,122.3,714.3,122.3,1472.2c0,444.3,211.1,839.3,538.7,1090.1
			c231.1,177,520.1,282.1,833.7,282.1c226.9,0.3,384.7-32.8,593.5-134.8l789.6,190.4L2701.5,2125.5z M2496.8,2063.4l127.6,572.1
			l-578.7-138.5c-169.3,91.2-358.7,138.9-551,138.6c-269.9,0-518.1-91.8-715.5-246c-272.6-212.9-447.9-544.7-447.9-917.4
			c0-642.6,520.9-1163.4,1163.4-1163.4c217.8,0,421.7,59.9,596,164.1C2430.4,676,2658,1047.6,2658,1472.2
			C2658.3,1680.2,2602.6,1884.4,2496.8,2063.4L2496.8,2063.4z"
          />
          <circle className="st2wly1" cx="2076.4" cy="1278.3" r="154.2" />
          <path
            className="st2wly1"
            d="M1686,1197.6c-14.5-23.2-33.7-40.8-55.3-52.6c-66.4-36.5-155.5-19.7-200.9,50.8L1271,1442.3l-134.5-239.1
			c-27.5-48.8-79.2-79.1-135.1-79.1c-121.3,0-195.5,132.9-132.1,236.2l273.2,444.4c5.7,9.2,12.4,17.6,19.8,25
			c25.4,25.4,60.1,40.3,96.9,40.3c45.2,0,87.5-22.3,113-59.5l177.3-258.7l154.3,246.8c27.7,44.4,76.4,71.4,128.6,71.4
			c119.2,0,191.9-131.1,128.7-232.3L1686,1197.6z"
          />
        </g>
      </g>
    </svg>
  );
};

export default WhatslyIconBranco;
