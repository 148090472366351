import React from 'react';

const DashIcon = (props: React.SVGProps<SVGSVGElement>) => (
	<svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' id='Camada_6' x={0} y={0} viewBox='0 0 3000 3000' width={22} height={22} {...props}>
		<style>{'.dash31{fill:#080413}.dash141{fill:#fff}'}</style>
		<path
			d='M2889.2 1718.1c5.6-43.8 6.6-74.6 8.3-118.7 2-50.1-.3-99.9-4.3-149.8-4.3-54.2-12.4-107.9-24.9-160.7-30.5-129.2-53.2-172.1-53.2-172.1l-533 201.3 607.1 400zM1730.7 1896.4l447 865c138.8-68 261.9-158.2 368.5-271.7 128.4-136.7 222-294.2 281.4-472.1 12.7-38 23.4-76.4 32.2-115.2-188-.7-380.1-1.8-566.3-3-185.1-1.2-376-2.4-562.8-3z'
			className='dash31'
		/>

		<path
			d='m965.5 1351 425.4-457.7-191.3-800.2c-1.2.1-96.1 20.8-99.8 22-69.2 22.3-137.1 47.9-202.7 79.4C693.7 292 519.3 427.1 375 600.6 275.9 719.7 196.7 851 137.5 994.4c-5.8 14.1-23.3 74.6-23.3 74.6l851.3 282z'
			style={{
				fill: '#7f21ed',
			}}
		/>
		<path
			d='M1456.8 768c.8.5 739.5 484.2 739.5 484.2 180.8-79.8 526-231.8 527.8-232.6-.3-.6-19.7-41.9-33-68.7-63.9-128.6-156-238.2-262.9-334.8C2299 499.4 2153 411.3 1988.7 353.6c-135.2-47.5-274.7-67.1-418-73.2-97.3-4.1-210.7 20.3-211.6 20.5l97.7 467.1zM900.6 1426.8c-222.9-69.2-631-194.1-631-194.1s-8 31.6-8.8 35.4c-13.6 63-25 126.3-29.9 190.7-6.5 84.8-5.5 169.3 3.9 253.8 8.6 77.3 23.7 153.5 45.8 228.1 60.3 204.4 163.2 384.5 308.9 539.9 114.2 121.7 246.6 217.9 396.1 289.7 9.1 4.4 18.2 8.6 27.4 12.8L619.9 1750.9l280.7-324.1z'
			className='dash31'
		/>
		<path
			d='m2168.4 1428.7-637.2-428.1c-.6-.4-1.1-.8-1.7-1.1-2.1-1.4-4.8-1.1-6.5.8-.5.6-1.1 1.2-1.6 1.8-217.5 235.1-435.1 470.1-652.6 705.2-11.1 12-22.1 23.9-33.4 36.2 77.7 0 155.5 0 233.2-.1 152 0 309.2-.1 463.8-.1 5.6 0 10.2-1.1 15.2-3.5 126.5-61.7 255.1-124.4 379.5-185 80.2-39.1 160.5-78.2 240.7-117.3 3.5-1.7 3.9-6.6.6-8.8zM838.1 1867c-4.1-2.6-9.2 1.5-7.5 6 19.6 52.6 39.3 105.2 58.9 157.8 91.2 244.4 185.6 497.2 278.1 745.9 2 5.5 4.2 7.5 10 9.3 90 28 182.5 46.2 275 54.3 91.7 8 184.5 6 275.8-5.9 94.9-12.4 188.2-35.4 277.4-68.6 2.8-1.1 4.1-4.4 2.8-7.1l-23.1-45.6c-25.5-50.4-51.9-102.6-77.8-153.9-1.2-2.4-3.1-4.2-7.3-6.9L1172 2082.5 838.1 1867z'
			className='dash141'
		/>
		<path
			d='m2832.6 1794.5-16.6-11.4c-4.3-3-8.7-6-13-9-16.3-11.2-33.2-22.9-49.9-34.1-101.2-68-202.5-136.1-303.7-204.1-88.4-59.4-176.7-118.7-265.1-178.1-1-.7-1.9-1.2-2.8-1.7-.4-.3-1-.6-1.4-.9-.6-.4-1.1-.9-1.5-1.5l-.2-.3c-.4-.6-.9-1.1-1.6-1.5l-1.2-.7c-28.8-17.8-56.2-36.7-86.5-57.8l-.6-.4-.6-.3c-.5-.3-1-.5-1.5-.8-.6-.3-1.3-.6-1.5-.8l-331.8-222.9L1550.2 932c-2.9-1.9-5.8-3.8-8.6-5.7-4.2-2.7-8.5-5.6-12.7-8.4-8.2-5.7-17.2-4.7-24 2.6-6.5 7-317.5 343-456.3 492.9-3.6 3.9-7.3 8-10.8 11.8-9.6 10.6-18.7 20.7-28.7 29.9l-.4.4c-.2.2-.4.4-.6.7l-.3.5c-.5.7-1 1.4-1.5 2-.7 1-1.4 2-2 2.6-78.8 85.2-157.6 170.3-236.4 255.4-3.1 3.4-6.2 6.8-9.4 10.1-9.4 10.2-19.1 20.7-28.8 30.9-7.2 7.6-8.9 16.6-4.8 26 1.9 4.5 3.6 9.1 5.4 14 .8 2.1 1.5 4.2 2.3 6.3l73 195.5 277.2 742.5c7.5 20.2 15 40.8 22.2 60.7 3.4 9.4 6.8 18.9 10.3 28.3l1 2.7c.5 1.4 1.6 2.5 2.9 3l2.7 1.1c1.3.5 2.7 1.1 4 1.6 3.1 1.2 6.2 2.5 9.6 3.6 109.8 36.8 224.9 59.3 342 66.8 28.5 1.8 57.1 2.7 85.7 2.7 58 0 116.1-3.7 173.5-11.2 106.9-13.9 212.4-41.9 314.8-80.6 27.7-10.5 47.3-21.5 47.3-21.5 2.1-1.4 2.9-4.1 1.9-6.4l-2.3-5.1c-8.2-17.8-17.1-35.5-25.8-52.6l-1.8-3.5c-162.5-321-324.7-641.4-462.5-913.9-1.7-3.4.7-7.4 4.5-7.4H2201.4c202.1-.1 604.1-6.5 608.2-6.5l20.1-.3c5 .2 6.9-6.2 2.9-9zm-932.1 757.8c4.1 2.7 6 4.5 7.3 6.9 25.9 51.3 52.2 103.5 77.8 153.9l23.1 45.6c1.4 2.7.1 6-2.8 7.1-89.2 33.2-182.5 56.3-277.4 68.6-91.3 11.9-184 13.9-275.8 5.9-92.6-8-185.1-26.3-275-54.3-5.8-1.8-8-3.8-10-9.3-92.6-248.7-186.9-501.5-278.1-745.9-19.6-52.6-39.3-105.2-58.9-157.8-1.7-4.6 3.4-8.7 7.5-6l334 215.4c242.7 156.7 485.5 313.3 728.3 469.9zm267.3-1114.8c-80.2 39.1-160.5 78.2-240.7 117.3-124.4 60.6-253 123.3-379.5 185-4.9 2.4-9.6 3.5-15.2 3.5-154.6 0-311.8 0-463.8.1-77.7 0-155.5 0-233.2.1 11.3-12.2 22.3-24.2 33.4-36.2 217.5-235.1 435.1-470.2 652.6-705.2.5-.6 1.1-1.2 1.6-1.8 1.7-1.8 4.5-2.1 6.5-.8.6.4 1.1.8 1.7 1.1l637.2 428.1c3.3 2.2 2.9 7.1-.6 8.8z'
			className='dash31'
		/>
		<path d='M1518.4 1433.3c.1-48.1 39.3-87 87.5-86.9 48 .1 87.2 39.2 87.4 87.2.1 48.2-40.2 88.3-88.3 87.6-48.3-.6-86.8-39.7-86.6-87.9z' className='dash31' />
	</svg>
);

export default DashIcon;
