import styled from 'styled-components';

export const Card = styled.div`
    width: 100%;
    padding: 20px 0px;
    //background: #fff;
    border-bottom: 1px solid ${({ theme }) => theme.border};
    //border-radius: 8px;
    //margin-bottom: 20px;
`;

export const Header = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
`;

export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Name = styled.span`
    font-weight: 600;
    color: ${({ theme }) => theme.text};
    margin-bottom: 5px;
    font-size: 20px;
`;

export const DateText = styled.span`
    font-size: 14px;
    color: ${({ theme }) => theme.text};
`;

export const RatingInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const Stars = styled.div`
    display: flex;
    gap: 2px;
    margin-bottom: 5px;

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const Question = styled(Name)`
    font-size: 16px;
`;

export const DateInfo = styled.label`
    font-size: 14px;
    color: ${({ theme }) => theme.text};
`;

export const Comment = styled.p`
    margin: 0;
    color: ${({ theme }) => theme.text};
    line-height: 1.5;
    margin-bottom: 15px;
    font-size: 14px;
`;

export const BoxImages = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 15px;

    img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.border};
    }
`;

export const MediaContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const VideoThumbnail = styled.div`
  position: relative;
  width: 150px;
  height: 100px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 2rem;
  }
`;