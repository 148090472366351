import styled from "styled-components";


export const Container = styled.div`

  display: flex ;
  //background-color: yellow;
  flex-direction: column;

`
export const Box = styled.div`
  background-color:  ${props => props.backgroundColor};
  padding: 20px;
  border-radius: 5px;

`

export const SelectScreen = styled.div`

  display: flex ;
  padding-right: 20px;
  //background-color: gray;

`
export const BarActions = styled.div`
  //background-color:  orange;
  display: flex ;
  height: 50px ;
  width: 100% ;
  //align-items: flex-start ;

  @media(max-width: 1100px) {
     //background-color: yellow ;
     flex-direction: column-reverse;
     height: 100px ;
     justify-content: center ;
     align-items: center;
  }
`

export const BarOptions = styled.div`

  display: flex ;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;

`


export const BoxActions = styled.div`
  //background-color:  aliceblue;
  display: flex ;
  height: 50px ;
  flex: 1 ;
  //align-items: center ;
  justify-content: end ;

  @media(max-width: 1100px) {
     align-items: center;
     justify-content: center !important ;
  }
`
export const Action = styled.div`
  background-color:  transparent;
  display: flex ;
  height: 35px ;
  width: 125px;
  margin-left: 10px ;
  align-items: center ;
  justify-content: center ;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  border: ${props => `1px solid ${props.primaryColor}`}  ;

  
  @media(max-width: 1100px) {
     align-items: center;
     justify-content: center !important ;
     margin-left: 0px ;
     :nth-child(1){
        margin-right: 10px ;
     
      }

  }

  :hover{
    background-color:  ${props => props.primaryColor};
    svg{
      color: ${props => props.fontColorHover};
    }
    label{
      color: ${props => props.fontColorHover} ;
    }
  }

  label{
    cursor: pointer;
    margin-left: 5px;
    color: ${props => props.fontColor};
  }

  svg{
    color: ${props => props.primaryColor};
  }
`

export const BoxOptions = styled.div`
  //background-color:  blueviolet;
  display: flex ;
  height: 50px ;
  flex: 1 ;


  @media(max-width: 1100px) {
     align-items: center;
     justify-content: center !important ;


  }
`

export const Option = styled.span`
  background-color:  ${props => props.backgroundColor};
  display: flex ;
  height: 35px ;
  width: 125px;
  margin-right: 10px ;
  color: ${props => props.fontColor};
  align-items: center ;
  justify-content: center ;
  //border-radius: 2px 2px 0px 0px;
  font-size: 14px;
  cursor: pointer;
  border-bottom: ${props => props.selected ? `2px solid ${props.primaryColor}` : "none"};
  padding-bottom: ${props => props.selected ? "0px" : "2px"} ;

  @media(max-width: 1100px) {
     align-items: center;
     justify-content: center !important ;
     :nth-child(1){
        margin-left: 10px ;
      }

  }



  label{
    cursor: pointer;

  }
`
