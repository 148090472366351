
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE, DELETE_CUSTOM } from "../../../../services/api";

export function* listCupons(action) {
    yield put({ type: "CUPONS_REQUEST", })
    //yield delay(5000);
    const result = yield call(GET, { endpoint: `/cupoms?search=${action.payload.search}&perPage=${action.payload.perPage}&page=${action.payload.page}` })
    if (result === false) {
        yield put({ type: "CUPONS_ERROR" })
    } else {
        yield put({ type: "CUPONS_SUCCESS", payload: result.data.data })
    }
}

export function* updateCupom(action) {

    yield put({ type: "CUPOM_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/cupoms/${action.payload.id}`, data: action.payload })
    if (result === false) {
        yield put({ type: "CUPOM_UPDATE_ERROR" })
    } else {
        yield put({ type: "CUPOM_UPDATE_SUCCESS", payload: result.data })
    }
}

export function* deleteCupom(action) {



    yield put({ type: "CUPOM_DELETE_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE, { endpoint: `/cupoms/${action.payload.id}` })
    if (result === false) {
        yield put({ type: "CUPOM_DELETE_ERROR" })
    } else {
        yield put({ type: "CUPOM_DELETE_SUCCESS", payload: result.data })
    }
}


export function* addCupom(action) {
    yield put({ type: "CUPOM_ADD_REQUEST", })
    //yield delay(200);
    const result = yield call(POST, { endpoint: `/cupoms`, data: action.payload })
    if (result === false) {
        yield put({ type: "CUPOM_ADD_ERROR" })
    } else {
        yield put({ type: "CUPOM_ADD_SUCCESS", payload: result.data })
    }
}

export function* deleteSelectCupons(action) {

    const dataCupons = action.payload.itens



    yield put({ type: "CUPONS_DELETE_SELECT_REQUEST", })
    try {
        for (let element in dataCupons) {
            //console.log(element)
            yield call(DELETE, { endpoint: `/cupoms/${dataCupons[element]}` })
        }
        yield put({ type: "CUPONS_DELETE_SELECT_SUCCESS", payload: "fim" })
    } catch (error) {
        yield put({ type: "CUPONS_DELETE_SELECT_ERROR" })
    }


    // yield put({ type: "CUPONS_DELETE_SELECT_REQUEST", })
    // //yield delay(200);
    // const result = yield call(DELETE_CUSTOM, { endpoint: `/cupoms/all`, data: action.payload })
    // if (result === false) {
    //     yield put({ type: "CUPONS_DELETE_SELECT_ERROR" })
    // } else {
    //     yield put({ type: "CUPONS_DELETE_SELECT_SUCCESS", payload: result.data })
    //     //yield put({ type: "CUPONS_CONTROL_SELECT_RESET" })
    // }
}