import styled from "styled-components";


export const Container = styled.div`

  display: flex ;
  background-color: #E5E7EB;
  width: 100%;
  min-height: 900px;
  flex-direction: column;

`

