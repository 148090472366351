import styled from 'styled-components';

export const CardWrapper = styled.div`
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  padding: 1.75rem;
  width: 100%;
  margin: 0 0 1.5rem 0;
  transition: all 0.2s ease;
  break-inside: avoid-column;
  page-break-inside: avoid;
  display: inline-block;
  font-family: ${props => props.font}, sans-serif;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Question = styled.h3`
  color: ${props => props.theme.text};
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
`;

export const Answer = styled.p`
  color: ${props => props.theme.textSecondary};
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  padding: 1.25rem;
  background: ${props => props.theme.backgroundItem};
  border-radius: 12px;
`;

export const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  padding-top: 1.25rem;
  border-top: 1px solid ${props => props.theme.border};
`;

export const UserName = styled.span`
  color: ${props => props.theme.text};
  font-size: 0.95rem;
  font-weight: 500;
`;

export const Date = styled.span`
  color: ${props => props.theme.textSecondary};
  font-size: 0.9rem;
  font-weight: 400;
`;
