import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.text};
  margin-bottom: 60px;



`

export const SelectScreen = styled.div`
  display: flex;
  padding-right: 20px;
  
`

export const BarConfig = styled.div`
  display: flex;
  padding: 8px 16px;
  background-color: #f9fafb;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  max-width: ${props => props.isMobile ? '360px' : '1280px'};

  border-right: 1px solid #e5e7eb;
  border-left: 1px solid #e5e7eb;
  border-top: 1px solid #e5e7eb;
  
`

export const BarConfigLeft = styled.div`
  display: flex;
  //background-color: red;
  gap: 4px;


  span {
    min-width: 8px;
    min-height: 8px;
    border-radius: 50%;
    background-color: ${props => props.color};
    
  }

  span:nth-child(1){
    background-color: #F66689;    
  }
  span:nth-child(2){
    background-color: #FFCE5B;    
  }
  span:nth-child(3){
    background-color: #56D990;    
  }
  
`

export const BarConfigRight = styled.div`
  display: flex;
  //background-color: blue;
  align-items: center;
  gap: 16px;

`

export const BarOptions = styled.div`
  display: flex;
  background-color: ${props => props.theme.cardBackground};
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 16px 0px;
`

export const Contents = styled.div`
    display: flex;
    //padding: 20px 20px;
    margin: 20px;
    border-radius: 8px; 
    flex-direction: column;
    align-items: center;
    //background-color: ${props => props.isMobile ? "transparent" : props.theme.background};

    //background-color: #E5E7EB;

    background-color: transparent;
`

export const Box = styled.div`
    display: flex;
    max-width: ${props => props.isMobile ? '360px' : '1280px'};
    width: 100%;
    flex-direction: column;
    background-color: ${props => props.theme.background};
    padding: 20px;
    border-radius: 0px 0px 8px 8px ;

    font-family: ${props => props.theme.font};

    border-right: 1px solid #e5e7eb;
    border-left: 1px solid #e5e7eb;
    border-bottom: 1px solid #e5e7eb;
`

export const BoxOptions = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  gap: 20px;
`;

export const Tabs = styled.div`
  display: flex;
  gap: 0px;
  width: ${props => props.isMobile ? '100%' : '320px'};
  //background-color: red;
  //background-color: yellow;
`;

export const Tab = styled.button`
  flex: 1;
  padding: 10px;
  //background-color: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.textSecondary};
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: ${props => props.theme.font};



  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.text};
  }

  &:hover {
    //background-color: ${({ theme }) => theme.border};
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
`;

export const Button = styled.button`
  //background-color: ${({ theme }) => theme.buttonBackground};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.text};
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  font-family: ${props => props.theme.font};

  &:hover {
    background-color: ${({ theme }) => theme.buttonBackground};
    color: ${({ theme }) => theme.buttonText};
  }
`;

export const BoxFilters = styled.div`
  display: flex;
  //align-items: center;
  //padding: 20px;
  //background-color: #fff;
  flex-direction: column;
  gap: 20px;
  
  //background-color: red;
`;

export const FilterSelect = styled.select`
    padding: 6px 40px;
    border: 1px solid ${props => props.theme.borderColor || '#ccc'};
    border-radius: 8px;
    color: ${props => props.theme.text || '#282828'};
    background-color: ${props => props.theme.background || '#fff'};
    font-size: 14px;
    cursor: pointer;
    width: ${props => props.isMobile ? '100%' : 'auto'};

    &:hover {
    //background-color: #f9f9f9;
    }
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: ${props => props.active ? props.theme.buttonBackground : 'transparent'};
  color: ${props => props.active ? props.theme.buttonText : props.theme.text};
  cursor: pointer;
  //margin-left: 10px;
  border-radius: 20px; 
  font-size: 14px;
  font-weight: 600; 
  font-family: ${props => props.theme.font};
  
 
  &:hover {
    background-color: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.buttonText};
  }

  svg {
    margin-right: 5px;
    width: 20px;
    height: 20px
  }

  label {
    margin-top: 1px;
    cursor: pointer;
  }
`;

export const BoxFiltersItems = styled.div`
  display: flex;
  //background-color: yellow;
  justify-content: space-between;
  align-items: start;
  //padding: 10px 15px;
  //border: 1px solid #ccc;
  border-radius: 8px;
  color: #282828;
  //background-color: #fff;
  cursor: pointer;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  gap: 20px;

//background-color: red;
`;


export const BoxFiltersItemsActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: ${props => props.isMobile ? 'start' : 'end'};
    //background-color: purple;

`;

export const BoxContents = styled.div`
  display: flex;
  gap: 40px;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  //background-color: red;

`;

export const BoxResume = styled.div`
  width: ${({ isMobile }) => isMobile ? '100%' : '320px'};
  //background-color: ${({ theme }) => theme.cardBackground};
  border-radius: 8px;
  padding: 20px;
  color: ${({ theme }) => theme.text};

  //background-color: red;

  .rating-summary {
    display: flex;
    width: 100%;
    gap: 20px;

    .average-rating {
      font-size: 72px;
      font-weight: 600;
      color: ${({ theme }) => theme.text};
    }

    .star-rating {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      align-items: left;
      gap: 5px;

      .star-count {
        font-size: 14px;
        color: ${({ theme }) => theme.textSecondary};
      }
    }
  }

  .rating-bars {
    margin-top: 0px;
    width: 100%;
  }
`;

export const StarRating = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const RatingBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: ${({ theme }) => theme.starInactive};
  border-radius: 5px;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 100%;
    background-color: ${({ theme }) => theme.starActive};
    width: ${props => props.percentage}%;
  }
`;

export const RatingRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.text};
  gap: 10px;
  margin-bottom: 4px;
`;

export const BoxList = styled.div`
  display: flex;
  //background-color: red;
  flex-direction: column;
  flex: 1;
  gap: 20px;
`;

export const BoxListReviews = styled.div`
  display: flex;
  flex-direction: column;
  //background-color: red;
`;
export const BoxListAsks = styled.div`
  display: flex;
  flex-direction: column;
  //background-color: red;
`;

export const ReviewContainer = styled.div`
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const CommentsSection = styled.div`
  label {
    font-size: 20px;
    font-weight: 600;
    color: ${({ theme }) => theme.text};
  }

  p {
    color: ${({ theme }) => theme.text};
    font-size: 14px;
    font-weight: 400;
  }
`;