import React, { useEffect, useState } from 'react'
import SideBar from '../../../empreenderUI/SideBar';
import ContentApp from '../../../empreenderUI/ContentApp';
import PerfilMenu from '../../../empreenderUI/ui/PerfilMenu';
import { Button } from '../../../empreenderUI/ui/Button';
import ItemMenu from '../../../empreenderUI/ui/ItemMenu';
import { AcademicCapIcon, ChatBubbleBottomCenterIcon, ChatBubbleLeftRightIcon, CreditCardIcon, HomeIcon, PaintBrushIcon, ShoppingBagIcon, ShoppingCartIcon, Square3Stack3DIcon, StarIcon, TagIcon, UserIcon } from '@heroicons/react/24/outline';
import ItemText from '../../../empreenderUI/ui/ItemText';
import ItemIcon from '../../../empreenderUI/ui/ItemIcon';
import Separator from '../../../empreenderUI/ui/Separator';
import { useHistory } from 'react-router-dom'
import ItemSub from '../../../empreenderUI/ItemSub';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../../empreenderUI/Content';
import BarLeftMenu from '../../../modules/thema/components/bar-left-menu';
import { getTranslation } from '../../../translations';

const LayoutUI = (props) => {

    const history = useHistory()
    const dispatch = useDispatch()

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language)

    let token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
    let tokenType = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.type;

    const reducerControlVisibleModalNotifications = useSelector(state => state.reducerControlVisibleModalNotifications)
    const reducerControlVisibleModalGoogle = useSelector(state => state.reducerControlVisibleModalGoogle)
    useEffect(() => {
        console.log("reducerControlVisibleModalGoogle", reducerControlVisibleModalGoogle)
    }, [reducerControlVisibleModalGoogle])


    const [isOpenMenu, setIsOpenMenu] = useState(localStorage.getItem("isOpenMenu") == null || localStorage.getItem("isOpenMenu") == undefined ? true : localStorage.getItem("isOpenMenu"))

    useEffect(() => {
        const menu = document.getElementById("sidebar_trigger");
        if (menu) { menu.addEventListener("click", (handleClick)) }
        return () => { if (menu) { menu.removeEventListener("click", handleClick); } };
    }, []);

    // Função que será chamada no clique
    const handleClick = (event) => {
        if (localStorage.getItem("isOpenMenu") == "true") {
            localStorage.setItem("isOpenMenu", "false")
            setIsOpenMenu(localStorage.getItem("isOpenMenu"))
        } else {
            localStorage.setItem("isOpenMenu", "true")
            setIsOpenMenu(localStorage.getItem("isOpenMenu"))
        }
    };

    return (
        <>
            <SideBar

                appEndPoint={process.env.REACT_APP_API_URL.replace(/^https?:\/\//, "")}
                //headers={{ 'Content-type': 'application/json', Authorization: 'blablabla' }}
                headers={{ "Content-Type": "application/json", Authorization: tokenType + " " + token, }}
                app={"Lily"}
                isOpen={isOpenMenu == "false" ? false : true}
                chave_empreender={JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}
            >

                <ItemMenu onClick={() => { history.push("/") }} isActive={window.location.pathname == "/" ? true : false}>
                    <ItemIcon>
                        <HomeIcon />
                    </ItemIcon>
                    <ItemText> {translation.sidebar.dashboard}</ItemText>
                </ItemMenu>

                <ItemMenu onClick={() => { history.push("/avaliacoes") }} isActive={window.location.pathname == "/avaliacoes" ? true : false}>
                    <ItemIcon>
                        <StarIcon />
                    </ItemIcon>
                    <ItemText> {translation.sidebar.avaliacoes}</ItemText>
                </ItemMenu>

                <ItemMenu onClick={() => { history.push("/perguntas") }} isActive={window.location.pathname == "/perguntas" ? true : false}>
                    <ItemIcon>
                        <ChatBubbleLeftRightIcon />
                    </ItemIcon>
                    <ItemText>{translation.sidebar.perguntas}</ItemText>
                </ItemMenu>

                <ItemMenu onClick={() => { history.push("/cupons") }} isActive={window.location.pathname == "/cupons" ? true : false}>
                    <ItemIcon>
                        <TagIcon />
                    </ItemIcon>
                    <ItemText>{translation.sidebar.cupons}</ItemText>
                </ItemMenu>

                <ItemMenu onClick={() => { history.push("/produtos") }} isActive={window.location.pathname == "/produtos" ? true : false}>
                    <ItemIcon>
                        <ShoppingBagIcon />
                    </ItemIcon>
                    <ItemText>{translation.sidebar.meusProdutos}</ItemText>
                </ItemMenu>

                <ItemMenu onClick={() => { history.push("/pedidos") }} isActive={window.location.pathname == "/pedidos" ? true : false}>
                    <ItemIcon>
                        <ShoppingCartIcon />
                    </ItemIcon>
                    <ItemText> {translation.sidebar.pedidos}</ItemText>
                </ItemMenu>

                <ItemMenu onClick={() => { history.push("/depoimentos") }} isActive={window.location.pathname == "/depoimentos" ? true : false}>
                    <ItemIcon>
                        <ChatBubbleBottomCenterIcon />
                    </ItemIcon>
                    <ItemText> {translation.sidebar.depoimentos}</ItemText>
                </ItemMenu>

                <ItemMenu onClick={() => { history.push("/passo-a-passo") }} isActive={window.location.pathname == "/passo-a-passo" ? true : false}>
                    <ItemIcon>
                        <AcademicCapIcon />
                    </ItemIcon>
                    <ItemText> {translation.sidebar.passoapasso}</ItemText>
                </ItemMenu>

                {/* <ItemMenu onClick={() => { history.push("/ajuda") }} isActive={window.location.pathname == "/ajuda" ? true : false}>
                    <ItemIcon>
                        <UserIcon />
                    </ItemIcon>
                    <ItemText>Ajuda</ItemText>
                </ItemMenu> */}

                <Separator>{translation.sidebar.configuracoes}</Separator>

                <ItemMenu

                    //onClick={() => { history.push("/personalizar") }} 
                    isActive={window.location.pathname.includes("/personalizar") ? true : false}>
                    <ItemIcon>
                        <PaintBrushIcon />
                    </ItemIcon>
                    <ItemText>{translation.sidebar.personalizar}</ItemText>

                    <ItemSub id='menu-personalizar' onClick={() => { history.push("/personalizar/tema") }}>Avaliações e Perguntas</ItemSub>
                    <ItemSub id='menu-personalizar' onClick={() => { history.push("/personalizar/home") }}>Página Inicial</ItemSub>
                    <ItemSub id='menu-personalizar' onClick={() => { history.push("/personalizar/avaliar") }}>Tela Avaliar</ItemSub>
                    <ItemSub id='menu-personalizar' onClick={() => { history.push("/personalizar/perguntar") }}>Tela Perguntar</ItemSub>
                    <ItemSub onClick={() => { history.push("/personalizar/email") }}>Template E-mail</ItemSub>
                    <ItemSub onClick={() => { history.push("/personalizar/email"); dispatch({ type: "CONFIG_CONTROL_VISIBLE_MODAL_EMAIL_SET", payload: true }) }}>Tempo E-mail</ItemSub>
                    <ItemSub id='menu-personalizar' onClick={() => { history.push("/personalizar/mural") }}>Mural</ItemSub>
                    <ItemSub onClick={() => { history.push("/personalizar/mural"); dispatch({ type: "CONFIG_CONTROL_VISIBLE_MODAL_GOOGLE_SET", payload: true }) }}>Google</ItemSub>
                    <ItemSub onClick={() => { history.push("/personalizar/tema"); dispatch({ type: "CONFIG_CONTROL_VISIBLE_MODAL_NOTIFICATIONS_SET", payload: true }) }}>Notificações</ItemSub>


                </ItemMenu>

                <ItemMenu onClick={() => { history.push("/integracoes") }} isActive={window.location.pathname == "/integracoes" ? true : false}>
                    <ItemIcon>
                        <Square3Stack3DIcon />
                    </ItemIcon>
                    <ItemText> {translation.sidebar.integracoes}</ItemText>
                </ItemMenu>

                <ItemMenu onClick={() => { history.push("/pagamentos") }} isActive={window.location.pathname == "/pagamentos" ? true : false}>
                    <ItemIcon>
                        <CreditCardIcon />
                    </ItemIcon>
                    <ItemText>{translation.sidebar.assinatura}</ItemText>
                </ItemMenu>

                <PerfilMenu>
                    <Button
                        onClick={() => {
                            try {
                                localStorage.removeItem("depoimentos@login");
                                window.location.href = 'https://app.lily.com.br/login'
                            } catch (error) { }
                        }}
                        className="text-gray-800 text-xs font-normal">{translation.sidebar.sair}</Button>
                </PerfilMenu>

                <ContentApp>
                    {window.location.pathname.includes("/personalizar") ?
                        <div style={{ padding: "0px" }}>
                            {props.children}
                        </div> :
                        <div style={{ padding: "20px 0px 60px 0px" }}>
                            {props.children}
                        </div>
                    }

                </ContentApp>


                {/* <ItemMenu id='menu-personalizar' onClick={() => {  }} isActive={window.location.pathname == "/menu" ? true : false}>
                    <ItemIcon>
                        <CreditCardIcon />
                    </ItemIcon>
                    <ItemText>3 menu</ItemText>
                </ItemMenu> */}
                <Content id='menu-personalizar'>
                    <BarLeftMenu />
                </Content>

            </SideBar >
        </>

    )
}

export default LayoutUI