import { StarIcon, CheckBadgeIcon } from "@heroicons/react/24/solid"
import { CardWrapper, Content, ReviewText, UserInfo, UserName, RatingContainer, MediaContainer, VideoThumbnail, UserHeader, DateText, MediaCount } from "./styled"
import { useSelector } from "react-redux"

const Review02 = ({ review, index, isMobile, configControl }) => {
    
    const renderMedia = () => {
        if (review.media && review.media.length > 0) {
            const firstMedia = review.media[0]
            const remainingCount = review.media.length - 1

            return (
                <MediaContainer hasMedia={true}>
                    {firstMedia.type === 'video' ? (
                        <VideoThumbnail>
                            <img 
                                src={firstMedia.thumbnail} 
                                alt="Video thumbnail" 
                            />
                            <div className="play-overlay">
                                <div className="play-icon">▶</div>
                            </div>
                        </VideoThumbnail>
                    ) : (
                        <img 
                            src={firstMedia.url} 
                            alt="Review media" 
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                    )}
                    {remainingCount > 0 && (
                        <MediaCount>+{remainingCount}</MediaCount>
                    )}
                </MediaContainer>
            )
        }
        return <MediaContainer hasMedia={false} />
    }

    const renderStars = () => {
        const stars = []
        for (let i = 0; i < review.rating; i++) {
            stars.push(
                <StarIcon 
                    key={i} 
                    style={{ 
                        color: JSON.parse(configControl?.layout_configs)?.starActive, 
                        width: "20px" 
                    }} 
                />
            )
        }
        return stars
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString)
        return date.toLocaleDateString('pt-BR', { 
            day: 'numeric',
            month: 'long',
            year: 'numeric'
        })
    }

    return (
        <CardWrapper font={configControl?.font} style={{ display: isMobile == true && index > 1 ? "none" : "block" }} key={review.id}>
            <Content>
                {renderMedia()}
                <RatingContainer>
                    {renderStars()}
                </RatingContainer>
                <ReviewText>{review.text}</ReviewText>
                <UserInfo>
                    <UserHeader>
                        <UserName>{review.user}</UserName>
                        <CheckBadgeIcon style={{ 
                            color: JSON.parse(configControl?.layout_configs)?.verified || '#4CAF50',
                            width: "20px",
                            marginLeft: "4px"
                        }} />
                    </UserHeader>
                    <DateText style={{ 
                        display: configControl?.visible_date_review == 1 ? "block" : "none" 
                    }}>
                        {formatDate(review.date)}
                    </DateText>
                </UserInfo>
            </Content>
        </CardWrapper>
    )
}

export default Review02