import { ChevronDownIcon, KeyIcon } from '@heroicons/react/24/outline';
import { useSubMenu } from '../SubMenuContext';
import { AnimatePresence, motion } from 'framer-motion';
import { DropdownMenu } from './DropDown/DropdownMenu';
import { DropdownMenuTrigger } from './DropDown/DropdownMenuTrigger';
import { DropdownMenuContent } from './DropDown/DropdownMenuContent';
import { useState, ReactNode, useEffect, useRef } from 'react';
import SkelletonLoader from './Skelleton';

interface PerfilMenuProps {
	children?: ReactNode;
	name?: string;
	imagem_perfil?: string;
	chave_empreender?: string;
}

const PerfilMenu = ({ children, name, chave_empreender, imagem_perfil }: PerfilMenuProps) => {
	const { isSidebarOpen, setChaveWidth } = useSubMenu();
	const [loading, setLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const chaveRef = useRef<HTMLSpanElement>(null);

	const handleOnOpen = (state: boolean) => {
		setIsOpen(state);
	};

	useEffect(() => {
		setLoading(name ? false : true);
	}, [name]);

	useEffect(() => {
		if (!chaveRef.current) return;

		const { width } = chaveRef.current.getBoundingClientRect();

		setChaveWidth?.(width + 72);
	}, [isOpen, chave_empreender, loading]);

	return (
		<DropdownMenu isOpen={isOpen} setIsOpen={handleOnOpen}>
			{loading ? (
				<DropdownMenuTrigger disabled>
					<div className={`group w-full text-left bg-white !p-1.5 ${isSidebarOpen ? 'group-hover/container:bg-gray-100 cursor-pointer' : ''} transition-all h-14 rounded-lg flex items-center flex-row overflow-hidden gap-x-3 group`}>
						<div>
							<SkelletonLoader width={`${isSidebarOpen ? '36px' : '24px'}`} className={`${isSidebarOpen ? 'h-9' : 'h-6'} transition-all`} />
						</div>

						<AnimatePresence mode='popLayout'>
							{isSidebarOpen ? (
								<>
									<motion.div initial={{ opacity: 0, x: 0 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.1 }} exit={{ opacity: 0, x: 0 }} className='w-full flex flex-row items-center justify-between text-xs text-gray-900 gap-0.5'>
										<div className='flex flex-col gap-0.5'>
											<SkelletonLoader width='150px' />
											<div className='flex flex-row items-center gap-0.5 text-gray-500'>
												<span className='size-3.5'>
													<SkelletonLoader width='14px' />
												</span>
												<span>
													<SkelletonLoader width='100px' />
												</span>
												<span className='transition-all ease-in-out size-3.5 invisible opacity-0 group-hover:opacity-100 group-hover:visible'>
													<SkelletonLoader width='14px' className='transition-all' />
												</span>
											</div>
										</div>
									</motion.div>
								</>
							) : null}
						</AnimatePresence>
					</div>
				</DropdownMenuTrigger>
			) : (
				<DropdownMenuTrigger>
					<div className={`group w-full text-left bg-white !p-1.5 ${isSidebarOpen ? 'group-hover/container:bg-gray-100 cursor-pointer' : ''} transition-all h-14 rounded-lg flex items-center flex-row overflow-hidden gap-x-3 group outline-none`}>
						<motion.div initial={{ width: 24, height: 24, borderRadius: 4 }} animate={{ width: isSidebarOpen ? 36 : 24, height: isSidebarOpen ? 36 : 24, borderRadius: isSidebarOpen ? 8 : 4 }} className='size-[24px] flex-shrink-0 overflow-hidden outline-none'>
							{imagem_perfil ? <img className='object-cover w-full h-full' src={imagem_perfil} /> : <div className='w-full h-full bg-gray-900 text-white flex items-center justify-center text-sm'>{name?.[0]}</div>}
						</motion.div>
						<AnimatePresence mode='popLayout'>
							{isSidebarOpen ? (
								<>
									<motion.div initial={{ opacity: 0, x: 0 }} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.1 }} exit={{ opacity: 0, x: 0 }} className='w-full flex flex-row items-center justify-between text-xs text-gray-900 gap-0.5'>
										<div>
											<span className='font-bold text-sm break-normal whitespace-nowrap'>{name}</span>
											<div className='flex flex-row items-center gap-0.5 text-gray-500'>
												<div className='size-3.5'>{chave_empreender ? <KeyIcon className='stroke-[2px]' /> : null}</div>
												<span className='break-normal whitespace-nowrap' ref={chaveRef}>
													{chave_empreender}
												</span>
											</div>
										</div>

										<motion.div className='size-3 text-gray-500' animate={{ rotate: isOpen ? 180 : 0 }} transition={{ duration: 0.25, delay: 0.1, ease: [0, 0.71, 0.2, 1.01] }}>
											<ChevronDownIcon className='stroke-[2.5px]' />
										</motion.div>
									</motion.div>
								</>
							) : null}
						</AnimatePresence>
					</div>
				</DropdownMenuTrigger>
			)}
			<DropdownMenuContent side={isSidebarOpen ? 'top' : 'right'} sideOffset={isSidebarOpen ? 0 : 20} verificarSide={true}>
				{children}
			</DropdownMenuContent>
		</DropdownMenu>
	);
};

export default PerfilMenu;
