import React, { useEffect, useState } from 'react'
import { BarOptions, Box, Container, Contents, SelectScreen, BoxOptions, Tabs, Tab, Buttons, Button, BoxFilters, FilterSelect, FilterButton, BoxFiltersItems, BoxFiltersItemsActions, BoxContents, BoxResume, BoxList, BoxListReviews, StarRating, RatingBar, RatingRow, BoxListAsks, CommentsSection, BarConfig, BarConfigLeft, BarConfigRight } from './styled'
import { ChatBubbleOvalLeftEllipsisIcon, CheckBadgeIcon, ChevronDownIcon, PhotoIcon, PlayIcon } from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../../../translations'
import { FaStar } from 'react-icons/fa'
import { ThemeProvider } from 'styled-components'
import Review01 from '../cards/review01'
import Ask01 from '../cards/ask01'
import { reviewsData, questionsData, temas } from './data';


const Geb = ({ tema = 'dark', cores }) => {

    const [optionSelected, setOptionSelected] = useState("reviews")
    const [isMobile, setIsMobile] = useState(false)
    const configControl = useSelector(state => state.reducerControlConfig)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    // Determina o tema atual baseado na prop tema
    //const temaAtual = tema === 'dark' || tema === 'light' ? temas[tema] : cores
    const temaAtual = configControl.layout_configs == "null" ? temas.light : JSON.parse(configControl.layout_configs)
    // Se for custom e não tiver cores definidas, usa o tema light como fallback
    const currentTheme = temaAtual || temas.light

    const [color_verified, set_color_verified] = useState(currentTheme.verified)
    const [color_star_active, set_color_star_active] = useState(currentTheme.starActive)
    const [color_star_inactive, set_color_star_inactive] = useState(currentTheme.starInactive)

    const tabs = [
        { name: 'Avaliações', href: '#', current: false },
        { name: 'Perguntas', href: '#', current: true },
        { name: 'Estrelas', href: '#', current: false },
        { name: 'Botão', href: '#', current: false },
    ]


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const [activeFilters, setActiveFilters] = useState([]);

    const toggleFilter = (filter) => {
        setActiveFilters((prev) =>
            prev.includes(filter)
                ? prev.filter((item) => item !== filter)
                : [...prev, filter]
        );
    };

    useEffect(() => {
        console.log(configControl)
        if (configControl.visible_button_review == 1 && configControl.visible_button_ask == 0) {
            setOptionSelected("reviews")
        } else if (configControl.visible_button_review == 0 && configControl.visible_button_ask == 1) {
            setOptionSelected("asks")
        }
    }, [configControl])



    return (

        <Container>
            <BarOptions>
                <div style={{ padding: "0px 20px" }}>

                    <div className="grid grid-cols-1 sm:hidden">
                        <select
                            defaultValue={tabs.find((tab) => tab.current).name}
                            aria-label="Select a tab"
                            className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-2 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600"
                        >
                            {tabs.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                        <ChevronDownIcon
                            aria-hidden="true"
                            className="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end fill-gray-500"
                        />
                    </div>
                    <div className="hidden sm:block">
                        <nav aria-label="Tabs" className="flex space-x-4">

                            {configControl.visible_button_review == 1 && (
                                <a
                                    style={{ cursor: "pointer" }}
                                    key={"tab.name"}
                                    onClick={() => { setOptionSelected("reviews") }}
                                    aria-current={optionSelected == "reviews" ? 'page' : undefined}
                                    className={classNames(
                                        optionSelected == "reviews" ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                        'rounded-md px-3 py-2 text-sm font-medium',
                                    )}
                                >
                                    {"Avaliações"}
                                </a>
                            )}

                            {configControl.visible_button_ask == 1 && (
                                <a
                                    style={{ cursor: "pointer" }}
                                    key={"perguntas"}
                                    onClick={() => { setOptionSelected("asks") }}
                                    aria-current={optionSelected == "asks" ? 'page' : undefined}
                                    className={classNames(
                                        optionSelected == "asks" ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                        'rounded-md px-3 py-2 text-sm font-medium',
                                    )}
                                >
                                    {"Perguntas"}
                                </a>)}
                        </nav>
                    </div>
                </div>

                {/* <SelectScreen>
                    <div className="flex my-4 gap-0 m-0">
                        <button
                            onClick={() => setIsMobile(true)}
                            className={classNames(
                                isMobile ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                'w-[40px] p-2 rounded-md text-sm font-medium flex items-center justify-center',
                            )}
                            type="button" data-headlessui-state="" style={{ border: "0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"></path></svg>
                        </button>
                        <button
                            onClick={() => setIsMobile(false)}
                            className={classNames(
                                !isMobile ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                                'w-[40px] p-2 rounded-md text-sm font-medium flex items-center justify-center',
                            )}
                            type="button" data-headlessui-state="" style={{ border: "0px" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"></path></svg>
                        </button>
                    </div>
                </SelectScreen> */}
            </BarOptions>


            <Contents isMobile={isMobile}>
                <BarConfig isMobile={isMobile}>
                    <BarConfigLeft>
                        <span />
                        <span />
                        <span />
                    </BarConfigLeft>
                    <BarConfigRight>
                        <div onClick={() => setIsMobile(false)} style={{ cursor: 'pointer' }}>
                            <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.0002 9V2.25C14.0002 1.85218 13.8422 1.47064 13.5609 1.18934C13.2796 0.908035 12.898 0.75 12.5002 0.75H3.5002C3.10237 0.75 2.72084 0.908035 2.43954 1.18934C2.15823 1.47064 2.0002 1.85218 2.0002 2.25V9M14.0002 9H2.0002M14.0002 9L14.9602 10.9125C15.018 11.0272 15.0454 11.1549 15.0397 11.2833C15.034 11.4116 14.9954 11.5364 14.9277 11.6455C14.8599 11.7547 14.7652 11.8446 14.6527 11.9067C14.5402 11.9688 14.4137 12.0009 14.2852 12H1.7152C1.58672 12.0009 1.46016 11.9688 1.34766 11.9067C1.23516 11.8446 1.14049 11.7547 1.07273 11.6455C1.00497 11.5364 0.966395 11.4116 0.960699 11.2833C0.955002 11.1549 0.982377 11.0272 1.0402 10.9125L2.0002 9" stroke={!isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>

                        <div onClick={() => setIsMobile(true)} style={{ cursor: 'pointer' }}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.6893 1.5H6.31066C5.63153 1.5 4.98022 1.76978 4.5 2.25C4.01978 2.73022 3.75 3.38153 3.75 4.06066V13.9393C3.75 14.6185 4.01978 15.2698 4.5 15.75C4.98022 16.2302 5.63153 16.5 6.31066 16.5H11.6893C12.3685 16.5 13.0198 16.2302 13.5 15.75C13.9802 15.2698 14.25 14.6185 14.25 13.9393V4.06066C14.25 3.38153 13.9802 2.73022 13.5 2.25C13.0198 1.76978 12.3685 1.5 11.6893 1.5Z" stroke={isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.2998 13.5H9.7998" stroke={isMobile ? '#111827' : '#6B7280'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </BarConfigRight>
                </BarConfig>
                <ThemeProvider theme={{ ...currentTheme, font: configControl?.font }}>
                    <Box isMobile={isMobile}>
                        <BoxOptions isMobile={isMobile}>
                            <Tabs isMobile={isMobile}>
                                {configControl?.visible_button_review == 1 && <Tab className={optionSelected === "reviews" ? "active" : ""} isMobile={isMobile} onClick={() => setOptionSelected("reviews")}>Avaliações</Tab>}
                                {configControl?.visible_button_ask == 1 && <Tab className={optionSelected === "asks" ? "active" : ""} isMobile={isMobile} onClick={() => setOptionSelected("asks")}>Perguntas</Tab>}
                            </Tabs>
                            <Buttons isMobile={isMobile}>
                                {configControl?.visible_button_ask == 1 && <Button>Fazer uma pergunta</Button>}
                                {configControl?.no_review_store == 0 && configControl?.visible_button_review == 1 && <Button>Fazer uma avaliação</Button>}
                            </Buttons>
                        </BoxOptions>
                        <BoxContents isMobile={isMobile}>
                            <BoxResume isMobile={isMobile}>
                                <div className="rating-summary">
                                    <div className="average-rating">4.7</div>
                                    <div className="star-rating">
                                        <StarRating>
                                            {[...Array(5)].map((_, i) => {
                                                const isActive = i < Math.floor(4.7);
                                                const isHalf = i === Math.floor(4.7) && 4.7 % 1 !== 0;
                                                return (
                                                    <span key={i} style={{ position: 'relative', display: 'inline-block' }}>
                                                        <FaStar
                                                            color={currentTheme.starInactive}
                                                            size={28}
                                                        />
                                                        {isActive && (
                                                            <FaStar
                                                                color={currentTheme.starActive}
                                                                size={28}
                                                                style={{ position: 'absolute', top: 0, left: 0 }}
                                                            />
                                                        )}
                                                        {isHalf && (
                                                            <FaStar
                                                                color={currentTheme.starActive}
                                                                size={28}
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    clipPath: 'inset(0 50% 0 0)'
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                );
                                            })}
                                        </StarRating>
                                        <div className="star-count">100 avaliações</div>
                                    </div>
                                </div>
                                <div className="rating-bars">
                                    {[5, 4, 3, 2, 1].map((rating, index) => (
                                        <RatingRow key={index}>
                                            <span>{rating}</span>
                                            <RatingBar percentage={rating === 5 ? 85 : rating === 4 ? 15 : 0} />
                                            <span>{rating === 5 ? 85 : rating === 4 ? 15 : 0}</span>
                                        </RatingRow>
                                    ))}
                                </div>
                            </BoxResume>
                            <BoxList>
                                {optionSelected === "reviews" && (
                                    <>
                                        <BoxFilters>
                                            <CommentsSection>
                                                <label>Avaliações com comentários</label>
                                                <p>10 comentários</p>
                                            </CommentsSection>
                                            <BoxFiltersItems isMobile={isMobile}>
                                                <FilterSelect isMobile={isMobile}>
                                                    <option>Mais recente</option>
                                                    <option>Mais antigas</option>
                                                    <option>Mais relevantes</option>
                                                </FilterSelect>
                                                <BoxFiltersItemsActions isMobile={isMobile}>
                                                    <FilterButton
                                                        active={activeFilters.includes('image')}
                                                        onClick={() => toggleFilter('image')}
                                                    >
                                                        <PhotoIcon />
                                                        <label>Com imagem</label>
                                                    </FilterButton>
                                                    <FilterButton
                                                        active={activeFilters.includes('video')}
                                                        onClick={() => toggleFilter('video')}
                                                    >
                                                        <PlayIcon />
                                                        <label>Com Vídeo</label>
                                                    </FilterButton>
                                                    <FilterButton
                                                        active={activeFilters.includes('verified')}
                                                        onClick={() => toggleFilter('verified')}
                                                    >
                                                        <CheckBadgeIcon />
                                                        <label>Compra verificada</label>
                                                    </FilterButton>
                                                </BoxFiltersItemsActions>
                                            </BoxFiltersItems>
                                        </BoxFilters>
                                        <BoxListReviews>
                                            {reviewsData.map((review, index) => (
                                                <Review01 review={review} currentTheme={currentTheme} index={index} key={index} />
                                            ))}
                                        </BoxListReviews>
                                    </>
                                )}

                                {optionSelected === "asks" && (
                                    <>
                                        <BoxFilters>
                                            <CommentsSection>
                                                <label>Perguntas sobre o produto</label>
                                                <p>10 perguntas</p>
                                            </CommentsSection>
                                            <BoxFiltersItems isMobile={isMobile}>
                                                <FilterSelect isMobile={isMobile}>
                                                    <option>Mais recente</option>
                                                    <option>Mais antigas</option>
                                                    <option>Mais relevantes</option>
                                                </FilterSelect>
                                                <BoxFiltersItemsActions isMobile={isMobile}>
                                                    <FilterButton
                                                        active={activeFilters.includes('image')}
                                                        onClick={() => toggleFilter('image')}
                                                    >
                                                        <ChatBubbleOvalLeftEllipsisIcon />
                                                        <label>Respondidas</label>
                                                    </FilterButton>
                                                </BoxFiltersItemsActions>
                                            </BoxFiltersItems>
                                        </BoxFilters>
                                        <BoxListAsks>
                                            {questionsData.map((question, index) => (
                                                <Ask01 ask={question} index={index} key={index} />
                                            ))}
                                        </BoxListAsks>
                                    </>
                                )}
                            </BoxList>
                        </BoxContents>
                    </Box>
                </ThemeProvider>
            </Contents>
        </Container>
    )
}

export default Geb;