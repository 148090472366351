import { ItemSelect } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { BoxButtons, ButtonHelp, ButtonReset, ButtonSave } from '../../bar-config-product-ajustes/styled';

import IconBossVideo from "../../../../../assets/imgs/boss/boss-video.svg";
import IconVideo from "../../../../../assets/imgs/icon-video.svg";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../../empreenderUI/ui/Select/Select';

const TabConfigDefault = ({ save, setDefault, setVisibleModalVideo }) => {

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const dispatch = useDispatch()

    const visibleMenu = (opc) => {
        switch (opc) {
            case "0":
                //set_visible_button_ask(1)
                dispatch({ type: "CONFIG_CONTROL_SET", payload: { visible_button_ask: 1, visible_button_review: 1 } })
                //set_visible_button_review(1)
                dispatch({ type: "CONFIG_CONTROL_ABA_SET", payload: "reviews" })

                break;
            case "1":
                //set_visible_button_ask(0)
                //set_visible_button_review(1)
                dispatch({ type: "CONFIG_CONTROL_SET", payload: { visible_button_ask: 0, visible_button_review: 1 } })
                dispatch({ type: "CONFIG_CONTROL_ABA_SET", payload: "reviews" })
                break;
            case "2":
                //set_visible_button_ask(1)
                //set_visible_button_review(0)
                dispatch({ type: "CONFIG_CONTROL_SET", payload: { visible_button_ask: 1, visible_button_review: 0 } })
                dispatch({ type: "CONFIG_CONTROL_ABA_SET", payload: "questions" })
                break;
            default:
                break;
        }
    }

    const getTextMenu = () => {
        if (configControl?.visible_button_ask == 1 && configControl?.visible_button_review == 1) return "Avaliações e perguntas"
        if (configControl?.visible_button_ask == 0 && configControl?.visible_button_review == 1) return "Somente avaliações"
        if (configControl?.visible_button_ask == 1 && configControl?.visible_button_review == 0) return "Somente perguntas"
    }



    return (
        <>
            <ItemSelect>
                <label>Função</label>
                <Select defaultValue={getTextMenu() == "Avaliações e perguntas" ? "0" : getTextMenu() == "Somente avaliações" ? "1" : "2"} onValueChange={(value) => { visibleMenu(value) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={getTextMenu()} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="0">Avaliações e perguntas</SelectItem>
                        <SelectItem value="1">Somente avaliações</SelectItem>
                        <SelectItem value="2">Somente perguntas</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Modelos de avaliações</label>
                <Select defaultValue={configControl?.layout} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.layout} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="lily">Modelo 01</SelectItem>
                        <SelectItem value="lily2">Modelo 02</SelectItem>
                        <SelectItem value="lily3">Modelo 03</SelectItem>
                        <SelectItem value="dropi">Modelo 04</SelectItem>
                        <SelectItem value="dropi2">Modelo 05</SelectItem>
                        <SelectItem value="dropi3">Modelo 06</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Modelos de perguntas</label>
                <Select defaultValue={configControl?.layout_question} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_question: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.layout_question} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="modelo1">Modelo 01</SelectItem>
                        <SelectItem value="modelo2">Modelo 02</SelectItem>
                        <SelectItem value="modelo3">Modelo 03</SelectItem>
                        <SelectItem value="modelo4">Modelo 04</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Fonte</label>
                <Select defaultValue={configControl?.font} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { font: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.font} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="Poppins">Poppins</SelectItem>
                        <SelectItem value="Courier">Courier</SelectItem>
                        <SelectItem value="Times">Times</SelectItem>
                        <SelectItem value="Roboto">Roboto</SelectItem>
                        <SelectItem value="Georgia">Georgia</SelectItem>
                        <SelectItem value="Oswald">Oswald</SelectItem>
                        <SelectItem value="Montserrat">Montserrat</SelectItem>
                        <SelectItem value="Arial">Arial</SelectItem>
                        <SelectItem value="DM Sans">DM Sans</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Botão avaliar</label>
                <Select defaultValue={"" + configControl?.no_review_store} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { no_review_store: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.no_review_store == 0 ? "Sim" : "Não"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="0">Sim</SelectItem>
                        <SelectItem value="1">Não</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Sem avaliações</label>
                <Select defaultValue={"" + configControl?.no_review_star} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { no_review_star: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.no_review_star == 0 ? "Mostrar estrelas" : "Não mostrar estrelas"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="0">Mostrar estrelas</SelectItem>
                        <SelectItem value="1">Não mostrar estrelas</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Datas</label>
                <Select defaultValue={"" + configControl?.visible_date_review} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { visible_date_review: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.visible_date_review == 0 ? "Mostrar" : "Não mostrar"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="1">Mostrar</SelectItem>
                        <SelectItem value="0">Não mostrar</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Resumo</label>
                <Select defaultValue={"" + configControl?.resume_reviews} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { resume_reviews: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.resume_reviews == 0 ? "Mostrar" : "Não mostrar"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="1">Mostrar</SelectItem>
                        <SelectItem value="0">Não mostrar</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Paginação</label>
                <Select defaultValue={"" + configControl?.itens_per_page} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { itens_per_page: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.itens_per_page + " itens"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="6">6 itens</SelectItem>
                        <SelectItem value="12">12 itens</SelectItem>
                        <SelectItem value="14">14 itens</SelectItem>
                        <SelectItem value="16">16 itens</SelectItem>
                        <SelectItem value="18">18 itens</SelectItem>
                        <SelectItem value="20">20 itens</SelectItem>
                        <SelectItem value="30">30 itens</SelectItem>
                        <SelectItem value="40">40 itens</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect >

            <ItemSelect>
                <label>Ordenar avaliações</label>
                <Select defaultValue={configControl?.order_reviews} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { order_reviews: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.order_reviews == "default" ? "Recentes" : configControl?.order_reviews == "positive" ? "Positivas" : configControl?.order_reviews} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="default">Recentes</SelectItem>
                        <SelectItem value="positive">Positivas</SelectItem>
                        <SelectItem value="tiktok,video,image">tiktok,video,image</SelectItem>
                        <SelectItem value="tiktok,image,video">tiktok,image,video</SelectItem>
                        <SelectItem value="video,image,tiktok">video,image,tiktok</SelectItem>
                        <SelectItem value="video,image,tiktok">video,image,tiktok</SelectItem>
                        <SelectItem value="image,video,tiktok">image,video,tiktok</SelectItem>
                        <SelectItem value="image,tiktok,video">image,tiktok,video</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Ordenar perguntas</label>
                <Select defaultValue={configControl?.order_asks} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { order_asks: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.order_asks == "default" ? "Recentes" : configControl?.order_reviews == "answered" ? "Respondidas" : "Pendentes"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="default">Recentes</SelectItem>
                        <SelectItem value="answered">Respondidas</SelectItem>
                        <SelectItem value="pending">Pendentes</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Posição das estrelas nos produtos</label>
                <Select defaultValue={configControl?.layout_stars} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { layout_stars: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.layout_stars == "center" ? "Centro" : "Esquerda"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="center">Centro</SelectItem>
                        <SelectItem value="left">Esquerda</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Posição das estrelas nas avaliações</label>
                <Select defaultValue={configControl?.position_star_review} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { position_star_review: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.position_star_review == "default" ? "Padrão" : configControl?.position_star_review == "left" ? "Esquerda" : configControl?.position_star_review == "center" ? "Centro" : "Direita"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="default">Padrão</SelectItem>
                        <SelectItem value="left">Esquerda</SelectItem>
                        <SelectItem value="center">Centro</SelectItem>
                        <SelectItem value="right">Direita</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Aprovação automática das avaliações</label>
                <Select defaultValue={"" + configControl?.auto_approve} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { auto_approve: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.auto_approve == "1" ? "Todas" : configControl?.auto_approve == "0" ? "Apenas manual" : `${configControl?.auto_approve} estrelas ou mais`} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="1">Todas</SelectItem>
                        <SelectItem value="5">5 estrelas</SelectItem>
                        <SelectItem value="4">4 estrelas ou mais</SelectItem>
                        <SelectItem value="3">3 estrelas ou mais</SelectItem>
                        <SelectItem value="2">2 estrelas ou mais</SelectItem>
                        <SelectItem value="0">Apenas manual</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Largura da borda</label>
                <Select defaultValue={configControl?.border_card_size} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { border_card_size: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.border_card_size?.replace("px", " px")} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="0px">0 px</SelectItem>
                        <SelectItem value="1px">1 px</SelectItem>
                        <SelectItem value="2px">2 px</SelectItem>
                        <SelectItem value="3px">3 px</SelectItem>
                        <SelectItem value="4px">4 px</SelectItem>
                        <SelectItem value="5px">5 px</SelectItem>
                        <SelectItem value="6px">6 px</SelectItem>
                        <SelectItem value="7px">7 px</SelectItem>
                        <SelectItem value="8px">8 px</SelectItem>
                        <SelectItem value="9px">9 px</SelectItem>
                        <SelectItem value="10px">10 px</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Arredondamento borda</label>
                <Select defaultValue={configControl?.border_card_radius} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { border_card_radius: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.border_card_radius == "none" ? "0 px" : configControl?.border_card_radius?.replace("px", " px")} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="none">0 px</SelectItem>
                        <SelectItem value="1px">1 px</SelectItem>
                        <SelectItem value="2px">2 px</SelectItem>
                        <SelectItem value="3px">3 px</SelectItem>
                        <SelectItem value="4px">4 px</SelectItem>
                        <SelectItem value="5px">5 px</SelectItem>
                        <SelectItem value="6px">6 px</SelectItem>
                        <SelectItem value="7px">7 px</SelectItem>
                        <SelectItem value="8px">8 px</SelectItem>
                        <SelectItem value="9px">9 px</SelectItem>
                        <SelectItem value="10px">10 px</SelectItem>
                        <SelectItem value="11px">11 px</SelectItem>
                        <SelectItem value="12px">12 px</SelectItem>
                        <SelectItem value="13px">13 px</SelectItem>
                        <SelectItem value="14px">14 px</SelectItem>
                        <SelectItem value="15px">15 px</SelectItem>
                        <SelectItem value="16px">16 px</SelectItem>
                        <SelectItem value="17px">17 px</SelectItem>
                        <SelectItem value="18px">18 px</SelectItem>
                        <SelectItem value="19px">19 px</SelectItem>
                        <SelectItem value="20px">20 px</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ItemSelect>
                <label>Idioma</label>
                <Select defaultValue={configControl?.script_public_language == "" || configControl?.script_public_language == null ? " " : configControl?.script_public_language} onValueChange={(value) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { script_public_language: value } }) }}>
                    <SelectTrigger className="w-[260px]">
                        <SelectValue placeholder={configControl?.script_public_language == "pt_br" ? "Português" : configControl?.script_public_language == "es" ? "Espanhol" : "Inglês"} />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value=" ">Padrão do app</SelectItem>
                        <SelectItem value="pt_br">Português</SelectItem>
                        <SelectItem value="es">Espanhol</SelectItem>
                        <SelectItem value="en">Inglês</SelectItem>
                    </SelectContent>
                </Select>
            </ItemSelect>

            <ButtonHelp onClick={() => setVisibleModalVideo(true)} style={{ minWidth: "250px" }}>
                <img width={20} src={IconBossVideo} />
                <label>{"Tutorial"}</label>
                <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
            </ButtonHelp>

            <BoxButtons style={{ minWidth: "250px" }}>
                <ButtonReset
                    onClick={() => setDefault()}
                >
                    <label>{"Restaurar"}</label>
                </ButtonReset>
                <ButtonSave
                    onClick={() => save()}
                >
                    {"Salvar"}
                </ButtonSave>
            </BoxButtons>

        </>

    )
}

export default TabConfigDefault