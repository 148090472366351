'use client';

import { createContext, ReactNode, useContext, useReducer, useRef } from 'react';

interface SubMenuState {
	subID?: string;
	menuID?: string;
	[id: string]: boolean | string | number | undefined;
	chaveWidth?: number | undefined;
}

type Action =
	| { type: 'CHAVEWIDTH'; id: number }
	| { type: 'TOGGLE'; id: string }
	| { type: 'CLOSE'; id: string }
	| { type: 'CLOSEALL' }
	| { type: 'TOGGLESUB'; idSub?: string }
	| { type: 'SETMENUID'; id: string }
	| { type: 'CLOSESUBS' }
	| { type: 'CLOSETHIRDMENU' }
	| { type: 'SETSUBID'; id: string }
	| { type: 'CLEARSUB' };

type HeadersType = {
	[key: string]: string;
};

interface SubMenuContextProps {
	state: SubMenuState;
	isSidebarOpen: boolean;
	subState?: SubMenuState;
	menuID?: string;
	appEndPoint?: string;
	headers?: HeadersType;
	toggle: (id: string) => void;
	close: (id: string) => void;
	closeAll?: () => void;
	setSideBarOpen: (toggle: boolean) => void;
	setMenuId?: (id: string) => void;
	toggleSub?: (toggleSub: string) => void;
	closeSubs?: () => void;
	closeThirdMenus?: () => void;
	setSubId?: (id: string) => void;
	clearSub?: () => void;
	setChaveWidth?: (id: number) => void;
	registerItemRef: (id: string, ref: HTMLElement | null) => void;
	scrollToItemMenu: (id: string, behavior?: ScrollBehavior) => void;
}

const SubMenuContext = createContext<SubMenuContextProps | undefined>(undefined);

const subMenuReducer = (state: SubMenuState, action: Action): SubMenuState => {
	switch (action.type) {
		case 'TOGGLE': {
			const { menuID } = state;
			return {
				menuID,
				[action.id]: !state[action.id],
			};
		}
		case 'CLOSE':
			return {
				...state,
				[action.id]: false,
			};
		case 'CLOSEALL':
			return {};
		case 'TOGGLESUB': {
			return {
				...state,
				subID: action.idSub,
			};
		}
		case 'CLOSESUBS': {
			return {
				...state,
				subID: undefined,
			};
		}
		case 'CLOSETHIRDMENU': {
			const { subID, menuID, subIdActive } = state;
			return { subID, menuID, subIdActive };
		}
		case 'SETMENUID': {
			return {
				...state,
				menuID: action.id,
			};
		}
		case 'SETSUBID': {
			return {
				...state,
				subIdActive: action.id,
			};
		}

		case 'CHAVEWIDTH': {
			return {
				...state,
				chaveWidth: action.id,
			};
		}

		case 'CLEARSUB': {
			return {
				...state,
				subIdActive: undefined,
			};
		}

		default:
			return state;
	}
};

interface SubMenuProviderProps {
	children: ReactNode;
	isSidebarOpen: boolean;
	setSideBarOpen: (toggle: boolean) => void;
	closeAll?: () => void;
	appEndPoint?: string;
	headers?: HeadersType;
}

export const SubMenuProvider = ({ children, isSidebarOpen, setSideBarOpen, appEndPoint, headers }: SubMenuProviderProps) => {
	const [state, dispatch] = useReducer(subMenuReducer, {});

	const itemRefs = useRef<Record<string, HTMLElement | null>>({});

	const registerItemRef = (id: string, ref: HTMLElement | null) => {
		itemRefs.current[id] = ref;
	};

	const scrollToItemMenu = (id: string, behavior: ScrollBehavior = 'smooth') => {
		const item = itemRefs.current[id];
		if (item) {
			item.scrollIntoView({ behavior, block: 'nearest', inline: 'start' });
		}
	};

	const toggle = (id: string) => {
		dispatch({ type: 'TOGGLE', id });
		if (isSidebarOpen) setSideBarOpen(false);
	};
	const close = (id: string) => dispatch({ type: 'CLOSE', id });
	const closeAll = () => dispatch({ type: 'CLOSEALL' });
	const toggleSub = (id: string) => dispatch({ type: 'TOGGLESUB', idSub: id });
	const setMenuId = (id: string) => dispatch({ type: 'SETMENUID', id });
	const closeSubs = () => dispatch({ type: 'CLOSESUBS' });
	const closeThirdMenus = () => dispatch({ type: 'CLOSETHIRDMENU' });
	const setSubId = (id: string) => dispatch({ type: 'SETSUBID', id });
	const clearSub = () => dispatch({ type: 'CLEARSUB' });
	const setChaveWidth = (id: number) => dispatch({ type: 'CHAVEWIDTH', id });

	return (
		<SubMenuContext.Provider
			value={{
				state,
				toggle,
				close,
				isSidebarOpen,
				setSideBarOpen: (toggle) => {
					setSideBarOpen(toggle);
					closeSubs();
					closeThirdMenus();
				},
				closeAll,
				toggleSub,
				setMenuId,
				closeSubs,
				closeThirdMenus,
				appEndPoint,
				headers,
				setSubId,
				clearSub,
				registerItemRef,
				scrollToItemMenu,
				setChaveWidth,
			}}>
			{children}
		</SubMenuContext.Provider>
	);
};

export const useSubMenu = () => {
	const context = useContext(SubMenuContext);

	if (!context) {
		throw new Error('useSubMenu must be used within a SubMenuProvider');
	}
	return context;
};
