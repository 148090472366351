import { useEffect, useState } from "react";
import { BoxButtons, BoxThemes, ButtonHelp, ButtonReset, ButtonSave, ItemColor, ItemTheme } from "./styled"
import { useDispatch, useSelector } from 'react-redux';
import IconBossVideo from "../../../../../../assets/imgs/boss/boss-video.svg"
import IconVideo from "../../../../../../assets/imgs/icon-video.svg"

const Colors = ({ save, setDefault, setVisibleModalVideo }) => {

  const dispatch = useDispatch()
  const configControl = useSelector(state => state.reducerControlConfig)

  const defaultConfigColors = {
    background: "#ffffff",
    text: "#282828",
    textSecondary: "#cbd5e1",
    starActive: "#282828",
    starInactive: "#cbd5e1",
    buttonBackground: "#282828",
    buttonText: "#ffffff",
  }

  useEffect(() => {
    console.log("configControl.screen_review_configs", configControl.screen_review_configs)
    if (configControl.screen_review_configs == null) {
      dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs: JSON.stringify(defaultConfigColors) } })
    }
  }, [configControl.screen_review_configs])

  return (
    <>
      {/* <BoxThemes>
        <ItemTheme
          onClick={() => { configControl.layout_widget == "dimona" && setTheme("light") }}
          className={getTheme() == "light" ? "active" : ""}
          background="#FFFFFF"
          textColor="#374151"
        >
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </ItemTheme>
        <ItemTheme
          onClick={() => { configControl.layout_widget == "dimona" && setTheme("dark") }}
          className={getTheme() == "dark" ? "active" : ""}
          background="#1F2937"
          textColor="#FFFFFF"
        >
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </ItemTheme>
      </BoxThemes> */}
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Fundo</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), background: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.screen_review_configs )?.background} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), background: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.screen_review_configs )?.background} />
        </span>
      </ItemColor>

      <ItemColor>
        <label style={{ fontSize: "12px", }}>Texto principal</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), text: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.screen_review_configs )?.text} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), text: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.screen_review_configs )?.text} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Texto auxiliar</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), textSecondary: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.screen_review_configs )?.textSecondary} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), textSecondary: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.screen_review_configs )?.textSecondary} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Estrela principal</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), starActive: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.screen_review_configs )?.starActive} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), starActive: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.screen_review_configs )?.starActive} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Estrela auxiliar</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), starInactive: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.screen_review_configs )?.starInactive} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), starInactive: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.screen_review_configs )?.starInactive} />
        </span>
      </ItemColor>
      <ItemColor>
        <label style={{ fontSize: "12px", }}>Fundo botão</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), buttonBackground: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.screen_review_configs )?.buttonBackground} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), buttonBackground: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.screen_review_configs )?.buttonBackground} />
        </span>
      </ItemColor>

      <ItemColor>
        <label style={{ fontSize: "12px", }}>Texto botão</label>
        <span>
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), buttonText: e.target.value }) } }) }} style={{ textTransform: "uppercase" }} className="border border-gray-200 px-3 py-1.5" type='text' value={JSON.parse(configControl.screen_review_configs )?.buttonText} />
          <input onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { screen_review_configs : JSON.stringify({ ...JSON.parse(configControl.screen_review_configs ), buttonText: e.target.value }) } }) }} type='color' value={JSON.parse(configControl.screen_review_configs )?.buttonText} />
        </span>
      </ItemColor>

      <ButtonHelp
        onClick={() => setVisibleModalVideo(true)}
        style={{ minWidth: "250px" }}>
        <img width={20} src={IconBossVideo} />
        <label>{"Precisa de ajuda?"}</label>
        <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
      </ButtonHelp>

      <BoxButtons style={{ minWidth: "250px" }}>
        <ButtonReset onClick={() => setDefault()}>
          <label>{"Restaurar"}</label>
        </ButtonReset>
        <ButtonSave onClick={() => save()}>
          {"Salvar"}
        </ButtonSave>
      </BoxButtons>

    </>
  )
}

export default Colors