import styled from 'styled-components';

export const CardWrapper = styled.div`
  background: ${props => props.theme.cardBackground};
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);
  padding: 1.75rem;
  width: 100%;
  margin: 0 0 1.5rem 0;
  transition: all 0.2s ease;
  font-family: ${props => props.font}, sans-serif;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.12);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  .header-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .review-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const MediaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
`;

export const MediaContainer = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 8px;
  overflow: hidden;
  background: ${props => props.theme.background};
`;

export const VideoThumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    transition: background 0.2s ease;
  }

  &:hover .play-icon {
    background: rgba(0, 0, 0, 0.9);
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  gap: 4px;
`;

export const ReviewText = styled.p`
  color: ${props => props.theme.text};
  font-size: 1.1rem;
  line-height: 1.6;
  margin: 0;
  font-style: italic;
`;

export const UserHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const UserName = styled.h3`
  margin: 0;
  color: ${props => props.theme.text};
  font-size: 1.3rem;
  font-weight: 600;
`;

export const DateText = styled.span`
  color: ${props => props.theme.textSecondary};
  font-size: 0.9rem;
`;

export const UserInfo = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`;
